import { NgModule } from '@angular/core';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faDatabase, faSearch, faFileExcel, faHourglassHalf, faEuroSign, faQuestion } from '@fortawesome/free-solid-svg-icons';

@NgModule({
    imports: [
        FontAwesomeModule
    ],
    exports: [
        FontAwesomeModule
    ]
})
export class FaModule {
    constructor(private library: FaIconLibrary) {
        library.addIcons(faDatabase);
        library.addIcons(faSearch);
        library.addIcons(faFileExcel);
        library.addIcons(faHourglassHalf);
        library.addIcons(faEuroSign);
        library.addIcons(faQuestion);
    }
}
