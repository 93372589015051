import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective, NgForm, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';

import { AuthService } from '@services/auth.service';
import { UiService } from '@services/ui.service';
import { DateTime } from 'luxon';

class ConfirmValidMatcher implements ErrorStateMatcher {
    isErrorState(control: UntypedFormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.touched && form.hasError('mismatch');
    }
}

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    passwordForm: UntypedFormGroup;
    isWelcome: boolean;
    isExpired: boolean;
    isLoading = false;
    hideNewPassword = true;
    hideConfirmPassword = true;

    confirmValidMatcher = new ConfirmValidMatcher();

    token: string;
    id: string;

    constructor(
        private authSrv: AuthService,
        private fb: UntypedFormBuilder,
        private route: ActivatedRoute,
        private uiSrv: UiService
    ) { }

    ngOnInit() {
        this.checkRouteParams();
        this.initForm();
    }

    onSubmit(): void {
        this.isLoading = true;

        if (this.isWelcome) {
            this.authSrv.resetPassword(this.id, this.token, this.passwordForm.value.newPassword).subscribe();
        } else {
            this.authSrv.updatePassword(this.passwordForm.value).subscribe();
        }
    }

    toggleNewPasswordVisibility(event: MouseEvent): void {
        event.stopPropagation();
        this.hideNewPassword = !this.hideNewPassword;
    }

    toggleConfirmPasswordVisibility(event: MouseEvent): void {
        event.stopPropagation();
        this.hideConfirmPassword = !this.hideConfirmPassword;
    }

    private checkRouteParams(): void {
        this.token = this.route.snapshot.queryParams.token;
        this.id = this.route.snapshot.queryParams.id;

        const exp = this.route.snapshot.queryParams.exp;
        const username = this.route.snapshot.queryParams.user;
        const expDate = DateTime.fromFormat(exp, "MM/dd/yyyy hh:mm:ss ZZ");
        const now = DateTime.now();

        if (this.token && this.id && exp && username) {
            // Logout if user is already logged in with another account to avoid confusion
            if (this.authSrv.getDecodedToken()) {
                localStorage.clear();
                this.authSrv.authChanged.next(false);
            }

            if (expDate < now) {
                this.isExpired = true;
                this.authSrv.requestPasswordInit(username).subscribe();
            } else {
                this.isWelcome = true;
            }
        }
    }

    private initForm(): void {
        // const regEx = new RegExp()
        this.passwordForm = this.fb.group({
            newPassword: ['', [Validators.required, this.passwordValidator(/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[a-zA-Z\d@$!%*?&]{12,}$/)]],
            confirmPassword: ['', Validators.required]
        }, { validators: this.passwordMatchValidator });

        if (!this.isWelcome) {
            this.passwordForm.addControl('currentPassword', this.fb.control('', [Validators.required]));
        }
    }

    // Check if password repect password rule
    private passwordValidator(passwordRe: RegExp): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const allowed = passwordRe.test(control.value);
            return allowed ? null : { forbiddenPassword: true };
        }
    }

    // Check if password and confirmPassword matches
    private passwordMatchValidator(control: UntypedFormGroup): ValidationErrors | null {
        const newPassword = control.get('newPassword');
        const confirmPassword = control.get('confirmPassword');

        return newPassword.value !== confirmPassword.value ? { mismatch: true } : null;
    }
}
