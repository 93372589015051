<div class="events-loading"
     *ngIf="isLoading">
    <mat-spinner [diameter]="96"></mat-spinner>
</div>
<app-events-create *ngIf="isCreating"
                   [minMonth]="minMonth"
                   [indexValue]="indexValue"
                   (eventsDone)="onEventsAdded()"></app-events-create>
<ng-container *ngIf="!isCreating">
    <app-events-toolbar (addEvents)="addEvents()"
                        (editMultiple)="toggleEditMultiple($event)"
                        (cancelEdits)="onCancelEdits($event)"
                        (displayCreated)="onDisplayCreatedEvents($event)"
                        (displayDeleted)="onDisplayDeletedEvents($event)"
                        (filter)="onFilterChange($event)"
                        [isEditingMultiple]="isEditingMultiple"
                        [sortingString]="sortingString"
                        [mode]="mode"
                        [baseFilter]="baseFilter"
                        [minMonth]="minMonth"
                        [maxMonth]="maxMonth"
                        [isLoading]="isLoading"
                        [indexValue]="indexValue"></app-events-toolbar>

    <ng-container *ngIf="!isEditingMultiple && !isCreatedDisplayed">
        <table mat-table
               [dataSource]="dataSource"
               multiTemplateDataRows
               matSort
               (matSortChange)="onSortChange($event)"
               class="events">

            <ng-container matColumnDef="agent">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.agent.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    <div>
                        <!-- <mat-checkbox></mat-checkbox> -->
                        <button mat-icon-button
                                color="accent"
                                (click)="expandEvent(event)">
                            <mat-icon>
                                {{event === selectedEvent ? 'expand_less' : 'expand_more'}}
                            </mat-icon>
                        </button>
                        <span *ngIf="event.agent"> {{event.agent.display}} </span>
                        <!-- <span *ngIf="event.virtualAgent"> {{event.virtualAgent.display}} </span> -->
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="eventType">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.eventType.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    {{event.eventType.text.fullLabel}}
                </td>
            </ng-container>

            <ng-container matColumnDef="newProfessionalStatus">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.professionalStatus.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    {{event.newProfessionalStatus?.code}}
                </td>
            </ng-container>

            <ng-container matColumnDef="eventMonth">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.eventMonth.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    {{event.eventMonth | date : 'MM/yyyy'}}
                </td>
            </ng-container>

            <ng-container matColumnDef="newFte">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.fte.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    {{event?.newFte >= 0 ? event.newFte : ''}}
                </td>
            </ng-container>

            <ng-container matColumnDef="scale">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.scales.fullLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    <span> {{event.newScalePayment?.id > 0 ? event.newScalePayment?.externalId : ''}} </span>
                    <span *ngIf="event.newParallelScalePayment && event.newParallelScalePayment?.id > 0"> {{'{'}}
                        {{event.newParallelScalePayment?.externalId}} {{'}'}} </span>
                    <span *ngIf="event.newSuperiorScalePayment && event.newSuperiorScalePayment?.id > 0"> {{'['}}
                        {{event.newSuperiorScalePayment?.externalId}} {{']'}} </span>
                    <span *ngIf="event.newSeniority >= 0 && event.newSeniority !== null"> {{'('}} {{event.newSeniority}}
                        {{')'}}
                    </span>
                </td>
            </ng-container>

            <ng-container matColumnDef="strategyId"
                          *ngIf="mode !== eventsTableMode.provider && mode !== eventsTableMode.realized">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.eventOrigin.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event;">
                    {{event.origin?.strategy.text.shortLabel}}
                    <ng-container *ngIf="event.origin?.label">({{event.origin?.label}})</ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="salaryBase">
                <th mat-header-cell
                    *matHeaderCellDef
                    mat-sort-header>
                    {{'scenarios.events.properties.salaryBase.shortLabel' | translate}}
                </th>
                <td mat-cell
                    *matCellDef="let event">
                    <ng-container *ngIf="event.newSalaryBase">
                        <span *ngIf="(indexValue || event.indexValue) && event.fteContractual > 0; else rawSalary">
                            {{event.newSalaryBase | formatSalarayBase: indexValue ? indexValue : event.indexValue
                            :event.fteContractual}}
                        </span>
                        <ng-template #rawSalary>
                            <span [matTooltip]="'shared.rawSalary.summary' | translate">
                                {{event.newSalaryBase}}€
                                <span style="color:red">*</span>
                            </span>
                        </ng-template>
                    </ng-container>
                </td>
            </ng-container>

            <ng-container matColumnDef="actions">
                <th mat-header-cell
                    *matHeaderCellDef></th>
                <td mat-cell
                    *matCellDef="let event;">
                    <div>
                        <ng-container
                                      *ngIf="mode !== eventsTableMode.provider && mode !== eventsTableMode.realized && mode !== eventsTableMode.restricted && mode !== eventsTableMode.agent">
                            <button mat-icon-button
                                    color="accent"
                                    onclick="this.blur()"
                                    [disabled]="event.deleted"
                                    (click)="editEvent(event)"
                                    *ngIf="appRights.scenario.canEditEvents">
                                <mat-icon *ngIf="event !== editedEvent"
                                          [matTooltip]="'scenarios.events.list.actions.edit.summary' | translate"
                                          aria-label="Edit">mode_edit</mat-icon>
                                <mat-icon *ngIf="event === editedEvent && isEditing"
                                          [matTooltip]="'scenarios.events.list.actions.stopEdit.summary' | translate"
                                          aria-label="Close">close</mat-icon>
                            </button>

                            <button mat-icon-button
                                    color="accent"
                                    onclick="this.blur()"
                                    [disabled]="!event.oldEvent"
                                    [matTooltip]="'scenarios.events.list.actions.reset.summary' | translate"
                                    (click)="resetEvent(event)"
                                    *ngIf="appRights.scenario.canEditEvents">
                                <mat-icon>refresh</mat-icon>
                            </button>

                            <button mat-icon-button
                                    color="accent"
                                    onclick="this.blur()"
                                    [matTooltip]="'scenarios.events.list.actions.delete.summary' | translate"
                                    [disabled]="event.deleted"
                                    (click)="onDelete(event)"
                                    *ngIf="appRights.scenario.canDeleteEvents">
                                <mat-icon aria-label="Delete">delete</mat-icon>
                            </button>

                        </ng-container>
                        <button *ngIf="event.userComment"
                                mat-icon-button
                                color="accent"
                                [disableRipple]="true"
                                [matTooltip]="event.userComment">
                            <mat-icon>comment</mat-icon>
                        </button>

                        <button *ngIf="event.systemComment"
                                mat-icon-button
                                color="accent"
                                [disableRipple]="true"
                                [matTooltip]="event.systemComment">
                            <mat-icon>build</mat-icon>
                        </button>

                        <button *ngIf="event.manuallyModified"
                                mat-icon-button
                                color="accent"
                                [disableRipple]="true"
                                [matTooltip]="'scenarios.events.list.actions.manuallyModified.summary' | translate">
                            <mat-icon>accessibility</mat-icon>
                        </button>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="tags">
                <td mat-cell
                    *matCellDef="let event"
                    [attr.colSpan]="displayedColumns.length">
                    <div class="tags">
                        <div *ngFor="let tag of event.userTags"
                             class="tag"
                             [style.backgroundColor]="tag.color">
                            <mat-icon [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                      *ngIf="tag.type.id === 2">grid_on</mat-icon>
                            <mat-icon [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                      *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                            <span> {{tag.name}} </span>
                        </div>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="expandedDetail">
                <td mat-cell
                    *matCellDef="let event"
                    [attr.colSpan]="displayedColumns.length">
                    <div class="event-expanded"
                         *ngIf="event === selectedEvent || event === editedEvent"
                         @expandCollapse>
                        <app-event-detail [event]="event"
                                          [isEdited]="isEditing"
                                          [mode]="mode"
                                          [indexValue]="indexValue"
                                          (editedEvent)="onEditEvent($event)"
                                          (deleteContractEvents)="deleteContractEvents($event)"></app-event-detail>
                    </div>
                </td>
            </ng-container>

            <tr mat-header-row
                *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row
                *matRowDef="let event; columns: ['tags']"
                class="tags-events"
                [ngClass]="{'expanded-row': selectedEvent === event || event === editedEvent, 'no-tags': !event.userTags?.length}">
            </tr>
            <tr mat-row
                *matRowDef="let event; columns: displayedColumns"
                class="row-event"
                [class.expanded-row]="selectedEvent === event || event === editedEvent"
                [ngClass]="{'edited': event.oldEvent, 'deleted': event.deleted}"></tr>
            <tr mat-row
                *matRowDef="let event; columns: ['expandedDetail']"
                class="detail-event"></tr>
        </table>
        <mat-paginator *ngIf="pagination"
                       [pageIndex]="pagination.pageNumber - 1"
                       [pageSize]="pagination.pageSize"
                       [length]="pagination.totalRecords"
                       [hidePageSize]="true"
                       [showFirstLastButtons]="true"
                       [disabled]="isLoading"
                       (page)="onPagechange($event)"></mat-paginator>
    </ng-container>

    <ng-container *ngIf="isCreatedDisplayed">
        <app-events-table [isCreated]="true"
                          [indexValue]="indexValue"></app-events-table>
    </ng-container>

    <ng-container *ngIf="isEditingMultiple">
        <app-event-edit-multiple [mode]="mode"
                                 [minMonth]="minMonth"
                                 [maxMonth]="maxMonth"
                                 [indexValue]="indexValue"></app-event-edit-multiple>
    </ng-container>
</ng-container>