import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { environment } from '@environments/environment';

import { UserTag } from '@classes/parameters/user-tag';
import { UserTagType } from '@interfaces/parameters/user-tag-type';
import { map } from 'rxjs/operators';
import { AppRightsService } from './app-rights.service';

@Injectable({
    providedIn: 'root'
})
export class UserTagService {
    private baseUrl = environment.apiUrl + 'user-tag';

    userTagsSubject = new BehaviorSubject<UserTag[]>([]);

    constructor(
        private http: HttpClient,
        private appRightsSrv: AppRightsService 
    ) { }

    get(): Observable<UserTag[]> {
        return this.http.get<UserTag[]>(this.baseUrl)
            .pipe(
                map((userTags: UserTag[]) => {
                    if (userTags?.length) {
                        let tags = userTags.map(tag => UserTag.fromObject(tag));
                        return tags;
                    }
                    return [];
                })
            );
    }

    post(tag: UserTag): Observable<UserTag> {
        return this.http.post<UserTag>(this.baseUrl, tag);
    }

    put(tag: UserTag): Observable<UserTag> {
        return this.http.put<UserTag>(this.baseUrl, tag);
    }

    delete(tag: UserTag): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/${tag.id}`);
    }

    getType(): Observable<UserTagType[]> {
        if (this.appRightsSrv.appRights.tagsManagement.canAddTag)
            return this.http.get<UserTagType[]>(`${this.baseUrl}-type`);
        return of([])
    }

    /**
     * 
     * @param tagIds the ids of the tags to check
     * @param tagType the type of tag to check for (1 = Libre, 2 = Rapport, 3 = Estimation)
     * @returns true if there is at least one tag with the given type in tagIds
     */
    hasTagType(tagIds: number[], tagType: number): boolean {
        return tagIds.some(tagId => this.userTagsSubject.value.find(tag => tag.id === tagId && tag.type.id === 3));
    }
}
