<div class="auto-complete"
     *ngIf="control">
    <mat-form-field class="operator"
                    *ngIf="operator">
        <mat-select [formControl]="operator">
            <mat-option *ngFor="let operator of operators"
                        [value]="operator.value"> {{operator.label}} </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <input type="text"
               matInput
               appSelectOnTab
               [formControl]="control"
               [matAutocomplete]="auto"
               (focus)="onAutoCompleteFocus()"
               [required]="required">
        <mat-label>{{ label }} <ng-container *ngIf="number > 1">{{number + 1}}</ng-container>
        </mat-label>

        <mat-autocomplete #auto="matAutocomplete"
                          [displayWith]="displayFn"
                          [panelWidth]="'fit-content'">
            <mat-option *ngFor="let item of filteredList"
                        [value]="item"
                        [matTooltip]="tooltipProperty ? item[tooltipProperty] : ''"
                        [matTooltipPosition]="'after'">
                {{ item.display }}
            </mat-option>
        </mat-autocomplete>
        <mat-error>{{ errorMsg }}</mat-error>
    </mat-form-field>
</div>