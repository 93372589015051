<header>
    <mat-icon>person_outline</mat-icon>
    <h2>{{'agent.agentDetail.selectedAgentDetails.fullLabel' | translate}}</h2>
</header>
<section class="content">
    <mat-list>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.lastName.fullLabel' | translate}}</span> {{agent.lastName}}
        </mat-list-item>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.firstName.fullLabel' | translate}}</span> {{agent.firstName}}
        </mat-list-item>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.matricule.fullLabel' | translate}}</span> {{agent.matricule}}
        </mat-list-item>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.gender.fullLabel' | translate}}</span> {{agent.gender}}
        </mat-list-item>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.birthDate.fullLabel' | translate}}</span> {{agent.birthDate | date: dateFormat }}
        </mat-list-item>
    </mat-list>
</section>
