<form [formGroup]="articleForm" class="functional-article" (ngSubmit)="addToRepartition()">
    <mat-form-field class="article">
        <mat-label> {{'scenarios.events.properties.article.shortLabel' | translate}} </mat-label>
        <input appSelectOnTab type="text" matInput formControlName="article" [matAutocomplete]="article" (focus)="onAutoCompleteFocus()">
        <mat-autocomplete #article="matAutocomplete" [displayWith]="displayArticle" [panelWidth]="280">
            <mat-option *ngFor="let article of filteredArticles" [value]="article">
                {{article.code}}
            </mat-option>
        </mat-autocomplete>
        <mat-error> {{'scenarios.events.error.noSelectedValue.summary' | translate}} </mat-error>
    </mat-form-field>
    <div class="ratio">
        <app-input-number [label]="'scenarios.events.properties.ratio.shortLabel' | translate" 
            [hint]="'scenarios.events.properties.ratioBetween.fullLabel' | translate: {min: 0, max: 1}"
            [group]="articleForm" [controlName]="'ratio'"
            [min]="0" [max]="1" [step]="0.1"></app-input-number>
    </div>
    <!-- <mat-form-field class="ratio">
        <mat-label> {{'scenarios.events.properties.ratio.shortLabel' | translate}} </mat-label>
        <input type="number" matInput formControlName="ratio" [min]="0" [max]="1" [step]="0.1" [required]="required">
    </mat-form-field> -->
    <button type="submit" mat-icon-button color="accent" [matTooltip]="'scenarios.events.properties.addRatio.fullLabel' | translate">
        <mat-icon aria-label="Add">add</mat-icon>*
    </button>
</form>
<ul *ngIf="!dense">
    <li *ngFor="let article of articlesRepartition; index as i">
        <span> {{'scenarios.events.properties.article.shortLabel' | translate}} : {{article.ArticleFunctionalCode}} | {{'scenarios.events.properties.ratio.shortLabel' | translate}} : {{article.Ratio}} </span>
        <button mat-icon-button color="accent" (click)="deleteFromRepartition(i)">
            <mat-icon aria-label="Delete">delete</mat-icon>
        </button>
    </li>
</ul>
<span class="values-to-submit" *ngIf="valuesToSubmit">Cliquez sur le bouton "+" pour ajouter la répartition d'article</span>