import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
    template: ''
})
export class SubscriptionBaseComponent implements OnDestroy {

  subscription: Subscription = new Subscription();
  interval;

  constructor() { }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    clearInterval(this.interval);
  }

}
