import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';

import { Language } from '@classes/language';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    private baseUrl = environment.apiUrl + 'language';
    private languagesSubject = new BehaviorSubject<Language[]>([]);

    constructor(
        private http: HttpClient,
        private translateSrv: TranslateService,
        private uiSrv: UiService
    ) { }

    get languages$(): Observable<Language[]> {
        return this.languagesSubject.asObservable();
    }

    set languages(languages: Language[]) {
        this.languagesSubject.next(languages)
    }

    getLanguages(): Observable<Language[]> {
        return this.http.get<Language[]>(this.baseUrl)
            .pipe(
                map(languagesFromApi => this.languages = languagesFromApi)
            );
    }

    setCurrentLanguage(lang?: string): void {
        const selLanguage: string = lang || '';

        this.uiSrv.translationLoadingStateChanged.next(true);

        this.translateSrv.reloadLang(selLanguage)
            .subscribe(() => {
                this.translateSrv.use(selLanguage).subscribe();
                this.uiSrv.translationLoadingStateChanged.next(false);
            });
    }
}
