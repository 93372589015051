export interface SelectedFilter {
    id: number;
    parameterValue?: any;
}

export class ScenariosToReconcile {
    destinationScenario: {
        id: number
    };
    sourceScenario: {
        id: number
    };
    selectedFilters: SelectedFilter[];

    constructor(sourceScenario: number, destinationScenario: number, selectedFilters?: SelectedFilter[]) {
        this.sourceScenario = {
            id: sourceScenario
        };
        this.destinationScenario = {
            id: destinationScenario
        };
        this.selectedFilters = selectedFilters;
    }
}
