import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';
import { ReconciliationService } from '@services/reconciliation.service';
import { UiService } from '@services/ui.service';

import { FilterEvent } from '@interfaces/scenario/anti-strategies/filterEvent';

import { EventsTableMode } from '@enums/events-table-mode.enum';

import { Agent } from '@classes/agent/agent';
import { AppRights } from '@classes/app-rights';
import { EventType } from '@classes/scenario/event-type';
import { ThotEvent } from '@classes/scenario/thot-event';
import { AppRightsService } from '@services/app-rights.service';
import { thotAnimations } from 'app/shared/animations/thot-animations';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-events-table',
    templateUrl: './events-table.component.html',
    styleUrls: ['./events-table.component.scss'],
    animations: thotAnimations
})
export class EventsTableComponent extends SubscriptionBaseComponent implements OnInit, OnChanges {
    @Input() events: ThotEvent[];
    @Input() filterEvents: FilterEvent[];
    @Input() invalidEvents: ThotEvent[]; // only when on invalid events page
    @Input() restrictedMode: boolean;
    @Input() isPreview: boolean; // true only when on events preview page
    @Input() isCreated: boolean;
    @Input() isScenarioEvents: boolean; // true only when on invalid events page
    @Input() isReconciliation: boolean; // true only when on invalid events page
    @Input() isInvalidEvents: boolean; // true only when on invalid events page
    @Input() isEditMultiple: boolean; // true only when editing multiple events
    @Input() mode: EventsTableMode;
    @Input() indexValue: number;
    @Input() disableReconciliarionFilter: boolean; // true when reconciliation is finished to disable ability to exclude or not events from reconciliation
    @Output() eventEdited = new EventEmitter<ThotEvent>();
    @Output() resetedEvent = new EventEmitter<ThotEvent[]>();
    @Output() eventSelected = new EventEmitter<ThotEvent[]>();
    @Output() displayDeleted = new EventEmitter<boolean>();
    @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    appRights: Partial<AppRights>

    eventTypes: EventType[];

    dataSource: MatTableDataSource<ThotEvent>;
    displayedColumns = [
        'checkbox',
        'agent',
        'eventType',
        'status',
        'eventMonth',
        'newFte',
        'scale',
        'origin',
        'salaryBase',
        'actions'
    ];
    reconciliationDisplayedColumns = [
        'checkbox',
        'agent',
        'eventType',
        'status',
        'initialEventMonth',
        'eventMonth',
        'newFte',
        'scale',
        'initialEventOrigin',
        'salaryBase',
        'actions'
    ];

    isEditing: boolean;
    selectedEvent: ThotEvent;
    editedEvent: ThotEvent;

    editedEvents: ThotEvent[] = [];
    createdEvents: ThotEvent[] = [];
    editedCreatedEvents: ThotEvent[] = [];

    editedEventsCount: number;
    deletedEventsCount: number;
    eventsSelectedForEditing = 0;
    eventModes = EventsTableMode;

    searchControl = new FormControl<string>('');

    private unsubscribeAll = new Subject<void>;

    constructor(
        private uiSrv: UiService,
        private forecastEventSrv: ForecastEventService,
        private reconciliationSrv: ReconciliationService,
        private appRightsSrv: AppRightsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initSubscription();
        if (this.isPreview || this.isCreated) {
            this.initTable();
        }
        this.validateEvents();
        this.applyFilter();
    }

    ngOnChanges(): void {
        if (this.events?.length) {
            this.initTable();
        } else if (this.filterEvents?.length) {
            this.events = this.filterEvents.map(filterEvent => filterEvent.providerEvent);
            this.initTable();
        } else if (this.events?.length === 0 && this.dataSource?.data.length) {
            this.dataSource.data = [];
        }
    }

    expandEvent(event: ThotEvent): void {
        this.selectedEvent = this.selectedEvent === event || this.editedEvent === event ? null : event;
        if (this.isScenarioEvents && this.selectedEvent) {
            this.forecastEventSrv.getEvent(event.id).subscribe((eventFromSrv: ThotEvent) => {
                eventFromSrv.agent = Agent.fromObject(eventFromSrv.agent);
                this.selectedEvent.originalEvent = eventFromSrv;
            });
        }
        this.editedEvent = null;
        this.isEditing = false;
    }

    toggleAllForEditing(): void {
        if (this.eventsSelectedForEditing < this.events.length) {
            this.events.forEach(event => event.isSelectedForEditing = true);
            this.eventsSelectedForEditing = this.events.length;
        } else {
            this.events.forEach(event => event.isSelectedForEditing = false);
            this.eventsSelectedForEditing = 0;
        }
        this.eventSelected.emit(this.events);
    }

    toggleEditing(event: ThotEvent): void {
        event.isSelectedForEditing = !event.isSelectedForEditing;
        event.isSelectedForEditing ? this.eventsSelectedForEditing++ : this.eventsSelectedForEditing--;
        this.eventSelected.emit([event]);
    }

    editEvent(event: ThotEvent): void {
        this.editedEvent = this.editedEvent === event ? null : event;
        this.selectedEvent = this.editedEvent;
        if (this.isScenarioEvents && this.selectedEvent) {
            this.forecastEventSrv.getEvent(event.id).subscribe((eventFromSrv: ThotEvent) => {
                eventFromSrv.agent = Agent.fromObject(eventFromSrv.agent);
                this.selectedEvent.originalEvent = eventFromSrv;
            });
        }
        this.isEditing = this.editedEvent === null ? false : true;
    }

    onEditEvent(event: ThotEvent): void {
        event.edited = true;
        if (event.temporaryId) {
            // temporaryId means an event not in db
            const index = this.createdEvents.findIndex((e: ThotEvent) => e.temporaryId === event.temporaryId);
            this.createdEvents[index] = event;
            this.editedCreatedEvents.push(event);
            this.forecastEventSrv.createdEventsSubject.next(this.createdEvents);
        } else {
            const index = this.findEventIndex(event, this.editedEvents);
            if (index !== -1) {
                // console.log(JSON.parse(JSON.stringify(event)));
                this.editedEvents[index] = event;
            } else {
                this.editedEvents.push(event);
            }

            if (this.isPreview) {
                this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
            }
            if (this.isScenarioEvents) {
                this.eventEdited.emit(event);
            }
        }
    }

    onCancelEdits(deletedEvents?: boolean): void {
        if (this.isPreview) {
            this.events = this.events.filter((event: ThotEvent) => {
                if (deletedEvents) {
                    if (event.id === 0 || !event.deleted) {
                        return event;
                    }
                } else {
                    if (event.id === 0 || event.deleted) {
                        return event;
                    }
                }
            })
            if (deletedEvents) {
                this.editedEvents = this.editedEvents.filter((event: ThotEvent) => !event.deleted);
            } else {
                this.editedEvents = this.editedEvents.filter((event: ThotEvent) => event.deleted);
            }
            this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
            this.forecastEventSrv.createdEventsSubject.next(this.events);
        } else {
            this.events = this.events.map((event: ThotEvent) => {
                if (event.oldEvent) {
                    return event.oldEvent;
                } else {
                    return event;
                }
            });
        }

        this.dataSource.data = this.events;
        if (!deletedEvents) {
            this.editedCreatedEvents = [];
        }
    }

    resetEvent(event: ThotEvent): void {
        this.selectedEvent === event ? null : this.selectedEvent;
        this.editedEvent === event ? null : this.editedEvent;
        this.isEditing = this.editedEvent === null ? false : true;
        const index = this.findEventIndex(event, this.events);
        this.events[index] = event.oldEvent;
        const editedIndex = this.findEventIndex(event, this.editedEvents);
        if (editedIndex !== -1) {
            this.editedEvents.splice(editedIndex, 1);
        }

        if (this.isPreview && !this.events[index].temporaryId) {
            this.events.splice(index, 1);
            this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
        } else if (this.isPreview && this.events[index].temporaryId) {
            const createdIndex = this.findEventIndex(event, this.createdEvents);
            this.createdEvents[createdIndex] = event.oldEvent;
            this.forecastEventSrv.createdEventsSubject.next(this.createdEvents);
        }
        this.dataSource.data = this.events;
        this.resetedEvent.emit(this.events);
    }

    onDelete(event: ThotEvent): void {
        let message = 'scenarios.events.list.actions.confirmDelete.summary';
        if (this.isPreview || this.isCreated) {
            if (event.oldEvent && !event.deleted) {
                message = 'scenarios.events.list.actions.confirmDeletePreviewEdited.summary';
            } else if (event.deleted) {
                message = 'scenarios.events.list.actions.confirmDeletePreviewDeleted.summary';
            }
        }

        this.uiSrv.confirmDialog(message).afterClosed()
            .subscribe((result: boolean) => {
                if (result) {
                    if (event.id === 0 && (this.isPreview || this.isCreated)) {
                        const createdIndex = this.findEventIndex(event, this.createdEvents);
                        if (createdIndex !== -1) {
                            const index = this.findEventIndex(event, this.events);
                            this.events.splice(index, 1);
                            this.dataSource.data = this.events;
                            this.createdEvents.splice(createdIndex, 1);
                            this.forecastEventSrv.createdEventsSubject.next(this.createdEvents);
                            const message = 'scenarios.events.list.actions.canceled.summary';
                            this.uiSrv.showSnackbar(message, true);
                        }
                    } else {
                        if (event.oldEvent) {
                            event = event.oldEvent;
                        }
                        event.oldEvent = JSON.parse(JSON.stringify(event));
                        if (event.oldEvent.agent) {
                            event.oldEvent.agent = Agent.fromObject(event.oldEvent.agent);
                        }
                        event.deleted = true;
                        event.edited = true;
                        this.onEditEvent(event);
                        this.initTable();
                        if (this.isScenarioEvents) {
                            this.eventEdited.emit(event);
                        }
                    }
                }
            });
    }

    deleteContractEvents(contractToDelete: { scenarioId: number, agentId: number, contractId: number }): void {
        const message = 'scenarios.events.list.actions.confirmDeleteContract.summary';
        this.uiSrv.confirmDialog(message).afterClosed().subscribe((result) => {
            if (result) {
                this.forecastEventSrv.deleteContractEvents(contractToDelete.scenarioId, contractToDelete.agentId, contractToDelete.contractId).subscribe(() => {
                    const successMessage = 'scenarios.events.list.actions.contractDeleted.summary';
                    this.uiSrv.showSnackbar(successMessage, true);
                    this.forecastEventSrv.revalidateEventsSubject.next();
                });
            }
        })
    }

    isEventFilered(eventId: number): boolean {
        return this.filterEvents.find(filterEvent => filterEvent.providerEvent.id === eventId).isFiltered;
    }

    toggleEvent(eventId: number): void {
        const filterEvent = this.filterEvents.find(filterEvent => filterEvent.providerEvent.id === eventId);
        this.reconciliationSrv.toggleFilteredEvent(filterEvent.id, !filterEvent.isFiltered).subscribe(() => {
            filterEvent.isFiltered = !filterEvent.isFiltered;
        });
    }

    private applyFilter() {
        this.searchControl.valueChanges
            .subscribe(filterValue => this.dataSource.filter = filterValue.trim().toLowerCase());
    }

    private validateEvents(): void {
        if (this.invalidEvents?.length) {
            // console.log(this.invalidEvents);
            this.events.forEach((event: ThotEvent) => {
                const index = this.invalidEvents.findIndex((e: ThotEvent) => event.id === e.id);
                if (index === -1) {
                    event.isInvalid = false;
                } else {
                    event.isInvalid = true;
                }
            });
        }
    }

    private findEventIndex(event: ThotEvent, eventsList: ThotEvent[]): number {
        return eventsList.findIndex(e => {
            if (event.oldContract) {
                return e.id === event.id && e.oldContract.docNumber === event.oldContract.docNumber;
            } else if (event.newContract) {
                return e.id === event.id && e.newContract.docNumber === event.newContract.docNumber;
            } else if (event.oldVirtualContract?.docNumber) {
                return e.id === event.id && e.oldVirtualContract.docNumber === event.oldVirtualContract.docNumber;
            } else if (event.newVirtualContract?.docNumber) {
                return e.id === event.id && e.newVirtualContract.docNumber === event.newVirtualContract.docNumber;
            } else if (event.systemComment) {
                return e.eventType.id === event.eventType.id && e.eventMonth === event.eventMonth && e.systemComment === event.systemComment;
            } else {
                return e.eventType.id === event.eventType.id && e.eventMonth === event.eventMonth;
            }
        });
    }

    private sortTable(): void {
        this.dataSource.sortingDataAccessor = (item, property) => {
            console.log(item);
            console.log(property);
            switch (property) {
                case "agent":
                    return item.agent ? item.agent.lastName.toLowerCase() : '';
                case "eventType":
                    return item.eventType ? item.eventType.text.fullLabel.toLowerCase() : '';
                case "status":
                    return item.newProfessionalStatus ? item.newProfessionalStatus.description.toLowerCase() : '';
                case "scale":
                    return item.newScalePayment ? item.newScalePayment.externalDescription.toLowerCase() : '';
                case "origin":
                    return item.origin ? `${item.origin.strategy.text.shortLabel}${item.origin.label ? '(' + item.origin.label + ')' : ''}` : '';
                case "salaryBase":
                    return item.newSalaryBase;
                default:
                    return item[property];
            }
        };
        this.dataSource.sort = this.sort;
    }

    private filterTable() {
        const filterPredicate = (data: ThotEvent, filter: string): boolean => {
            return (
                (data.agent && data.agent.lastName
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1) ||
                data.eventType.text.fullLabel
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                data.oldScalePayment?.id >= 0 && data.oldScalePayment.externalDescription
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                data.newScalePayment?.id >= 0 && data.newScalePayment.externalDescription
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                data.oldSuperiorScalePayment?.id >= 0 && data.oldSuperiorScalePayment.externalDescription
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                data.newSuperiorScalePayment?.id >= 0 && data.newSuperiorScalePayment.externalDescription
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                data.oldParallelScalePayment?.id >= 0 && data.oldParallelScalePayment.externalDescription
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1 ||
                data.newParallelScalePayment?.id >= 0 && data.newParallelScalePayment.externalDescription
                    .toString()
                    .trim()
                    .toLowerCase()
                    .indexOf(filter) !== -1
            );
        };
        return filterPredicate;
    }

    private initTable(): void {
        if (this.isPreview) {
            this.events = [...this.createdEvents, ...this.editedEvents];
        } else if (this.isCreated) {
            this.events = [...this.createdEvents];
        } else {
            this.events = this.events.map(event => {
                if (event.agent?.matricule) {
                    event.agent = Agent.fromObject(event.agent)
                }
                const editedIndex = this.findEventIndex(event, this.editedEvents);
                if (editedIndex !== -1) {
                    return this.editedEvents[editedIndex];
                }
                return event;
            });
        }
        this.dataSource = new MatTableDataSource(this.events);
        this.dataSource.paginator = this.paginator;
        this.sortTable();
        this.dataSource.filterPredicate = this.filterTable();
    }

    private initSubscription(): void {
        this.appRightsSrv.appRights$
        .pipe(takeUntil(this.unsubscribeAll))
        .subscribe((appRights: AppRights) => {
            this.appRights = {
                scenario: appRights.scenario
            }
        });

        if (this.isPreview) {
            this.subscription.add(
                this.forecastEventSrv.editedEventsSubject.subscribe((eventsFromSrv: ThotEvent[]) => {
                    this.editedEvents = eventsFromSrv.map(event => {
                        if (event.agent?.matricule) {
                            event.agent = Agent.fromObject(event.agent)
                        }
                        return event;
                    });
                    this.editedEventsCount = this.editedEvents.filter((event: ThotEvent) => !event.deleted).length;
                    this.deletedEventsCount = this.editedEvents.filter((event: ThotEvent) => event.deleted).length;
                })
            );
            this.subscription.add(
                this.forecastEventSrv.createdEventsSubject.subscribe((eventsFromSrv: ThotEvent[]) => {
                    this.createdEvents = eventsFromSrv.map(event => {
                        if (event.agent?.matricule) {
                            event.agent = Agent.fromObject(event.agent)
                        }
                        return event;
                    });
                })
            );
        }

        if (this.isCreated) {
            this.subscription.add(
                this.forecastEventSrv.createdEventsSubject.subscribe((eventsFromSrv: ThotEvent[]) => {
                    this.createdEvents = eventsFromSrv.map(event => {
                        if (event.agent?.matricule) {
                            event.agent = Agent.fromObject(event.agent)
                        }
                        return event;
                    });
                })
            );
        }

        this.subscription.add(
            this.forecastEventSrv.eventTypesSubject
                .subscribe((typesFromSrv: EventType[]) => {
                    if (typesFromSrv) {
                        this.eventTypes = typesFromSrv;
                    }
                })
        );
    }

}
