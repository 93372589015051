import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { ContractService } from '@services/contract.service';
import { EmployerCategoryService } from '@services/employer-category.service';
import { ForecastEventService } from '@services/forecast-event.service';
import { ReferenceContractService } from '@services/reference-contract.service';
import { ScenarioService } from '@services/scenario.service';
import { UiService } from '@services/ui.service';
import { UserTagService } from '@services/user-tag.service';
import { WorkerCategoryService } from '@services/worker-category.service';

import { Agent } from '@classes/agent/agent';
import { Contract } from '@classes/contract/contract';
import { EmployerCategory } from '@classes/contract/employer-category';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { ReferenceSituation } from '@classes/contract/reference-situation';
import { WorkerCategory } from '@classes/contract/worker-category';
import { DateTimeFormat } from '@classes/date-time-format';
import { UserTag } from '@classes/parameters/user-tag';
import { EventType } from '@classes/scenario/event-type';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { Guid } from '@classes/scenario/guid';
import { Scale } from '@classes/scenario/scale';
import { Scenario } from '@classes/scenario/scenario';
import { ThotEvent } from '@classes/scenario/thot-event';
import { EventTypeId } from '@enums/event-type-id.enum';


@Component({
    selector: 'app-events-create',
    templateUrl: './events-create.component.html',
    styleUrls: ['./events-create.component.scss']
})
export class EventsCreateComponent implements OnInit, OnDestroy {
    @Input() minMonth: DateTime;
    @Input() indexValue: number;
    @Output() eventsDone = new EventEmitter<any>();

    eventForm: UntypedFormGroup;
    // eventDetailsForm: UntypedFormGroup;
    formsValid: boolean;
    individualEvent: boolean;
    contract: Contract;
    private agent: Agent;

    eventTypes: EventType[];
    professionalStatus: ProfessionalStatus[];
    scalesPayment: Scale[];
    articlesFunctional: FunctionalArticle[];
    contracts: Contract[];
    employerCategories: EmployerCategory[];
    workerCategories: WorkerCategory[];
    userTags: UserTag[];

    selectedScenario: Scenario;
    selectedEventType: EventType;

    hideEmployerCategories: boolean;

    private refMonth: string;
    private createdEvents: ThotEvent[];
    private contractualFte: number;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private forecastEventSrv: ForecastEventService,
        private contractSrv: ContractService,
        private scenarioSrv: ScenarioService,
        private uiSrv: UiService,
        private employerCatSrv: EmployerCategoryService,
        private workerCatSrv: WorkerCategoryService,
        private userTagSrv: UserTagService,
        private refContractSrv: ReferenceContractService
    ) { }

    //#region Angular Life Cycle
    ngOnInit(): void {
        this.initSubscription();
        this.initForm();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }
    //#endregion

    get eventDetailsForm(): UntypedFormGroup {
        return this.eventForm.get('eventDetailsGroup') as UntypedFormGroup;
    }

    getControl(control: string): UntypedFormControl {
        return this.eventForm.get(control) as UntypedFormControl;
    }

    onSubmit(): void {
        if (this.eventForm.status === 'VALID' && this.eventDetailsForm.status === 'VALID') {
            const eventValues = this.eventForm.value;
            const detailsValues = this.eventDetailsForm.value;
            const eventMonth = (eventValues.eventMonth as DateTime).toFormat(DateTimeFormat.dateTime);

            const event: ThotEvent = {
                scenario: this.selectedScenario,
                eventMonth: eventMonth,
                initialEventMonth: eventMonth,
                eventType: eventValues.eventType,
                agent: eventValues.agent,
                newContract: !eventValues.eventsNumber && (eventValues.eventType.id === 10 || eventValues.eventType.id === 21) ? eventValues.contract : undefined,
                oldContract: !eventValues.eventsNumber && (eventValues.eventType.id !== 10 && eventValues.eventType.id !== 21) ? eventValues.contract : undefined,
                userComment: eventValues.userComment,
                userTags: eventValues.tags
            };

            if (!eventValues.contract) {
                if ([EventTypeId.INUNKNOWN, EventTypeId.CDD, EventTypeId.INHOLIDAY, EventTypeId.INCFTE, EventTypeId.DECFTE].includes(eventValues.eventType.id)) {
                    event.fteContractual = detailsValues["newFte"];
                } else if ([EventTypeId.OUTUNKNOWN, EventTypeId.PENSIONUNKNOWN, EventTypeId.OUTHOLIDAY].includes(eventValues.eventType.id)) {
                    event.fteContractual = detailsValues["oldFte"];
                } else {
                    event.fteContractual = detailsValues["newFte"] ? detailsValues["newFte"] : detailsValues["oldFte"]
                }
            } else {
                event.fteContractual = this.contractualFte;
            }

            Object.keys(detailsValues).forEach(key => {
                if (key === "newSalaryBase" && detailsValues[key]) {
                    event.newSalaryBase = Math.round((detailsValues[key] / this.indexValue / event.fteContractual) * 100) / 100;
                } else if (key === "oldSalaryBase" && detailsValues[key]) {
                    event.oldSalaryBase = Math.round((detailsValues[key] / this.indexValue / event.fteContractual) * 100) / 100;
                } else {
                    event[key] = detailsValues[key];
                }

            });

            let newEvents: ThotEvent[] = [];
            if (eventValues.eventsNumber) {
                for (let i = 0; i < eventValues.eventsNumber; i++) {
                    event.id = 0;
                    event.temporaryId = Guid.newGuid();
                    if (eventValues.eventType.id === 10 || eventValues.eventType.id === 21) {
                        event.newVirtualContract = {
                            employerCategory: eventValues.employerCategory,
                            workerCategory: eventValues.workerCategory
                        }
                    } else if (eventValues.eventType.id !== 10 && eventValues.eventType.id !== 21) {
                        event.oldVirtualContract = {
                            employerCategory: eventValues.employerCategory,
                            workerCategory: eventValues.workerCategory
                        }
                    }
                    newEvents.push(JSON.parse(JSON.stringify(event)));
                }
            } else if (!eventValues.eventNumber && !eventValues.contract) {
                event.id = 0;
                event.temporaryId = Guid.newGuid();
                if (eventValues.eventType.id === 10 || eventValues.eventType.id === 21) {
                    event.newVirtualContract = {
                        employerCategory: eventValues.employerCategory,
                        workerCategory: eventValues.workerCategory
                    }
                } else if (eventValues.eventType.id !== 10 && eventValues.eventType.id !== 21) {
                    event.oldVirtualContract = {
                        employerCategory: eventValues.employerCategory,
                        workerCategory: eventValues.workerCategory
                    }
                }
                newEvents.push(JSON.parse(JSON.stringify(event)));
            } else {
                event.id = 0;
                event.temporaryId = Guid.newGuid();
                newEvents.push(JSON.parse(JSON.stringify(event)));
            }

            newEvents = newEvents.map(event => {

                return event;
            });

            this.createdEvents = [...this.createdEvents, ...newEvents];
            this.forecastEventSrv.createdEventsSubject.next(this.createdEvents);
            this.forecastEventSrv.isIndividualEventSubject.next(false);
            this.eventsDone.emit();
        } else if (this.eventDetailsForm.errors?.sameFunctionalArticleRepartition) {
            this.forecastEventSrv.errorEventCreation.next();
            this.uiSrv.showSnackbar('scenarios.events.error.sameFunctionalArticleRepartition.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.sameStatus) {
            this.uiSrv.showSnackbar('scenarios.events.error.sameProfessionalStatus.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.sameScalePayment) {
            this.uiSrv.showSnackbar('scenarios.events.error.sameScalePayment.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.sameParallelScalePayment) {
            this.uiSrv.showSnackbar('scenarios.events.error.sameParallelScalePayment.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.sameSuperiorScalePayment) {
            this.uiSrv.showSnackbar('scenarios.events.error.sameSuperiorScalePayment.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.fteIncrease) {
            this.uiSrv.showSnackbar('scenarios.events.error.fteIncrease.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.fteDecrease) {
            this.uiSrv.showSnackbar('scenarios.events.error.fteDecrease.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else if (this.eventDetailsForm.errors?.sameSeniority) {
            this.uiSrv.showSnackbar('scenarios.events.error.sameSeniority.summary', true);
            this.forecastEventSrv.errorEventCreation.next();
        } else {
            // console.log(this.eventForm);
            // console.log(this.eventDetailsForm);
            this.forecastEventSrv.errorEventCreation.next();
            this.uiSrv.showSnackbar('scenarios.events.error.invalidCreateForm.summary', true)
        }
    }

    onCancel(): void {
        this.eventsDone.emit();
    }

    hasReportTag(tag: UserTag): boolean {
        if (tag.type.id === 2) {
            return !!this.eventForm.controls.tags.value?.find((userTag: UserTag) => {
                return this.userTags.find(uT => uT.id === userTag?.id).type.id === 2 && tag.id !== userTag?.id;
            });
        } else {
            return false;
        }
    }

    hasContract(tag: UserTag): boolean {
        return !!(tag.type.id === 3 && this.eventForm.controls.contract.value);
    }

    private getContractsList(eventMonth: DateTime): Observable<Contract[]> {
        let date: string;
        if (this.eventForm.controls.eventType.value && [9, 11].includes(this.eventForm.controls.eventType.value.id)) {
            date = eventMonth.minus({ months: 1 }).toFormat(DateTimeFormat.dateTime);
        } else {
            date = eventMonth.toFormat(DateTimeFormat.dateTime);
        }
        return this.contractSrv.get(this.agent.id, date);
    }

    private observeFormChanges(): void {
        this.eventForm.controls.eventMonth.valueChanges
            .subscribe((month: DateTime) => {
                if (month?.day === 1 && month?.hour === 0 && month?.minute === 0) {
                    this.eventForm.controls.agent.enable();
                } else {
                    this.eventForm.controls.agent.disable();
                }
            });

        this.eventForm.controls.eventType.valueChanges
            .subscribe((eventType: EventType) => {
                this.selectedEventType = eventType;
                if (this.agent) {
                    this.getContractsList(this.eventForm.controls.eventMonth.value).subscribe(contractsFromSrv => {
                        this.contracts = contractsFromSrv.filter((contract: Contract) => DateTime.fromISO(contract.endDate) > DateTime.fromISO(this.refMonth));
                    });
                }
                for (let control in this.eventDetailsForm.controls) {
                    this.eventDetailsForm.removeControl(control);
                }
            });

        this.eventForm.controls.individualEvent.valueChanges
            .subscribe((isIndividual: boolean) => {
                this.individualEvent = isIndividual;
                if (this.individualEvent) {
                    this.eventForm.controls.eventsNumber.reset();
                    this.eventForm.controls.eventsNumber.clearValidators();
                    this.eventForm.controls.agent.setValidators([Validators.required]);
                } else if (!this.individualEvent) {
                    this.eventForm.controls.eventsNumber.setValidators([Validators.required]);
                    this.eventForm.controls.agent.reset();
                    this.eventForm.controls.agent.clearValidators();
                    this.eventForm.controls.contract.reset();
                }
                // this.forecastEventSrv.isIndividualEventSubject.next(this.individualEvent);
            });

        this.eventForm.controls.agent.valueChanges
            .pipe(
                debounceTime(500),
                switchMap(value => {
                    if (value?.id) {
                        this.agent = value;
                        return this.getContractsList(this.eventForm.controls.eventMonth.value);
                    } else {
                        return of(null);
                    }
                })
            ).subscribe((contractsFromSrv: Contract[]) => {
                if (contractsFromSrv?.length) {
                    this.contracts = contractsFromSrv.filter((contract: Contract) => DateTime.fromISO(contract.endDate) > DateTime.fromISO(this.refMonth));
                } else {
                    this.contracts = [];
                }
            });

        this.eventForm.controls.contract.valueChanges.subscribe((contract: Contract) => {
            this.eventDetailsForm.reset();
            this.contract = contract;
            if (contract) {
                this.refContractSrv.get(this.agent, this.refMonth).subscribe((refSituationFromSrv: ReferenceSituation[]) => {
                    this.contractualFte = refSituationFromSrv.find(rs => rs.contract.docNumber === contract.docNumber).fteContractuel;
                });
                this.forecastEventSrv.isIndividualEventSubject.next(true);
                this.eventForm.removeControl('workerCategory');
                this.eventForm.removeControl('employerCategory');
                const tags = this.eventForm.controls.tags.value?.length ? this.eventForm.controls.tags.value.filter((tagId: number) => {
                    return this.userTags.find((userTag: UserTag) => userTag.id === tagId).type.id !== 3;
                }) : undefined;
                this.eventForm.controls.tags.setValue(tags)
            } else if (!contract) {
                this.forecastEventSrv.isIndividualEventSubject.next(false);
                this.eventForm.addControl('workerCategory', new UntypedFormControl(undefined, [Validators.required]));
                this.eventForm.addControl('employerCategory', new UntypedFormControl(undefined, [Validators.required]));
            }
        })
    }

    private initForm(): void {
        this.eventForm = this.fb.group({
            eventMonth: [null, Validators.required],
            eventType: [null, Validators.required],
            individualEvent: [false],
            eventsNumber: [null, Validators.required],
            workerCategory: [null, Validators.required],
            employerCategory: [this.hideEmployerCategories ? this.employerCategories[0] : null, Validators.required],
            agent: [{ value: null, disabled: true }],
            contract: [],
            userComment: [],
            tags: [],
            eventDetailsGroup: this.fb.group({})
        });
        this.eventForm.markAllAsTouched();

        this.observeFormChanges();
    }

    private initSubscription(): void {
        this.scenarioSrv.selectedScenarioSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scenarioFromSrv: Scenario) => {
                this.selectedScenario = scenarioFromSrv;
                this.refMonth = this.selectedScenario?.referenceMonth;
            });

        // this.refMonthSrv.refMonthSubject
        //     .pipe(takeUntil(this.unsubscribeAll))
        //     .subscribe((refMonthFromSrv: Date) => this.refMonth = refMonthFromSrv)

        this.forecastEventSrv.eventTypesSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((typesFromSrv: EventType[]) => this.eventTypes = typesFromSrv);

        this.forecastEventSrv.professionalStatusSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((statusFromSrv: ProfessionalStatus[]) => this.professionalStatus = statusFromSrv);

        this.forecastEventSrv.scalePaymentSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scalesFromSrv: Scale[]) => this.scalesPayment = scalesFromSrv);

        this.forecastEventSrv.articleFunctionalSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((articlesFromSrv: FunctionalArticle[]) => this.articlesFunctional = articlesFromSrv);

        this.forecastEventSrv.createdEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((createdEventsFromSrv: ThotEvent[]) => this.createdEvents = createdEventsFromSrv);

        this.workerCatSrv.workerCategorySubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((categoriesFromSrv: WorkerCategory[]) => {
                this.workerCategories = [];
                categoriesFromSrv.map((category: WorkerCategory) => {
                    if (category.id > 0) {
                        this.workerCategories.push(WorkerCategory.fromObject(category));
                    }
                });
            });

        this.employerCatSrv.employerCategorySubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((categoriesFromSrv: EmployerCategory[]) => {
                if (categoriesFromSrv.every(category => category.id === -1)) {
                    this.employerCategories = [categoriesFromSrv[0]];
                    this.hideEmployerCategories = true;
                } else {
                    this.employerCategories = categoriesFromSrv.filter((category: EmployerCategory) => {
                        if (category.id > 0) {
                            return EmployerCategory.fromObject(category);
                        }
                    });
                }
            });

        this.userTagSrv.userTagsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((userTagsFromSrv: UserTag[]) => this.userTags = userTagsFromSrv);
    }

}
