import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DateTime } from 'luxon';
import { Observable, Subject, of } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';

import { DetailContractComponent } from './detail-contract/detail-contract.component';

import { ContractService } from '@services/contract.service';
import { ForecastEventService } from '@services/forecast-event.service';
import { ReconciliationEventService } from '@services/reconciliation-event.service';
import { ReferenceContractService } from '@services/reference-contract.service';
import { ScenarioService } from '@services/scenario.service';
import { UiService } from '@services/ui.service';
import { UserTagService } from '@services/user-tag.service';
import { VirtualContractService } from '@services/virtual-contract.service';

import { EventsTableMode } from '@enums/events-table-mode.enum';
import { ScenarioStatus } from '@enums/story-line/scenario-status.enum';

import { Agent } from '@classes/agent/agent';
import { Contract } from '@classes/contract/contract';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { ReferenceSituation } from '@classes/contract/reference-situation';
import { DateTimeFormat } from '@classes/date-time-format';
import { UserTag } from '@classes/parameters/user-tag';
import { EventsTags } from '@classes/scenario/events-tags';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';
import { Scale } from '@classes/scenario/scale';
import { Scenario } from '@classes/scenario/scenario';
import { ThotEvent } from '@classes/scenario/thot-event';

export const MONTH_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

@Component({
    selector: 'app-event-detail',
    templateUrl: './event-detail.component.html',
    styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit, OnChanges, OnDestroy {
    @Input() event: ThotEvent;
    @Input() isEdited: boolean;
    @Input() mode: EventsTableMode;
    @Input() isInvalidEvent: boolean;
    @Input() indexValue: number;
    @Output() editedEvent = new EventEmitter<ThotEvent>();
    @Output() deleteContractEvents = new EventEmitter<{ scenarioId: number, agentId: number, contractId: number }>();

    professionalStatuts: ProfessionalStatus[];
    scalesPayment: Scale[];
    articlesFunctional: FunctionalArticle[];

    userTags: UserTag[];
    tagsControl: UntypedFormControl;

    dateFormat = 'MM/yyyy';
    colors: string[];
    canDeleteContract = true;

    detailsForm: UntypedFormGroup;
    contracts: Contract[];

    minMonth: DateTime;
    nullIds = [-1, -2]
    private refMonth: string;
    private selectedScenario: Scenario;

    private unsubscribeAll = new Subject<void>();

    constructor(
        public dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private uiSrv: UiService,
        private contractSrv: ContractService,
        private forecastEventSrv: ForecastEventService,
        private virtualContractSrv: VirtualContractService,
        private userTagSrv: UserTagService,
        private reconciliationEventSrv: ReconciliationEventService,
        private scenarioSrv: ScenarioService,
        private refContractSrv: ReferenceContractService
    ) { }

    ngOnInit(): void {
        this.colors = this.uiSrv.getColors();
        this.initSubscription();
        // if (this.isEdited)
            // this.initForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.isEdited.currentValue === true) {
            this.initForm();
            if (this.event.agent?.id) {
                this.getContractsList(this.event.eventMonth).subscribe(contractsFromSrv => {
                    this.contracts = contractsFromSrv.filter((contract: Contract) => DateTime.fromISO(contract.endDate) > DateTime.fromISO(this.refMonth));
                });
            }
        }

        if (!this.isEdited && this.isInvalidEvent) {
            this.canDeleteContract = true;
        } else {
            this.canDeleteContract = false;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[], old?: boolean): void {
        if (old) {
            this.detailsForm.controls.oldFunctionalArticleRepartition.setValue(articlesRepartition);
        } else {
            this.detailsForm.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
        }
    }

    getContract(event: ThotEvent): void {
        if (event.newContract) {
            this.contractSrv.getContract(event.newContract.id).subscribe((contractFromSrv: Contract) => {
                this.showContractDetails(contractFromSrv, false);
            });
        } else if (event.oldContract) {
            this.contractSrv.getContract(event.oldContract.id).subscribe((contractFromSrv: Contract) => {
                this.showContractDetails(contractFromSrv, false);
            });
        } else if (event.newVirtualContract) {
            this.virtualContractSrv.get(event.newVirtualContract.id).subscribe((contractFromSrv: Contract) => {
                this.showContractDetails(contractFromSrv, true);
            });
        } else if (event.oldVirtualContract) {
            this.virtualContractSrv.get(event.oldVirtualContract.id).subscribe((contractFromSrv: Contract) => {
                this.showContractDetails(contractFromSrv, true);
            });
        }
    }

    deleteContract(): void {
        this.deleteContractEvents.emit({ scenarioId: this.event.scenario.id, agentId: this.event.agent.id, contractId: this.event.oldContract.id })
    }

    editUserTags(isOpen: boolean): void {
        if (this.event.id === 0) {
            if (!isOpen) {
                this.event.userTags = this.tagsControl.value.map((tagId: number) => {
                    return this.userTags.find((userTag: UserTag) => userTag.id === tagId)
                });
            }
        } else {
            if (!isOpen) {
                const eventTags = new EventsTags([this.event.id], this.tagsControl.value);
                if (this.mode === EventsTableMode.reconciliation) {
                    if (this.event.userTags?.length) {
                        this.reconciliationEventSrv.editUserTags(eventTags).subscribe(() => {
                            this.setNewTags();
                        });
                    } else {
                        this.reconciliationEventSrv.addUserTags(eventTags).subscribe(() => {
                            this.setNewTags();
                        });
                    }
                } else {
                    if (this.event.userTags?.length) {
                        this.forecastEventSrv.editUserTags(eventTags, this.selectedScenario).subscribe(() => {
                            this.setNewTags();
                            // if (this.userTagSrv.hasTagType(eventTags.tags, 3)) {
                            //   this.scenarioSrv.editStatus(this.selectedScenario, ScenarioStatus.outdated).subscribe();
                            // }
                        });
                    } else {
                        this.forecastEventSrv.addUserTags(eventTags, this.selectedScenario).subscribe(() => {
                            this.setNewTags();
                            // if (this.userTagSrv.hasTagType(eventTags.tags, 3)) {
                            //   this.scenarioSrv.editStatus(this.selectedScenario, ScenarioStatus.outdated).subscribe();
                            // }
                        });
                    }
                }
            }
        }
    }

    hasReportTag(tag: UserTag): boolean {
        if (tag.type.id === 2) {
            return !!this.tagsControl.value?.find((userTagId: number) => {
                return this.userTags.find(uT => uT.id === userTagId).type.id === 2 && tag.id !== userTagId;
            });
        } else {
            return false;
        }
    }

    hasContract(tag: UserTag): boolean {
        return !!(tag.type.id === 3 && (this.event.newContract || this.event.oldContract));
    }

    isNullId(id: number | string): boolean {
        if (typeof id === 'string') {
            return this.nullIds.some(nullId => nullId === parseInt(id));
        } else {
            return this.nullIds.some(nullId => nullId === id);
        }
    }

    getControl(controlName: string): UntypedFormControl {
        return this.detailsForm.get(controlName) as UntypedFormControl;
    }

    private getContractsList(date: string): Observable<Contract[]> {
        if ([9, 11].includes(this.event.eventType.id)) {
            date = DateTime.fromISO(date).minus({ months: 1 }).toFormat(DateTimeFormat.dateTime)
        } else {
            date = DateTime.fromISO(date).toFormat(DateTimeFormat.dateTime);
        }
        return this.contractSrv.get(this.event.agent.id, date);
    }

    private setNewTags(): void {
        const newTags = this.tagsControl.value.map((tagId: number) => this.userTags.find((userTag: UserTag) => userTag.id === tagId));
        this.event.userTags = newTags;
    }

    private showContractDetails(contract: Contract, isVirtual: boolean): void {
        const dialogRef = this.dialog.open(DetailContractComponent, {
            width: '400px',
            data: {
                contract,
                isVirtual
            },
            autoFocus: false
        });

        dialogRef.afterClosed().subscribe((result: { contract: Contract, isVirtual: boolean }) => {
            if (result) {
                this.virtualContractSrv.put(result.contract, this.selectedScenario.id).subscribe({
                    next: (contractFromSrv: Contract) => {
                        if (contractFromSrv) {
                            this.forecastEventSrv.reloadEventsSubject.next();
                            this.scenarioSrv.editStatus(this.selectedScenario, ScenarioStatus.outdated).subscribe();
                        }
                    }
                });
            }
        })
    }

    private onEditEvent(formValues: any): void {
        Object.keys(formValues).forEach(key => {
            if (key === 'eventMonth') {
                if (formValues[key]) {
                    const newDate = formValues[key] !== null && formValues[key] !== "Invalid Date" ? formValues[key] : this.event[key];
                    this.event[key] = newDate;
                } else {
                    delete this.event[key]
                }
            } else if (key === 'agent') {
                if (formValues[key] instanceof Agent) {
                    this.event.agent = formValues[key];
                } else {
                    // this.event.agent = null;
                    // this.event.oldContract = null;
                    // this.event.newContract = null;
                    delete this.event.agent;
                    delete this.event.oldContract;
                    delete this.event.newContract;
                    this.detailsForm.controls.contract.setValue(null, { emitEvent: false })
                }
            } else if (key === "oldSalaryBase") {
                if (formValues[key]) {
                    this.event[key] = Math.round((formValues[key] / this.indexValue / this.event.fteContractual) * 100) / 100;
                } else {
                    delete this.event[key];
                }
            } else if (key === "newSalaryBase") {
                if (formValues[key]) {
                    this.event[key] = Math.round((formValues[key] / this.indexValue / this.event.fteContractual) * 100) / 100;
                } else {
                    delete this.event[key];
                }
            } else if (key.match('Scale') || key.match('Status')) {
                if (typeof formValues[key] === 'string') {
                    delete this.event[key];
                } else if (formValues[key]) {
                    this.event[key] = formValues[key];
                } else {
                    delete this.event[key];
                }
            } else if (key.match("Fte") || key.match("Seniority")) {
                if (formValues[key] !== null) {
                    this.event[key] = formValues[key];
                } else {
                    delete this.event[key];
                }
            } else if (key !== 'contract') {
                if (formValues[key]) {
                    this.event[key] = formValues[key];
                } else {
                    delete this.event[key];
                }
            }
        });
        if (this.event.id !== 0 && this.mode !== EventsTableMode.invalid) {
            this.event.manuallyModified = true;
        }
        this.editedEvent.emit(this.event);
    }

    private ObserveFormChanges(): void {
        this.detailsForm.valueChanges
            .subscribe(values => {
                if (!this.event.oldEvent) {
                    this.event.oldEvent = JSON.parse(JSON.stringify(this.event));
                    this.event.oldEvent.agent = Agent.fromObject(this.event.oldEvent.agent);
                    this.event.edited = true;
                }

                if (!this.event.oldEvent.agent?.matricule) {
                    delete this.event.oldEvent.agent;
                }

                if (values.agent?.matricule) {
                    delete this.event.virtualAgent;
                }
                this.onEditEvent(values);
            });

        this.detailsForm.controls.eventMonth.valueChanges.subscribe((month: DateTime) => {
            if (month?.day === 1 && month?.hour === 0 && month?.minute === 0) {
                this.detailsForm.controls.eventMonth.setValue(month.toFormat(DateTimeFormat.dateTime), { emitEvent: false });
                this.getContractsList(this.detailsForm.value.eventMonth).subscribe(contractsFromSrv => {
                    this.contracts = contractsFromSrv.filter((contract: Contract) => DateTime.fromISO(contract.endDate) > DateTime.fromISO(this.refMonth));
                });
            }
        });

        this.detailsForm.controls.agent.valueChanges
            .pipe(
                debounceTime(100),
                switchMap(agent => {
                    if (agent instanceof Agent) {
                        this.detailsForm.controls.contract.enable();
                        return this.getContractsList(this.detailsForm.value.eventMonth);
                    } else {
                        this.detailsForm.controls.contract.disable();
                        return of(null);
                    }
                })
            ).subscribe(contractsFromSrv => {
                if (contractsFromSrv?.length) {
                    this.contracts = contractsFromSrv.filter((contract: Contract) => DateTime.fromISO(contract.endDate) > DateTime.fromISO(this.refMonth));
                } else {
                    this.contracts = [];
                }
            });

        this.detailsForm.controls.contract.valueChanges.subscribe((docNumber: string) => {
            if (docNumber) {
                const contract = this.contracts.find((c: Contract) => c.docNumber === docNumber);
                if (this.event.eventType.id === 10 || this.event.eventType.id === 21) {
                    this.event.newContract = contract;
                } else {
                    this.event.oldContract = contract;
                }
                this.refContractSrv.get(this.event.agent, this.refMonth).subscribe((refSituationFromSrv: ReferenceSituation[]) => {
                    if (refSituationFromSrv?.length) {
                        this.event.fteContractual = refSituationFromSrv.find(rs => rs.contract.docNumber === docNumber).fteContractuel;
                    }
                });
            }
        });
    }

    private initForm(): void {
        let agent: Agent;
        let contract: string;
        let eventMonth: string;
        let durationInMonth: number
        let userComment: string;
        let oldFte: number;
        let newFte: number;
        let oldSeniority: number;
        let newSeniority: number;
        let oldScalePayment: Scale;
        let newScalePayment: Scale;
        let oldSuperiorScalePayment: Scale;
        let newSuperiorScalePayment: Scale;
        let oldParallelScalePayment: Scale;
        let newParallelScalePayment: Scale;
        let oldProfessionalStatus: ProfessionalStatus;
        let newProfessionalStatus: ProfessionalStatus;
        let oldSalaryBase: number;
        let newSalaryBase: number;
        let newFunctionalArticleRepartition: FunctionalArticleRepartition[];
        let oldFunctionalArticleRepartition: FunctionalArticleRepartition[];

        if (this.event) {
            agent = this.event.agent;
            contract = this.event.oldContract ? this.event.oldContract.docNumber : this.event.newContract ? this.event.newContract.docNumber : null;
            eventMonth = this.event.eventMonth;
            durationInMonth = this.event.durationInMonth;
            userComment = this.event.userComment;
            oldFte = this.event.oldFte;
            newFte = this.event.newFte;
            oldSeniority = this.event.oldSeniority;
            newSeniority = this.event.newSeniority;
            oldScalePayment = Scale.fromObject(this.event.oldScalePayment);
            newScalePayment = Scale.fromObject(this.event.newScalePayment);
            oldSuperiorScalePayment = Scale.fromObject(this.event.oldSuperiorScalePayment);
            newSuperiorScalePayment = Scale.fromObject(this.event.newSuperiorScalePayment);
            oldParallelScalePayment = Scale.fromObject(this.event.oldParallelScalePayment);
            newParallelScalePayment = Scale.fromObject(this.event.newParallelScalePayment);
            oldProfessionalStatus = ProfessionalStatus.fromObject(this.event.oldProfessionalStatus);
            newProfessionalStatus = ProfessionalStatus.fromObject(this.event.newProfessionalStatus);
            oldSalaryBase = Number((this.event.oldSalaryBase * this.indexValue * this.event.fteContractual).toFixed(2));
            newSalaryBase = Number((this.event.newSalaryBase * this.indexValue * this.event.fteContractual).toFixed(2));
            oldFunctionalArticleRepartition = this.event.oldFunctionalArticleRepartition;
            newFunctionalArticleRepartition = this.event.newFunctionalArticleRepartition;
        }

        this.detailsForm = this.fb.group({
            agent: [agent],
            contract: [{ value: contract, disabled: !agent }],
            eventMonth: [eventMonth, [Validators.min(0)]],
            durationInMonth: [{ value: durationInMonth, disabled: !durationInMonth }],
            userComment: [userComment],
            oldFte: [oldFte, [Validators.min(0), Validators.max(1)]],
            newFte: [newFte, [Validators.min(0), Validators.max(1)]],
            oldSeniority: [oldSeniority, Validators.min(0)],
            newSeniority: [newSeniority, Validators.min(0)],
            oldScalePayment: [oldScalePayment],
            newScalePayment: [newScalePayment],
            oldSuperiorScalePayment: [oldSuperiorScalePayment],
            newSuperiorScalePayment: [newSuperiorScalePayment],
            oldParallelScalePayment: [oldParallelScalePayment],
            newParallelScalePayment: [newParallelScalePayment],
            oldProfessionalStatus: [oldProfessionalStatus],
            newProfessionalStatus: [newProfessionalStatus],
            oldSalaryBase: [oldSalaryBase],
            newSalaryBase: [newSalaryBase],
            oldFunctionalArticleRepartition: [oldFunctionalArticleRepartition],
            newFunctionalArticleRepartition: [newFunctionalArticleRepartition]
        });
        this.ObserveFormChanges();
        this.tagsControl = new UntypedFormControl(this.event?.userTags?.map(tag => tag.id));
    }

    private initSubscription(): void {
        this.forecastEventSrv.professionalStatusSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((statutsFromSrv: ProfessionalStatus[]) => this.professionalStatuts = statutsFromSrv);

        this.forecastEventSrv.scalePaymentSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scalesFromSrv: Scale[]) => this.scalesPayment = scalesFromSrv);

        this.forecastEventSrv.articleFunctionalSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((articlesFromSrv: FunctionalArticle[]) => this.articlesFunctional = articlesFromSrv);

        this.userTagSrv.userTagsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((userTagsFromSrv: UserTag[]) => this.userTags = userTagsFromSrv);

        this.scenarioSrv.selectedScenarioSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scenarioFromSrv: Scenario) => {
                if (scenarioFromSrv) {
                    this.selectedScenario = scenarioFromSrv;
                    this.refMonth = scenarioFromSrv.referenceMonth;
                    this.minMonth = DateTime.fromISO(this.refMonth).plus({ months: 1 });
                }
            });
    }

}
