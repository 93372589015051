import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';

import { ForecastEventService } from '@services/forecast-event.service';
import { UiService } from '@services/ui.service';
import { WorkerCategoryService } from '@services/worker-category.service';
import { EmployerCategoryService } from '@services/employer-category.service';
import { EmployerCategory } from '@classes/contract/employer-category';
import { WorkerCategory } from '@classes/contract/worker-category';
import { EventType } from '@classes/scenario/event-type';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { Scale } from '@classes/scenario/scale';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { ProfessionalStatusGroup } from '@classes/scenario/professional-status-group';
import { UserTagService } from '@services/user-tag.service';
import { UserTag } from '@classes/parameters/user-tag';

@Injectable()
export class ScenarioResolver implements Resolve<void> {
    constructor(
        private uiSrv: UiService,
        private forecastEventSrv: ForecastEventService,
        private employerCatSrv: EmployerCategoryService,
        private workerCatSrv: WorkerCategoryService,
        private userTagSrv: UserTagService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): void {
        this.uiSrv.routeLoadingStateChanged.next(true);

        const employerCatPromise = new Promise<EmployerCategory[]>((resolve) => {
            this.employerCatSrv.get(true).subscribe((categoriesFromSrv: EmployerCategory[]) => {
                resolve(categoriesFromSrv);
            });
        });
        const workerCatPromise = new Promise<WorkerCategory[]>((resolve) => {
            this.workerCatSrv.get(true).subscribe((categoriesFromSrv: WorkerCategory[]) => resolve(categoriesFromSrv));
        });
        const eventTypePromise = new Promise<EventType[]>((resolve) => {
            this.forecastEventSrv.getEventTypes().subscribe((typesFromSrv: EventType[]) => resolve(typesFromSrv));
        });
        const statusPromise = new Promise<ProfessionalStatus[]>((resolve) => {
            this.forecastEventSrv.getProfessionalStatus().subscribe((statusFromSrv: ProfessionalStatus[]) => resolve(statusFromSrv));
        });
        const scalePaymentPromise = new Promise<Scale[]>((resolve) => {
            this.forecastEventSrv.getScalePayment().subscribe((scalesFromSrv: Scale[]) => resolve(scalesFromSrv));
        });
        const articlePromise = new Promise<FunctionalArticle[]>((resolve) => {
            this.forecastEventSrv.getArticleFunctional().subscribe((articlesFromSrv: FunctionalArticle[]) => resolve(articlesFromSrv));
        });
        const statusGroupPromise = new Promise<ProfessionalStatusGroup[]>((resolve) => {
            this.forecastEventSrv.getProfessionalStatusGroup().subscribe((statusGroupFromSrv: ProfessionalStatusGroup[]) => resolve(statusGroupFromSrv));
        });
        const userTagsPromise = new Promise<UserTag[]>((resolve) => {
            this.userTagSrv.get().subscribe((userTagsFromSrv: UserTag[]) => resolve(userTagsFromSrv));
        });

        Promise.all([employerCatPromise, workerCatPromise, eventTypePromise, statusPromise, scalePaymentPromise, articlePromise, statusGroupPromise, userTagsPromise])
            .then((result) => {
                this.employerCatSrv.employerCategorySubject.next(result[0]);
                this.workerCatSrv.workerCategorySubject.next(result[1]);
                this.forecastEventSrv.eventTypesSubject.next(result[2]);
                this.forecastEventSrv.professionalStatusSubject.next(result[3].map(status => ProfessionalStatus.fromObject(status)));
                this.forecastEventSrv.scalePaymentSubject.next(result[4].map(scale => Scale.fromObject(scale)));
                this.forecastEventSrv.articleFunctionalSubject.next(result[5].map(article => FunctionalArticle.fromObject(article)));
                this.forecastEventSrv.professionalStatusGroupSubject.next(result[6].map(group => ProfessionalStatusGroup.fromObject(group)));
                this.userTagSrv.userTagsSubject.next(result[7]);
                this.uiSrv.routeLoadingStateChanged.next(false);
            });
    }
}
