<section class="search-section">
    <header>
        <h4> {{'scenarios.events.multipleEdit.criteria.fullLabel' | translate}} </h4>
        <button type="button"
                mat-icon-button
                color="accent"
                [matTooltip]="'scenarios.events.multipleEdit.criteria.summary' | translate"
                (click)="addCriteria()">
            <mat-icon aria-label="Add">add</mat-icon>
        </button>
    </header>
    <form [formGroup]="criteriaForm"
          (ngSubmit)="onSubmitSearch()">
        <ng-container formArrayName="selectionCriteria">
            <div *ngFor="let criteria of criterias; index as i"
                 class="search-group">
                <ng-container [formGroup]="criteria">
                    <mat-form-field>
                        <mat-label> {{'scenarios.events.multipleEdit.parameter.shortLabel' | translate}} </mat-label>
                        <mat-select formControlName="criteria">
                            <ng-container *ngFor="let param of params">
                                <mat-option [value]="param">
                                    {{param.label + '.fullLabel' | translate}}
                                </mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="operator">
                        <mat-select formControlName="operator">
                            <mat-option *ngFor="let operator of operators"
                                        [value]="operator.value"> {{operator.label}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div class="input"
                         *ngIf="criteria.get('criteria').value">
                        <app-month-selector [group]="criteria"
                                            [controlName]="'value'"
                                            [label]="criteria.get('criteria').value.label + '.shortLabel'  | translate"
                                            [min]="minMonth"
                                            *ngIf="criteria.get('criteria').value.filter.match('Month')"></app-month-selector>
                        <app-autocomplete *ngIf="criteria.get('criteria').value.filter.match('Scale') && !criteria.get('criteria').value.withOperator"
                                          [control]="getCriteriaControl(criteria, 'value')"
                                          [list]="scalesPayment"
                                          [label]="criteria.get('criteria').value.label + '.shortLabel' | translate">
                        </app-autocomplete>
                        <app-autocomplete *ngIf="criteria.get('criteria').value.filter.match('Status')"
                                          [control]="getCriteriaControl(criteria, 'value')"
                                          [list]="professionalStatus"
                                          [label]="criteria.get('criteria').value.label + '.shortLabel' | translate">
                        </app-autocomplete>
                        <app-event-type-select [control]="getCriteriaControl(criteria, 'value')"
                                               [label]="criteria.get('criteria').value.label + '.shortLabel' | translate"
                                               *ngIf="criteria.get('criteria').value.filter.match('eventType')"></app-event-type-select>
                        <app-input-number [group]="criteria"
                                          [controlName]="'value'"
                                          [min]="0"
                                          [max]="criteria.get('criteria').value.filter.match('Fte') ? 1 : undefined"
                                          [step]="criteria.get('criteria').value.filter.match('Fte') ? 0.1 : 1"
                                          [label]="criteria.get('criteria').value.label + '.shortLabel' | translate"
                                          *ngIf="criteria.get('criteria').value.withOperator && !criteria.get('criteria').value.filter.match('Month')">
                        </app-input-number>
                        <app-autocomplete [control]="getCriteriaControl(criteria, 'value')"
                                          [list]="origins"
                                          [label]="'scenarios.events.properties.origin.shortLabel' | translate"
                                          *ngIf="criteria.get('criteria').value.filter.match('strategyId')"></app-autocomplete>
                        <app-autocomplete [control]="getCriteriaControl(criteria, 'value')"
                                          [list]="initialOrigins"
                                          [label]="'scenarios.events.properties.initialEventOrigin.shortLabel' | translate"
                                          *ngIf="criteria.get('criteria').value.filter.match('initialEventOrigin')">
                        </app-autocomplete>
                        <mat-form-field *ngIf="criteria.get('criteria').value.filter.match('tags')">
                            <mat-label> {{'scenarios.strategies.userTags.shortLabel' | translate}} </mat-label>
                            <mat-select [formControl]="getCriteriaControl(criteria, 'value')"
                                        multiple>
                                <mat-select-trigger>
                                    {{criteria.value.value?.length ? (criteria.value.value | getTagsNames : userTags) :
                                    ''}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let tag of userTags"
                                            [value]="tag.id">
                                    <div class="user-tag">
                                        <mat-icon color="accent"
                                                  [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                                  *ngIf="tag.type.id === 2">grid_on</mat-icon>
                                        <mat-icon color="accent"
                                                  [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                                  *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                                        <span> {{tag.name}} </span>
                                    </div>
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field
                                        *ngIf="!criteria.get('criteria').value.withOperator && !criteria.get('criteria').value.filter.match(exceptionParams)">
                            <mat-label>
                                {{criteria.get('criteria').value.label + '.shortLabel' | translate}}
                            </mat-label>
                            <input matInput
                                   formControlName="value">
                        </mat-form-field>
                    </div>
                    <mat-form-field *ngIf="!criteria.get('criteria').value">
                        <mat-label>
                            {{'scenarios.events.multipleEdit.value.shortLabel' | translate}}
                        </mat-label>
                        <input matInput>
                    </mat-form-field>
                    <button mat-icon-button
                            color="accent"
                            [matTooltip]="'scenarios.events.multipleEdit.deleteCriteria.summary' | translate"
                            (click)="deleteCriteria(i)"
                            [disabled]="selectionCriterias?.length <= 1">
                        <mat-icon aria-label="Delete">delete</mat-icon>
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <div>
            <button type="submit"
                    mat-raised-button
                    color="accent"
                    [disabled]="isSearching">
                <mat-icon aria-label="Search"
                          *ngIf="!isSearching">search</mat-icon>
                <mat-spinner [diameter]="24"
                             *ngIf="isSearching"></mat-spinner>
                {{'scenarios.events.multipleEdit.search.fullLabel' | translate}}
            </button>
            <button type="button"
                    mat-button
                    color="accent"
                    [disabled]="!events?.length"
                    (click)="deleteEvents()">
                <ng-container *ngIf="!isDeleting">
                    <mat-icon aria-label="Delete">delete</mat-icon>
                    {{'scenarios.events.multipleEdit.deleteEvents.fullLabel' | translate}}
                </ng-container>
                <ng-container *ngIf="isDeleting">
                    <mat-spinner [diameter]="24"></mat-spinner>
                    {{'scenarios.events.multipleEdit.deletingEvents.fullLabel' | translate}}
                </ng-container>
            </button>
        </div>
    </form>
</section>

<section class="edit-section">
    <header>
        <h4> {{'scenarios.events.multipleEdit.valuesToReplace.fullLabel' | translate}} </h4>
    </header>
    <form [formGroup]="editForm"
          (ngSubmit)="onSubmitEdits()">
        <div class="edit-group">
            <mat-form-field>
                <mat-label> {{'scenarios.events.multipleEdit.parameter.shortLabel' | translate}} </mat-label>
                <mat-select formControlName="criteria">
                    <ng-container *ngFor="let param of params">
                        <mat-option *ngIf="param.canReplace"
                                    [value]="param">
                            {{param.label + '.fullLabel' | translate}}
                        </mat-option>
                    </ng-container>
                </mat-select>
            </mat-form-field>
            <div class="input"
                 *ngIf="editForm.get('criteria').value">
                <app-month-selector [group]="editForm"
                                    [controlName]="'value'"
                                    [min]="minMonth"
                                    *ngIf="editForm.get('criteria').value.filter.match('Month')"></app-month-selector>
                <app-event-scales-autocomplete [group]="editForm"
                                               [controlName]="'value'"
                                               [scales]="scalesPayment"
                                               *ngIf="editForm.get('criteria').value.filter.match('Scale') && !editForm.get('criteria').value.withOperator">
                </app-event-scales-autocomplete>
                <app-event-status-autocomplete [group]="editForm"
                                               [controlName]="'value'"
                                               [status]="professionalStatus"
                                               *ngIf="editForm.get('criteria').value.filter.match('Status') && !editForm.get('criteria').value.withOperator">
                </app-event-status-autocomplete>
                <app-input-number [group]="editForm"
                                  [controlName]="'value'"
                                  [min]="0"
                                  [max]="editForm.get('criteria').value.filter.match('Fte') ? 1: undefined"
                                  [step]="editForm.get('criteria').value.filter.match('Fte') ? 0.1 : 1"
                                  *ngIf="editForm.get('criteria').value.withOperator && !editForm.get('criteria').value.filter.match('Month')">
                </app-input-number>
                <mat-form-field *ngIf="editForm.get('criteria').value.filter.match('userComment')">
                    <input matInput
                           formControlName="value">
                </mat-form-field>
                <mat-form-field *ngIf="editForm.get('criteria').value.filter.match('tags')">
                    <mat-label> {{'scenarios.strategies.userTags.shortLabel' | translate}} </mat-label>
                    <mat-select formControlName="value"
                                multiple>
                        <mat-select-trigger>
                            {{editForm.get('value').value?.length ? (editForm.get('value').value | getTagsNames :
                            userTags) : ''}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let tag of userTags"
                                    [value]="tag.id"
                                    [disabled]="hasReportTag(tag)">
                            <div class="user-tag">
                                <mat-icon color="accent"
                                          [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                          *ngIf="tag.type.id === 2">grid_on</mat-icon>
                                <mat-icon color="accent"
                                          [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                          *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                                <span> {{tag.name}} </span>
                            </div>
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
        <div>
            <button type="submit"
                    mat-raised-button
                    color="accent"
                    [disabled]="editForm.invalid || isReplacing">
                <mat-spinner *ngIf="isReplacing" [diameter]="24" color="accent" ></mat-spinner>
                <mat-icon *ngIf="!isReplacing">edit</mat-icon>
                {{'scenarios.events.multipleEdit.replace.fullLabel' | translate}}
            </button>
        </div>
    </form>
</section>
<app-events-table [events]="events"
                  [restrictedMode]="true"
                  [isEditMultiple]="true"
                  [indexValue]="indexValue"
                  (eventSelected)="toggleEventForEditing($event)"></app-events-table>