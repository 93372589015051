import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { WorkflowExecution } from '@classes/workflow/workflow-execution';
import { WorkflowService } from '@services/workflow.service';

@Injectable()
export class WorkflowExecutionsResolver implements Resolve<WorkflowExecution[]> {
    constructor(
        private uiSrv: UiService,
        private workflowSrv: WorkflowService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WorkflowExecution[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.workflowSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
