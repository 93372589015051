import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as signalR from '@microsoft/signalr';

import { environment } from '@environments/environment';

import { AuthService } from './auth.service';

import { SignalrNotifcation } from '@interfaces/notifications/signalrNotifcation';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {
    authChanged = new Subject<boolean>();
    notificationsSubject = new Subject<SignalrNotifcation>();

    private signalRConnection: signalR.HubConnection;
    private hubUrl: string =  environment.apiUrl + 'hub/notifications';
    private connected = false;
    private disconnected = false;

    constructor(
        private authSrv: AuthService
    ) { }

    init(): void {
        this.stop();

        this.signalRConnection = new signalR.HubConnectionBuilder()
            .withUrl(this.hubUrl, {
                accessTokenFactory: () => {
                    return localStorage.getItem(environment.tokenKey);
                }
            })
            .build();
        
        this.signalRConnection.start().then(() => {
            this.connected = true;
            this.disconnected = false;
        });

        this.signalRConnection.onclose((error) => {
            if (!this.disconnected) {
                setTimeout(() => {
                    if (this.connected) {
                        new Promise((resolve, reject) => {
                            this.authSrv.refreshToken().subscribe(() => {
                                resolve(true);
                            })
                        }).then(() => {
                            this.reconnect();
                        })
                    }
                }, 3000);
            }
        })

        this.signalRConnection.on("ReceiveMessage", (response: SignalrNotifcation) => {
            this.notificationsSubject.next(response);
        });
    }

    stop(): void {
        this.disconnected = true;
        if(this.connected) {
            this.signalRConnection.stop().then(() => {
                this.connected = false;
                this.notificationsSubject.next(null);
            });
        }
    }

    reconnect(): void {
        this.signalRConnection.start().then(() => {
            this.connected = true;
            this.disconnected = false;
        });
    }
}
