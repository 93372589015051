import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';

import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { Scale } from '@classes/scenario/scale';

@Component({
    selector: 'app-budget-assignement-change',
    templateUrl: './budget-assignement-change.component.html',
    styleUrls: ['./budget-assignement-change.component.scss']
})
export class BudgetAssignementChangeComponent extends SubscriptionBaseComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    isIndividualEvent: boolean;

    constructor(
        private forecastEventSrv: ForecastEventService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initSubscription();
        this.group.markAllAsTouched();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[], old?: boolean): void {
        if (old) {
            this.group.controls.oldFunctionalArticleRepartition.setValue(articlesRepartition);
        } else {
            this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
        }
    }

    private functionalArticleRepartitionValidator(): ValidatorFn {
        return (group: UntypedFormGroup): { [key: string]: any } | null => {
            if (group.controls.newFunctionalArticleRepartition.value?.length === group.controls.oldFunctionalArticleRepartition.value?.length &&
                group.controls.newFunctionalArticleRepartition.value?.length > 0 && group.controls.oldFunctionalArticleRepartition.value?.length > 0) {
                const same = group.controls.oldFunctionalArticleRepartition.value?.every((ov: FunctionalArticleRepartition) => {
                    return group.controls.newFunctionalArticleRepartition.value?.some((nv: FunctionalArticleRepartition) => {
                        if (ov.ArticleFunctionalCode === nv.ArticleFunctionalCode && ov.Ratio === nv.Ratio) {
                            return true;
                        } else {
                            return false;
                        }
                    });
                });
                return same ? {sameFunctionalArticleRepartition: true} : null;
            } else if (group.controls.newFunctionalArticleRepartition.value?.length !== group.controls.oldFunctionalArticleRepartition.value?.length &&
                group.controls.newFunctionalArticleRepartition.value?.length > 0 && group.controls.oldFunctionalArticleRepartition.value?.length > 0) {
                return null;
            } else {
                return {sameFunctionalArticleRepartition: true};
            }
        };
    }

    private initForm(): void {
        this.group.addControl('newFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));

        if (!this.isIndividualEvent) {
            this.group.addControl('newFte', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('oldFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newScalePayment', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newSuperiorScalePayment', new UntypedFormControl(null));
            this.group.addControl('newParallelScalePayment', new UntypedFormControl(null));
            this.group.addControl('newSeniority', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newSalaryBase', new UntypedFormControl(null));
            this.group.setValidators(this.functionalArticleRepartitionValidator());
        } else {
            this.group.removeControl('newFte');
            this.group.removeControl('newProfessionalStatus');
            this.group.removeControl('oldFunctionalArticleRepartition');
            this.group.removeControl('newScalePayment');
            this.group.removeControl('newSuperiorScalePayment');
            this.group.removeControl('newParallelScalePayment');
            this.group.removeControl('newSeniority');
            this.group.removeControl('newSalaryBase');
        }
        this.group.updateValueAndValidity();
    }

    private initSubscription(): void {
        this.subscription.add(
            this.forecastEventSrv.isIndividualEventSubject
                .subscribe(isIndividualEvent => {
                    if (isIndividualEvent) {
                        this.group.clearValidators();
                        this.group.updateValueAndValidity();
                    }
                    this.isIndividualEvent = isIndividualEvent
                    this.initForm();
                })
        );
    }

}
