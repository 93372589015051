import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Story } from '@classes/scenario/story';
import { StoryCopy } from '@classes/scenario/story-copy';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StoryService {
    private baseUrl = environment.apiUrl + 'story'

    selectedStorySubject = new BehaviorSubject<Story>(null);

    constructor(
        private http: HttpClient
    ) { }

    getAll(): Observable<Story[]> {
        return this.http.get<Story[]>(this.baseUrl);
    }

    getStory(storyId: number): Observable<Story> {
        return this.http.get<Story>(`${this.baseUrl}/${storyId}`);
    }

    post(newStory: Story): Observable<Story> {
        return this.http.post<Story>(this.baseUrl, newStory);
    }

    put(story: Story): Observable<Story> {
        return this.http.put<Story>(this.baseUrl, story);
    }

    delete(storyId: number): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/${storyId}`)
    }

    copy(newStory: StoryCopy): Observable<Story> {
        return this.http.post<Story>(`${this.baseUrl}/copy`, newStory);
    }

}
