import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { EmployerCategoryService } from '@services/employer-category.service';
import { WorkerCategoryService } from '@services/worker-category.service';

import { Contract } from '@classes/contract/contract';
import { EmployerCategory } from '@classes/contract/employer-category';
import { WorkerCategory } from '@classes/contract/worker-category';

@Component({
    selector: 'app-detail-contract',
    templateUrl: './detail-contract.component.html',
    styleUrls: ['./detail-contract.component.scss']
})
export class DetailContractComponent extends SubscriptionBaseComponent implements OnInit {
    contractForm: UntypedFormGroup;
    dateFormat = 'dd-MM-yyyy';

    employerCategories: EmployerCategory[];
    workerCategories: WorkerCategory[];

    constructor(
        public dialogRef: MatDialogRef<DetailContractComponent>,
        private workerCatSrv: WorkerCategoryService,
        private employerCatSrv: EmployerCategoryService,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: {
            contract: Contract,
            isVirtual: boolean
        }
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.data.isVirtual) {
            this.initSubscription();
            this.initForm();
        }
    }

    getControl(control: string): UntypedFormControl {
        return this.contractForm.get(control) as UntypedFormControl;
    }

    onSubmit(): void {
        this.data.contract.workerCategory = this.contractForm.value.workerCategory;
        this.data.contract.employerCategory = this.contractForm.value.employerCategory;
        this.dialogRef.close(this.data)
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    private initForm(): void {
        this.contractForm = this.fb.group({
            workerCategory: [WorkerCategory.fromObject(this.data.contract.workerCategory), Validators.required],
            employerCategory: [EmployerCategory.fromObject(this.data.contract.employerCategory), Validators.required]
        });

        
    }

    private initSubscription(): void {
        this.subscription.add(
            this.workerCatSrv.workerCategorySubject.subscribe((categoriesFromSrv: WorkerCategory[]) => {
                this.workerCategories = [];
                categoriesFromSrv.map((category: WorkerCategory) => {
                    if (category.id > 0) {
                        this.workerCategories.push(WorkerCategory.fromObject(category));
                    }
                });
            })
        )
        .add(
            this.employerCatSrv.employerCategorySubject.subscribe((categoriesFromSrv: EmployerCategory[]) => {
                this.employerCategories = [];
                categoriesFromSrv.map((category: EmployerCategory) => {
                    if (category.id > 0) {
                        this.employerCategories.push(EmployerCategory.fromObject(category));
                    }
                });
            })
        )
    }
}
