import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtModule, JwtInterceptor } from '@auth0/angular-jwt';
import {
  TranslateModule,
  TranslateLoader,
  MissingTranslationHandler
} from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';

import { environment } from '@environments/environment';
import { ApiTranslateLoader } from './shared/apiTranslationLoader';
import { ApiMissingTranslationHandler } from './shared/apiMissingTranslationHandler';
import { httpInterceptorProviders } from './shared/http-interceptors';
import { errorHandlerProviders } from './shared/error-handler';

import { resolverProviders } from './shared/resolvers';
import { appInitializerProviders } from './shared/appInitializerProviders';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './main/main.module';
import { AuthModule } from './auth/auth.module';
import { HelpModule } from './help/help.module';
import { AgentModule } from './agent/agent.module';
import { DashboardModule } from './dashboard/dashboard.module';

import { AppComponent } from './app.component';
import { ErrorComponent } from './error/error.component';

import { registerLocaleData } from '@angular/common';
import localeBe from '@angular/common/locales/be';
import { CustomMatPaginator } from './shared/customMatPaginator';
registerLocaleData(localeBe, 'be');

export function tokenGetter(): string {
  return localStorage.getItem(environment.tokenKey);
}

// AoT requires an exported function for factories
export function TranslateLoaderFactory(http: HttpClient): ApiTranslateLoader {
  return new ApiTranslateLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    ErrorComponent,
    // EstimatorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    AuthModule,
    HelpModule,
    AgentModule,
    MainModule,
    DashboardModule,
    AppRoutingModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: [environment.apiDomain],
        disallowedRoutes: [environment.identityApiUrl + 'connect/token']
      }
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient]
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: ApiMissingTranslationHandler
      }
    }),
    FontAwesomeModule
  ],
  providers: [
    httpInterceptorProviders,
    errorHandlerProviders,
    JwtInterceptor,
    resolverProviders,
    appInitializerProviders,
    {
      provide: MatPaginatorIntl, useClass: CustomMatPaginator
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
