import { Component, OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';

import { environment } from '@environments/environment';
import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';
import { NavLink } from '@classes/nav-link';
import { AuthService } from '@services/auth.service';
import { AppConfigService } from '@services/app-config.service';
import { NavService } from '@services/nav.service';
import { AuthUser } from '@classes/auth/auth-user';

@Component({
    selector: 'app-main-nav',
    templateUrl: './main-nav.component.html',
    styleUrls: ['./main-nav.component.scss']
})
export class MainNavComponent extends SubscriptionBaseComponent implements OnInit, OnChanges {
    @Input() isAuth: boolean;
    @Output() sidenavClose = new EventEmitter<void>();

    navLinks: NavLink[];
    isMaintenance: boolean;
    isLocalTranslations: boolean;
    appVersion: string;

    constructor(
        private authSrv: AuthService,
        private configSrv: AppConfigService,
        private navSrv: NavService
    ) {
        super();
    }

    ngOnInit() {
        this.appVersion = environment.version;
        this.isLocalTranslations = environment.localTranslations;
        this.isMaintenance = this.configSrv.isMaintenance;

        this.initSubscriptions();
    }

    ngOnChanges(): void {
        // this.navLinks = this.navSrv.getNavLinks();
    }

    onCloseSidenav(): void {
        this.sidenavClose.emit();
    }

    private initSubscriptions() {
        this.subscription.add(
            this.authSrv.authUserChanged.subscribe(() => {
                this.navLinks = this.navSrv.getNavLinks();
            })
        );
    }
}
