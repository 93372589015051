import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from 'app/shared/guards/auth.guard';

import { DashboardComponent } from './dashboard.component';
import { DashboardResolver } from 'app/shared/resolvers/dashboard.resolver';
import { Roles } from '@enums/profile/roles';

const routes: Routes = [
    {
        path: 'home', component: DashboardComponent,
        canActivate: [AuthGuard],
        data: {
            roles: [Roles.visuAccueil, Roles.administrateurClient, Roles.visuClient]
        },
        resolve: { dashboard: DashboardResolver }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class DashboardRoutingModule { }
