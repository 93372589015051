export const environment = {
    name: 'spw-qual',

    production: true,
    apiDomain: 'sgi-serv1028.segi.ulg.ac.be',
    apiUrl: 'https://sgi-serv1028.segi.ulg.ac.be/api/',
    identityApiUrl: 'https://sgi-serv1028.segi.ulg.ac.be/identity/',

    bizzmineUrl: 'https://bizzmine.bizzdev.net/login?projectinfo=',
    bizzmineApiKey: '6e0ed6c42a73965603ca175f70bedeaf2dccf870',
    bizzmineProjectName: 'Thot',
    customFieldClient: 10,
    customFieldUser: 9,
    customFieldEmail: 12,
    customFieldLanguage: 13,

    tokenKey: 'bizz-thot-token',
    tokenExpKey: 'bizz-thot-token-exp',
    rfTokenKey: 'bizz-thot-rf-token',

    language: 'language', // clés session storagevisu
    editable: 'editable',
    directoryModel: 'directoryModel',
    wordSearched: 'wordSearched',

    localTranslations: false,
    versionNumber: "1.0.0.0",
    version: require('../../package.json').version,
    build: require('../../package.json').build
};
