import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { WorkerCategory } from '@classes/contract/worker-category';

@Injectable({
    providedIn: 'root'
})
export class WorkerCategoryService {
    private baseUrl = environment.apiUrl + 'worker-category'

    workerCategorySubject = new BehaviorSubject<WorkerCategory[]>([]);

    constructor(
        private http: HttpClient
    ) { }

    get(fromCotisationRate?: boolean): Observable<WorkerCategory[]> {
        const params = new HttpParams()
            .append('fromCotisationRate', fromCotisationRate ? `${fromCotisationRate}` : 'false');

        return this.http.get<WorkerCategory[]>(this.baseUrl, {params});
    }
}
