import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppRightsService } from '@services/app-rights.service';
import { DashboardService } from '@services/dashboard.service';
import { ReportService } from '@services/report.service';

import { ReportDataType } from '@components/bizz-chart/report-data-type.enum';

import { ReportLayoutType } from '@enums/report-layout-type.enum';

import { AppRights } from '@classes/app-rights';
import { DashboardHeaderItem } from '@classes/dashboard/dashboard-header-item';
import { DashboardItemConfig } from '@classes/dashboard/dashboard-item-config';
import { Report } from '@classes/report/report';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnChanges, OnInit, OnDestroy {
    @Input() headers: DashboardHeaderItem[];
    @Input() isDefault: boolean;

    appRights: Partial<AppRights>

    reports: Report[];
    reportsList: Report[];

    dataType = ReportDataType;

    dateFormat = 'MM-yyyy';

    editMode: boolean;
    isLoading: boolean;
    isLoadingDefault: boolean;

    selectReport = new UntypedFormControl();

    private unsubscribeAll = new Subject<void>;

    constructor(
        private dashboardSrv: DashboardService,
        private reportSrv: ReportService,
        private appRightsSrv: AppRightsService
    ) { }

    ngOnInit(): void {
        this.initSubsciption();
        this.ObserveSelectReport();
    }

    ngOnChanges(changes: SimpleChanges): void {
        for (const propName in changes) {
            if (changes.hasOwnProperty(propName)) {
                switch (propName) {
                    case 'headers':
                        this.checkAvailableReports();
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    deleteHeader(header: DashboardHeaderItem): void {
        this.dashboardSrv.delete(header.config)
            .subscribe(() => {
                this.headers = this.headers.filter(h => h.config.reportId !== header.config.reportId);
                this.checkAvailableReports();
            });
    }

    private ObserveSelectReport(): void {
        this.selectReport.valueChanges
            .subscribe((reportId: number) => {
                if (reportId) {
                    this.isLoading = true;
                    const config = new DashboardItemConfig(
                        this.headers.length,
                        this.isDefault,
                        reportId,
                        ReportLayoutType.kpiHeader
                    );

                    this.dashboardSrv.post(config)
                        .subscribe((headers: DashboardHeaderItem[]) => {
                            if (headers && headers.length) {
                                headers.forEach(header => {
                                    this.headers = [...this.headers, header];
                                });
                                const index = this.reportsList.findIndex(r => r.id === reportId);
                                this.reportsList.splice(index, 1);
                                this.isLoading = false;
                            }
                            this.selectReport.setValue('');
                        });
                }
            });
    }

    private checkAvailableReports(): void {
        if (this.reports && this.reports.length) {
            this.reportsList = this.reports.filter(report => {
                return !this.headers.find(header => header.config.reportId === report.id);
            });
        }
    }

    private initSubsciption(): void {
        this.appRightsSrv.appRights$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((appRights: AppRights) => {
                this.appRights = {
                    dashboard: appRights.dashboard
                }
            });

        if (this.appRights.dashboard.canEditHome) {
            this.reportSrv.getAll(ReportLayoutType.kpiHeader)
                .subscribe((reportsFromSrv: Report[]) => {
                    this.reports = reportsFromSrv;
                    this.checkAvailableReports();
                });
        }

        this.dashboardSrv.editModeSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((isEditMode: boolean) => {
                this.editMode = isEditMode;
            });

        this.dashboardSrv.isLoadingDefaultSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((isLoadingDefault: boolean) => {
                this.isLoadingDefault = isLoadingDefault;
                if (isLoadingDefault) {
                    this.selectReport.disable();
                } else {
                    this.selectReport.enable();
                }
            });
    }

}
