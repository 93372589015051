<mat-card class="login-form">
    <mat-card-header>
        <mat-card-title>
            <h2 *ngIf="!isExpired">
                {{'auth.changePassword.title.fullLabel' | translate}}
            </h2>
            <h2 *ngIf="isExpired">
                {{'auth.changePassword.linkExpired.fullLabel' | translate}}
            </h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="passwordForm"
              (ngSubmit)="onSubmit()"
              *ngIf="!isExpired; else expiredMessage">
            <div class="form-content">
                <div>
                    <mat-form-field *ngIf="!isWelcome">
                        <mat-label>{{'auth.changePassword.currentPassword.fullLabel' | translate}}</mat-label>
                        <input type="password"
                               matInput
                               formControlName="currentPassword"
                               autocomplete="new-password">
                        <mat-error>{{'auth.changePassword.requiredPassword.fullLabel' | translate}}</mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'auth.changePassword.newPassword.fullLabel' | translate}}</mat-label>
                        <input [type]="hideNewPassword ? 'password' : 'text'"
                               matInput
                               formControlName="newPassword"
                               autocomplete="new-password">
                        <button type="button"
                                mat-icon-button
                                matSuffix
                                (click)="toggleNewPasswordVisibility($event)">
                            <mat-icon aria-label="Hide password"> {{hideNewPassword ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </button>
                        <mat-error *ngIf="passwordForm.controls.newPassword.errors?.required">
                            {{'auth.changePassword.requiredPassword.fullLabel' | translate}}
                        </mat-error>
                        <mat-error
                                   *ngIf="passwordForm.controls.newPassword.errors?.forbiddenPassword && !passwordForm.controls.newPassword.errors?.required">
                            {{'auth.changePassword.forbiddenPassword.summary' | translate}}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{'auth.changePassword.confirmPassword.fullLabel' | translate}}</mat-label>
                        <input [type]="hideConfirmPassword ? 'password' : 'text'"
                               matInput
                               formControlName="confirmPassword"
                               [errorStateMatcher]="confirmValidMatcher">
                        <button type="button"
                                mat-icon-button
                                matSuffix
                                (click)="toggleConfirmPasswordVisibility($event)">
                            <mat-icon aria-label="Hide password"> {{hideConfirmPassword ? 'visibility_off' : 'visibility'}}
                            </mat-icon>
                        </button>
                        <mat-error>{{'auth.changePassword.passwordMismatch.summary' | translate}}</mat-error>
                    </mat-form-field>
                </div>
                <div class="password-rules" [innerHTML]="'auth.changePassword.forbiddenPassword.detail' | translate">
                </div>
            </div>
            <div class="form-actions">
                <button *ngIf="!isLoading"
                        type="submit"
                        mat-raised-button
                        color="accent"
                        [disabled]="passwordForm.invalid">
                    {{'auth.changePassword.submit.fullLabel' | translate}}
                </button>
                <mat-spinner *ngIf="isLoading"
                             [diameter]="35"
                             color="primary"></mat-spinner>
            </div>
        </form>
        <ng-template #expiredMessage>
            <div class="expired-message">
                <p>
                    {{'auth.changePassword.linkExpired.summary' | translate}}
                </p>
            </div>
        </ng-template>
    </mat-card-content>
</mat-card>