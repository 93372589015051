import { ErrorHandler } from '@angular/core';
import { JL } from 'jsnlog';

import { environment } from '@environments/environment';

import { UncaughtExceptionHandler } from './error.handler';

JL.setOptions({ defaultAjaxUrl: environment.apiUrl + 'jsnlog.logger' })
JL().setOptions({ level: JL.getWarnLevel() })

export const errorHandlerProviders = [
    { provide: ErrorHandler, useClass: UncaughtExceptionHandler },
];