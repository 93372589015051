<div class="app-container">
    <mat-sidenav-container class="sidenav-container" *ngIf="!isWiki; else wiki">
        <mat-sidenav
            #sidenav
            [mode]="mobileQuery.matches ? 'over' : 'side'"
            [opened]="mobileQuery.matches ? 'false' : 'true'"
            class="mat-elevation-z3"
            [fixedInViewport]="true">
            <app-main-nav [isAuth]="isAuth" (sidenavClose)="toggleSidenav(sidenav)"></app-main-nav>
        </mat-sidenav>

        <mat-sidenav-content>
            <app-main-header [isAuth]="isAuth" (sidenavToggle)="toggleSidenav(sidenav, true)"></app-main-header>
            <main>
                <app-maintenance *ngIf="isMaintenance"></app-maintenance>
                <router-outlet *ngIf="!isMaintenance"></router-outlet>
            </main>
        </mat-sidenav-content>
    </mat-sidenav-container>

    <ng-template #wiki>
        <router-outlet *ngIf="!isMaintenance"></router-outlet>
    </ng-template>
</div>
<div class="loading" *ngIf="isRouteLoading || isTranslationLoading">
    <mat-progress-bar class="progress-bar" mode="indeterminate"></mat-progress-bar>
</div>
