import { UserTagType } from "@interfaces/parameters/user-tag-type";

export class UserTag {
    color: string;
    id: number;
    name: string;
    nbEvents: number;
    type: UserTagType

    constructor(name?: string, color?: string, type?: UserTagType) {
        this.name = name?.trim();
        this.color = color;
        this.type = type;
    }

    static fromObject(object: UserTag): UserTag {
        return Object.assign(new UserTag(), object);
    }

    get display(): string {
        return `${this.name}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.name], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
