import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MaintenanceComponent } from './maintenance/maintenance.component';
import { HelpComponent } from './help.component';

import { SharedModule } from 'app/shared/shared.module';
import { HelpRoutingModule } from './help-routing.module';

@NgModule({
    declarations: [MaintenanceComponent, HelpComponent],
    imports: [
        CommonModule,
        SharedModule,
        HelpRoutingModule
    ],
    exports: [
        MaintenanceComponent
    ]
})
export class HelpModule { }
