import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Universe } from '@classes/report/universe';
import { UiService } from './ui.service';
import { Mapping } from '@classes/mapping/mapping';
import { MappingService } from './mapping.service';

@Injectable({
    providedIn: 'root'
})
export class UniverseService {
    selectedUniverseSubject = new Subject<Universe>();

    private baseUrl = environment.apiUrl + 'universe';
    private universeId: number;

    private universesSubject = new BehaviorSubject<Universe[]>([]);
    readonly universes: Observable<Universe[]> = this.universesSubject.asObservable();

    constructor(
        private http: HttpClient,
        private uiSrv: UiService,
        private mappingSrv: MappingService
    ) {}

    get(): Observable<Universe[]> {
        return this.http.get<Universe[]>(this.baseUrl)
        .pipe(
            tap(universesFromApi => {
                this.universesSubject.next(universesFromApi);
            })
        );
    }

    getUniverseById(universeID: number, includeMetadata = true): Observable<Universe> {
        const httpParams = new HttpParams()
            .append('includeMetadata', includeMetadata.toString());

        return this.http.get<Universe>(`${this.baseUrl}/${universeID}`, {params: httpParams});
    }

    /**
     * refresh metadatas
     * @param universe selected universe
     * @param isPartial refresh metadata with isColumnVisible property set to true only
     */
    refreshMetaData(universe: Universe, isPartial = false): Observable<Universe> {
        const httpParams = new HttpParams()
            .append('isPartial', isPartial.toString());

        return this.http.post<Universe>(`${this.baseUrl}/refresh-metadata`, universe, {params: httpParams});
    }

    post(universe: Universe): Observable<Universe> {
        return this.http.post<Universe>(this.baseUrl, universe)
        .pipe(
            tap(createdUniverse => {
                this.universesSubject.next([ ...this.universesSubject.getValue(), createdUniverse ]);
                this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
            })
        );
    }

    put(universe: Universe): Observable<Universe> {
        return this.http.put<Universe>(this.baseUrl, universe)
        .pipe(
            tap(editedUniverse => {
                const universes = this.universesSubject.getValue()
                    .map(u => u.id === editedUniverse.id ? editedUniverse : u);
                this.universesSubject.next([ ...universes ]);
                this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
            })
        );
    }

    getStructure(id: number): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);
        this.mappingSrv.structureChanged.next(null);

        return this.http.get<Mapping>(`${this.baseUrl}/${id}/metadata-structure`)
        .pipe(
            tap(structureFromApi => {
                this.universeId = id;
                this.uiSrv.isLoadingSubject.next(false);
                this.mappingSrv.structureChanged.next(structureFromApi);
            })
        );
    }

    putStructure(structure: Mapping): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);

        return this.http.put<Mapping>(`${this.baseUrl}/${this.universeId}/metadata-structure`, structure)
        .pipe(
            tap(structureFromApi => {
                this.uiSrv.isLoadingSubject.next(false);
                this.mappingSrv.structureChanged.next(structureFromApi);
                this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
            })
        );
    }
}
