import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { DateTime } from 'luxon';


@Directive({
    selector: '[appTimelineContract]'
})
export class TimelineContractDirective {
    @Input() startDate: string;
    @Input() endDate: string;

    @Input() set year(year: number) {
        const start = DateTime.fromISO(this.startDate);
        const end = DateTime.fromISO(this.endDate);

        if (start.year === year) {
            const left = this.monthLength * start.month;
            let width = 100 - left;
            this.renderer.setStyle(this.el.nativeElement, 'left', `${left}%`);
            if (start.year === end.year) {
                let right = (end.month - start.month) * this.monthLength;
                right = right === 0 ? 1 * this.monthLength : right;
                width = right;
                this.renderer.setStyle(this.el.nativeElement, 'border-radius', `5px`);
            } else {
                this.renderer.setStyle(this.el.nativeElement, 'border-radius', `5px 0 0 5px`);
            }
            this.renderer.setStyle(this.el.nativeElement, 'width', `${width}%`);
        } else if (end.year === year) {
            let right = this.monthLength * (12 - end.month);
            const width = 100 - right;
            this.renderer.setStyle(this.el.nativeElement, 'width', `${width}%`);
            this.renderer.setStyle(this.el.nativeElement, 'border-radius', `0 5px 5px 0`);
        } else if (start.year < year && end.year > year) {
            this.renderer.setStyle(this.el.nativeElement, 'width', `100%`);
        } else {
            this.renderer.setStyle(this.el.nativeElement, 'width', 0);
        }
    }

    private yearLength = 100;
    private monthLength = 8.333;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) {}

}
