import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';

import { ProfessionalStatus } from '@classes/contract/professional-status';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';
import { Scale } from '@classes/scenario/scale';
@Component({
    selector: 'app-return-from-leave',
    templateUrl: './return-from-leave.component.html',
    styleUrls: ['./return-from-leave.component.scss']
})
export class ReturnFromLeaveComponent extends SubscriptionBaseComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    isIndividualEvent: boolean;

    constructor(
        private forecastEventSrv: ForecastEventService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initSubscription();
        this.group.markAllAsTouched();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
        this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
    }

    private initForm(): void {
        if (!this.isIndividualEvent) {
            this.group.addControl('oldFte', new UntypedFormControl(0, Validators.required));
            this.group.addControl('newFte', new UntypedFormControl(null, Validators.required));
            this.group.addControl('newProfessionalStatus', new UntypedFormControl(null, Validators.required));
            this.group.addControl('newFunctionalArticleRepartition', new UntypedFormControl(null, Validators.required));
            this.group.addControl('newScalePayment', new UntypedFormControl(null, Validators.required));
            this.group.addControl('newSuperiorScalePayment', new UntypedFormControl(null));
            this.group.addControl('newParallelScalePayment', new UntypedFormControl(null));
            this.group.addControl('newSeniority', new UntypedFormControl(null, Validators.required));
            this.group.addControl("newSalaryBase", new UntypedFormControl(null));
        } else {
            this.group.removeControl('oldFte');
            this.group.removeControl('newFte');
            this.group.removeControl('newProfessionalStatus');
            this.group.removeControl('newFunctionalArticleRepartition');
            this.group.removeControl('newScalePayment');
            this.group.removeControl('newSuperiorScalePayment');
            this.group.removeControl('newParallelScalePayment');
            this.group.removeControl('newSeniority');
            this.group.removeControl('newSalaryBase');
        }

    }

    private initSubscription(): void {
        this.subscription.add(
            this.forecastEventSrv.isIndividualEventSubject
                .subscribe(isIndividualEvent => {
                    this.isIndividualEvent = isIndividualEvent
                    this.initForm();
                })
        );
    }

}
