export class PrestationType {
    externalDescription: string;
    externalId: string;
    id: number;
    isPaid: boolean;
    isPerformed: boolean;

    // front only
    // selected: boolean;

    constructor()
    constructor(externalDescription?: string, externalId?: string, id?: number, isPaid?: boolean, isPerformed?: boolean) {
        this.externalDescription = externalDescription;
        this.externalId = externalId;
        this.id = id;
        this.isPaid = isPaid;
        this.isPerformed = isPerformed;
    }

    static fromObject(object: PrestationType): PrestationType {
        return Object.assign(new PrestationType(), object);
    }

    get display(): string {
        return `${this.externalDescription} (${this.externalId})`;
    }

    get shortDisplay(): string {
        return `${this.externalId}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.externalDescription, this.externalId], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
