import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ReportLayoutType } from '@enums/report-layout-type.enum';

import { Dashboard } from '@classes/dashboard/dashboard';
import { ReportItem } from '@components/bizz-chart/report-item';
import { DashboardItemConfig } from '@classes/dashboard/dashboard-item-config';
import { DashboardHeaderItem } from '@classes/dashboard/dashboard-header-item';


@Injectable({
    providedIn: 'root'
})
export class DashboardService {
    editModeSubject = new Subject<boolean>();
    isLoadingDefaultSubject = new Subject<boolean>();

    // private dashboardSubject = new BehaviorSubject<Dashboard>(null);
    // readonly dashboard$ = this.dashboardSubject.asObservable();

    private baseUrl = environment.apiUrl + 'dashboard';

    constructor(
        private http: HttpClient
    ) {}

    getAll(isDefault: boolean): Observable<Dashboard> {
        this.isLoadingDefaultSubject.next(true);

        const httpParams = new HttpParams()
            .append('isDefault', isDefault.toString());

        return this.http.get<Dashboard>(this.baseUrl, {params: httpParams})
            .pipe(
                tap((result: Dashboard) => {
                    this.isLoadingDefaultSubject.next(false);
                    // this.dashboardSubject.next(result);
                })
            );
    }

    get(reportId: number, type: ReportLayoutType): Observable<ReportItem | DashboardHeaderItem> {
        const httpParams = new HttpParams()
            .append('reportId', reportId.toString())
            .append('type', type);

        return this.http.get<ReportItem | DashboardHeaderItem>(`${this.baseUrl}/item`, {params: httpParams});
    }

    post(dashboardItem: DashboardItemConfig): Observable<any> {
        return this.http.post<DashboardItemConfig>(`${this.baseUrl}/config/item`, dashboardItem);
    }

    delete(dashboardItem: DashboardItemConfig): Observable<any> {
        const options = {
            body: dashboardItem
        };

        return this.http.request('delete', `${this.baseUrl}/config/item`, options);
    }
}
