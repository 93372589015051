import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { ArticleFunctional } from '@classes/article/article-functional';
import { ArticleMapping } from '@classes/article/article-mapping';
import { ArticleEconomical } from '@classes/article/article-economical';
import { ArticleBudgetary } from '@classes/article/article-budgetary';

@Injectable({
  providedIn: 'root'
})
export class ArticleFunctionalService {
  private articlelUrl = environment.apiUrl + 'article';
  private articleFunctionalUrl = environment.apiUrl + 'article-functional';
  private articleEconomicalUrl = environment.apiUrl + 'article-economical';

  constructor(
    private http: HttpClient
  ) { }

  getFunctional(): Observable<ArticleFunctional[]> {
    return this.http.get<ArticleFunctional[]>(this.articleFunctionalUrl);
  }

  getHistory(articleId: number, startDate: string, endDate: string): Observable<ArticleMapping[]> {
    let params = new HttpParams()
      .append('articleId', `${articleId}`)
      .append('startDate', startDate)
      .append('endDate', endDate);

    return this.http.get<ArticleMapping[]>(`${this.articleFunctionalUrl}/history`, { params });
  }

  postFunctional(articleFunctional: ArticleFunctional): Observable<ArticleFunctional> {
    return this.http.post<ArticleFunctional>(this.articleFunctionalUrl, articleFunctional);
  }

  putFunctional(articleFunctional: ArticleFunctional): Observable<ArticleFunctional> {
    return this.http.put<ArticleFunctional>(this.articleFunctionalUrl, articleFunctional);
  }

  postBatchFunctional(articlesFunctional: ArticleFunctional[]): Observable<ArticleFunctional[]> {
    return this.http.post<ArticleFunctional[]>(this.articleFunctionalUrl + '/batch', articlesFunctional);
  }

  putBatchFunctional(articlesFunctional: ArticleFunctional[]): Observable<ArticleFunctional[]> {
    return this.http.put<ArticleFunctional[]>(this.articleFunctionalUrl + '/batch', articlesFunctional);
  }

}
