import { ClientsResolver } from './clients.resolver';
import { UsersResolver } from './users.resolver';
import { BudgetStructuresResolver } from './budget-structures.resolver';
import { ReportCategoriesResolver } from './report-categories.resolver';
import { ReportsAdvancedResolver } from './reports-advanced.resolver';
import { PaymentSectionResolver } from './payment-section.resolver';
import { ProfessionalStatusResolver } from './professional-status.resolver';
import { WorkflowExecutionsResolver } from './workflow-executions.resolver';
import { ReportStructuresResolver } from './report-structure.resolver';
import { MonitoringResolver } from './monitoring.resolver';
import { UniverseResolver } from './universe.resolver';
import { OrganigramsResolver } from './organigram.resolver';
import { ReportsResolver } from './reports.resolver';
import { DashboardResolver } from './dashboard.resolver';
import { UiItemsResolver } from './ui-items.resolver';
import { ScenarioResolver } from './scenario.resolver';
import { StoryLineResolver } from './story-line.resolver';
import { FunctionalArticlesResolver } from './functional-articles.resolver';
import { SubstitutionScalePaymentResolver } from './substitution-scale-payment.resolver';
import { HiringPlanResolver } from './hiring-plan.resolver';
import { PensionResolver } from './pension.resolver';
import { ReportCustomResolver } from './report-custom.resolver';
import { UserTagResolver } from './user-tag.resolver';
import { ScaleAmountResolver } from './scale-amount.resolver';
import { ConjoncturalParametersResolver } from './conjonctural-parameters.resolver';

export const resolverProviders = [
    ClientsResolver,
    UsersResolver,
    BudgetStructuresResolver,
    ReportCategoriesResolver,
    ReportsAdvancedResolver,
    PaymentSectionResolver,
    ProfessionalStatusResolver,
    WorkflowExecutionsResolver,
    ReportStructuresResolver,
    MonitoringResolver,
    UniverseResolver,
    OrganigramsResolver,
    ReportsResolver,
    DashboardResolver,
    UiItemsResolver,
    ScenarioResolver,
    StoryLineResolver,
    FunctionalArticlesResolver,
    SubstitutionScalePaymentResolver,
    HiringPlanResolver,
    PensionResolver,
    ReportCustomResolver,
    UserTagResolver,
    ScaleAmountResolver,
    ConjoncturalParametersResolver
];
