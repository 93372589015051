import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { LegacyPageEvent as PageEvent } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute } from '@angular/router';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { thotAnimations } from 'app/shared/animations/thot-animations';

import { AppRightsService } from '@services/app-rights.service';
import { EventService } from '@services/event.service';
import { ForecastEventService } from '@services/forecast-event.service';
import { ProviderEventService } from '@services/provider-event.service';
import { SalaryIndexService } from '@services/salary-index.service';
import { ScenarioService } from '@services/scenario.service';
import { UiService } from '@services/ui.service';
import { UserTagService } from '@services/user-tag.service';

import { EventsTableMode } from '@enums/events-table-mode.enum';
import { ScreenSize } from '@enums/screen-size.enum';

import { Agent } from '@classes/agent/agent';
import { AppRights } from '@classes/app-rights';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { SalaryIndex } from '@classes/parameters/conjonctural-parameters/salary-index';
import { UserTag } from '@classes/parameters/user-tag';
import { EventType } from '@classes/scenario/event-type';
import { EventsList } from '@classes/scenario/events-list';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { Scale } from '@classes/scenario/scale';
import { Scenario } from '@classes/scenario/scenario';
import { ThotEvent } from '@classes/scenario/thot-event';
import { Pagination } from '@interfaces/pagination';

@Component({
    selector: 'app-events-table-server',
    templateUrl: './events-table-server.component.html',
    styleUrls: ['./events-table-server.component.scss'],
    animations: thotAnimations
})
export class EventsTableServerComponent extends SubscriptionBaseComponent implements OnInit, OnChanges, OnDestroy {
    @Input() selectedTab: number // only on /scenarios route
    @Input() mode: EventsTableMode;
    @Input() baseFilter: string;
    @Input() refMonth: string;
    @Input() agent: Agent;
    @Input() indexValue: number;

    appRights: Partial<AppRights>;

    eventsTableMode = EventsTableMode;

    events: ThotEvent[];

    isCreating: boolean;
    isEditing: boolean;
    isEditingMultiple: boolean;
    isCreatedDisplayed: boolean;
    isDeletedDisplayed: boolean;

    eventTypes: EventType[];
    professionalStatuts: ProfessionalStatus[];
    scalePayment: Scale[];
    articlesFunctional: FunctionalArticle[];
    userTags: UserTag[];
    salaryIndexes: SalaryIndex[];

    selectedScenario: Scenario;
    sortingString: string;
    filter: string;

    pageIndex: number = 1;
    pagination: Pagination;
    isLoading = false;

    dataSource = new MatTableDataSource<ThotEvent>();
    displayedColumns = [
        'agent',
        'eventType',
        'newProfessionalStatus',
        'eventMonth',
        'newFte',
        'scale',
        'strategyId',
        'salaryBase',
        'actions'
    ];
    selectedEvent: ThotEvent;
    editedEvent: ThotEvent;

    editedEvents: ThotEvent[] = [];
    createdEvents: ThotEvent[];

    maxMonth: DateTime;
    minMonth: DateTime;

    screenSize: ScreenSize;
    screenSizes = ScreenSize;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private forecastEventSrv: ForecastEventService,
        private providerSrv: ProviderEventService,
        private eventSrv: EventService,
        private scenarioSrv: ScenarioService,
        private userTagSrv: UserTagService,
        private uiSrv: UiService,
        private route: ActivatedRoute,
        private appRightsSrv: AppRightsService,
        private salaryIndexSrv: SalaryIndexService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.getScreenSize();
        this.setMinMaxMonths();
        if (this.mode === EventsTableMode.provider || this.mode === EventsTableMode.realized) {
            const start = this.displayedColumns.length - 3
            this.displayedColumns.splice(start, 1);
            this.initSubscription();
        } else if (this.mode === EventsTableMode.agent) {
            const start = this.displayedColumns.length - 3
            this.displayedColumns.splice(start, 1);
            // this.getEvents();
            // this.initSubscription();
        } else {
            this.initForecastSubscription();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.selectedTab?.previousValue !== changes.selectedTab?.currentValue) {
            this.isCreating = false;
            this.isEditing = false;
            this.isEditingMultiple = false;
        }
        if (changes.baseFilter?.previousValue !== changes.baseFilter?.currentValue) {
            this.filter = this.baseFilter;
            if (this.mode === EventsTableMode.agent) {
                if (this.salaryIndexes?.length) {
                    this.getEvents();
                } else {
                    this.salaryIndexSrv.get().subscribe((indexesFromSrv: SalaryIndex[]) => {
                        this.salaryIndexes = indexesFromSrv;
                        this.getEvents();
                    });
                }
            }
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onFilterChange(filter: string): void {
        this.filter = filter;
        this.pageIndex = 1;
        this.getEvents();
    }

    addEvents(): void {
        this.isCreating = true;
    }

    onEventsAdded(): void {
        this.isCreating = false;
        this.forecastEventSrv.isIndividualEventSubject.next(false);
    }

    toggleEditMultiple(isEditingMultiple): void {
        this.isEditingMultiple = isEditingMultiple;
        if (!this.isEditingMultiple) {
            this.getEvents();
        }
    }

    expandEvent(event: ThotEvent): void {
        if (this.selectedEvent) {
            if (this.selectedEvent.id === event.id || this.selectedEvent.temporaryId === event.temporaryId) {
                this.selectedEvent = null;
            } else {
                this.selectedEvent = event;
            }
        } else {
            this.selectedEvent = event;
        }
        this.editedEvent = null;
        this.isEditing = false;
    }

    editEvent(event: ThotEvent): void {
        if (this.editedEvent) {
            if (this.editedEvent.id === event.id) {
                this.editedEvent = null;
                this.isEditing = false;
            } else {
                this.editedEvent = event;
                this.isEditing = true;
            }
        } else {
            this.editedEvent = event;
            this.isEditing = true;
        }
        this.selectedEvent = null;
        // this.editedEvent = this.editedEvent === event ? null : event;
        // this.isEditing = this.editedEvent === null ? false : true;
    }

    onEditEvent(event: ThotEvent): void {
        // console.log(event)
        const index = this.editedEvents.findIndex(e => event.id === e.id);
        if (index !== -1) {
            this.editedEvents[index] = event;
        } else {
            this.editedEvents.push(event);
        }
        this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
    }

    onCancelEdits(deletedEvents: boolean): void {
        this.events = this.events.map(event => {
            if (deletedEvents) {
                if (event.oldEvent && event.deleted) {
                    return event.oldEvent;
                }
            } else {
                if (event.oldEvent && !event.deleted) {
                    return event.oldEvent;
                }
            }
            return event;
        });
        if (deletedEvents) {
            this.editedEvents = this.editedEvents.filter((event: ThotEvent) => !event.deleted);
        } else {
            this.editedEvents = this.editedEvents.filter((event: ThotEvent) => event.deleted);
        }
        this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
        this.initTable(this.events);
        if (this.createdEvents?.length === 0 && this.editedEvents?.length === 0) {
            this.forecastEventSrv.eventsChangedSubject.next(false);
        }
    }

    resetEvent(event: ThotEvent): void {
        this.selectedEvent === event ? null : this.selectedEvent;
        this.editedEvent === event ? null : this.editedEvent;
        this.isEditing = this.editedEvent === null ? false : true;
        this.editedEvents.splice(this.editedEvents.findIndex(e => e.id === event.id), 1);
        const index = this.events.findIndex(e => e.id === event.id);
        this.events[index] = event.oldEvent;
        this.dataSource.data = this.events;
        this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
    }

    /**
     * Mark an event as deleted
     * @param event event to mark as deleted
     */
    onDelete(event: ThotEvent): void {
        const message = 'scenarios.events.list.actions.confirmDelete.summary';
        this.uiSrv.confirmDialog(message).afterClosed()
            .subscribe((result: boolean) => {
                if (result) {
                    if (event.oldEvent) {
                        event = event.oldEvent;
                    }
                    event.oldEvent = JSON.parse(JSON.stringify(event));
                    if (event.oldEvent.agent) {
                        event.oldEvent.agent = Agent.fromObject(event.oldEvent.agent);
                    }
                    event.deleted = true;
                    event.edited = true;
                    event.manuallyModified = true;
                    this.onEditEvent(event);
                    this.initTable(this.events);
                }
            });
    }

    /**
     * Delete events linked to a contract permanently as opposed to just marked as deleted
     * @param contractToDelete 
     */
    deleteContractEvents(contractToDelete: { scenarioId: number, agentId: number, contractId: number }): void {
        const message = 'scenarios.events.list.actions.confirmDeleteContract.summary';
        this.uiSrv.confirmDialog(message).afterClosed().subscribe((result) => {
            if (result) {
                this.forecastEventSrv.deleteContractEvents(contractToDelete.scenarioId, contractToDelete.agentId, contractToDelete.contractId).subscribe(() => {
                    this.isLoading = true;
                    this.getEvents('scenarios.events.list.actions.contractDeleted.summary');
                });
            }
        })
    }

    onPagechange(event: PageEvent): void {
        this.isLoading = true;
        this.pageIndex = event.pageIndex + 1;
        this.getEvents();
    }

    onSortChange(event: Sort): void {
        this.isLoading = true;
        this.pageIndex = 1;
        if (event.direction) {
            if (event.active === "agent") {
                this.sortingString = `${event.direction === "desc" ? '-' : ''}agent.lastname`;
            } else if (event.active === "scale") {
                this.sortingString = `${event.direction === "desc" ? '-' : ''}scalePayment`;
            } else if (event.active === "salaryBase") {
                this.sortingString = `${event.direction === "desc" ? '-' : ''}newSalaryBase`;
            } else {
                this.sortingString = `${event.direction === "desc" ? '-' : ''}${event.active}`;
            }
        } else {
            this.sortingString = '';
        }
        this.getEvents();
    }

    onDisplayCreatedEvents(isDisplayed: boolean): void {
        this.isCreatedDisplayed = isDisplayed;
    }

    onDisplayDeletedEvents(isDisplayed: boolean): void {
        this.isDeletedDisplayed = isDisplayed;
        this.pageIndex = 1;
        this.getEvents();
    }

    private getEvents(messageToDisplay?: string): void {
        this.isLoading = true;
        if (this.mode === EventsTableMode.provider) {
            this.providerSrv.get(this.refMonth, this.pageIndex, 15, this.sortingString, this.filter)
                .subscribe(() => this.isLoading = false);
        } else if (this.mode === EventsTableMode.realized) {
            this.eventSrv.get(this.pageIndex, 15, this.sortingString, this.filter)
                .subscribe(() => this.isLoading = false);
        } else if (this.mode === EventsTableMode.agent) {
            this.eventSrv.get(this.pageIndex, 15, this.sortingString, this.filter, true).subscribe((responseFromSrv: HttpResponse<ThotEvent[]>) => {
                this.getDataFromResponse(responseFromSrv);
                this.isLoading = false
            });
        } else {
            this.forecastEventSrv.get(this.selectedScenario.id, this.pageIndex, 15, this.filter, this.sortingString, this.isDeletedDisplayed)
                .subscribe(() => {
                    if (messageToDisplay) {
                        this.uiSrv.showSnackbar(messageToDisplay, true);
                    }
                    this.isLoading = false
                });
        }
    }

    private setMinMaxMonths(): void {
        if (this.route.snapshot.queryParams.month) {
            this.refMonth = this.route.snapshot.queryParams.month;
            if (this.mode === EventsTableMode.realized || this.mode === EventsTableMode.agent) {
                this.maxMonth = DateTime.fromISO(this.refMonth);
            } else {
                this.minMonth = DateTime.fromISO(this.refMonth).plus({ months: 1 });
            }
        } else {
            if (this.mode === EventsTableMode.realized || this.mode === EventsTableMode.agent) {
                this.maxMonth = DateTime.fromISO(this.refMonth);
            } else {
                this.minMonth = DateTime.fromISO(this.refMonth).plus({ months: 1 });
            }
        }
    }

    private getDataFromResponse(httpResponse: HttpResponse<ThotEvent[]>): void {
        const eventsList = new EventsList(httpResponse.body, httpResponse.headers.get('x-pagination'));
        if (eventsList?.pagination) {
            this.pagination = eventsList.pagination
        }
        if (eventsList?.events?.length) {
            this.initTable(eventsList.events);
        } else {
            this.dataSource.data = [];
        }
    }

    private initTable(events: ThotEvent[]): void {
        if (this.editedEvents?.length) {
            this.events = events.map(event => {
                const editedIndex = this.editedEvents.findIndex(e => e.id === event.id);
                if (editedIndex !== -1) {
                    return this.editedEvents[editedIndex];
                } else if (event.oldEvent) {
                    return event.oldEvent;
                }
                if (this.mode === EventsTableMode.agent || this.mode === EventsTableMode.realized) {
                    event.indexValue = this.salaryIndexes.filter((index: SalaryIndex) => index.fromDueMonth <= event.eventMonth)[0]?.indexValue;
                }
                return event;
            });
        } else {
            this.events = events.map(event => {
                if (this.mode === EventsTableMode.agent || this.mode === EventsTableMode.realized) {
                    event.indexValue = this.salaryIndexes.filter((index: SalaryIndex) => index.fromDueMonth <= event.eventMonth)[0]?.indexValue;
                }
                return event;
            });
        }
        this.dataSource.data = this.events;
    }

    private getScreenSize(): void {
        this.uiSrv.screenSize$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((screenSize: ScreenSize) => this.screenSize = screenSize);
    }

    private initSubscription(): void {
        if (this.mode === EventsTableMode.provider) {
            this.providerSrv.eventsListSubject
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe({
                    next: (responseFromSrv: EventsList) => {
                        if (responseFromSrv?.pagination) {
                            this.pagination = responseFromSrv.pagination
                        }
                        if (responseFromSrv?.events?.length) {
                            this.initTable(responseFromSrv.events);
                        } else {
                            this.dataSource.data = [];
                        }
                    }
                });
        } else if (this.mode === EventsTableMode.realized) {
            this.eventSrv.eventsListSubject
                .pipe(takeUntil(this.unsubscribeAll))
                .subscribe({
                    next: (responseFromSrv: EventsList) => {
                        const initEvents = () => {
                            if (responseFromSrv?.pagination) {
                                this.pagination = responseFromSrv.pagination
                            }
                            if (responseFromSrv?.events?.length) {
                                this.initTable(responseFromSrv.events);
                            } else {
                                this.dataSource.data = [];
                            }
                        };
                        if (this.salaryIndexes?.length) {
                            initEvents();
                        } else {
                            this.salaryIndexSrv.get().subscribe((indexesFromSrv: SalaryIndex[]) => {
                                this.salaryIndexes = indexesFromSrv;
                                initEvents();
                            });
                        }
                    }
                });
        }
    }

    private initForecastSubscription(): void {
        this.appRightsSrv.appRights$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((appRights: AppRights) => {
                this.appRights = {
                    scenario: appRights.scenario
                }
                // console.log(this.appRights);
            });

        this.scenarioSrv.selectedScenarioSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scenarioFromSrv: Scenario) => {
                if (scenarioFromSrv) {
                    this.selectedScenario = scenarioFromSrv;
                    this.getEvents();
                } else {
                    this.selectedScenario = null;
                }
            });

        this.forecastEventSrv.eventsListSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((responseFromSrv: EventsList) => {
                this.selectedEvent = null;
                this.editedEvent = null;
                this.isEditing = false;
                if (responseFromSrv?.pagination) {
                    this.pagination = responseFromSrv.pagination
                }
                if (responseFromSrv?.events?.length) {
                    this.initTable(responseFromSrv.events);
                } else {
                    this.dataSource.data = [];
                }
                this.cdr.detectChanges();
            });

        this.forecastEventSrv.editedEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((eventsFromSrv: ThotEvent[]) => {
                this.editedEvents = eventsFromSrv;
                if (this.editedEvents?.length) {
                    this.forecastEventSrv.eventsChangedSubject.next(true);
                } else {
                    this.forecastEventSrv.eventsChangedSubject.next(false);
                }
            });

        this.forecastEventSrv.createdEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((eventsFromSrv: ThotEvent[]) => {
                this.createdEvents = eventsFromSrv;
                if (this.createdEvents?.length) {
                    this.forecastEventSrv.eventsChangedSubject.next(true);
                } else {
                    this.isCreatedDisplayed = false;
                    this.forecastEventSrv.eventsChangedSubject.next(false);
                }
            });

        this.forecastEventSrv.eventTypesSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((typesFromSrv: EventType[]) => {
                if (typesFromSrv) {
                    this.eventTypes = typesFromSrv;
                }
            });

        this.forecastEventSrv.professionalStatusSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((statutsFromSrv: ProfessionalStatus[]) => this.professionalStatuts = statutsFromSrv);

        this.forecastEventSrv.scalePaymentSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scalesFromSrv: Scale[]) => this.scalePayment = scalesFromSrv);

        this.forecastEventSrv.articleFunctionalSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((articlesFromSrv: FunctionalArticle[]) => this.articlesFunctional = articlesFromSrv);

        this.userTagSrv.userTagsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((userTagsFromSrv: UserTag[]) => this.userTags = userTagsFromSrv);

        this.forecastEventSrv.reloadEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => this.getEvents());
    }

}
