import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ForecastEventService } from './forecast-event.service';
import { UiService } from './ui.service';

import { RedoStrategy } from '@interfaces/redo-strategy';

import { Strategy } from '@classes/scenario/strategy';
import { CddExtension } from '@classes/scenario/strategies/cdd-extension';
import { PensionByAge } from '@classes/scenario/strategies/pension-by-age';
import { StrategyBase } from '@classes/scenario/strategies/strategy-base';
import { ThotEvent } from '@classes/scenario/thot-event';
import { PensionSubstitution } from '@classes/scenario/strategies/pension-substitution';
import { OutSubstitution } from '@classes/scenario/strategies/out-substitution';
import { CancellationLayoffs } from '@classes/scenario/strategies/cancellation-layoffs';
import { OutReplacement } from '@classes/scenario/strategies/out-replacement';
import { CareerEvolution } from '@classes/scenario/strategies/career-evolution';
import { Nomination } from '@classes/scenario/strategies/nomination';

@Injectable({
    providedIn: 'root'
})
export class StrategyService {
    private baseUrl = environment.apiUrl + 'strategy';
    private eventUrl = environment.apiUrl + 'forecast-event';

    constructor(
        private http: HttpClient,
        private forecastEventSrv: ForecastEventService,
        private uiSrv: UiService
    ) { }

    get(): Observable<Strategy[]> {
        return this.http.get<Strategy[]>(this.baseUrl);
    }

    pensionByAge(strategyParams: PensionByAge | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/pension-by-age`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {    
                    this.manageEvents(eventsFromAPi);   
                    // if (eventsFromAPi?.length) {
                    //     this.forecastEventSrv.createdEventsSubject.next([...this.forecastEventSrv.createdEventsSubject.value, ...eventsFromAPi]);
                    // } else {
                    //     this.uiSrv.showSnackbar(this.noEventMessage, true);
                    // } 
                })
            );
    }

    outByContract(strategyParams: StrategyBase | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/out-by-contract`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    cddExtension(strategyParams: CddExtension | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/cdd-extension`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    pensionSubstitution(strategyParams: PensionSubstitution | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/pension-substitution`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    outSubstitution(strategyParams: OutSubstitution | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/out-substitution`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    cancellationLayoffs(strategyParams: CancellationLayoffs | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/cancellation-layoffs`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    careerEvolution(strategyParams: CareerEvolution | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/career-evolution`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    outReplacement(strategyParams: OutReplacement | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/out-replacement`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    nomination(strategyParams: Nomination | RedoStrategy): Observable<ThotEvent[]> {
        return this.http.post<ThotEvent[]>(`${this.eventUrl}/nomination`, strategyParams)
            .pipe(
                tap(eventsFromAPi => {
                    this.manageEvents(eventsFromAPi);
                })
            );
    }

    private manageEvents(events: ThotEvent[]): void {
        const message = 'scenarios.strategies.noGeneratedEvent.summary';
        if (events?.length) {
            this.forecastEventSrv.createdEventsSubject.next([...this.forecastEventSrv.createdEventsSubject.value, ...events]);
        } else {
            this.uiSrv.showSnackbar(message, true);
        } 
    }
}
