import { RoleKeys } from '@enums/profile/roles';

export class AuthUser {
  name: string;
  roles: RoleKeys[];
  client: string;
  email: string;
  profile: string;
  language: string

  constructor(name: string, roles: RoleKeys[], client: string, email: string, profile: string, language: string) {
    this.name = name;
    this.roles = roles;
    this.client = client;
    this.email = email;
    this.profile = profile
    this.language = language;
  }
}
