<ng-container *ngIf="link.children; else listItem">
    <mat-expansion-panel class="main-nav-panel mat-elevation-z0" dense [expanded]="isActive">
        <mat-expansion-panel-header>
            <mat-panel-title [ngClass]="{'active-link': isActive}" (click)="onCloseSidenav()">
                <mat-icon aria-label="Nav" *ngIf="link.icon">{{link.icon}}</mat-icon>
                <span class="link-label">{{link.title | translate}}</span>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-nav-list>
            <app-main-nav-item *ngFor="let childLink of link.children" [link]="childLink"></app-main-nav-item>
        </mat-nav-list>
    </mat-expansion-panel>
</ng-container>

<ng-template #listItem>
    <a mat-list-item class="main-nav-item" [routerLink]="link.route" routerLinkActive="router-link-active"
        (click)="onCloseSidenav()">
        <mat-icon aria-label="Nav" *ngIf="link.icon">{{link.icon}}</mat-icon>
        <span class="link-label">{{link.title | translate}}</span>
    </a>
</ng-template>