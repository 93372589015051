import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';

import { DateTime } from 'luxon';

import { PrestationService } from '@services/prestation.service';

import { Contract } from '@classes/contract/contract';
import { DateTimeFormat } from '@classes/date-time-format';
import { PrestationType } from '@classes/prestation/prestation-type';

const DATE_FORMATS = {
    parse: {
        dateInput: 'dd/MM/yyyy',
    },
    display: {
        dateInput: 'dd/MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
    }
};

@Component({
    selector: 'app-prestation-filter',
    templateUrl: './prestation-filter.component.html',
    styleUrls: ['./prestation-filter.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS }
    ]
})
export class PrestationFilterComponent implements OnInit {
    @Input() contracts: Contract[];
    @Output() filter = new EventEmitter<string>();

    prestationTypes: PrestationType[];

    filterForm: FormGroup;

    constructor(
        private prestationSrv: PrestationService,
        private fb: FormBuilder
    ) { }

    ngOnInit(): void {
        this.initSubscription();
    }

    getControl(controlName: string): UntypedFormControl {
        return this.filterForm.get(controlName) as UntypedFormControl;
    }

    onSubmit(): void {
        let filter = '';
        const values = this.filterForm.value;
        Object.keys(values).forEach(v => {
            if (values[v]) {
                if (v === 'contractId' || v === 'prestationTypeId') {
                    filter += `${v}==${values[v].id},`;
                } else if (v === 'dueMonth') {
                    const month = (values[v] as DateTime).startOf("month").toFormat(DateTimeFormat.dateTime);
                    filter += `${v}==${month},`;
                } else {
                    const date = (values[v] as DateTime).toFormat(DateTimeFormat.dateTime);
                    filter += `${v}==${date},`;
                }
            }
        });
        filter = filter.slice(0, filter.length - 1);
        this.filter.emit(filter);
    }

    resetFilter(): void {
        this.filterForm.reset();
        this.filter.emit();
    }

    private initForm(): void {
        this.filterForm = this.fb.group({
            contractId: [],
            dueMonth: [],
            periodStartdate: [],
            periodEnddate: [],
            prestationTypeId: []
        });
    }

    private initSubscription(): void {
        this.prestationSrv.getPrestationType()
            .subscribe((typesFromSrv: PrestationType[]) => {
                this.prestationTypes = typesFromSrv.filter(t => t.id > 0);
                this.initForm();
            })
    }
}
