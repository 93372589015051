import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';

import { SharedModule } from 'app/shared/shared.module';
import { AuthRoutingModule } from './auth-routing.module';

@NgModule({
    declarations: [
        LoginComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        ReactiveFormsModule,
        AuthRoutingModule
    ]
})
export class AuthModule { }
