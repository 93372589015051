export class Scale {
    description?: string;
    externalDescription: string;
    externalId: string;
    id: number;

    constructor()
    constructor(description?: string, externalDescription?: string, externalId?: string, id?: number) {
        this.description = description;
        this.externalDescription = externalDescription;
        this.externalId = externalId;
        this.id = id;
    }

    static fromObject(object: Scale): Scale {
        if (object)
            return Object.assign(new Scale(), object);
        return;
    }

    get display(): string {
        return `${this.externalId}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.externalId], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
