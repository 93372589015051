import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '@services/language.service';

import { Language } from '@classes/language';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',
  styleUrls: ['./language-selector.component.scss']
})
export class LanguageSelectorComponent implements OnInit {

  languageForm: UntypedFormGroup;
  languageList: Language[];
  isLoading: boolean;

  constructor(
      private fb: UntypedFormBuilder,
      private langSrv: LanguageService,
      private translateSrv: TranslateService
  ) { }

  ngOnInit() {
      this.initSubscriptions();
  }

  private initForm(): void {
    const browserLang = this.translateSrv.getBrowserLang();
    const lang = this.languageList.find(l => l.codeISO === browserLang) || this.languageList[0];

    this.languageForm = this.fb.group({
        lang: [lang]
    });

    this.languageForm.controls.lang.valueChanges
    .subscribe((selected: Language) => {
        this.langSrv.setCurrentLanguage(selected.codeISO);
    });
  }

  private initSubscriptions(): void {
    this.isLoading = true;

    this.langSrv.getLanguages()
    .subscribe(languages => {
        this.languageList = languages;
        this.initForm();
        this.isLoading = false;
    });
  }
}
