import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { Mapping } from '@classes/mapping/mapping';
import { ProfessionalStatusService } from '@services/professional-status.service';

@Injectable()
export class ProfessionalStatusResolver implements Resolve<Mapping[]> {
    constructor(
        private uiSrv: UiService,
        private statusSrv: ProfessionalStatusService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mapping[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.statusSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
