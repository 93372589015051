import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ReportService } from './report.service';

import { Agent } from '@classes/agent/agent';
import { SelectedAgent } from '@classes/agent/selected-agent';
import { BudgetStructureService } from './budget-structure.service';

@Injectable({
    providedIn: 'root'
})
export class AgentService {
    private readonly context = 'Agent';

    private baseUrl = environment.apiUrl + 'agent';

    private selectedAgents: SelectedAgent[] = [];

    constructor(
        private http: HttpClient,
        private reportSrv: ReportService,
        private structureSrv: BudgetStructureService
    ) { }

    getAgents(filter: string, clientId?: number): Observable<Agent[]> {
        const httpParams = new HttpParams()
            .append('filter', filter);

        return this.http.get<Agent[]>(this.baseUrl, { params: httpParams })
        .pipe(
            map(agentsFromApi => {
                return agentsFromApi.map(a => Agent.fromObject(a));
            })
        );
    }

    getContractDates(agentId: number): Observable<Date[]> {
        return this.http.get<Date[]>(`${this.baseUrl}/${agentId}/contract-dates`);
    }

    /**
     * Return the contracts & detailled informations for an agent
     * @param agent the selected agent
     * @param indexAgent used to differentiate the agents when getting contextual reports when comparing 2 agents
     */
    getAgentDetails(agent: SelectedAgent, indexAgent: number): Observable<Agent> {
        let httpHeaders = new HttpHeaders();
        let httpParams = new HttpParams();

        if(agent.startMonth && agent.endMonth) {
            httpParams = httpParams
                .append('from', agent.startMonth.toString())
                .append('to', agent.endMonth.toString());
        }

        // this.getReportsFromContext(agent, indexAgent);

        return this.http.get<Agent>(`${this.baseUrl}/${agent.id}`, { headers: httpHeaders, params: httpParams })
        .pipe(
            map(agentFromApi => {
                const agent = Agent.fromObject(agentFromApi);
                return agent;
            })
        );
    }

    resetSelectedAgents(emptyArray?: boolean): void {
        if (emptyArray) {
            this.selectedAgents = [];
        } else if (this.selectedAgents && this.selectedAgents.length && !emptyArray) {
            this.selectedAgents.length = 1;
            this.reportSrv.getReportsFromContext(this.context, this.selectedAgents).subscribe();
        }
    }

    // private getReportsFromContext(agent: SelectedAgent, indexAgent: number): void {
    //     this.selectedAgents[indexAgent] = agent;
    //     this.structureSrv.getList().subscribe(structures => {
    //         structures.map(structure => {
    //             if (structure.isDefault) {
    //                 this.selectedAgents[indexAgent].budgetaryStructureId = structure.id;
    //             }
    //         });
    //         this.reportSrv.getReportsFromContext(this.context, this.selectedAgents).subscribe();
    //     });
    // }
}
