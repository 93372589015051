import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Agent } from '@classes/agent/agent';
import { ReferenceSituation } from '@classes/contract/reference-situation';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReferenceContractService {
    private baseUrl = environment.apiUrl + 'reference-contract';

    constructor(
        private http: HttpClient
    ) { }

    get(agent: Agent, refMonth: string): Observable<ReferenceSituation[]> {
        const params = new HttpParams()
            .append('filters', `agentId==${agent.id},referenceMonth==${refMonth}`);

        return this.http.get<ReferenceSituation[]>(this.baseUrl, { params });
    }

}
