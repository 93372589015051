export class FunctionalArticleRepartition {
    // première majuscule temporaire
    ArticleFunctionalCode : string;
    Ratio: number;

    constructor(
        articleFunctionalCode : string,
        ratio: number
    ) {
        this.ArticleFunctionalCode = articleFunctionalCode;
        this.Ratio = ratio;
    }
}
