import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';

import { BaseInterface } from '@interfaces/base-interface';
import { OperatorValue } from '@interfaces/operator-value';
import { ShowErrorOnChanges } from '@classes/show-error-on-changes';

@Component({
    selector: 'app-autocomplete',
    templateUrl: './autocomplete.component.html',
    styleUrls: ['./autocomplete.component.scss']
})
export class AutocompleteComponent<T extends BaseInterface> implements OnChanges {
    @Input() list: T[];
    @Input() control: UntypedFormControl;
    @Input() operator: UntypedFormControl;
    @Input() label: string;
    @Input() errorMsg: string;
    @Input() number: number;
    @Input() required: boolean = false;
    @Input() tooltipProperty: string;

    filteredList: T[];
    operators: OperatorValue[] = [
        {
            label: '=',
            value: '=='
        },
        {
            label: '≠',
            value: '!='
        }
    ];
    showErrorOnChanges = new ShowErrorOnChanges();

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        this.list?.sort(this.compare)
        if (changes.control?.previousValue != changes.control?.currentValue) {
            this.setfilters();
        }
    }

    displayFn(object: T): string | undefined {
        return object ? object.display : null;
    }

    onAutoCompleteFocus(): void {
        this.filteredList = this.list.slice();
    }

    private setfilters(): void {
        const validators = [this.forbiddenTypeValidator('string')];
        this.control.addValidators(validators);

        this.control.valueChanges
            .pipe(
                startWith(''),
                map((value: string | T) => typeof value === 'string' ? value : value?.display),
                map((value: string) => value ? this.filter(value) : this.list?.slice())
            ).subscribe((list: T[]) => {
                // console.log(list);
                this.filteredList = list
            });
    }

    private filter(value: string): T[] {
        const filterValue = value.toLowerCase();
        // console.log('filterValue:', filterValue);
        const filterList = this.list.filter(item => item.doesInclude(filterValue));
        // console.log('filterList:', filterList);
        return filterList;
    }

    private forbiddenTypeValidator(forbiddenType: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = control.value !== '' && typeof control.value === forbiddenType;
            return forbidden ? { forbiddenType: { value: control.value } } : null;
        };
    }


    compare(a, b) {
        if (a.display < b.display) {
            return -1;
        }
        if (a.display > b.display) {
            return 1;
        }
        return 0;
    }
}
