import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { ReportService } from './report.service';
import { Scenario } from '@classes/scenario/scenario';
import { ScenarioStatus } from '@enums/story-line/scenario-status.enum';
import { ScaleAmountEvolutionGroup } from '@classes/parameters/scale-amount/scale-amount-evolution-group';
import { Router } from '@angular/router';
import { DateTime } from 'luxon';
import { DateTimeFormat } from '@classes/date-time-format';
import { EstimatorConfiguration } from '@classes/estimator/estimator-configuration';

@Injectable({
  providedIn: 'root'
})
export class ScenarioService {
  private readonly context = 'Scenario';

  private baseUrl = environment.apiUrl + 'scenario';

  scenariosSubject = new Subject<Scenario[]>();
  selectedScenarioSubject = new BehaviorSubject<Scenario>(null);
  selectedScenarioIndexSubject = new BehaviorSubject<number>(null);
  dontUpdateScenarioSubject = new Subject<boolean>();

  constructor(
    private reportSrv: ReportService,
    private http: HttpClient,
    private router: Router
  ) { }

  getAll(refMonth?: DateTime): Observable<Scenario[]> {
    let params = new HttpParams()
      .append('refMonth', `${refMonth?.toFormat(DateTimeFormat.dateTime)}`);
    if (params.get('refMonth') === 'undefined') {
      params = params.delete('refMonth');
    }

    return this.http.get<Scenario[]>(this.baseUrl, { params });
  }

  getScenario(scenarioId: number, checkStatus = false): Observable<Scenario> {
    return this.http.get<Scenario>(`${this.baseUrl}/${scenarioId}`)
      .pipe(
        tap((scenarioFromApi: Scenario) => {
          if (checkStatus && scenarioFromApi.status === ScenarioStatus.reconciliation) {
            this.router.navigate(['story-line']);
            this.selectedScenarioSubject.next(null);
          } else {
            this.selectedScenarioSubject.next(scenarioFromApi);
          }
        })
      );
  }

  get(scenarioId: number): Observable<Scenario> {
    return this.http.get<Scenario>(`${this.baseUrl}/${scenarioId}`);
  }

  post(newScenario: Scenario): Observable<Scenario> {
    return this.http.post<Scenario>(this.baseUrl, newScenario);
  }

  put(scenario: Scenario): Observable<Scenario> {
    return this.http.put<Scenario>(this.baseUrl, scenario);
  }

  delete(scenarioId: number): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/${scenarioId}`)
  }

  /**
   * Copy a scenario 1:1
   * @param scenarioParams Scenario parameters like label, description, private, ...
   * @param scenarioId Id of the scenario to copy events and strategies from
   */
  copyScenario(newScenario: Scenario, scenarioId: number): Observable<Scenario> {
    return this.http.post<Scenario>(`${this.baseUrl}/${scenarioId}/copy`, newScenario);
  }

  executeScenario(scenarioId: number): Observable<any> {
    return this.http.post<any>(`${this.baseUrl}/${scenarioId}/execute`, null);
  }

  editStatus(scenario: Scenario, status: ScenarioStatus): Observable<Scenario> {
    const body = { status };
    return this.http.patch<Scenario>(`${this.baseUrl}/${scenario.id}/status`, body);
  }

  setScaleAmountEvolutionGroup(scenarioId: number, scaleAmountEvolutionGroup: ScaleAmountEvolutionGroup): Observable<Scenario> {
    const body = {
      scaleAmountEvolutionGroup
    }
    return this.http.patch<Scenario>(`${this.baseUrl}/${scenarioId}/scale-amount-evolution-group`, body);
  }

  removeScaleAmountEvolutionGroup(scenarioId: number): Observable<Scenario> {
    return this.http.delete<Scenario>(`${this.baseUrl}/${scenarioId}/scale-amount-evolution-group`)
  }

  hasScaleAmountEvolutionGroup(groupId: number): Observable<any> {
    let params = new HttpParams()
      .append('groupId', `${groupId}`);

    return this.http.get<any>(`${this.baseUrl}`, { params });
  }

  setEstimatorConfiguration(scenarioId: number, estimatorConfiguration: EstimatorConfiguration): Observable<Scenario> {
    return this.http.patch<Scenario>(`${this.baseUrl}/${scenarioId}/estimator-configuration`, estimatorConfiguration);
  }

  // private getReportsFromContext(): void {
  //     const scenario = [this.selectedScenarioSubject.getValue()];
  //     this.reportSrv.getReportsFromContext(this.context, scenario).subscribe();

  //     // this.selectedAgents[indexAgent] = selAgent;
  //     // this.structureSrv.getList().subscribe(structures => {
  //     //     structures.map(structure => {
  //     //         if (structure.isDefault) {
  //     //             this.selectedAgents[indexAgent].budgetaryStructureId = structure.id;
  //     //         }
  //     //     });
  //     // });
  // }

}
