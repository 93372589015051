import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LanguageService } from '@services/language.service';
import { ProfileService } from '@services/profile.service';
import { UiService } from '@services/ui.service';
import { UserService } from '@services/user.service';

import { User } from '@classes/user/user';
import { Profile } from '@classes/profiles/profile';
import { Language } from '@classes/language';

interface ResolverUser {
    users: User[];
    profiles: Profile[];
    languages: Language[];
}

@Injectable()
export class UsersResolver implements Resolve<ResolverUser> {
    constructor(
        private uiSrv: UiService,
        private userSrv: UserService,
        private profileSrv: ProfileService,
        private languageSrv: LanguageService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ResolverUser> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        // return this.userSrv.getList()
        //   .pipe(
        //     tap(() => {
        //       this.uiSrv.routeLoadingStateChanged.next(false);
        //     })
        //   );

        return forkJoin([
            this.userSrv.getList(),
            this.profileSrv.get(),
            this.languageSrv.getLanguages()
        ]).pipe(
            map(([users, profiles, languages]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return { users, profiles, languages };
            })
        );
    }
}
