import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';

import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { Scale } from '@classes/scenario/scale';

@Component({
    selector: 'app-status-change',
    templateUrl: './status-change.component.html',
    styleUrls: ['./status-change.component.scss']
})
export class StatusChangeComponent extends SubscriptionBaseComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    isIndividualEvent: boolean;
    inputRequired: boolean;

    constructor(
        private forecastEventSrv: ForecastEventService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initSubscription();
        this.group.markAllAsTouched();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
        this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
    }

    private professionalStatusValidator(): ValidatorFn {
        return (group: UntypedFormGroup): { [key: string]: any } | null => {
            if (group.value.newProfessionalStatus?.id === group.value.oldProfessionalStatus?.id) {
                return {sameStatus: true};
            } else {
                return null;
            }
        };
    }

    private initForm(): void {
        this.group.addControl('newProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newScalePayment', new UntypedFormControl(null));
        this.group.addControl('newSuperiorScalePayment', new UntypedFormControl(null));
        this.group.addControl('newParallelScalePayment', new UntypedFormControl(null));
        this.group.addControl('newSeniority', new UntypedFormControl(null));

        if (!this.isIndividualEvent) {
            this.group.addControl('newFte', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('oldProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newSalaryBase', new UntypedFormControl(null));
            this.group.controls.newScalePayment.setValidators(Validators.required);
            this.group.controls.newSeniority.setValidators(Validators.required);
            this.inputRequired = true;
            this.group.setValidators(this.professionalStatusValidator());
            this.group.updateValueAndValidity();
        } else {
            this.group.removeControl('newFte');
            this.group.removeControl('oldProfessionalStatus');
            this.group.removeControl('newFunctionalArticleRepartition');
            this.group.removeControl('newSalaryBase');
            this.group.controls.newScalePayment.clearValidators();
            this.group.controls.newSeniority.clearValidators();
            this.inputRequired = false;
        }
    }

    private initSubscription(): void {
        this.subscription.add(
            this.forecastEventSrv.isIndividualEventSubject
                .subscribe(isIndividualEvent => {
                    if (isIndividualEvent) {
                        this.group.clearValidators();
                        this.group.updateValueAndValidity();
                    }
                    this.isIndividualEvent = isIndividualEvent
                    this.initForm();
                })
        );
    }

}
