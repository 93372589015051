import { Injectable, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";
import { Subscription } from "rxjs";

@Injectable()
export class CustomMatPaginator extends MatPaginatorIntl implements OnDestroy {
    langSubscription = new Subscription();
    OF_LABEL = 'of';


    constructor(private translateSrv: TranslateService) {
        super();

        this.langSubscription.add(
            this.translateSrv.onLangChange.subscribe(() => {
                this.getAndInitTranslations();
            })
        );

        this.getAndInitTranslations();
    }

    private getAndInitTranslations(): void {
        this.translateSrv.get([
            'shared.matPaginator.itemsPerPageLabel.shortLabel',
            'shared.matPaginator.nextPageLabel.shortLabel',
            'shared.matPaginator.previousPageLabel.shortLabel',
            'shared.matPaginator.lastPageLabel.shortLabel',
            'shared.matPaginator.firstPageLabel.shortLabel',
            'shared.matPaginator.ofLabel.shortLabel'
        ]).subscribe(translations => {
            this.itemsPerPageLabel = translations['shared.matPaginator.itemsPerPageLabel.shortLabel'];
            this.nextPageLabel = translations['shared.matPaginator.nextPageLabel.shortLabel'];
            this.previousPageLabel = translations['shared.matPaginator.previousPageLabel.shortLabel'];
            this.lastPageLabel = translations['shared.matPaginator.lastPageLabel.shortLabel'];
            this.firstPageLabel = translations['shared.matPaginator.firstPageLabel.shortLabel'];
            this.OF_LABEL = translations['shared.matPaginator.ofLabel.shortLabel'];
        });
    }

    getRangeLabel = (
        page: number,
        pageSize: number,
        length: number,
    ) => {
        if (length === 0 || pageSize === 0) {
            return `0 ${this.OF_LABEL} ${length}`;
        }
        length = Math.max(length, 0);
        const startIndex = page * pageSize;
        const endIndex =
            startIndex < length
                ? Math.min(startIndex + pageSize, length)
                : startIndex + pageSize;
        return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL
            } ${length}`;
    };

    ngOnDestroy(): void {
        this.langSubscription.unsubscribe();
    }

}