import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { EventsList } from '@classes/scenario/events-list';
import { ThotEvent } from '@classes/scenario/thot-event';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  private baseUrl = environment.apiUrl + 'event';

  eventsListSubject = new BehaviorSubject<EventsList>(null);

  constructor(
    private http: HttpClient
  ) { }


  get(pageIndex: number, pageSize: number, sorting?: string, filters?: string, dontUpdateSubject?: boolean): Observable<HttpResponse<ThotEvent[]>> {
    let params = new HttpParams()
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`)
      .append('sorts', `${sorting}`)
      .append('filters', `${filters}`);
    if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=' || params.get('filters') === '') {
      params = params.delete('filters');
    }
    if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
      params = params.delete('sorts');
    }
    return this.http.get<ThotEvent[]>(this.baseUrl, {
      observe: 'response',
      params
    }).pipe(
      tap(responseFromApi => {
        if (!dontUpdateSubject) {
          const eventsList = new EventsList(responseFromApi.body, responseFromApi.headers.get('x-pagination'));
          this.eventsListSubject.next(eventsList);
        }
      })
    );
  }

  downloadExcel(filters?: string, sorting?: string): Observable<Blob> {
    let params = new HttpParams()
      .append('page', '0')
      .append('pageSize', '0')
      .append('filters', `${filters}`)
      .append('sorts', `${sorting}`);
    if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=') {
      params = params.delete('filters');
    }
    if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
      params = params.delete('sorts');
    }

    return this.http.get(`${this.baseUrl}/excel`, { observe: 'response', params, responseType: 'blob' })
      .pipe(map(res => this.saveFile(res)));
  }

  private saveFile(res: HttpResponse<Blob>): Blob {
    FileSaver.saveAs(res.body, res.headers.get('filename'));
    return res.body;
  }
}
