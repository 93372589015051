import { Component, OnInit, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogOptions } from '@interfaces/confirm-dialog-options';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {

    constructor(
        @Inject(MAT_DIALOG_DATA) public passedData: { message: string, options: ConfirmDialogOptions }
    ) { }

    ngOnInit() {}

}
