import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ForecastEventService } from '@services/forecast-event.service';

import { EventType } from '@classes/scenario/event-type';
import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

@Component({
    selector: 'app-event-type-select',
    templateUrl: './event-type-select.component.html',
    styleUrls: ['./event-type-select.component.scss']
})
export class EventTypeSelectComponent extends SubscriptionBaseComponent implements OnInit {
    @Input() label: string;
    @Input() control: UntypedFormControl;
    @Input() required: boolean;

    eventTypes: EventType[];

    constructor(
        private forecastEventSrv: ForecastEventService
    ) {
        super();
    }

    ngOnInit(): void {
        this.setValidator();
        this.initSubscription();
    }

    private setValidator(): void {
        if (this.required) {
            this.control.setValidators(Validators.required);
        }
    }

    private initSubscription(): void {
        this.subscription.add(
            this.forecastEventSrv.eventTypesSubject
                .subscribe(typesFromSrv => {
                    if (typesFromSrv) {
                        this.eventTypes = typesFromSrv;
                    }
                })
        );
    }

}
