import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { ReportService } from '@services/report.service';
import { Report } from '@classes/report/report';

@Injectable()
export class ReportsResolver implements Resolve<Report[]> {
    constructor(
        private uiSrv: UiService,
        private reportSrv: ReportService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Report[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.reportSrv.getAll()
            .pipe(
                tap(() => {
                    this.uiSrv.routeLoadingStateChanged.next(false);
                })
            );
    }
}
