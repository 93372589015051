import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { DragDropItem } from '@interfaces/drag-drop-item';
import { MappingDependency } from '@classes/mapping/mapping-dependency';
import { MappingTree } from '@classes/mapping/mapping-tree';
import { Mapping } from '@classes/mapping/mapping';


@Injectable({
    providedIn: 'root'
})
export class MappingService {
    // structuresChanged = new Subject<Mapping[]>();
    structureChanged = new Subject<Mapping>();

    droppedItemChanged = new Subject<boolean>();

    treeChanged = new Subject<MappingTree[]>();
    unmapDependencies = new Subject<MappingDependency[]>();

    canCreateNode: boolean;
    canEditDependencies: boolean;

    private draggedItem: DragDropItem;

    constructor() { }

    dragItem(item: DragDropItem): void {
        this.draggedItem = item;
    }

    get dragged(): DragDropItem {
        return this.draggedItem;
    }
}
