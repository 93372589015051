import { Component, Input, OnChanges } from '@angular/core';

// import { EventService } from '@services/event.service';

import { EventsTableMode } from '@enums/events-table-mode.enum';

import { Agent } from '@classes/agent/agent';

@Component({
    selector: 'app-detail-past-events',
    templateUrl: './detail-past-events.component.html',
    styleUrls: ['./detail-past-events.component.scss']
})
export class DetailPastEventsComponent implements OnChanges {
    @Input() refMonth: string;
    @Input() agent: Agent;

    eventsTableMode = EventsTableMode;
    baseFilter: string;
    indexValue: number;

    constructor(
        // private eventSrv: EventService
    ) { }

    ngOnChanges(): void {
        this.baseFilter = `eventMonth<=${this.refMonth},agent.matricule==${this.agent.matricule}`;
        // this.eventSrv.get(1, 15, '', this.baseFilter).subscribe();
    }

}
