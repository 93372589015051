import { Injectable } from '@angular/core';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { UserTagService } from '@services/user-tag.service';
import { UserTag } from '@classes/parameters/user-tag';
import { UserTagType } from '@interfaces/parameters/user-tag-type';

@Injectable({
    providedIn: 'root'
})
export class UserTagResolver implements Resolve<{tags: UserTag[], tagTypes: UserTagType[]}> {
    constructor(
        private uiSrv: UiService,
        private userTagSrv: UserTagService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{tags: UserTag[], tagTypes: UserTagType[]}> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return forkJoin([
            this.userTagSrv.get(),
            this.userTagSrv.getType()
        ]).pipe(
            map(([tags, tagTypes]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return {tags, tagTypes};
            })
        );
    }
}
