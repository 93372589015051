import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';

import { ProfessionalStatus } from '@classes/contract/professional-status';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';
import { Scale } from '@classes/scenario/scale';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';

@Component({
  selector: 'app-final-departure',
  templateUrl: './final-departure.component.html',
  styleUrls: ['./final-departure.component.scss']
})
export class FinalDepartureComponent extends SubscriptionBaseComponent implements OnInit {
  @Input() group: UntypedFormGroup;
  @Input() professionalStatus: ProfessionalStatus[];
  @Input() scalesPayment: Scale[];
  @Input() articlesFunctional: FunctionalArticle[];

  isIndividualEvent: boolean;

  constructor(
    private forecastEventSrv: ForecastEventService
  ) {
    super();
  }

  ngOnInit(): void {
    this.initSubscription();
    this.group.markAllAsTouched();
  }

  onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
    this.group.controls.oldFunctionalArticleRepartition.setValue(articlesRepartition);
  }

  private initForm(): void {
    if (!this.isIndividualEvent) {
      this.group.addControl('oldFte', new UntypedFormControl(null, [Validators.required]));
      this.group.addControl('oldProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
      this.group.addControl('oldFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));
      this.group.addControl('oldScalePayment', new UntypedFormControl(null, [Validators.required]));
      this.group.addControl('oldSuperiorScalePayment', new UntypedFormControl(null));
      this.group.addControl('oldParallelScalePayment', new UntypedFormControl(null));
      this.group.addControl('oldSeniority', new UntypedFormControl(null, [Validators.required]));
      this.group.addControl('oldSalaryBase', new UntypedFormControl(null));
    } else {
      this.group.removeControl('oldFte');
      this.group.removeControl('oldProfessionalStatus');
      this.group.removeControl('oldFunctionalArticleRepartition');
      this.group.removeControl('oldScalePayment');
      this.group.removeControl('oldSuperiorScalePayment');
      this.group.removeControl('oldParallelScalePayment');
      this.group.removeControl('oldSeniority');
      this.group.removeControl('oldSalaryBase');
    }

  }

  private initSubscription(): void {
    this.subscription.add(
      this.forecastEventSrv.isIndividualEventSubject
        .subscribe(isIndividualEvent => {
          this.isIndividualEvent = isIndividualEvent
          this.initForm();
        })
    );
  }
}
