import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subject, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';
import { ScenarioService } from './scenario.service';

import { ReportItem } from '@components/bizz-chart/report-item';
import { ThotEvent } from '@classes/scenario/thot-event';
import { EventType } from '@classes/scenario/event-type';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { Scale } from '@classes/scenario/scale';
import { EventPreviewSummary } from '@classes/scenario/event-preview-summary';
import { EventOrigin } from '@classes/scenario/event-origin';
import { EventsList } from '@classes/scenario/events-list';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { ProfessionalStatusGroup } from '@classes/scenario/professional-status-group';
import { Scenario } from '@classes/scenario/scenario';
import { EventsTags } from '@classes/scenario/events-tags';
import { ScenarioStatus } from '@enums/story-line/scenario-status.enum';

@Injectable({
  providedIn: 'root'
})
export class ForecastEventService {
  private baseUrl = environment.apiUrl + 'forecast-event';

  originFilterSubject = new Subject<EventOrigin>();

  eventsListSubject = new BehaviorSubject<EventsList>(null);

  eventTypesSubject = new BehaviorSubject<EventType[]>([]);
  professionalStatusSubject = new BehaviorSubject<ProfessionalStatus[]>([]);
  scalePaymentSubject = new BehaviorSubject<Scale[]>([]);
  articleFunctionalSubject = new BehaviorSubject<FunctionalArticle[]>([]);
  professionalStatusGroupSubject = new BehaviorSubject<ProfessionalStatusGroup[]>([]);
  isIndividualEventSubject = new BehaviorSubject<boolean>(false);
  editMode = new BehaviorSubject<boolean>(false);
  errorEventCreation = new Subject<void>();

  isPreviewingEvents = new BehaviorSubject<boolean>(false);
  editedEventsSubject = new BehaviorSubject<ThotEvent[]>([]);
  createdEventsSubject = new BehaviorSubject<ThotEvent[]>([]);
  eventPreviewSummary = new BehaviorSubject<EventPreviewSummary>(null);

  eventsChangedSubject = new Subject<boolean>();

  reloadEventsSubject = new Subject<void>();
  revalidateEventsSubject = new Subject<void>();

  constructor(
    private http: HttpClient,
    private scenarioSrv: ScenarioService,
    private uiSrv: UiService
  ) { }

  /**
   * Get events
   * @param scenarioId the current scenario
   * @param pageIndex the page we are on, pass 0 to this and pageSize to get all filtered events
   * @param pageSize the number of events to request, pass 0 to this and pageIndex to get all filtered events
   * @param sorting string that tells what to sort in ascending or descending order
   * @param filters string used to tell what to search for in the list of events
   */
  get(scenarioId: number, pageIndex: number, pageSize: number, filters?: string, sorting?: string, includeDeleted?: boolean): Observable<HttpResponse<ThotEvent[]>> {
    // this.filterStringSubject.next(filters);
    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`)
      .append('filters', `${filters}`)
      .append('sorts', `${sorting}`)
      .append('includeDeleted', `${includeDeleted}`);

    if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=' || params.get('filters') === '') {
      params = params.delete('filters');
    }
    if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
      params = params.delete('sorts');
    }
    if (params.get('includeDeleted') === 'undefined' || params.get('includeDeleted') === '') {
      params = params.delete('includeDeleted');
    }

    return this.http.get<ThotEvent[]>(this.baseUrl, { observe: 'response', params })
      .pipe(
        tap(responseFromApi => {
          const eventsList = new EventsList(responseFromApi.body, responseFromApi.headers.get('x-pagination'));
          this.eventsListSubject.next(eventsList);
        })
      );
  }

  getEvent(eventId: number): Observable<ThotEvent> {
    return this.http.get<ThotEvent>(`${this.baseUrl}/${eventId}`);
  }

  getForMultipleEdit(scenarioId: number, pageIndex: number, pageSize: number, filters?: string, isVirtual?: boolean): Observable<HttpResponse<ThotEvent[]>> {
    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`)
      .append('filters', `${filters}`)
      .append('isVirtual', `${isVirtual}`);
    if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=') {
      params = params.delete('filters');
    }
    if (params.get('isVirtual') === 'undefined' || params.get('isVirtual') === 'false') {
      params = params.delete('isVirtual');
    }
    return this.http.get<ThotEvent[]>(this.baseUrl, {
      observe: 'response',
      params
    });
  }

  getForAgent(scenarioId: number, filter: string, includeDeleted: boolean): Observable<HttpResponse<ThotEvent[]>> {
    const params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('page', `${0}`)
      .append('pageSize', `${0}`)
      .append('filters', filter)
      .append('includeDeleted', `${includeDeleted}`);

    return this.http.get<ThotEvent[]>(this.baseUrl, {
      observe: 'response',
      params
    });
  }

  /**
   * Add an event
   * @param event Event to add
   */
  post(event: ThotEvent): Observable<ThotEvent> {
    return this.http.post<ThotEvent>(this.baseUrl, event)
      .pipe(
        tap(eventFromApi => {
          if (eventFromApi) {
            this.uiSrv.showSnackbar('scenarios.events.preview.created.summary', true);
          }
        })
      );
  }

  /**
   * Add mutliple events at the same time
   * @param events list of events to add
   */
  postBatch(events: ThotEvent[], scenarioId?: number): Observable<EventPreviewSummary> {
    return this.http.post<EventPreviewSummary>(`${this.baseUrl}/batch`, events)
      .pipe(
        tap(summaryFromApi => {
          if (scenarioId) {
            this.scenarioSrv.getScenario(scenarioId).subscribe();
          }
          if (summaryFromApi?.invalidEvents.length) {
            this.eventPreviewSummary.next(summaryFromApi);
            this.uiSrv.showSnackbar('scenarios.events.previewSummary.validationError.summary', true);
          } else {
            this.uiSrv.showSnackbar('scenarios.events.preview.created.summary', true);
          }
        })
      );
  }

  /**
   * edit an event
   * @param event Event to edit
   */
  put(event: ThotEvent): Observable<ThotEvent> {
    return this.http.put<ThotEvent>(this.baseUrl, event)
      .pipe(
        tap(eventFromApi => {
          if (eventFromApi) {
            this.uiSrv.showSnackbar('scenarios.events.preview.updated.summary', true);
          }
        })
      );
  }

  /**
   * edit mutliple events at the same time
   * @param events list of events to edit
   */
  putBatch(events: ThotEvent[], scenarioId?: number): Observable<EventPreviewSummary> {
    return this.http.put<EventPreviewSummary>(`${this.baseUrl}/batch`, events)
      .pipe(
        tap(summaryFromApi => {
          if (scenarioId) {
            this.scenarioSrv.getScenario(scenarioId).subscribe();
          }
          if (summaryFromApi?.invalidEvents.length) {
            this.eventPreviewSummary.next(summaryFromApi);
            this.uiSrv.showSnackbar('scenarios.events.previewSummary.validationError.summary', true);
          } else {
            this.uiSrv.showSnackbar('scenarios.events.preview.updated.summary', true);
          }
        })
      );
  }

  addUserTags(EventsTags: EventsTags, scenario: Scenario): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/tag`, EventsTags)
      .pipe(
        tap(() => {
            this.scenarioSrv.dontUpdateScenarioSubject.next(true);
            this.scenarioSrv.editStatus(scenario, ScenarioStatus.outdated).subscribe();
        })
      );
  }

  editUserTags(EventsTags: EventsTags, scenario: Scenario): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/tag`, EventsTags)
      .pipe(
        tap(() => {
            this.scenarioSrv.dontUpdateScenarioSubject.next(true);
            this.scenarioSrv.editStatus(scenario, ScenarioStatus.outdated).subscribe();
        })
      );
  }

  deleteContractEvents(scenarioId: number, agentId: number, contractId: number): Observable<void> {
    const params = new HttpParams()
      .append('scenarioId', scenarioId)
      .append('agentId', agentId)
      .append('contractId', contractId);

    return this.http.put<void>(`${this.baseUrl}/deleted`, null, { params });
  }

  /**
   * delete an event
   * @param eventId id of the event to delete
   */
  delete(eventId: number, scenarioId?: number): Observable<any> {
    return this.http.delete(`${this.baseUrl}/${eventId}`)
      .pipe(
        tap(() => {
          if (scenarioId) {
            this.scenarioSrv.getScenario(scenarioId).subscribe();
          }
        })
      );
  }

  deleteBatch(events: ThotEvent[]): Observable<any> {
    const ids = events.map(event => event.id);
    const options = {
      body: ids
    };

    return this.http.request('delete', `${this.baseUrl}`, options)
  }

  downloadExcel(scenarioId: number, indexValue: number, pageIndex: number, pageSize: number, filters?: string, sorting?: string, strategyId?: number): Observable<Blob> {
    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('indexValue', `${indexValue}`)
      .append('page', `${pageIndex}`)
      .append('pageSize', `${pageSize}`)
      .append('filters', `${filters}`)
      .append('sorts', `${sorting}`)
      .append('strategyId', `${strategyId}`);
    if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=') {
      params = params.delete('filters');
    }
    if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
      params = params.delete('sorts');
    }
    if (params.get('strategyId') === 'undefined') {
      params = params.delete('strategyId');
    }

    return this.http.get(`${this.baseUrl}/excel`, { observe: 'response', params, responseType: 'blob' })
      .pipe(map(res => this.saveFile(res)));
  }

  uploadExcel(scenarioId: number, file: File, indexValue: number, origin?: string, tagIds?: number[], ): Observable<EventPreviewSummary> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('indexValue', `${indexValue}`)
      .append('origin', `${origin}`);
    if (params.get('origin') === 'null' || params.get('origin') === 'undefined') {
      params = params.delete('origin');
    }
    if (tagIds?.length) {
      tagIds.forEach((tagId: number) => params = params.append('tag', `${tagId}`));
    }

    // return of();
    return this.http.post<EventPreviewSummary>(`${this.baseUrl}/excel`, formData, { params })
      .pipe(
        tap({
          next: (eventPreviewSummary: EventPreviewSummary) => {
            if (eventPreviewSummary?.invalidEvents?.length) {
              this.uiSrv.showSnackbar('scenarios.events.previewSummary.validationError.summary', true);
            } else if (eventPreviewSummary?.invalidCells?.length) {
              this.uiSrv.showSnackbar('upload.form.events.invalidCells.summary', true);
            } else {
              this.uiSrv.showSnackbar('files.processingStates.success.summary', true);
            }
          }
        })
      );
  }

  getTemplate(): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/excel/template`, { observe: 'response', responseType: 'blob' })
      .pipe(map(res => this.saveFile(res)));
  }

  getEventTypes(): Observable<EventType[]> {
    return this.http.get<EventType[]>(`${environment.apiUrl}event-type`)
      .pipe(
        tap(typesFromApi => {
          this.eventTypesSubject.next(typesFromApi);
        })
      );
  }

  getProfessionalStatus(): Observable<ProfessionalStatus[]> {
    return this.http.get<ProfessionalStatus[]>(`${environment.apiUrl}professional-status`)
      .pipe(
        tap(statusFromApi => {
          const status = statusFromApi.sort((a: ProfessionalStatus, b: ProfessionalStatus) => {
            const statusA = a.code.toLowerCase();
            const statusB = b.code.toLowerCase();
            if (statusA < statusB) {
              return -1;
            } else if (statusA > statusB) {
              return 1;
            }
            return 0;
          })
          return status;
        })
      );
  }

  getScalePayment(): Observable<Scale[]> {
    return this.http.get<Scale[]>(`${environment.apiUrl}scale-payment`)
      .pipe(
        tap(scalesFromApi => {
          const scales = scalesFromApi.sort((a: Scale, b: Scale) => {
            const idA = a.externalId.toLowerCase();
            const idB = b.externalId.toLowerCase();
            if (idA < idB) {
              return -1;
            } else if (idA > idB) {
              return 1;
            }
            return 0;
          })
          return scales;
        })
      );
  }

  getArticleFunctional(): Observable<FunctionalArticle[]> {
    return this.http.get<FunctionalArticle[]>(`${environment.apiUrl}article-functional`)
      .pipe(
        tap(articlesFromApi => {
          const articles = articlesFromApi.sort((a: FunctionalArticle, b: FunctionalArticle) => {
            const articleA = a.code.toLowerCase();
            const articleB = b.code.toLowerCase();
            if (articleA < articleB) {
              return -1;
            } else if (articleA > articleB) {
              return 1;
            }
            return 0;
          })
          return articles;
        })
      );
  }

  getProfessionalStatusGroup(): Observable<ProfessionalStatusGroup[]> {
    return this.http.get<ProfessionalStatusGroup[]>(`${environment.apiUrl}professional-status-group`);
  }

  validateScenario(scenarioId: number): Observable<ThotEvent[]> {
    const params = new HttpParams()
      .append('scenarioId', `${scenarioId}`);

    return this.http.get<ThotEvent[]>(`${this.baseUrl}/invalid`, { params });
  }

  // getMockPreview(): Observable<any> {
  //     const mockPreviewUrl = '../../../assets/mock-data/mock-preview-summary.json';
  //     return this.http.get<any>(mockPreviewUrl)
  //         .pipe(
  //             tap((summaryFromApi: EventPreviewSummary) => {
  //                 this.eventPreviewSummary.next(summaryFromApi);
  //             })
  //         )
  // }

  private saveFile(res: HttpResponse<Blob>): Blob {
    FileSaver.saveAs(res.body, res.headers.get('filename'));
    return res.body;
  }

}
