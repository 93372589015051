<h1 mat-dialog-title> {{'agent.agentDetail.contracts.contract.fullLabel' | translate}} {{data.contract.docNumber}} </h1>
<div mat-dialog-content>
    <mat-list>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.contracts.startDate.shortLabel' | translate}}</span>
            <span>{{data.contract.startDate | date: dateFormat}}</span>
        </mat-list-item>
        <mat-list-item>
            <span class="label">{{'agent.agentDetail.contracts.endDate.shortLabel' | translate}}</span>
            <span>{{data.contract.endDate | date: dateFormat}}</span>
        </mat-list-item>
        <mat-list-item *ngIf="data.contract.startMotif">
            <span class="label">{{'agent.agentDetail.contracts.startMotif.shortLabel' | translate}}</span>
            <span *ngIf="data.contract.startMotif.id > 0; else nullProp">
                {{data.contract.startMotif.externalId}} ({{data.contract.startMotif.externalDescription}})
            </span>
        </mat-list-item>
        <mat-list-item *ngIf="data.contract.endMotif">
            <span class="label">{{'agent.agentDetail.contracts.endMotif.shortLabel' | translate}}</span>
            <span *ngIf="data.contract.endMotif.id > 0; else nullProp">
                {{data.contract.endMotif.externalId}} ({{data.contract.endMotif.externalDescription}})
            </span>
        </mat-list-item>
        <mat-list-item *ngIf="!data.isVirtual">
            <span class="label">{{'agent.agentDetail.contracts.workerCategory.shortLabel' | translate}}</span>
            <span *ngIf="data.contract.workerCategory.id > 0; else nullProp">
                {{data.contract.workerCategory.externalId}} ({{data.contract.workerCategory.externalDescription}})
            </span>
        </mat-list-item>
        <mat-list-item *ngIf="!data.isVirtual">
            <span class="label">{{'agent.agentDetail.contracts.employerCategory.shortLabel' | translate}}</span>
            <span *ngIf="data.contract.employerCategory.id > 0; else nullProp">
                {{data.contract.employerCategory.externalId}}
            </span>
        </mat-list-item>
    </mat-list>
    <form [formGroup]="contractForm" (ngSubmit)="onSubmit()" *ngIf="data.isVirtual">
        <div>

            <app-autocomplete *ngIf="workerCategories?.length" [control]="getControl('workerCategory')"
                [list]="workerCategories" [label]="'agent.agentDetail.contracts.workerCategory.shortLabel' | translate"
                [errorMsg]="'errors.valueSelected.fullLabel' | translate" [required]="true"></app-autocomplete>
        </div>
        <div>

            <app-autocomplete *ngIf="employerCategories?.length" [control]="getControl('employerCategory')"
                [list]="employerCategories"
                [label]="'agent.agentDetail.contracts.employerCategory.shortLabel' | translate"
                [errorMsg]="'errors.valueSelected.fullLabel' | translate" [required]="true">
            </app-autocomplete>
        </div>
        <div>
            <button type="submit" mat-raised-button color="accent" [disabled]="contractForm.invalid">
                {{'shared.validate.fullLabel' | translate}} </button>
            <button type="button" mat-raised-button color="accent" (click)="onCancel()"> {{'shared.cancel.fullLabel' |
                translate}} </button>
        </div>
    </form>
</div>

<ng-template #nullProp>
    <span>-</span>
</ng-template>