import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, UrlTree, Router, CanActivateChild, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { AuthService } from '@services/auth.service';
import { AppConfigService } from '@services/app-config.service';
import { UiService } from '@services/ui.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

    constructor(
        private authSrv: AuthService,
        private jwtHelperSrv: JwtHelperService,
        private router: Router,
        private configSrv: AppConfigService,
        private uiSrv: UiService
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        // Site under maintenance or no token
        if (this.configSrv.isMaintenance || !this.jwtHelperSrv.tokenGetter()) {
            this.authSrv.redirectUrl = state.url;
            this.authSrv.logout();
            return false;
        }

        // User role cannot access this route
        if (next.data.roles && !this.authSrv.isAuthorized(next.data.roles)) {
            this.router.navigate(['/']);
            return false;
        }

        // token valid
        if (this.authSrv.isAuth()) {
            return true;
        }

        // Token exists but invalid
        return this.refreshToken();
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        return this.canActivate(route, state);
    }

    private refreshToken(): Observable<boolean> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.authSrv.refreshToken().pipe(
            map(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return true;
            }),
            catchError(error => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                this.authSrv.logout();
                return of(error);
            })
        );
    }
}
