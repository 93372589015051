import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '@environments/environment';
import { EmployerCategory } from '@classes/contract/employer-category';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class EmployerCategoryService {
    private baseUrl = environment.apiUrl + 'employer-category'

    employerCategorySubject = new BehaviorSubject<EmployerCategory[]>([]);

    constructor(
        private http: HttpClient
    ) { }

    get(fromCotisationRate?: boolean): Observable<EmployerCategory[]> {
        const params = new HttpParams()
            .append('fromCotisationRate', fromCotisationRate ? `${fromCotisationRate}` : 'false');

        return this.http.get<EmployerCategory[]>(this.baseUrl, {params})
            .pipe(
                map((categoriesFromApi: EmployerCategory[]) => categoriesFromApi.map(category => EmployerCategory.fromObject(category)))
            );
    }

}
