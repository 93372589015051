import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';

import { Dimension } from '@classes/report/dimension';

@Injectable({
    providedIn: 'root'
})
export class DimensionService {
    private baseUrl = environment.apiUrl + 'dimension';

    dimensionSubject = new Subject<Dimension[]>();
    dimensions: Dimension[];
    // readonly dimensions$: Observable<Dimension[]> = this.dimensionSubject.asObservable();

    constructor(
        private http: HttpClient,
        private uiSrv: UiService
    ) { }

    getAll(): Observable<Dimension[]> {
        return this.http.get<Dimension[]>(this.baseUrl)
            .pipe(
                tap(dimensionsFromApi => {
                    this.dimensionSubject.next(dimensionsFromApi);
                    this.dimensions = [...dimensionsFromApi];
                })
            );
    }

    getByFunctionId(functionId: number): Observable<Dimension[]> {
        return this.http.get<Dimension[]>(`${environment.apiUrl}fact-function-query/${functionId}/dimension`);
    }

    put(dimension: Dimension): Observable<Dimension> {
        return this.http.put<Dimension>(this.baseUrl, dimension)
            .pipe(
                tap(editedDimension => {
                    const dimensions = this.dimensions.map(md => md.id === editedDimension.id ? editedDimension : md);
                    this.dimensionSubject.next([...dimensions]);
                    this.uiSrv.showSnackbar('bizzdev.universes.dimension.saved.summary', true);
                })
            );
    }

    post(dimension: Dimension): Observable<Dimension> {
        return this.http.post<Dimension>(this.baseUrl, dimension)
            .pipe(
                tap(createdDimension => {
                    this.dimensions.push(createdDimension);
                    this.dimensionSubject.next([...this.dimensions]);
                    this.uiSrv.showSnackbar('bizzdev.universes.dimension.saved.summary', true);
                })
            );
    }

}
