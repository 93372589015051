import { Pagination } from "@interfaces/pagination";
import { ScaleAmount } from "./scale-amount";

export class ScalesAmount {
    scalesAmount: ScaleAmount[];
    pagination: Pagination;

    constructor(scalesAmount: ScaleAmount[], pagination: string) {
        this.scalesAmount = scalesAmount;
        this.pagination = JSON.parse(pagination);
    }
}
