import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormControl, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

import { ProfessionalStatus } from '@classes/contract/professional-status';
import { Scale } from '@classes/scenario/scale';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';

@Component({
    selector: 'app-entry-into-service',
    templateUrl: './entry-into-service.component.html',
    styleUrls: ['./entry-into-service.component.scss']
})
export class EntryIntoServiceComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    constructor() { }

    ngOnInit(): void {
        this.initForm();
        this.group.markAllAsTouched();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
        this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
    }

    private initForm(): void {
        this.group.addControl('newFte', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newScalePayment', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newSuperiorScalePayment', new UntypedFormControl(null));
        this.group.addControl('newParallelScalePayment', new UntypedFormControl(null));
        this.group.addControl('newSeniority', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl("newSalaryBase", new UntypedFormControl(null));
    }

}
