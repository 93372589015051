export class SelectedAgent {
    id: number;
    startMonth?: string;
    endMonth?: string;
    budgetaryStructureId?: number;

    constructor(id: number, startMonth?: string, endMonth?: string) {
        this.id = id;
        this.startMonth = startMonth;
        this.endMonth = endMonth;
    }
}
