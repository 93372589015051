import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile } from '@classes/profiles/profile';
import { environment } from '@environments/environment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ProfileService {
    private baseUrl = environment.apiUrl + 'profile';

    addProfileSubject = new Subject<Profile>();
    private profilesSubject = new BehaviorSubject<Profile[]>([]);
    private editedProfileSubject = new BehaviorSubject<Profile>(null);

    constructor(
        private http: HttpClient
    ) { }

    get profiles$(): Observable<Profile[]> {
        return this.profilesSubject.asObservable();
    }

    set profiles(profiles: Profile[]) {
        this.profilesSubject.next(profiles);
    }

    get editedProfile$(): Observable<Profile> {
        return this.editedProfileSubject.asObservable();
    }

    set editedProfile(profile: Profile) {
        this.editedProfileSubject.next(profile);
    }

    get(): Observable<Profile[]> {
        return this.http.get<Profile[]>(this.baseUrl)
            .pipe(
                map((profilesFromApi: Profile[]) => this.profiles = profilesFromApi)
            );
    }

    post(profile: Profile): Observable<Profile> {
        return this.http.post<Profile>(this.baseUrl, profile);
    }

    put(profile: Profile): Observable<Profile> {
        return this.http.put<Profile>(this.baseUrl, profile);
    }

    putReports(profile: Profile): Observable<Profile> {
        return this.http.put<Profile>(`${this.baseUrl}/reports`, profile);
    }

    delete(profile: Profile): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${profile.id}`);
    }

}
