import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainNavComponent } from './main-nav/main-nav.component';
import { MainNavItemComponent } from './main-nav/main-nav-item/main-nav-item.component';
import { MainHeaderComponent } from './main-header/main-header.component';
import { LanguageSelectorComponent } from './language-selector/language-selector.component';
import { ReportListComponent } from './report-list/report-list.component';
import { SharedModule } from 'app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
      MainNavComponent,
      MainNavItemComponent,
      MainHeaderComponent,
      LanguageSelectorComponent,
      ReportListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    RouterModule
  ],
  exports: [
      MainNavComponent,
      MainHeaderComponent
  ]
})
export class MainModule { }
