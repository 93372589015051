import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { AgentService } from '@services/agent.service';
import { ReferenceMonthService } from '@services/reference-month.service';

import { RefMonthStatus } from '@enums/ref-month-status.enum';

import { RefMonthWithStatus } from '@interfaces/ref-month-with-status';
import { Subject } from 'rxjs';

import { Agent } from '@classes/agent/agent';
import { SelectedAgent } from '@classes/agent/selected-agent';
import { Contract } from '@classes/contract/contract';
import { takeUntil } from 'rxjs/operators';


@Component({
    selector: 'app-agent-detail',
    templateUrl: './agent-detail.component.html',
    styleUrls: ['./agent-detail.component.scss']
})
export class AgentDetailComponent extends SubscriptionBaseComponent implements OnInit, OnDestroy {
    @Input() index: number;
    @Output() contractSelected = new EventEmitter<Contract>();

    refMonth: string;

    agent: Agent;
    dateFormat = 'MM-yyyy';
    isLoading: boolean;
    eventsDisabled: boolean;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private agentSrv: AgentService,
        private refMonthSrv: ReferenceMonthService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initSubscription();

        // setTimeout(() => {
        //     const test: SelectedAgent = {id: 158699, startMonth: '2017-07-01T00:00:00', endMonth: '2018-04-01T00:00:00'}
        //     this.agentSrv.getAgentDetails(test, this.index)
        //     .subscribe((agentFromSrv) => {
        //         this.agent = agentFromSrv;
        //         this.isLoading = false;
        //     });
        // }, 1000);
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    agentSelected(selectedAgent: SelectedAgent): void {
        this.contractSelected.emit(null);
        this.isLoading = true;
        this.agentSrv.getAgentDetails(selectedAgent, this.index)
            .subscribe((agentFromSrv) => {
                this.agent = agentFromSrv;
                this.isLoading = false;
            });
    }

    onSelectContract(contract: Contract): void {
        this.contractSelected.emit(contract);
    }

    private initSubscription(): void {
        this.refMonthSrv.refMonthSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((refMonthFromSrv: string) => {
                if (refMonthFromSrv) {
                    this.refMonth = refMonthFromSrv;
                }
            });

        this.refMonthSrv.getRefMonthsStatus().subscribe((monthsFromSrv: RefMonthWithStatus) => {
            for (const month in monthsFromSrv) {
                if (monthsFromSrv[month] === RefMonthStatus.initializing) {
                    this.eventsDisabled = true;
                    break;
                }
            }
        });
    }
}
