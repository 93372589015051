import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Client } from '@classes/client';
import { UiService } from '@services/ui.service';
import { ClientService } from '@services/client.service';

@Injectable()
export class ClientsResolver implements Resolve<Client[]> {
    constructor(
        private uiSrv: UiService,
        private clientSrv: ClientService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Client[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.clientSrv.get()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
