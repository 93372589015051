import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';
import { MappingService } from './mapping.service';
import { ReportService } from './report.service';

import { Mapping } from '@classes/mapping/mapping';

@Injectable({
    providedIn: 'root'
})
export class BudgetStructureService {
    structuresChanged = new Subject<Mapping[]>();

    private readonly context = 'Structure';
    private baseUrl = environment.apiUrl + 'budgetary-structure';
    private structures: Mapping[];

    constructor(
        private http: HttpClient,
        private uiSrv: UiService,
        private mappingSrv: MappingService,
        private reportSrv: ReportService
    ) { }

    getList(): Observable<Mapping[]> {
        return this.http.get<Mapping[]>(this.baseUrl)
        .pipe(
            tap(structuresFromApi => {
                this.structures = structuresFromApi;
                this.structuresChanged.next([ ...this.structures ]);
            })
        );
    }

    get(structureId: number, selMonth: string): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);
        this.mappingSrv.structureChanged.next(null);

        const httpParams = new HttpParams()
            .append('month', selMonth);

        this.reportSrv.getReportsFromContext(this.context, [{id: structureId}]).subscribe();

        return this.http.get<Mapping>(`${this.baseUrl}/${structureId}`, {params: httpParams})
        .pipe(
            tap(structureFromApi => {
                this.uiSrv.isLoadingSubject.next(false);
                this.mappingSrv.structureChanged.next(structureFromApi);
            })
        );
    }

    post(structure: Mapping): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);

        return this.http.post<Mapping>(`${this.baseUrl}`, structure)
        .pipe(
            tap(createdStructure => {
                this.structures.push(createdStructure);
                this.structuresChanged.next([ ...this.structures ]);
                this.uiSrv.isLoadingSubject.next(false);
                this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
            })
        );
    }

    put(structure: Mapping): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);

        return this.http.put<Mapping>(`${this.baseUrl}`, structure)
        .pipe(
            tap(structureFromApi => {
                this.structures = this.structures.map(s => s.id === structureFromApi.id ? structureFromApi : s);
                this.structuresChanged.next([ ...this.structures ]);
                this.mappingSrv.structureChanged.next(null);
                this.uiSrv.isLoadingSubject.next(false);

                this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
            })
        );
    }

    downloadExcel(structureId: number, month: string): Observable<Blob> {
        const httpParams = new HttpParams()
            .append('month', month);

        return this.http.get(`${this.baseUrl}/${structureId}/download-excel`, { observe: 'response', params: httpParams, responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }
}
