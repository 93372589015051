import { Injectable } from '@angular/core';
import { AppRights } from '@classes/app-rights';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppRightsService {
    private appRightsSubject = new BehaviorSubject<AppRights>(null);

    constructor() { }

    get appRights$(): Observable<AppRights> {
        return this.appRightsSubject.asObservable();
    }

    get appRights(): AppRights {
        return this.appRightsSubject.getValue();
    }

    set appRights(appRights: AppRights) {
        this.appRightsSubject.next(appRights);
    }

}
