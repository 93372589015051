export class CotisationCode {
    code: string;
    description: string;
    id: number;
    providerCode: string;

    constructor(code?: string, description?: string, id?: number, providerCode?: string) {
        this.code = code;
        this.description = description;
        this.id = id;
        this.providerCode = providerCode;
    }

    static fromObject(object: CotisationCode): CotisationCode {
        return Object.assign(new CotisationCode(), object);
    }

    get display(): string {
        return `${this.code}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.code], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
