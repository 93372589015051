<table mat-table [dataSource]="dataSource" multiTemplateDataRows *ngIf="dataSource.data?.length">
    <ng-container matColumnDef="agent">
        <th mat-header-cell *matHeaderCellDef>
            {{'scenarios.events.properties.agent.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation;">
            <div>
                <mat-icon color="accent" (click)="isExpanded = !isExpanded">
                    {{isExpanded ? 'expand_less' : 'expand_more'}}
                </mat-icon>
                <span *ngIf="refSituation.agent"> {{refSituation.agent.display}} </span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
            {{'scenarios.events.properties.professionalStatus.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation;">
            {{refSituation.professionalStatus?.code}}
        </td>
    </ng-container>
    
    <ng-container matColumnDef="startDate">
        <th mat-header-cell *matHeaderCellDef >
            {{'agent.agentDetail.refSituation.startDate.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation;">
            {{refSituation.startDate | date : 'dd/MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="endDate">
        <th mat-header-cell *matHeaderCellDef>
            {{'agent.agentDetail.refSituation.endDate.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation;">
            {{refSituation.endDate | date : 'dd/MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="dueMonth">
        <th mat-header-cell *matHeaderCellDef>
            {{'agent.agentDetail.refSituation.dueMonth.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation">
            {{refSituation.endDate | date : 'dd/MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="fte">
        <th mat-header-cell *matHeaderCellDef>
            {{'agent.agentDetail.refSituation.fte.fullLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation;">
            {{refSituation.fteReference}} [{{refSituation.fteContractuel}}]
        </td>
    </ng-container>

    <ng-container matColumnDef="scale">
        <th mat-header-cell *matHeaderCellDef>
            {{'scenarios.events.properties.scales.fullLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let refSituation;">
            <span> {{refSituation.scalePayment?.externalId}} </span>
            <span *ngIf="refSituation.parallelScalePayment && ![-1, -2].includes(refSituation.parallelScalePayment.id)"> {{'{'}} {{refSituation.parallelScalePayment?.externalId}} {{'}'}}  </span>
            <span *ngIf="refSituation.superiorScalePayment && ![-1, -2].includes(refSituation.superiorScalePayment.id)"> {{'['}} {{refSituation.superiorScalePayment?.externalId}} {{']'}} </span>
            <span *ngIf="refSituation.seniority"> {{'('}} {{refSituation.seniority | date: 'dd-MM-yyyy'}} {{')'}} </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let refSituation" [attr.colSpan]="displayedColumns.length">
            <div class="ref-situation-expanded" [@expandCollapse]="isExpanded ? 'expanded' : 'collapsed'">
                <app-detail-ref-situation [refSituation]="refSituation" [indexValue]="indexValue"></app-detail-ref-situation>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let refSituation; columns: displayedColumns" class="row-ref-situation"
        [class.expanded-row]="isExpanded"></tr>
    <tr mat-row *matRowDef="let refSituation; columns: ['expandedDetail']" class="detail-ref-situation"></tr>
</table>
