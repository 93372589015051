<app-prestation-filter [contracts]="selectedAgent.contracts | arrayFilter: 'isOpen': true" (filter)="onFilter($event)"></app-prestation-filter>
<table mat-table [dataSource]="dataSource" matSort (matSortChange)="onSortChange($event)">
    <ng-container matColumnDef="contractId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'agent.agentDetail.prestations.contract.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let prestation;">
            {{prestation.contract.docNumber}}
        </td>
    </ng-container>

    <ng-container matColumnDef="dueMonth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'agent.agentDetail.prestations.dueMonth.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let prestation;">
            {{prestation.dueMonth | date: 'MM-yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="periodStartdate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'agent.agentDetail.prestations.periodStartdate.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let prestation;">
            {{prestation.periodStartdate | date: 'dd-MM-yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="periodEnddate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'agent.agentDetail.prestations.periodEnddate.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let prestation;">
            {{prestation.periodEnddate | date: 'dd-MM-yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="fteValue">
        <th mat-header-cell *matHeaderCellDef>
            {{'agent.agentDetail.prestations.fteValue.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let prestation;">
            {{prestation.fteValue}}
        </td>
    </ng-container>

    <ng-container matColumnDef="prestationTypeId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'agent.agentDetail.prestations.prestationType.shortLabel' | translate}}

        </th>
        <td mat-cell *matCellDef="let prestation;">
            {{prestation.prestationType.externalId}} 
            <ng-container *ngIf="prestation.prestationType.externalDescription">- {{prestation.prestationType.externalDescription}}</ng-container>
        </td>
    </ng-container>
    
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let prestation; columns: displayedColumns;"></tr>
</table>
<mat-paginator *ngIf="pagination" [pageIndex]="pagination.pageNumber - 1" [pageSize]="pagination.pageSize" [length]="pagination.totalRecords" 
            [hidePageSize]="true" [showFirstLastButtons]="true" [disabled]="pageLoading" (page)="onPagechange($event)"></mat-paginator>
