import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { EventsList } from '@classes/scenario/events-list';
import { ThotEvent } from '@classes/scenario/thot-event';
import { EventPreviewSummary } from '@classes/scenario/event-preview-summary';
import { GetReconciliationEvents } from '@interfaces/get-reconciliation-events';
import { EventsTags } from '@classes/scenario/events-tags';

@Injectable({
    providedIn: 'root'
})
export class ReconciliationEventService {
    private baseUrl = environment.apiUrl + 'reconciliation-event';

    notNotedListSubject = new BehaviorSubject<EventsList>(null);
    notNotedEditedSubject = new BehaviorSubject<ThotEvent[]>([]);

    toPersonalizeListSubject = new BehaviorSubject<EventsList>(null);
    toPersonalizeEditedSubject = new BehaviorSubject<ThotEvent[]>([]);

    constructor(
        private http: HttpClient
    ) { }

    get(parameters: GetReconciliationEvents): Observable<HttpResponse<ThotEvent[]>> {
        let params = new HttpParams()
            .append('sourceScenarioId', `${parameters.sourceScenarioId}`)
            .append('destScenarioId', `${parameters.destScenarioId}`)
            .append('page', `${parameters.pageIndex}`)
            .append('pageSize', `${parameters.pageSize}`)
            .append('filters', `${parameters.filters}`)
            .append('sorts', `${parameters.sorting}`)
            // .append('strategyId', `${parameters.strategyId}`)
            .append('isProvider', `${parameters.isProvider}`)
            .append('isVirtual', `${parameters.isVirtual}`);
        if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=') {
            params = params.delete('filters');
        }
        if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
            params = params.delete('sorts');
        }
        // if (params.get('strategyId') === 'undefined') {
        //     params = params.delete('strategyId');
        // }
        if (params.get('isProvider') === 'undefined') {
            params = params.delete('isProvider');
        }
        if (params.get('isVirtual') === 'undefined') {
            params = params.delete('isVirtual');
        }

        return this.http.get<ThotEvent[]>(this.baseUrl, {observe: 'response', params})
            .pipe(
                tap(responseFromApi => {
                    const eventsList = new EventsList(responseFromApi.body, responseFromApi.headers.get('x-pagination'));
                    if (parameters.isVirtual) {
                        this.toPersonalizeListSubject.next(eventsList);
                    } else {
                        this.notNotedListSubject.next(eventsList);
                    }
                })
            );
    }

    getFormMultipleEdit(parameters: GetReconciliationEvents): Observable<HttpResponse<ThotEvent[]>> {
        let params = new HttpParams()
            .append('sourceScenarioId', `${parameters.sourceScenarioId}`)
            .append('destScenarioId', `${parameters.destScenarioId}`)
            .append('page', `${parameters.pageIndex}`)
            .append('pageSize', `${parameters.pageSize}`)
            .append('filters', `${parameters.filters}`)
            .append('sorts', `${parameters.sorting}`)
            // .append('strategyId', `${parameters.strategyId}`)
            .append('isProvider', `${parameters.isProvider}`)
            .append('isVirtual', `${parameters.isVirtual}`);
        if (params.get('filters') === 'undefined' || params.get('filters') === 'GlobalFilter@=') {
            params = params.delete('filters');
        }
        if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
            params = params.delete('sorts');
        }
        // if (params.get('strategyId') === 'undefined') {
        //     params = params.delete('strategyId');
        // }
        if (params.get('isProvider') === 'undefined') {
            params = params.delete('isProvider');
        }
        if (params.get('isVirtual') === 'undefined' || params.get('isVirtual') === 'false') {
            params = params.delete('isVirtual');
        }

        return this.http.get<ThotEvent[]>(this.baseUrl, {observe: 'response', params});
    }

    putBatch(events: ThotEvent[]): Observable<EventPreviewSummary> {
        return this.http.put<EventPreviewSummary>(`${this.baseUrl}/batch`, events);
    }

    delete(eventId: number): Observable<any> {
        return this.http.delete(`${this.baseUrl}/${eventId}`);
    }

    deleteBatch(events: ThotEvent[]): Observable<any> {
        const ids = events.map(event => event.id);
        const options = {
            body: ids
        };

        return this.http.request('delete', `${this.baseUrl}`, options);
    }

    addUserTags(EventsTags: EventsTags): Observable<void> {
        return this.http.post<void>(`${this.baseUrl}/tag`, EventsTags);
    }

    editUserTags(EventsTags: EventsTags): Observable<void> {
        return this.http.put<void>(`${this.baseUrl}/tag`, EventsTags);
    }

}
