import { RoleKeys, Roles } from '@enums/profile/roles';

export class AppRights {
    agent: {
        canViewAgent: boolean;
    };

    canAccessHangfire: boolean;

    conjoncturalParameters: {
        canAddCotisationCode: boolean;
        canAddCotisationRate: boolean;
        canAddIndex: boolean;
        canDeleteCotisationCode: boolean;
        canDeleteCotisationRate: boolean;
        canDeleteIndex: boolean;
        canEditCotisationCode: boolean;
        canEditCotisationRate: boolean;
        canEditIndex: boolean;
        canExportCotisationCodes: boolean;
        canExportCotisationRates: boolean;
        canExportIndexes: boolean;
        canViewCotisationCodes: boolean;
        canViewCotisationRates: boolean;
        canViewIndexes: boolean;
    };

    dashboard: {
        canEditDefaultHome: boolean;
        canEditHome: boolean;
        canViewHome: boolean;
    };

    estimator: {
        canAddConfiguration: boolean;
        canAddEstimator: boolean;
        canAddRecurringPattern: boolean;
        canEditConfiguration: boolean;
        canEditEstimator: boolean;
        canEditRecurringPattern: boolean;
        canToggleEstimator: boolean;
        canViewEstimators: boolean;
        canViewPaymentSections: boolean;
        canViewRecurringPatterns: boolean;
    };

    functionalArticle: {
        canToggleFunctionalCode: boolean;
        canViewFunctionalCodes: boolean;
    };

    mapping: {
        canAddStructure: boolean;
        canEditPaymentSection: boolean;
        canEditStatus: boolean;
        canEditStructure: boolean;
        canViewMapping: boolean;
    };

    replacements: {
        canViewReplacements: boolean;
    }

    report: {
        canDeleteFlexmonsterLayout: boolean;
        canEditGroups: boolean;
        canUseCustomHiringPlan: boolean;
        canUseCustomPension: boolean;
    };

    profile: {
        canAddProfile: boolean;
        canDeleteProfile: boolean;
        // canEditProfile: boolean;
        canEditReports: boolean;
        canEditRoles: boolean;
        canViewRoles: boolean;
        isAdminBizzdev: boolean;
        isAdminUser: boolean;
    };

    scaleAmount: {
        canAddCustom: boolean;
        canDeleteCustom: boolean;
        canEditCustom: boolean;
        canExportCustom: boolean;
        canExportOfficial: boolean;
        canImport: boolean;
        canViewCustom: boolean;
        canViewOfficial: boolean;
    };

    scenario: {
        canAddEvents: boolean;
        canAddPlanningFte: boolean;
        canAddScenario: boolean;
        canAddSimulatedIndex: boolean;
        canAddStrategies: boolean;
        canBlockScenario: boolean;
        canCalculateFailedScenario: boolean;
        canCalculateScenario: boolean;
        canDeleteEvents: boolean;
        canDeleteOrigins: boolean;
        canDeleteScenario: boolean;
        canDeleteSimulatedIndex: boolean;
        canEditEstimatorConfiguration: boolean;
        canEditEvents: boolean;
        canEditForecastAmounts: boolean;
        canEditOrigins: boolean;
        canEditScaleAmountEvolutionGroup: boolean;
        canEditSimulatedIndex: boolean;
        canEncodeFte: boolean;
        canExportEvents: boolean;
        canExportIndexes: boolean;
        canImportEvents: boolean;
        canRecalculateScenario: boolean;
        canRefreshStrategies: boolean;
        canRevalidateEvents: boolean;
        canViewBlockedScenario: boolean;
        canViewDeletedEvents: boolean;
        canViewReconciliation: boolean;
        canViewScenario: boolean;
    };

    storyLine: {
        canAddStories: boolean;
        canDeleteStories: boolean;
        canEditStories: boolean;
        canInitiateMonth: boolean;
        canImportBudget: boolean;
        canIntegrateMonth: boolean;
        canViewStoryLine: boolean,
    };

    strategiesConfiguration: {
        canAddConfig: boolean;
        canDeleteConfig: boolean;
        canEditConfig: boolean;
        canViewConfig: boolean;
    };

    tagsManagement: {
        canAddTag: boolean;
        canDeleteTag: boolean;
        canEditTag: boolean;
        canViewTags: boolean;
    };

    upload: {
        canImportEvents: boolean;
        canImportBudget: boolean;
    };

    user: {
        canAddUser: boolean;
        canEditUser: boolean;
        canViewUsers: boolean;
        isAdminBizzdev: boolean;
    };

    wiki: {
        canEditWiki: boolean;
    };

    constructor(userRoles: RoleKeys[]) {
        this.agent = {
            canViewAgent: this.hasSomeRoles(userRoles, [Roles.visuAgent])
        };

        this.canAccessHangfire = this.hasSomeRoles(userRoles, [Roles.adminBizzDev]);

        this.conjoncturalParameters = {
            canAddCotisationCode: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canAddCotisationRate: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canAddIndex: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canDeleteCotisationCode: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canDeleteCotisationRate: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canDeleteIndex: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canEditCotisationCode: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canEditCotisationRate: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canEditIndex: this.hasSomeRoles(userRoles, [Roles.adminParametresConjoncturels]),
            canExportCotisationCodes: this.hasSomeRoles(userRoles, [Roles.visuParametresConjoncturels]),
            canExportCotisationRates: this.hasSomeRoles(userRoles, [Roles.visuParametresConjoncturels]),
            canExportIndexes: this.hasSomeRoles(userRoles, [Roles.visuParametresConjoncturels]),
            canViewCotisationCodes: this.hasSomeRoles(userRoles, [Roles.visuParametresConjoncturels]),
            canViewCotisationRates: this.hasSomeRoles(userRoles, [Roles.visuParametresConjoncturels]),
            canViewIndexes: this.hasSomeRoles(userRoles, [Roles.visuParametresConjoncturels]),
        };

        this.dashboard = {
            canEditDefaultHome: this.hasSomeRoles(userRoles, [Roles.adminAccueil]),
            canEditHome: this.hasSomeRoles(userRoles, [Roles.adminAccueil]),
            canViewHome: this.hasSomeRoles(userRoles, [Roles.visuAccueil])
        };

        this.estimator = {
            canAddConfiguration: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canAddEstimator: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canAddRecurringPattern: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canEditConfiguration: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canEditEstimator: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canEditRecurringPattern: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canToggleEstimator: this.hasSomeRoles(userRoles, [Roles.adminEstimateurs]),
            canViewEstimators: this.hasSomeRoles(userRoles, [Roles.visuEstimateurs]),
            canViewPaymentSections: this.hasSomeRoles(userRoles, [Roles.visuEstimateurs]),
            canViewRecurringPatterns: this.hasSomeRoles(userRoles, [Roles.visuEstimateurs])
        };

        this.functionalArticle = {
            canToggleFunctionalCode: this.hasSomeRoles(userRoles, [Roles.adminCodesFonctionnel]),
            canViewFunctionalCodes: this.hasSomeRoles(userRoles, [Roles.visuCodesFonctionnels])
        };

        this.mapping = {
            canAddStructure: this.hasSomeRoles(userRoles, [Roles.adminMappingStructureBudgetaire]),
            canEditPaymentSection: this.hasSomeRoles(userRoles, [Roles.adminMappingRubriquesDePaie]),
            canEditStatus: this.hasSomeRoles(userRoles, [Roles.adminMappingStatuts]),
            canEditStructure: this.hasSomeRoles(userRoles, [Roles.adminMappingStructureBudgetaire]),
            canViewMapping: this.hasSomeRoles(userRoles, [Roles.visuMapping])
        };

        this.replacements = {
            canViewReplacements: this.hasSomeRoles(userRoles, [Roles.visuRemplacement])
        };

        this.report = {
            canDeleteFlexmonsterLayout: this.hasSomeRoles(userRoles, [Roles.adminOutilsDeRapport]),
            canEditGroups: this.hasSomeRoles(userRoles, [Roles.adminOutilsDeRapport]),
            canUseCustomHiringPlan: this.hasSomeRoles(userRoles, [Roles.adminPlanDEmbauche]),
            canUseCustomPension: this.hasSomeRoles(userRoles, [Roles.adminResponsabilisationPension]),
        };

        this.profile = {
            canAddProfile: this.hasSomeRoles(userRoles, [Roles.adminProfils]),
            canDeleteProfile: this.hasSomeRoles(userRoles, [Roles.adminProfils]),
            // canEditProfile: this.hasSomeRoles(userRoles, [Roles.adminProfils, Roles.adminOutilsDeRapport]),
            canEditReports: this.hasSomeRoles(userRoles, [Roles.adminOutilsDeRapport]),
            canEditRoles: this.hasSomeRoles(userRoles, [Roles.adminProfils]),
            canViewRoles: this.hasSomeRoles(userRoles, [Roles.visuRoles]),
            isAdminBizzdev: this.hasSomeRoles(userRoles, [Roles.adminBizzDev]),
            isAdminUser: this.hasSomeRoles(userRoles, [Roles.adminUtilisateurs])
        };

        this.scaleAmount = {
            canAddCustom: this.hasSomeRoles(userRoles, [Roles.adminBareme]),
            canDeleteCustom: this.hasSomeRoles(userRoles, [Roles.adminBareme]),
            canEditCustom: this.hasSomeRoles(userRoles, [Roles.adminBareme]),
            canExportCustom: this.hasSomeRoles(userRoles, [Roles.adminBareme]),
            canExportOfficial: this.hasSomeRoles(userRoles, [Roles.visuBaremeOfficiel]),
            canImport: this.hasSomeRoles(userRoles, [Roles.adminBareme]),
            canViewCustom: this.hasSomeRoles(userRoles, [Roles.visuBaremeCustom]),
            canViewOfficial: this.hasSomeRoles(userRoles, [Roles.visuBaremeOfficiel])
        };

        this.scenario = {
            canAddEvents: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canAddPlanningFte: this.hasSomeRoles(userRoles, [Roles.adminBizzDev]),
            canAddScenario: this.hasSomeRoles(userRoles, [Roles.adminCreationScenario]),
            canAddSimulatedIndex: this.hasSomeRoles(userRoles, [Roles.adminScenario]),
            canAddStrategies: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canBlockScenario: this.hasSomeRoles(userRoles, [Roles.adminBloquerScenario]),
            canCalculateFailedScenario: this.hasSomeRoles(userRoles, [Roles.adminCalculScenario]),
            canCalculateScenario: this.hasSomeRoles(userRoles, [Roles.adminCalculScenario]),
            canDeleteEvents: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canDeleteOrigins: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canDeleteScenario: this.hasSomeRoles(userRoles, [Roles.adminSupprimerLeScenario]),
            canDeleteSimulatedIndex: this.hasSomeRoles(userRoles, [Roles.adminScenario]),
            canEditEstimatorConfiguration: this.hasSomeRoles(userRoles, [Roles.adminScenario]),
            canEditEvents: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canEditForecastAmounts: this.hasSomeRoles(userRoles, [Roles.adminScenario]),
            canEditOrigins: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canEditScaleAmountEvolutionGroup: this.hasSomeRoles(userRoles, [Roles.adminScenario]),
            canEditSimulatedIndex: this.hasSomeRoles(userRoles, [Roles.adminScenario]),
            canEncodeFte: this.hasSomeRoles(userRoles, [Roles.adminBizzDev]),
            canExportEvents: this.hasSomeRoles(userRoles, [Roles.visuScenario]),
            canExportIndexes: this.hasSomeRoles(userRoles, [Roles.visuScenario]),
            canImportEvents: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canRecalculateScenario: this.hasSomeRoles(userRoles, [Roles.adminRecalculScenario]),
            canRefreshStrategies: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canRevalidateEvents: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario]),
            canViewBlockedScenario: this.hasSomeRoles(userRoles, [Roles.adminBloquerScenario]),
            canViewDeletedEvents: this.hasSomeRoles(userRoles, [Roles.visuAfficherLesEvenementsSupprimesScenario]),
            canViewReconciliation: this.hasSomeRoles(userRoles, [Roles.visuReconcilisation]),
            canViewScenario: this.hasSomeRoles(userRoles, [Roles.visuScenario]),
        };

        this.storyLine = {
            canAddStories: this.hasSomeRoles(userRoles, [Roles.adminCreerStory]),
            canDeleteStories: this.hasSomeRoles(userRoles, [Roles.adminSupprimerStory]),
            canEditStories: this.hasSomeRoles(userRoles, [Roles.adminStory]),
            canInitiateMonth: this.hasSomeRoles(userRoles, [Roles.adminInitialisation]),
            canImportBudget: this.hasSomeRoles(userRoles, [Roles.adminBudget, Roles.adminImporterUnBudget]),
            canIntegrateMonth: this.hasSomeRoles(userRoles, [Roles.adminIntegration]),
            canViewStoryLine: this.hasSomeRoles(userRoles, [Roles.visuStoryline]),
        };

        this.strategiesConfiguration = {
            canAddConfig: this.hasSomeRoles(userRoles, [Roles.adminParametresConfigurationDesStrategies]),
            canDeleteConfig: this.hasSomeRoles(userRoles, [Roles.adminParametresConfigurationDesStrategies]),
            canEditConfig: this.hasSomeRoles(userRoles, [Roles.adminParametresConfigurationDesStrategies]),
            canViewConfig: this.hasSomeRoles(userRoles, [Roles.visuParametresConfigurationDesStrategies]),
        };

        this.tagsManagement = {
            canAddTag: this.hasSomeRoles(userRoles, [Roles.adminGestionDesTags]),
            canDeleteTag: this.hasSomeRoles(userRoles, [Roles.adminGestionDesTags]),
            canEditTag: this.hasSomeRoles(userRoles, [Roles.adminGestionDesTags]),
            canViewTags: this.hasSomeRoles(userRoles, [Roles.visuGestionDesTags]),
        };

        this.upload = {
            canImportBudget: this.hasSomeRoles(userRoles, [Roles.adminBudget, Roles.adminImporterUnBudget]),
            canImportEvents: this.hasSomeRoles(userRoles, [Roles.adminEvtsScenario])
        }

        this.user = {
            canAddUser: this.hasSomeRoles(userRoles, [Roles.adminUtilisateurs]),
            canEditUser: this.hasSomeRoles(userRoles, [Roles.adminUtilisateurs]),
            canViewUsers: this.hasSomeRoles(userRoles, [Roles.adminUtilisateurs]),
            isAdminBizzdev: this.hasSomeRoles(userRoles, [Roles.adminBizzDev])
        }

        this.wiki = {
            canEditWiki: this.hasSomeRoles(userRoles, [Roles.adminBizzDev])
        }
    }

    /**
     * 
     * @param userRoles the roles possessed by the logged in user
     * @param neededRoles the roles the user need at least one of to get access to the action
     * @returns true if userRoles contains at least one of the neededRoles
     */
    private hasSomeRoles(userRoles: RoleKeys[], neededRoles: RoleKeys[]): boolean {
        return neededRoles.some(role => userRoles.find(r => r === role));
    }
}
