import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { Agent } from '@classes/agent/agent';
import { AgentService } from '@services/agent.service';
import { FormatService } from '@services/format.service';

@Component({
    selector: 'app-agent-select',
    templateUrl: './agent-select.component.html',
    styleUrls: ['./agent-select.component.scss']
})
export class AgentSelectComponent implements OnInit {
    @Input() label: string;
    @Input() group: UntypedFormGroup;
    @Input() controlName: string;
    @Input() required: boolean;

    filteredAgents: Agent[];

    constructor(
        private agentSrv: AgentService,
        private formatSrv: FormatService
    ) { }

    ngOnInit(): void {
        this.setValidators();
        this.observeControl();
    }

    displayAgent(agent?: Agent): string | undefined {
        return agent ? `${agent.lastName} ${agent.firstName} (${agent.matricule})` : undefined;
    }

    private setValidators(): void {
        if (this.required) {
            this.group.controls[this.controlName].setValidators([Validators.required, this.formatSrv.forbiddenTypeValidator('string')]);
        } else {
            this.group.controls[this.controlName].setValidators(this.formatSrv.forbiddenTypeValidator('string'));
        }
        this.group.updateValueAndValidity({emitEvent: false});
    }

    private observeControl(): void {
        this.group.controls[this.controlName].valueChanges
            .pipe(
                debounceTime(500),
                switchMap((search: string | Agent) => {
                    if (search && typeof search === 'string') {
                        return this.agentSrv.getAgents(search);
                    } else {
                        return of(null);
                    }
                })
            ).subscribe(agentsFromSrv => {
                if (agentsFromSrv?.length) {
                    this.filteredAgents = agentsFromSrv;
                }
            });
    }

}
