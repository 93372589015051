import { Pipe, PipeTransform } from '@angular/core';
import { UserTag } from '@classes/parameters/user-tag';

@Pipe({
    name: 'getTagsNames'
})
export class GetTagsNamesPipe implements PipeTransform {

    transform(tagIds: number[], userTags: UserTag[]): any {
        return userTags.filter(tag => tagIds.find(tagId => tag.id === tagId))
        .map(tag => tag.name).join(', ');
    }

}
