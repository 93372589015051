import { Strategy } from './strategy';

export class EventOrigin {
  executionDate: Date;
  strategy: Strategy;
  id?: number;
  label?: string;
  numberOfEvents?: number;
  parameterValue?: any;

  refreshBlocked?: boolean

  constructor()
  constructor(
    executionDate?: Date,
    strategy?: Strategy,
    id?: number,
    label?: string,
    numberOfEvents?: number,
    parameterValue?: any
  ) {
    this.executionDate = executionDate;
    this.strategy = strategy;
    this.id = id;
    this.label = label;
    this.numberOfEvents = numberOfEvents;
    this.parameterValue = parameterValue
  }

  static fromObject(object: EventOrigin): EventOrigin {
    return Object.assign(new EventOrigin(), object);
  }

  get display(): string {
    return `${this.strategy.text.shortLabel} ${this.label ? '(' + this.label + ')' : ''}`;
  }

  doesInclude(value: string): boolean {
    return this.some([this.strategy.text.shortLabel], value);
  }

  protected some(props: string[], value: string): boolean {
    return props.some(prop => prop.toLowerCase().includes(value));
  }
}
