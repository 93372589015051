import { MissingTranslationHandler, MissingTranslationHandlerParams } from '@ngx-translate/core';

import { environment } from '@environments/environment';

export class ApiMissingTranslationHandler implements MissingTranslationHandler {
    handle(params: MissingTranslationHandlerParams) {
        if (environment.production) {
            return '';
        }

        return '<No Translation>';
    }
}
