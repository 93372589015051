import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { Reconciliation } from '@classes/scenario/reconciliation';
import { ReconciliationFilter } from '@classes/scenario/anti-strategies/reconciliation-filter';
import { ScenariosToReconcile } from '@classes/scenario/scenarios-to-reconcile';
import { UntypedFormGroup } from '@angular/forms';
import * as FileSaver from 'file-saver';

@Injectable({
    providedIn: 'root'
})
export class ReconciliationService {
    private baseUrl = environment.apiUrl + 'reconciliation';

    reconciliationFiltersSubject = new Subject<UntypedFormGroup[]>();

    constructor(
        private http: HttpClient
    ) { }

    initReconciliation(sourceScenarioId: number, destinationScenarioId: number): Observable<any> {
        const body = new ScenariosToReconcile(sourceScenarioId, destinationScenarioId);
        return this.http.post<any>(`${this.baseUrl}/init`, body);
    }

    getStatus(sourceScenarioId: number, destinationScenarioId: number): Observable<Reconciliation> {
        const params = new HttpParams()
            .append('sourceScenarioId', `${sourceScenarioId}`)
            .append('destinationScenarioId', `${destinationScenarioId}`);

        return this.http.get<Reconciliation>(this.baseUrl, {params});
    }

    getFilteredEvents(reconciliationId: number): Observable<ReconciliationFilter[]> {
        return this.http.get<ReconciliationFilter[]>(`${this.baseUrl}/${reconciliationId}/filters/events`);
    }

    postEventsFilters(sourceScenarioId: number, destinationScenarioId: number, selectedFilters: ReconciliationFilter[]): Observable<void> {
        const body = new ScenariosToReconcile(sourceScenarioId, destinationScenarioId, selectedFilters);
        return this.http.post<void>(`${this.baseUrl}/filter`, body);
    }

    toggleFilteredEvent(filterEventId: number, isFiltered: boolean): Observable<any> {
        const params = new HttpParams()
            .append('isFiltered', `${isFiltered}`);

        return this.http.put<any>(`${this.baseUrl}/filter-event/${filterEventId}`, undefined, {params});
    }

    getFileteredEventsExcel(reconciliationId: number): Observable<Blob> {
        return this.http.get(`${this.baseUrl}/${reconciliationId}/filters/events/excel`, { observe: 'response', responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    getFilters(reconciliationId: number): Observable<ReconciliationFilter[]> {
        return this.http.get<ReconciliationFilter[]>(`${this.baseUrl}/${reconciliationId}/default-filters`);
    }

    getSelectedFilters(reconciliationId: number): Observable<ReconciliationFilter[]> {
        return this.http.get<ReconciliationFilter[]>(`${this.baseUrl}/${reconciliationId}/filters`);
    }

    finalizeReconciliation(sourceScenarioId: number, destinationScenarioId: number): Observable<any> {
        const body = new ScenariosToReconcile(sourceScenarioId, destinationScenarioId);
        return this.http.post<any>(`${this.baseUrl}/finalize`, body);
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }

}
