import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { Contract } from '@classes/contract/contract';

@Injectable({
    providedIn: 'root'
})
export class ContractService {
    private baseUrl = environment.apiUrl + 'contract';

    constructor(
        private http: HttpClient
    ) { }

    get(agentId: number, date?: string): Observable<Contract[]> {
        let params = new HttpParams()
            .append('agentId', `${agentId}`)
            .append('date', date);
        if (params.get('date') === 'undefined') {
            params = params.delete('date');
        }

        return this.http.get<Contract[]>(this.baseUrl, {params});
    }

    getContract(id: number): Observable<Contract> {
        return this.http.get<Contract>(`${this.baseUrl}/${id}`);
    }

}
