import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

import { FormatService } from '@services/format.service';

import { Scale } from '@classes/scenario/scale';

@Component({
    selector: 'app-event-scales-autocomplete',
    templateUrl: './event-scales-autocomplete.component.html',
    styleUrls: ['./event-scales-autocomplete.component.scss']
})
export class EventScalesAutocompleteComponent implements OnInit {
    @Input() label: string;
    @Input() group: UntypedFormGroup;
    @Input() controlName: string;
    @Input() scales: Scale[];
    @Input() required: boolean;

    filteredScales: Scale[];

    constructor(
        private formatSrv: FormatService
    ) { }

    ngOnInit(): void {
        this.filteredScales = this.scales;
        this.setValidators();
        this.observeControl();
    }

    displayScale(scale?: Scale): string {
        return scale ? scale.externalId : '';
    }

    onAutoCompleteFocus(): void {
        this.filteredScales = this.scales.slice();
    }

    private setValidators(): void {
        if (this.required) {
            this.group.controls[this.controlName].setValidators([Validators.required, this.formatSrv.forbiddenTypeValidator('string')]);
        } else {
            this.group.controls[this.controlName].setValidators(this.formatSrv.forbiddenTypeValidator('string'));
        }
        this.group.updateValueAndValidity({emitEvent: false});
    }

    private _filter(externalId: string): Scale[] {
        const filterValue = externalId.toLowerCase();
        return this.scales.filter(scale => {
            if (scale.externalId) {
                return scale.externalId.toLowerCase().indexOf(filterValue) !== -1;
            }
        });
    }

    private observeControl(): void {
        this.group.get(this.controlName).valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    if (value) {
                        return typeof value === 'string' ? value : value.externalId;
                    }
                }),
                map(externalId => externalId ? this._filter(externalId) : this.scales.slice())
            ).subscribe(scales => this.filteredScales = scales);
    }
}
