import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';

import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';

@Component({
    selector: 'app-event-articles-functional',
    templateUrl: './event-articles-functional.component.html',
    styleUrls: ['./event-articles-functional.component.scss']
})
export class EventArticlesFunctionalComponent extends SubscriptionBaseComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() controlName: string;
    @Input() articles: FunctionalArticle[];
    @Input() required: boolean;
    @Input() dense: boolean;
    @Output() articleRepartition = new EventEmitter<FunctionalArticleRepartition[]>()

    articleForm: UntypedFormGroup;
    valuesToSubmit = false;

    filteredArticles: FunctionalArticle[];
    articlesRepartition: FunctionalArticleRepartition[] = [];

    constructor(
        private forecastEventSrv: ForecastEventService,
        private fb: UntypedFormBuilder
    ) {
        super();
    }

    ngOnInit(): void {
        this.filteredArticles = this.articles;
        this.init();
        this.initForm();
        this.articleForm.markAllAsTouched();
    }

    addToRepartition(): void {
        if (this.articleForm.value.article?.code && this.articleForm.value.ratio) {
            const values = this.articleForm.value;
            let ratio = 0;
            this.articlesRepartition.forEach(article => ratio += article.Ratio);
            if (ratio < 1 && (ratio + values.ratio) <= 1) {
                this.articlesRepartition.push(new FunctionalArticleRepartition(values.article.code, values.ratio));
                this.articleRepartition.emit(this.articlesRepartition);
            }
            this.articleForm.reset();
            this.articleForm.markAllAsTouched();
            this.valuesToSubmit = false;
        }
    }

    deleteFromRepartition(index: number): void {
        this.articlesRepartition.splice(index, 1);
        this.articleRepartition.emit(this.articlesRepartition);
    }

    displayArticle(article?: FunctionalArticle): string {
        return article ? article.code : '';
    }

    onAutoCompleteFocus(): void {
        this.filteredArticles = this.articles.slice();
    }

    private _filter(code: string): FunctionalArticle[] {
        const filterValue = code.toLowerCase();
        return this.articles.filter(article => {
            if (article.code) {
                return article.code.toLowerCase().indexOf(filterValue) !== -1;
            }
        });
    }

    private forbiddenTypeValidator(forbiddenType: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = control.value !== '' && typeof control.value === forbiddenType;
            return forbidden ? { forbiddenType: { value: control.value } } : null;
        };
    }

    private ratioValidator(): ValidatorFn {
        let valid = (control: AbstractControl): { [key: string]: any } | null => {
            let value = 0;
            if (control.value?.length) {
                control.value.forEach(articleRepartition => {
                    value += articleRepartition.Ratio
                });
            } else {
                return null;
            }
            return value === 1 ? null : { ratio: value }
        }
        return valid;
    }

    private initForm(): void {
        if (this.required) {
            this.group.controls[this.controlName].setValidators([Validators.required, this.ratioValidator()]);
        } else {
            this.group.controls[this.controlName].setValidators(this.ratioValidator());
        }
        this.group.updateValueAndValidity({ emitEvent: false });

        this.articleForm = this.fb.group({
            article: [null, [this.forbiddenTypeValidator('string'), Validators.required]],
            ratio: [null, Validators.required]
        })

        this.observeChanges();
    }

    private observeChanges(): void {
        this.articleForm.controls.article.valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    if (value) {
                        return typeof value === 'string' ? value : value.code;
                    }
                }),
                map(code => code ? this._filter(code) : this.articles.slice())
            ).subscribe(articles => this.filteredArticles = articles);

        this.articleForm.controls.ratio.valueChanges
            .subscribe(value => {
                if (value < 0) {
                    this.articleForm.controls.ratio.setValue(0);
                } else if (value > 1) {
                    this.articleForm.controls.ratio.setValue(1);
                }
            });
    }

    private init(): void {
        if (this.group.controls[this.controlName].value) {
            this.articlesRepartition = this.group.controls[this.controlName].value;
        }

        this.subscription.add(
            this.forecastEventSrv.isIndividualEventSubject
                .subscribe(() => {
                    if (this.articleForm?.controls.article) {
                        this.articleForm.reset();
                        this.articlesRepartition = [];
                    }
                })
        );

        this.forecastEventSrv.errorEventCreation.subscribe(() => {
            const valuesToSubmit = Object.keys(this.articleForm.value).every(key => this.articleForm.value[key])
            if (valuesToSubmit) {
                this.valuesToSubmit = true;
            } else {
                this.valuesToSubmit = false;
            }
        });
    }

}
