import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpEvent, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { UiItem } from '@classes/bizzdev/text/ui-item';
import { UiService } from './ui.service';
import { FileUpload } from '@classes/file/file-upload';
import { Language } from '@classes/language';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  private baseUrl = environment.apiUrl + 'text';

  constructor(
    private http: HttpClient,
    private uiSrv: UiService
  ) { }

  getUiItems(): Observable<UiItem[]> {
    return this.http.get<UiItem[]>(environment.apiUrl + 'ui-item/text');
  }

  putUiItem(uiItem: UiItem): Observable<UiItem> {
    return this.http.put<UiItem>(environment.apiUrl + 'ui-item/text', uiItem)
      .pipe(
        tap({
          next: () => this.uiSrv.showSnackbar('bizzdev.texts.edit.saved.summary', true)
        })
      );
  }

  getJson(languageCode: string): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/${languageCode}`, { observe: 'response', responseType: 'blob' })
      .pipe(map(res => this.saveFile(res)));
  }

  postJson(languageId: number, file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    const httpParams = new HttpParams()
      .append('languageId', languageId.toString());

    return this.http.post<any>(`${this.baseUrl}/json`, formData, { params: httpParams })
      .pipe(
        tap({
          next: () => this.uiSrv.showSnackbar('bizzdev.texts.create.saved.summary', true)
        })
      );
  }

  getXlsx(): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/xlsx`, { observe: 'response', responseType: 'blob' })
      .pipe(map(res => this.saveFile(res)));
  }

  postXlsx(file: File): Observable<void> {
    const formData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post<void>(`${this.baseUrl}/xlsx`, formData)
      .pipe(
        tap({
          next: () => this.uiSrv.showSnackbar('bizzdev.texts.create.saved.summary', true)
        })
      );
  }

  private saveFile(res: HttpResponse<Blob>): Blob {
    FileSaver.saveAs(res.body, res.headers.get('filename'));
    return res.body;
  }
}
