<mat-card class="login-card">
    <mat-card-header>
        <mat-card-title>
            <h2>
                {{'auth.login.title.fullLabel' | translate}}
            </h2>
        </mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
            <mat-form-field>
                <mat-icon matPrefix>person</mat-icon>
                <mat-label>{{'auth.login.username.fullLabel' | translate}}</mat-label>
                <input type="text" matInput formControlName="username">
                <mat-error>{{'auth.login.requiredUsername.fullLabel' | translate}}</mat-error>
            </mat-form-field>
            <mat-form-field>
                <mat-icon matPrefix>lock</mat-icon>
                <mat-label>{{'auth.login.password.fullLabel' | translate}}</mat-label>
                <input matInput formControlName="password" [type]="hidePassword ? 'password' : 'text'">
                <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword">
                    <mat-icon aria-label="Hide password"> {{hidePassword ? 'visibility_off' : 'visibility'}} </mat-icon>
                </button>
                <mat-hint align="end"><a routerLink="/forgot-password">{{'auth.login.forgottenPassword.fullLabel' |
                        translate}}</a></mat-hint>
                <mat-error>{{'auth.login.requiredPassword.fullLabel' | translate}}</mat-error>
            </mat-form-field>
            <!-- [disabled]="loginForm.invalid" -->
            <button *ngIf="!isLoading" type="submit" mat-raised-button color="accent">
                {{'auth.login.connect.fullLabel' | translate}}
            </button>
            <mat-spinner *ngIf="isLoading" [diameter]="35" color="primary"></mat-spinner>
            <div *ngIf="showErrorMessage">
                <br>
                <mat-error>{{'auth.login.loginFailed.fullLabel' | translate}}</mat-error>
            </div>
        </form>
    </mat-card-content>
</mat-card>