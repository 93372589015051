import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from '@services/format.service';

@Pipe({
    name: 'diffNumber'
})
export class DiffNumberPipe implements PipeTransform {

    constructor(private formatSrv: FormatService) {}

    transform(values: number[]): string {
        if (values == null || values.length === 0) {
            return "";
        }

        const diff = values[1] - values[0];
        return this.formatSrv.formatAmount(diff, 'fixed', 1, false);
    }

}
