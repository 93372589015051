<header>
    <h4>
        {{'scenarios.events.details.title.fullLabel' | translate}}
        <app-tooltip [text]="'scenarios.events.details.oldNewValue.fullLabel' | translate"></app-tooltip>
    </h4>
    <span *ngIf="event.originalEvent"
          class="original-value"> ({{'scenarios.events.details.originalValues.fullLabel' |
        translate}}) </span>
</header>
<div *ngIf="!isEdited">
    <ul>
        <!-- Agent -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.agent.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent?.agent?.matricule !== event.agent?.matricule}"
                  [matTooltip]="(event.oldEvent?.agent?.display)"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.agent?.matricule === event.agent?.matricule">
                {{event.agent ? event.agent.display : '-'}}
            </span>
            <span class="original-value"
                  *ngIf="event.originalEvent && event.originalEvent.agent?.matricule !== event.agent?.matricule">
                ({{event.originalEvent?.agent?.display}})
            </span>
        </li>

        <!-- Contract -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.contract.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && 
                    (event.oldEvent.newContract?.docNumber !== event.newContract?.docNumber || event.oldEvent.oldContract?.docNumber !== event.oldContract?.docNumber)}"
                  [matTooltip]="event.newContract ? event.newContract?.docNumber : event.oldContract ? event.oldContract?.docNumber : null"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && 
                    (event.oldEvent.newContract?.docNumber === event.newContract?.docNumber || event.oldEvent.oldContract?.docNumber === event.oldContract?.docNumber)">
                {{event.newContract ? event.newContract?.docNumber : event.oldContract ? event.oldContract?.docNumber :
                '-'}}
            </span>
            <button mat-icon-button
                    color="accent"
                    *ngIf="!isEdited"
                    (click)="getContract(event)">
                <mat-icon aria-label="view contract">search</mat-icon>
            </button>
            <button mat-icon-button
                    color="accent"
                    *ngIf="canDeleteContract"
                    (click)="deleteContract()">
                <mat-icon aria-label="view contract">delete</mat-icon>
            </button>
        </li>

        <!-- InitialOrigin -->
        <li class="event-property"
            *ngIf="event.initialEventOrigin">
            <span class="label"> {{'scenarios.events.properties.initialEventOrigin.fullLabel' | translate}} </span>
            <span> {{event.initialEventOrigin}} </span>
        </li>

        <!-- EventType -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.eventType.fullLabel' | translate}} </span>
            <span>{{event.eventType.text.fullLabel}}</span>
        </li>

        <!-- InitialEventMonth -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.initialEventMonth.fullLabel' | translate}} </span>
            <span>
                {{event.initialEventMonth | date: dateFormat}}
            </span>
        </li>

        <!-- EventMonth -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.eventMonth.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent?.eventMonth !== event.eventMonth}"
                  [matTooltip]="(event.oldEvent?.eventMonth | date: dateFormat)"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent?.eventMonth === event.eventMonth">
                {{event.eventMonth | date: dateFormat}}
            </span>
            <span class="original-value"
                  *ngIf="event.originalEvent && event.originalEvent.eventMonth !== event.eventMonth">
                ({{event.originalEvent.eventMonth | date: dateFormat}})
            </span>
        </li>

        <!-- DurationInMonth -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.durationInMonth.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent?.durationInMonth !== event.durationInMonth}"
                  [matTooltip]="event.oldEvent?.durationInMonth?.toString()"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent?.durationInMonth === event.durationInMonth">
                {{event.durationInMonth ? event.durationInMonth : '-'}}
            </span>
            <span class="original-value"
                  *ngIf="event.originalEvent && event.originalEvent.durationInMonth !== event.durationInMonth">
                ({{event.originalEvent.durationInMonth}})
            </span>
        </li>

        <!-- userComment -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.userComment.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.userComment !== event.userComment }"
                  [matTooltip]="event.userComment ? event.userComment : null"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.userComment === event.userComment">
                {{event.userComment}}
            </span>
        </li>

        <!-- systemComment -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.systemComment.fullLabel' | translate}} </span>
            <span> {{event.systemComment}} </span>
        </li>

        <!-- userTags -->
        <li *ngIf="isEdited && !isInvalidEvent">
            <span class="label"> {{'scenarios.events.properties.userTags.fullLabel' | translate}} </span>
            <mat-form-field>
                <mat-select multiple
                            [formControl]="tagsControl"
                            (openedChange)="editUserTags($event)">
                    <mat-select-trigger>
                        {{tagsControl.value?.length ? (tagsControl.value | getTagsNames : userTags) : ''}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let tag of userTags"
                                [value]="tag.id"
                                [disabled]="hasReportTag(tag) || hasContract(tag)">
                        <div class="user-tag">
                            <mat-icon color="accent"
                                      [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                      *ngIf="tag.type.id === 2">grid_on</mat-icon>
                            <mat-icon color="accent"
                                      [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                      *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                            <span> {{tag.name}} </span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </li>
    </ul>
    <ul>
        <!-- FTE -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.fte.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent?.oldFte !== event.oldFte}"
                  [matTooltip]="event.oldEvent?.oldFte?.toString()"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent?.oldFte === event.oldFte">
                {{event?.oldFte >= 0 ? event.oldFte : '-'}}
            </span>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.newFte !== event.newFte}"
                  [matTooltip]="event.oldEvent?.newFte?.toString()"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.newFte === event.newFte">
                {{event?.newFte >= 0 ? event.newFte : '-'}}
            </span>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldFte !== event.oldFte || event.originalEvent.newFte !== event.newFte)">
                <span>({{event.originalEvent.oldFte >= 0 ? event.originalEvent.oldFte : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newFte >= 0 ? event.originalEvent.newFte : '-'}})</span>
            </div>
        </li>

        <!-- Seniority -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.seniority.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.oldSeniority !== event.oldSeniority}"
                  [matTooltip]="event.oldEvent?.oldSeniority?.toString()"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.oldSeniority === event.oldSeniority">
                {{event.oldSeniority ? event.oldSeniority : '-'}}
            </span>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.newSeniority !== event.newSeniority}"
                  [matTooltip]="event.oldEvent?.newSeniority?.toString()"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.newSeniority === event.newSeniority">
                {{event.newSeniority ? event.newSeniority : '-'}}
            </span>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldSeniority !== event.oldSeniority || event.originalEvent.newSeniority !== event.newSeniority)">
                <span>({{event.originalEvent.oldSeniority >= 0 ? event.originalEvent.oldSeniority : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newSeniority >= 0 ? event.originalEvent.newSeniority : '-'}})</span>
            </div>
        </li>

        <!-- Payment scale -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.scalePayment.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.oldScalePayment?.id !== event.oldScalePayment?.id}"
                  [matTooltip]="(event.oldEvent?.oldScalePayment && event.oldEvent.oldScalePayment.id > 0 ? event.oldEvent.oldScalePayment.externalId : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.oldScalePayment?.id === event.oldScalePayment?.id">
                {{event.oldScalePayment && event.oldScalePayment?.id > 0 ? event.oldScalePayment?.externalId :
                '-'}}
            </span>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.newScalePayment?.id !== event.newScalePayment?.id}"
                  [matTooltip]="(event.oldEvent?.newScalePayment && event.oldEvent.newScalePayment.id > 0 ? event.oldEvent.newScalePayment.externalId : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.newScalePayment?.id === event.newScalePayment?.id">
                {{event.newScalePayment && event.newScalePayment?.id > 0 ? event.newScalePayment?.externalId :
                '-'}}
            </span>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldScalePayment?.id !== event.oldScalePayment?.id || event.originalEvent.newScalePayment?.id !== event.newScalePayment?.id)">
                <span>({{event.originalEvent.oldScalePayment && event.originalEvent.oldScalePayment?.id > 0 ?
                    event.originalEvent.oldScalePayment?.externalId : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newScalePayment && event.originalEvent.newScalePayment?.id > 0 ?
                    event.originalEvent.newScalePayment?.externalId : '-'}})</span>
            </div>
        </li>

        <!-- Superior payment scale -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.superiorScalePayment.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.oldSuperiorScalePayment?.id !== event.oldSuperiorScalePayment?.id}"
                  [matTooltip]="(event.oldEvent?.oldSuperiorScalePayment && event.oldEvent?.oldSuperiorScalePayment.id > 0 ? event.oldEvent.oldSuperiorScalePayment.externalId : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.oldSuperiorScalePayment?.id === event.oldSuperiorScalePayment?.id">
                {{event.oldSuperiorScalePayment && event.oldSuperiorScalePayment.id > 0 ?
                event.oldSuperiorScalePayment?.externalId : '-'}}
            </span>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.newSuperiorScalePayment?.id !== event.newSuperiorScalePayment?.id}"
                  [matTooltip]="(event.oldEvent?.newSuperiorScalePayment && event.oldEvent.newSuperiorScalePayment.id > 0 ? event.oldEvent.newSuperiorScalePayment.externalId : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.newSuperiorScalePayment?.id === event.newSuperiorScalePayment?.id">
                {{event.newSuperiorScalePayment && event.newSuperiorScalePayment.id > 0 ?
                event.newSuperiorScalePayment?.externalId : '-'}}
            </span>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldSuperiorScalePayment?.id !== event.oldSuperiorScalePayment?.id || event.originalEvent.newSuperiorScalePayment?.id !== event.newSuperiorScalePayment?.id)">
                <span>({{event.originalEvent.oldSuperiorScalePayment &&
                    event.originalEvent.oldSuperiorScalePayment?.id > 0 ?
                    event.originalEvent.oldSuperiorScalePayment?.externalId : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newSuperiorScalePayment &&
                    event.originalEvent.newSuperiorScalePayment?.id > 0 ?
                    event.originalEvent.newSuperiorScalePayment?.externalId : '-'}})</span>
            </div>
        </li>

        <!-- // payment scale -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.parallelScalePayment.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.oldParallelScalePayment?.id !== event.oldParallelScalePayment?.id}"
                  [matTooltip]="(event.oldEvent?.oldParallelScalePayment && event.oldEvent.oldParallelScalePayment.id > 0 ? event.oldEvent.oldParallelScalePayment.externalId : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.oldParallelScalePayment?.id === event.oldParallelScalePayment?.id">
                {{event.oldParallelScalePayment && event.oldParallelScalePayment.id > 0 ?
                event.oldParallelScalePayment?.externalId : '-'}}
            </span>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.newParallelScalePayment?.id !== event.newParallelScalePayment?.id}"
                  [matTooltip]="(event.oldEvent?.newParallelScalePayment && event.oldEvent.newParallelScalePayment.id > 0 ? event.oldEvent.newParallelScalePayment.externalId : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.newParallelScalePayment?.id === event.newParallelScalePayment?.id">
                {{event.newParallelScalePayment && event.newParallelScalePayment.id > 0 ?
                event.newParallelScalePayment?.externalId : '-'}}
            </span>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldParallelScalePayment?.id !== event.oldParallelScalePayment?.id || event.originalEvent.newParallelScalePayment?.id !== event.newParallelScalePayment?.id)">
                <span>({{event.originalEvent.oldParallelScalePayment &&
                    event.originalEvent.oldParallelScalePayment?.id > 0 ?
                    event.originalEvent.oldParallelScalePayment?.externalId : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newParallelScalePayment &&
                    event.originalEvent.newParallelScalePayment?.id > 0 ?
                    event.originalEvent.newParallelScalePayment?.externalId : '-'}})</span>
            </div>
        </li>

        <!-- Professional statut -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.professionalStatus.fullLabel' | translate}} </span>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.oldProfessionalStatus?.id !== event.oldProfessionalStatus?.id}"
                  [matTooltip]="(event.oldEvent?.oldProfessionalStatus && event.oldEvent.oldProfessionalStatus.id > 0 ? event.oldEvent.oldProfessionalStatus.code : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.oldProfessionalStatus?.id === event.oldProfessionalStatus?.id">
                {{event.oldProfessionalStatus && event.oldProfessionalStatus.id > 0 ?
                event.oldProfessionalStatus?.code : '-'}}
            </span>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <span *ngIf="!isEdited"
                  [ngClass]="{'edited-value': event.oldEvent && event.oldEvent.newProfessionalStatus?.id !== event.newProfessionalStatus?.id}"
                  [matTooltip]="(event.oldEvent?.newProfessionalStatus && event.oldEvent.newProfessionalStatus.id > 0 ? event.oldEvent.newProfessionalStatus.code : '-')"
                  [matTooltipDisabled]="!event.oldEvent || event.oldEvent && event.oldEvent.newProfessionalStatus?.id === event.newProfessionalStatus?.id">
                {{event.newProfessionalStatus && event.newProfessionalStatus.id > 0 ?
                event.newProfessionalStatus?.code : '-'}}
            </span>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldProfessionalStatus?.id !== event.oldProfessionalStatus?.id || event.originalEvent.newProfessionalStatus?.id !== event.newProfessionalStatus?.id)">
                <span>({{event.originalEvent.oldProfessionalStatus &&
                    event.originalEvent.oldProfessionalStatus?.id > 0 ?
                    event.originalEvent.oldProfessionalStatus?.code : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newProfessionalStatus &&
                    event.originalEvent.newProfessionalStatus?.id > 0 ?
                    event.originalEvent.newProfessionalStatus?.code : '-'}})</span>
            </div>
        </li>

        <!-- SalaryBase -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.salaryBase.fullLabel' | translate}} </span>
            <ng-container *ngIf="!isEdited">
                <span *ngIf="event.oldSalaryBase && (indexValue || event.indexValue) && event.fteContractual > 0; else rawOldSalary">
                    {{event.oldSalaryBase | formatSalarayBase: indexValue ? indexValue : event.indexValue
                    :event.fteContractual}}
                </span>
                <ng-template #rawOldSalary>
                    <span [matTooltip]="'shared.rawSalary.summary' | translate" *ngIf="event.oldSalaryBase">
                        {{event.oldSalaryBase}}€
                        <span style="color:red; margin:0">*</span>
                    </span>
                </ng-template>
                <span *ngIf="!event.oldSalaryBase">-</span>
            </ng-container>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <ng-container *ngIf="!isEdited">
                <span *ngIf="event.newSalaryBase && (indexValue || event.indexValue) && event.fteContractual > 0; else rawNewSalary">
                    {{event.newSalaryBase | formatSalarayBase: indexValue ? indexValue : event.indexValue
                    :event.fteContractual}}
                </span>
                <ng-template #rawNewSalary>
                    <span [matTooltip]="'shared.rawSalary.summary' | translate" *ngIf="event.newSalaryBase">
                        {{event.newSalaryBase}}€
                        <span style="color:red; margin:0">*</span>
                    </span>
                </ng-template>
                <span *ngIf="!event.newSalaryBase">-</span>
            </ng-container>
        </li>

        <!-- FunctionalArticleRepartition -->
        <li class="functional-article-repartition">
            <span class="label"> {{'scenarios.events.properties.oldFunctionalArticleRepartition.fullLabel' | translate}}
            </span>
            <div class="container"
                 *ngIf="!isEdited">
                <div class="functional-article-repartition-bar"
                     *ngFor="let article of event.oldFunctionalArticleRepartition; index as i"
                     [matTooltip]="article.ArticleFunctionalCode + ' : ' + article.Ratio * 100 + '%'"
                     appRatioBar
                     [ratio]="article.Ratio"
                     [color]="colors[i]"> {{article.ArticleFunctionalCode}} </div>
            </div>
        </li>
        <li class="functional-article-repartition">
            <span class="label"> {{'scenarios.events.properties.newFunctionalArticleRepartition.fullLabel' | translate}}
            </span>
            <div class="container"
                 *ngIf="!isEdited">
                <div class="functional-article-repartition-bar"
                     *ngFor="let article of event.newFunctionalArticleRepartition; index as i"
                     [matTooltip]="article.ArticleFunctionalCode + ' : ' + article.Ratio * 100 + '%'"
                     appRatioBar
                     [ratio]="article.Ratio"
                     [color]="colors[i]"> {{article.ArticleFunctionalCode}} </div>
            </div>
        </li>

    </ul>
</div>
<form [formGroup]="detailsForm" *ngIf="isEdited">
    <ul>
        <!-- Agent -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.agent.fullLabel' | translate}} </span>
            <app-agent-select *ngIf="isEdited"
                              [group]="detailsForm"
                              [controlName]="'agent'"></app-agent-select>
            <span class="original-value"
                  *ngIf="event.originalEvent && event.originalEvent.agent?.matricule !== event.agent?.matricule">
                ({{event.originalEvent?.agent?.display}})
            </span>
        </li>

        <!-- Contract -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.contract.fullLabel' | translate}} </span>
            <button mat-icon-button
                    color="accent"
                    *ngIf="canDeleteContract"
                    (click)="deleteContract()">
                <mat-icon aria-label="view contract">delete</mat-icon>
            </button>
            <mat-form-field *ngIf="isEdited">
                <mat-select formControlName="contract">
                    <!-- <mat-option [value]="null"> - </mat-option> -->
                    <mat-option *ngFor="let contract of contracts"
                                [value]="contract.docNumber">
                        {{contract.docNumber}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </li>

        <!-- InitialOrigin -->
        <li class="event-property"
            *ngIf="event.initialEventOrigin">
            <span class="label"> {{'scenarios.events.properties.initialEventOrigin.fullLabel' | translate}} </span>
            <span> {{event.initialEventOrigin}} </span>
        </li>

        <!-- EventType -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.eventType.fullLabel' | translate}} </span>
            <span>{{event.eventType.text.fullLabel}}</span>
        </li>

        <!-- InitialEventMonth -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.initialEventMonth.fullLabel' | translate}} </span>
            <span>
                {{event.initialEventMonth | date: dateFormat}}
            </span>
        </li>

        <!-- EventMonth -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.eventMonth.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-month-selector [min]="minMonth"
                                    [group]="detailsForm"
                                    [controlName]="'eventMonth'">
                </app-month-selector>
            </div>
            <span class="original-value"
                  *ngIf="event.originalEvent && event.originalEvent.eventMonth !== event.eventMonth">
                ({{event.originalEvent.eventMonth | date: dateFormat}})
            </span>
        </li>

        <!-- DurationInMonth -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.durationInMonth.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'durationInMonth'"
                                  [min]="0"></app-input-number>
            </div>
            <span class="original-value"
                  *ngIf="event.originalEvent && event.originalEvent.durationInMonth !== event.durationInMonth">
                ({{event.originalEvent.durationInMonth}})
            </span>
        </li>

        <!-- userComment -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.userComment.fullLabel' | translate}} </span>
            <mat-form-field *ngIf="isEdited">
                <input matInput
                       formControlName="userComment">
            </mat-form-field>
        </li>

        <!-- systemComment -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.systemComment.fullLabel' | translate}} </span>
            <span> {{event.systemComment}} </span>
        </li>

        <!-- userTags -->
        <li *ngIf="isEdited && !isInvalidEvent">
            <span class="label"> {{'scenarios.events.properties.userTags.fullLabel' | translate}} </span>
            <mat-form-field>
                <mat-select multiple
                            [formControl]="tagsControl"
                            (openedChange)="editUserTags($event)">
                    <mat-select-trigger>
                        {{tagsControl.value?.length ? (tagsControl.value | getTagsNames : userTags) : ''}}
                    </mat-select-trigger>
                    <mat-option *ngFor="let tag of userTags"
                                [value]="tag.id"
                                [disabled]="hasReportTag(tag) || hasContract(tag)">
                        <div class="user-tag">
                            <mat-icon color="accent"
                                      [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                      *ngIf="tag.type.id === 2">grid_on</mat-icon>
                            <mat-icon color="accent"
                                      [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                      *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                            <span> {{tag.name}} </span>
                        </div>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </li>
    </ul>
    <ul>
        <!-- FTE -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.fte.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'oldFte'"
                                  [min]="0"
                                  [max]="1"
                                  [step]="0.1">
                </app-input-number>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'newFte'"
                                  [min]="0"
                                  [max]="1"
                                  [step]="0.1">
                </app-input-number>
            </div>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldFte !== event.oldFte || event.originalEvent.newFte !== event.newFte)">
                <span>({{event.originalEvent.oldFte >= 0 ? event.originalEvent.oldFte : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newFte >= 0 ? event.originalEvent.newFte : '-'}})</span>
            </div>
        </li>

        <!-- Seniority -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.seniority.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'oldSeniority'"
                                  [min]="0"></app-input-number>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'newSeniority'"
                                  [min]="0"></app-input-number>
            </div>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldSeniority !== event.oldSeniority || event.originalEvent.newSeniority !== event.newSeniority)">
                <span>({{event.originalEvent.oldSeniority >= 0 ? event.originalEvent.oldSeniority : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newSeniority >= 0 ? event.originalEvent.newSeniority : '-'}})</span>
            </div>
        </li>

        <!-- Payment scale -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.scalePayment.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="scalesPayment"
                                  [control]="getControl('oldScalePayment')"></app-autocomplete>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="scalesPayment"
                                  [control]="getControl('newScalePayment')"></app-autocomplete>
            </div>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldScalePayment?.id !== event.oldScalePayment?.id || event.originalEvent.newScalePayment?.id !== event.newScalePayment?.id)">
                <span>({{event.originalEvent.oldScalePayment && event.originalEvent.oldScalePayment?.id > 0 ?
                    event.originalEvent.oldScalePayment?.externalId : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newScalePayment && event.originalEvent.newScalePayment?.id > 0 ?
                    event.originalEvent.newScalePayment?.externalId : '-'}})</span>
            </div>
        </li>

        <!-- Superior payment scale -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.superiorScalePayment.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="scalesPayment"
                                  [control]="getControl('oldSuperiorScalePayment')"></app-autocomplete>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="scalesPayment"
                                  [control]="getControl('newSuperiorScalePayment')"></app-autocomplete>
            </div>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldSuperiorScalePayment?.id !== event.oldSuperiorScalePayment?.id || event.originalEvent.newSuperiorScalePayment?.id !== event.newSuperiorScalePayment?.id)">
                <span>({{event.originalEvent.oldSuperiorScalePayment &&
                    event.originalEvent.oldSuperiorScalePayment?.id > 0 ?
                    event.originalEvent.oldSuperiorScalePayment?.externalId : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newSuperiorScalePayment &&
                    event.originalEvent.newSuperiorScalePayment?.id > 0 ?
                    event.originalEvent.newSuperiorScalePayment?.externalId : '-'}})</span>
            </div>
        </li>

        <!-- // payment scale -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.parallelScalePayment.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="scalesPayment"
                                  [control]="getControl('oldParallelScalePayment')"></app-autocomplete>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="scalesPayment"
                                  [control]="getControl('newParallelScalePayment')"></app-autocomplete>
            </div>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldParallelScalePayment?.id !== event.oldParallelScalePayment?.id || event.originalEvent.newParallelScalePayment?.id !== event.newParallelScalePayment?.id)">
                <span>({{event.originalEvent.oldParallelScalePayment &&
                    event.originalEvent.oldParallelScalePayment?.id > 0 ?
                    event.originalEvent.oldParallelScalePayment?.externalId : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newParallelScalePayment &&
                    event.originalEvent.newParallelScalePayment?.id > 0 ?
                    event.originalEvent.newParallelScalePayment?.externalId : '-'}})</span>
            </div>
        </li>

        <!-- Professional statut -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.professionalStatus.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="professionalStatuts"
                                  [control]="getControl('oldProfessionalStatus')"
                                  [tooltipProperty]="'description'"></app-autocomplete>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-autocomplete [list]="professionalStatuts"
                                  [control]="getControl('newProfessionalStatus')"
                                  [tooltipProperty]="'description'"></app-autocomplete>
            </div>
            <div class="original-value"
                 *ngIf="event.originalEvent && (event.originalEvent.oldProfessionalStatus?.id !== event.oldProfessionalStatus?.id || event.originalEvent.newProfessionalStatus?.id !== event.newProfessionalStatus?.id)">
                <span>({{event.originalEvent.oldProfessionalStatus &&
                    event.originalEvent.oldProfessionalStatus?.id > 0 ?
                    event.originalEvent.oldProfessionalStatus?.code : '-'}}</span>
                <mat-icon class="old-new"> arrow_forward </mat-icon>
                <span>{{event.originalEvent.newProfessionalStatus &&
                    event.originalEvent.newProfessionalStatus?.id > 0 ?
                    event.originalEvent.newProfessionalStatus?.code : '-'}})</span>
            </div>
        </li>

        <!-- SalaryBase -->
        <li class="event-property">
            <span class="label"> {{'scenarios.events.properties.salaryBase.fullLabel' | translate}} </span>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'oldSalaryBase'">
                </app-input-number>
            </div>
            <mat-icon class="old-new"> arrow_forward </mat-icon>
            <div *ngIf="isEdited"
                 class="small-input">
                <app-input-number [group]="detailsForm"
                                  [controlName]="'newSalaryBase'">
                </app-input-number>
            </div>
        </li>

        <!-- FunctionalArticleRepartition -->
        <li class="functional-article-repartition">
            <span class="label"> {{'scenarios.events.properties.oldFunctionalArticleRepartition.fullLabel' | translate}}
            </span>
            <app-event-articles-functional [group]="detailsForm"
                                           [controlName]="'oldFunctionalArticleRepartition'"
                                           [articles]="articlesFunctional"
                                           (articleRepartition)="onArticleRepartition($event, true)"
                                           *ngIf="isEdited"></app-event-articles-functional>
        </li>
        <li class="functional-article-repartition">
            <span class="label"> {{'scenarios.events.properties.newFunctionalArticleRepartition.fullLabel' | translate}}
            </span>
            <app-event-articles-functional [group]="detailsForm"
                                           [controlName]="'newFunctionalArticleRepartition'"
                                           [articles]="articlesFunctional"
                                           (articleRepartition)="onArticleRepartition($event, false)"
                                           *ngIf="isEdited"></app-event-articles-functional>
        </li>
    </ul>
</form>