import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';

import { Metadata } from '@classes/report/meta-data';
import { FilterValue } from '@classes/report/filter-value';

@Injectable({
    providedIn: 'root'
})
export class MetadataService {
    private baseUrl = environment.apiUrl + 'metadata';

    constructor(
        private http: HttpClient,
        private uiSrv: UiService
    ) { }

    getValues(metadataId: number): Observable<FilterValue[]> {
        return this.http.get<FilterValue[]>(`${this.baseUrl}/${metadataId}/values`)
            .pipe(
                map((values: FilterValue[]) => {
                    if (values?.length) {
                        return values.map(value => FilterValue.fromObject(value))
                    }
                    return [];
                })
            );
    }

    getList(dimensionId?: number): Observable<Metadata[]> {
        let params = new HttpParams()
            .append('dimensionId', `${dimensionId}`);
        if (params.get('dimensionId') === 'undefined') {
            params = params.delete('dimensionId');
        }

        return this.http.get<Metadata[]>(this.baseUrl, { params });
    }

    put(metadata: Metadata): Observable<Metadata> {
        return this.http.put<Metadata>(this.baseUrl, metadata)
            .pipe(
                tap({
                    next: () => this.uiSrv.showSnackbar('bizzdev.universes.metadata.saved.summary', true)
                })
            );
    }

}
