import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Contract } from '@classes/contract/contract';

@Component({
    selector: 'app-detail-contracts',
    templateUrl: './detail-contracts.component.html',
    styleUrls: ['./detail-contracts.component.scss']
})
export class DetailContractsComponent implements OnInit {
    @Input() contracts: Contract[];
    @Output() contractSelected = new EventEmitter<Contract>();

    dateFormat = 'dd-MM-yyyy';

    constructor() { }

    ngOnInit() {
        // console.log(this.contracts);
    }

    onSelectContract(contract: Contract): void {
        this.contractSelected.emit(contract);
    }
}
