export const Roles = {
    defaultRole: '1',
    administrateurBizzDev: '2',
    administrateurClient: '3',
    visuClient: '4',
    adminAccueil: '11',
    adminBareme: '12',
    adminBizzDev: '13',
    adminBloquerScenario: '14',
    adminBudget: '15',
    adminCalculScenario: '16',
    adminCodesFonctionnel: '17',
    adminCreationScenario: '18',
    adminCreerStory: '19',
    adminEstimateurs: '20',
    adminEstimateursPropreAuScenario: '21',
    adminEvtsScenario: '22',
    adminGestionDesTags: '23',
    adminImporterUnBudget: '24',
    adminInitialisation: '25',
    adminIntegration: '26',
    adminMappingRubriquesDePaie: '27',
    adminMappingStatuts: '28',
    adminMappingStructureBudgetaire: '29',
    adminOutilsDeRapport: '30',
    adminParametresConfigurationDesStrategies: '31',
    adminParametresConjoncturels: '32',
    adminPlanDEmbauche: '33',
    adminProfils: '34',
    adminRecalculScenario: '35',
    adminResponsabilisationPension: '36',
    adminScenario: '37',
    adminStory: '38',
    adminSupprimerLeScenario: '39',
    adminSupprimerStory: '40',
    adminUtilisateurs: '41',
    visuAccueil: '43',
    visuAfficherLesEvenementsSupprimesScenario: '44',
    visuAgent: '45',
    visuBaremeCustom: '46',
    visuBaremeOfficiel: '47',
    visuCodesFonctionnels: '48',
    visuEstimateurs: '49',
    visuGestionDesTags: '50',
    visuMapping: '51',
    visuModificationUser: '52',
    visuOrganigramme: '53',
    visuOutilDeRapport: '54',
    visuOutilDeRapportModeAvance: '55',
    visuOutilDeRapportModeSimple: '56',
    visuParametres: '57',
    visuParametresConfigurationDesStrategies: '58',
    visuParametresConjoncturels: '59',
    visuReconcilisation: '60',
    visuRemplacement: '61',
    visuRoles: '62',
    visuScenario: '63',
    visuStoryline: '64',
    visuUtilisateurs: '65'
} as const

export type RoleKeys = typeof Roles[keyof typeof Roles];
