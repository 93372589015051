import { ErrorHandler, Injectable } from "@angular/core";
import { environment } from "@environments/environment";
import { JL } from "jsnlog";

@Injectable()
export class UncaughtExceptionHandler implements ErrorHandler {
    handleError(error: any): void {
        if (environment.production) {
            console.error(error);
            JL().fatalException('Uncaught Exception', error);
        } else {
            console.error(error);
        }
    }
}