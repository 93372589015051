import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { environment } from '@environments/environment';

import { PrestationType } from '@classes/prestation/prestation-type';
import { Prestation } from '@classes/prestation/prestation';
import { PrestationOptions } from '@interfaces/prestation-options';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class PrestationService {
    private baseUrl = environment.apiUrl;

    prestationsSubject = new Subject<HttpResponse<Prestation[]>>();

    constructor(
        private http: HttpClient
    ) { }

    get(page: number, pageSize: number, options?: PrestationOptions): Observable<HttpResponse<Prestation[]>> {
        let params = new HttpParams()
        .append('page', `${page}`)
        .append('pageSize', `${pageSize}`)
        .append('filters', options.filters)
        .append('sorts', options.sorts);
        
        if (params.get('filters') === 'undefined' || params.get('filters') === '') {
            params = params.delete('filters');
        }
        if (params.get('sorts') === 'undefined' || params.get('sorts') === '') {
            params = params.delete('sorts');
        }

        return this.http.get<Prestation[]>(`${this.baseUrl}prestation`, {observe: 'response', params});
    }

    getPrestationType(): Observable<PrestationType[]> {
        return this.http.get<PrestationType[]>(`${this.baseUrl}prestation-type`)
            .pipe(
                map((prestationTypes: PrestationType[]) => {
                    return prestationTypes.map(pt => PrestationType.fromObject(pt));
                })
            );
    }

}
