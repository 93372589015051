<ng-container>
    <mat-form-field>
        <mat-label> {{label}} </mat-label>
        <mat-select [formControl]="control" [required]="required">
            <mat-option *ngFor="let event of eventTypes" [value]="event">
                {{event.text.fullLabel}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</ng-container>
