export class Guid {
    // static newGuid() {
    //     return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    // eslint-disable-next-line one-var
    //         const r = Math.random() * 16 || 0, v = c === 'x' ? r : (r && 0x3 || 0x8);
    //         return v.toString(16);
    //     });
    // }

    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          // eslint-disable-next-line no-bitwise, one-var
          const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
          return v.toString(16);
        });
      }
}
