import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { ActivatedRoute } from '@angular/router';
import { Subject, of } from 'rxjs';
import { debounceTime, switchMap, takeUntil } from 'rxjs/operators';


import { AppRightsService } from '@services/app-rights.service';
import { EventOriginService } from '@services/event-origin.service';
import { EventService } from '@services/event.service';
import { ForecastEventService } from '@services/forecast-event.service';
import { ProviderEventService } from '@services/provider-event.service';
import { ReferenceMonthService } from '@services/reference-month.service';
import { ScenarioService } from '@services/scenario.service';
import { UserTagService } from '@services/user-tag.service';

import { EventsTableMode } from '@enums/events-table-mode.enum';

import { OperatorValue } from '@interfaces/operator-value';

import { AppRights } from '@classes/app-rights';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { DateTimeFormat } from '@classes/date-time-format';
import { UserTag } from '@classes/parameters/user-tag';
import { EventOrigin } from '@classes/scenario/event-origin';
import { InitialOrigin } from '@classes/scenario/initial-origin';
import { Scale } from '@classes/scenario/scale';
import { Scenario } from '@classes/scenario/scenario';
import { ThotEvent } from '@classes/scenario/thot-event';
import { DateTime } from 'luxon';

export const MONTH_FORMATS = {
    parse: {
        dateInput: 'MM/YYYY',
    },
    display: {
        dateInput: 'MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    }
};

@Component({
    selector: 'app-events-toolbar',
    templateUrl: './events-toolbar.component.html',
    styleUrls: ['./events-toolbar.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
    ]
})
export class EventsToolbarComponent implements OnInit, OnChanges, OnDestroy {
    @Output() addEvents = new EventEmitter<void>();
    @Output() editMultiple = new EventEmitter<boolean>();
    @Output() cancelEdits = new EventEmitter<boolean>();
    @Output() displayCreated = new EventEmitter<boolean>();
    @Output() displayDeleted = new EventEmitter<boolean>();
    @Output() filter = new EventEmitter<string>();
    @Input() sortingString: string;
    @Input() mode: EventsTableMode;
    @Input() baseFilter: string;
    @Input() refMonth: string;
    @Input() maxMonth: DateTime;
    @Input() minMonth: DateTime;
    @Input() isEditingMultiple: boolean;
    @Input() isLoading: boolean;
    @Input() indexValue: number;

    appRights: Partial<AppRights>;

    editedEventsCount: number;
    createdEventsCount: number;
    deletedEventsCount: number;

    isEditing: boolean;
    isCreatedDisplayed: boolean;
    isDeletedDisplayed: boolean;
    isDownloadingEvents: boolean;

    selectedScenario: Scenario;
    globalSearch = new UntypedFormControl();
    searchForm: UntypedFormGroup;
    showFilters: boolean;

    professionalStatuts: ProfessionalStatus[];
    scalePayment: Scale[];
    origins: EventOrigin[];
    initialOrigins: InitialOrigin[];
    userTags: UserTag[];

    operators: OperatorValue[] = [
        {
            label: '=',
            value: '=='
        },
        {
            label: '≠',
            value: '!='
        },
        {
            label: '<=',
            value: '<='
        },
        {
            label: '>=',
            value: '>='
        },
        {
            label: '<',
            value: '<'
        },
        {
            label: '>',
            value: '>'
        }
    ];

    eventsTableMode = EventsTableMode;

    // private refMonth: string;
    private downloadFilter: string;
    private originFilter: EventOrigin;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private forecastEventSrv: ForecastEventService,
        private providerSrv: ProviderEventService,
        private eventSrv: EventService,
        private scenarioSrv: ScenarioService,
        private refMonthSrv: ReferenceMonthService,
        private eventOriginSrv: EventOriginService,
        private userTagSrv: UserTagService,
        private route: ActivatedRoute,
        private appRightsSrv: AppRightsService
    ) { }

    ngOnInit(): void {
        this.downloadFilter = this.baseFilter;
        this.initSubscription();
        this.initForm();
        this.observeSearchChange();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.baseFilter?.previousValue !== changes.baseFilter?.currentValue) {
            this.downloadFilter = this.baseFilter;
        }
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    onAddEvents(): void {
        this.addEvents.emit();
    }

    toggleEditMultiple(): void {
        this.showFilters = false;
        this.isEditingMultiple = !this.isEditingMultiple;
        // if (!this.isEditingMultiple) {
        //     this.resetFilters();
        // }
        this.editMultiple.emit(this.isEditingMultiple);
    }

    saveEdits(): void {
        this.forecastEventSrv.isPreviewingEvents.next(true);
        if (this.editedEventsCount > 0 || this.deletedEventsCount > 0) {
            this.forecastEventSrv.editMode.next(true);
        }
    }

    onCancelEdits(deletedEvents: boolean): void {
        this.cancelEdits.emit(deletedEvents);
    }

    onCancelNew(): void {
        this.forecastEventSrv.createdEventsSubject.next([]);
        if (!this.editedEventsCount && !this.deletedEventsCount) {
            this.forecastEventSrv.eventsChangedSubject.next(false);
        }
    }

    toggleFilters(): void {
        this.showFilters = !this.showFilters;
    }

    resetFilters(): void {
        this.downloadFilter = this.baseFilter;
        this.originFilter = null;
        this.initForm();
        this.globalSearch.reset(null, { emitEvent: false })
        this.filter.emit(this.downloadFilter);
    }

    downloadExcel(): void {
        this.isDownloadingEvents = true;
        if (this.mode === EventsTableMode.realized || this.mode === EventsTableMode.agent) {
            this.eventSrv.downloadExcel(this.downloadFilter, this.sortingString).subscribe(() => this.isDownloadingEvents = false);
        } else if (this.mode === EventsTableMode.provider) {
            this.providerSrv.downloadExcel(this.refMonth, this.indexValue, this.downloadFilter, this.sortingString).subscribe(() => this.isDownloadingEvents = false);
        } else {
            this.forecastEventSrv.downloadExcel(this.selectedScenario.id, this.indexValue, 0, 0, this.downloadFilter, this.sortingString).subscribe(() => this.isDownloadingEvents = false);
        }
    }

    onSubmit(): void {
        this.isLoading = true;
        const values = this.searchForm.value;
        if (this.searchForm.pristine) {
            this.isLoading = false;
            return null;
        }
        let filter = '';
        Object.keys(values).forEach(value => {
            if (!value.match('Operator')) {
                switch (value) {
                    case 'eventType':
                        if (values[value]) {
                            filter += `eventType==${values[value].code ? values[value].code.trim().toLocaleLowerCase() : ''},`;
                        }
                        break;

                    case 'matricule':
                        if (values[value]) {
                            filter += `agent.${value}@=${values[value] ? values[value].trim().toLocaleLowerCase() : ''},`;
                        }
                        break;

                    case 'lastName':
                        if (values[value]) {
                            filter += `agent.${value}@=${values[value] ? values[value].trim().toLocaleLowerCase() : ''},`;
                        }
                        break;

                    case 'firstName':
                        if (values[value]) {
                            filter += `agent.${value}@=${values[value] ? values[value].trim().toLocaleLowerCase() : ''},`;
                        }
                        break;

                    case 'eventMonth':
                        if (values[value]) {
                            const month = (values[value] as DateTime).startOf("month").toFormat(DateTimeFormat.dateTime);
                            const mOperator = values.monthOperator ? values.monthOperator : '==';
                            filter += `${value}${mOperator}${month},`;
                        }
                        break;

                    case 'initialEventMonth':
                        if (values[value]) {
                            const month = (values[value] as DateTime).startOf("month").toFormat(DateTimeFormat.dateTime);
                            const mOperator = values.initialMonthOperator ? values.initialMonthOperator : '==';
                            filter += `${value}${mOperator}${month},`;
                        }
                        break;

                    case 'oldFte':
                        if (typeof values[value] === 'number') {
                            const ofteOperator = values.oldFteOperator ? values.oldFteOperator : '==';
                            filter += `${value}${ofteOperator}${values[value]},`;
                        }
                        break;

                    case 'newFte':
                        if (typeof values[value] === 'number') {
                            const nfteOperator = values.newFteOperator ? values.newFteOperator : '==';
                            filter += `${value}${nfteOperator}${values[value]},`;
                        }
                        break;

                    case 'origin':
                        if (values[value]) {
                            filter += `strategyId==${values[value].strategy.id},${values[value].label ? 'label==' + values[value].label + ',' : 'hasLabel==false,'}`;
                        }
                        break;

                    case 'initialEventOrigin':
                        if (values[value]) {
                            filter += `initialEventOrigin==${values[value].label ? values[value].label.trim().toLocaleLowerCase() : ''},`;
                        }
                        break;

                    case 'tags':
                        if (values[value]) {
                            filter += `tags==${values[value].join('|')},`;
                        }
                        break;

                    default:
                        if (values[value]?.externalId) {
                            filter += `${value}@=${values[value]?.externalId.trim().toLocaleLowerCase()},`;
                        } else if (values[value]?.code) {
                            filter += `${value}@=${values[value]?.code.trim().toLocaleLowerCase()},`;
                        } else if (values[value]) {
                            filter += `${value}@=${values[value] ? values[value].trim().toLocaleLowerCase() : ''},`;
                        }
                        break;
                }

            }
        });
        if (this.baseFilter) {
            filter += `${this.baseFilter}`;
        }
        if (filter.slice(-1) === ',') {
            filter = filter.slice(0, -1);
        }
        this.globalSearch.reset(null, { emitEvent: false });
        this.downloadFilter = filter;
        this.filter.emit(filter);
    }

    toggleCreatedEvents(change: MatCheckboxChange): void {
        this.isCreatedDisplayed = change.checked;
        this.displayCreated.emit(this.isCreatedDisplayed);
    }

    toggleDeletedEvents(change: MatCheckboxChange): void {
        this.isDeletedDisplayed = change.checked;
        this.displayDeleted.emit(this.isDeletedDisplayed);
    }

    getControl(control: string): UntypedFormControl {
        return this.searchForm.get(control) as UntypedFormControl;
    }

    private observeSearchChange(): void {
        this.globalSearch.valueChanges
            .pipe(
                debounceTime(300),
                switchMap((searchTerm: string) => {
                    if (this.globalSearch.pristine) {
                        return of(null);
                    }
                    const filter = `GlobalFilter@=${searchTerm}${this.baseFilter ? ',' + this.baseFilter : ''}`;
                    this.initForm();
                    return of(filter);
                }),
            ).subscribe({
                next: (filter: string) => {
                    this.downloadFilter = filter;
                    this.filter.emit(filter);
                }
            });
    }

    private initForm(): void {
        this.searchForm = this.fb.group({
            matricule: [],
            lastName: [],
            firstName: [],
            eventType: [],
            oldProfessionalStatus: [null, { updateOn: 'change' }],
            newProfessionalStatus: [null, { updateOn: 'change' }],
            eventMonth: [null, { updateOn: 'change' }],
            monthOperator: ['=='],
            initialEventMonth: [null, { updateOn: 'change' }],
            initialMonthOperator: ['=='],
            oldFte: [null, { updateOn: 'change' }],
            oldFteOperator: ['=='],
            newFte: [null, { updateOn: 'change' }],
            newFteOperator: ['=='],
            oldScalePayment: [null, { updateOn: 'change' }],
            oldSuperiorScalePayment: [null, { updateOn: 'change' }],
            oldParallelScalePayment: [null, { updateOn: 'change' }],
            newScalePayment: [null, { updateOn: 'change' }],
            newSuperiorScalePayment: [null, { updateOn: 'change' }],
            newParallelScalePayment: [null, { updateOn: 'change' }],
            tags: [null, { updateOn: 'change' }]
        }, { updateOn: 'submit' });

        if (this.mode === EventsTableMode.realized) {
            this.searchForm.removeControl('tags');
        }

        if (this.mode !== EventsTableMode.realized && this.mode !== EventsTableMode.provider && this.mode !== EventsTableMode.agent) {
            this.searchForm.addControl('origin', new UntypedFormControl(this.originFilter, { updateOn: 'change' }));
            this.searchForm.addControl('initialEventOrigin', new UntypedFormControl(null, { updateOn: 'change' }));
            this.searchForm.addControl('systemComment', new UntypedFormControl());
            this.searchForm.addControl('userComment', new UntypedFormControl());
        }

        if (this.mode === EventsTableMode.agent) {
            this.searchForm.removeControl('matricule');
            this.searchForm.removeControl('lastName');
            this.searchForm.removeControl('firstName');
        }
        this.searchForm.updateValueAndValidity();
        this.searchForm.markAsPristine();
    }

    private initSubscription(): void {
        this.appRightsSrv.appRights$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((appRights: AppRights) => {
                this.appRights = {
                    scenario: appRights.scenario
                }
                // console.log(this.appRights);
            });

        if (this.route.snapshot.queryParams.month) {
            this.refMonth = this.route.snapshot.queryParams.month;
            if (this.mode === EventsTableMode.realized || this.mode === EventsTableMode.agent) {
                this.maxMonth = DateTime.fromISO(this.refMonth);
            } else if (this.mode === EventsTableMode.provider) {
                this.minMonth = DateTime.fromISO(this.refMonth).plus({months: 1});
            }
        } else {
            this.refMonth = this.refMonthSrv.refMonth;
            if (this.mode === EventsTableMode.realized || this.mode === EventsTableMode.agent) {
                this.maxMonth = DateTime.fromISO(this.refMonth);
            } else if (this.mode === EventsTableMode.provider) {
                this.minMonth = DateTime.fromISO(this.refMonth).plus({months: 1});
            }
        }

        this.scenarioSrv.selectedScenarioSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scenarioFromSrv: Scenario) => {
                if (scenarioFromSrv) {
                    this.selectedScenario = scenarioFromSrv;
                    this.refMonth = this.selectedScenario.referenceMonth;
                }
            })

        this.forecastEventSrv.editedEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((eventsFromSrv: ThotEvent[]) => {
                this.editedEventsCount = eventsFromSrv.filter((event: ThotEvent) => !event.deleted).length;
                this.deletedEventsCount = eventsFromSrv.filter((event: ThotEvent) => event.deleted).length;
            })

        this.forecastEventSrv.createdEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((eventsFromSrv: ThotEvent[]) => {
                this.createdEventsCount = eventsFromSrv.length;
            })

        this.forecastEventSrv.originFilterSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((originFromSrv: EventOrigin) => {
                if (originFromSrv?.strategy) {
                    this.originFilter = originFromSrv;
                    this.searchForm.get('origin').setValue(this.originFilter);
                    this.downloadFilter = `strategyId==${originFromSrv.strategy.id},${originFromSrv.label ? 'label==' + originFromSrv.label : 'hasLabel==false'}`
                    this.filter.emit(this.downloadFilter);
                }
            })

        this.eventOriginSrv.initialOriginsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((originsFromSrv: InitialOrigin[]) => {
                this.initialOrigins = originsFromSrv;
            })

        this.forecastEventSrv.professionalStatusSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((statutsFromSrv: ProfessionalStatus[]) => this.professionalStatuts = statutsFromSrv)

        this.forecastEventSrv.scalePaymentSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((scalesFromSrv: Scale[]) => this.scalePayment = scalesFromSrv)

        this.eventOriginSrv.originsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((originsFromSrv: EventOrigin[]) => {
                this.origins = [];
                originsFromSrv.map((origin) => this.origins.push(EventOrigin.fromObject(origin)));
            })

        this.userTagSrv.userTagsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((userTagsFromSrv: UserTag[]) => this.userTags = userTagsFromSrv)
    }

}
