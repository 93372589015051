import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// import { HelpComponent } from './help.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';

// import { AuthGuard } from 'app/shared/guards/auth.guard';

// import { RoleType } from '@enums/role-type.enum';

const routes: Routes = [
    { path: 'maintenance', component: MaintenanceComponent },
    // {
    //     path: 'help', component: HelpComponent,
    //     canActivate: [AuthGuard],
    //     data: {
    //         roles: [
    //             RoleType.adm_bizzdev
    //         ]
    //     }
    // }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class HelpRoutingModule { }
