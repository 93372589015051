import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
    selector: '[appRatioBar]'
})
export class RatioBarDirective implements OnInit {
    @Input() ratio: number;
    @Input() color: string;

    constructor(
        private el: ElementRef,
        private renderer: Renderer2
    ) { }

    ngOnInit(): void {
        this.setRatio();
    }

    private setRatio(): void {
        this.renderer.setStyle(this.el.nativeElement, 'width', `${this.ratio * 100}%`);
        this.renderer.setStyle(this.el.nativeElement, 'background-color', this.color);
        // this.el.nativeElement.style.width = this.ratio * 100 + '%';
        // this.el.nativeElement.style.backgroundColor = this.color;
    }

}
