<div class="main-nav">
    <!-- <div class="logo">
        <img src="./assets/icons/logo.png" height=100 width=50% alt="logo Thot">
    </div> -->

    <mat-nav-list *ngIf="!isMaintenance">
        <mat-accordion>
            <app-main-nav-item *ngFor="let link of navLinks" [link]="link" (closeSidenav)="onCloseSidenav()">
            </app-main-nav-item>
        </mat-accordion>
    </mat-nav-list>

    <mat-list class="version">
        <mat-list-item>
            <a href="https://www.bizzdev.com/" target="_blank">
                <img src="/assets/icons/bizzdev.png" alt="Logo Bizzdev" width="44" height="28">
            </a>
            {{'main.version.shortLabel' | translate}}: {{ appVersion }}
        </mat-list-item>
        <mat-list-item *ngIf="isLocalTranslations" class="local-translation">
            Local Translations
        </mat-list-item>
    </mat-list>
</div>
