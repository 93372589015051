export class EmployerCategory {
    externalDescription: string;
    externalId: string;
    id: number;

    constructor()
    constructor(externalId?: string, id?: number) {
        this.externalId = externalId;
        this.id = id;
    }

    static fromObject(object: EmployerCategory): EmployerCategory {
        return Object.assign(new EmployerCategory(), object);
    }

    get display(): string {
        return `${this.externalId} ${this.externalDescription ? `(${this.externalDescription})` : ``}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.externalId], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
