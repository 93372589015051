import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ResizableModule } from 'angular-resizable-element';

import { NgxDropzoneModule } from 'ngx-dropzone';
import { FaModule } from './fa/fa.module';
import { MaterialModule } from './material/material.module';

import { ArrayFilterPipe } from './pipes/array-filter.pipe';
import { ArrayLabelFilterPipe } from './pipes/array-label-filter.pipe';
import { ArticleMappingPipe } from './pipes/article-mapping.pipe';
import { DiffNumberPipe } from './pipes/diff-number.pipe';
import { DiffPercentPipe } from './pipes/diff-percent.pipe';
import { FormatAmountPipe } from './pipes/format-amount.pipe';
import { FormatSalarayBasePipe } from './pipes/format-salaray-base.pipe';
import { FormatToTimePipe } from './pipes/format-to-time.pipe';
import { GetTagsNamesPipe } from './pipes/get-tags-names.pipe';
import { MappingDisplayOrderPipe } from './pipes/mapping-display-order.pipe';
import { MyCurrencyPipe } from './pipes/my-currency.pipe';
import { PaginationFilterPipe } from './pipes/pagination-filter.pipe';
import { SearchPipe } from './pipes/search-pipe.pipe';
import { ToTimePipe } from './pipes/to-time.pipe';

import { AutofocusDirective } from './directives/autofocus.directive';
import { DisableFormControlDirective } from './directives/disable-form-control.directive';
import { DragDropDirective } from './directives/drag-drop.directive';
import { HasMonthScenarioDirective } from './directives/has-month-scenario.directive';
import { IsCheckboxCheckedDirective } from './directives/is-checkbox-checked.directive';
import { PaddingDirective } from './directives/padding.directive';
import { RatioBarDirective } from './directives/ratio-bar.directive';
import { SelectOnTabDirective } from './directives/select-on-tab.directive';
import { TimelineContractDirective } from './directives/timeline-contract.directive';

import { BizzChartComponent } from '@components/bizz-chart/bizz-chart.component';
import { SunburstComponent } from '@components/bizz-chart/sunburst/sunburst.component';
import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { MultipleSelectFilterComponent } from '@components/multiple-select-filter/multiple-select-filter.component';
import { TooltipComponent } from '@components/tooltip/tooltip.component';
import { StructureEditComponent } from '@components/tree-mapping/structure-edit/structure-edit.component';
import { FilesListComponent } from '@components/uploader/files-list/files-list.component';
import { UploadMultiComponent } from '@components/uploader/upload-multi/upload-multi.component';
import { UploadProgressComponent } from '@components/uploader/upload-progress/upload-progress.component';
import { UploadSingleComponent } from '@components/uploader/upload-single/upload-single.component';
import { ProgressBarComponent } from './components/bizz-chart/progress-bar/progress-bar.component';
import { DependenciesListComponent } from './components/tree-mapping/dependencies-list/dependencies-list.component';
import { EditFormComponent } from './components/tree-mapping/edit-form/edit-form.component';
import { TreeMappingComponent } from './components/tree-mapping/tree-mapping.component';
import { TreeItemsComponent } from './components/tree-mapping/tree/tree-items/tree-items.component';
import { TreeComponent } from './components/tree-mapping/tree/tree.component';
// import { GaugeComponent } from './components/bizz-chart/gauge/gauge.component';
import { EventDetailComponent } from '@components/event-detail/event-detail.component';
import { ExecutionStateComponent } from '@components/execution-state/execution-state.component';
import { EventArticlesFunctionalComponent } from '@components/form-controls/event-articles-functional/event-articles-functional.component';
import { EventStatusAutocompleteComponent } from '@components/form-controls/event-status-autocomplete/event-status-autocomplete.component';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { EventsTableComponent } from './components/events-table/events-table.component';
import { AgentSelectComponent } from './components/form-controls/agent-select/agent-select.component';
import { EventScalesAutocompleteComponent } from './components/form-controls/event-scales-autocomplete/event-scales-autocomplete.component';
import { EventTypeSelectComponent } from './components/form-controls/event-type-select/event-type-select.component';
import { InputNumberComponent } from './components/form-controls/input-number/input-number.component';
import { MonthSelectorComponent } from './components/form-controls/month-selector/month-selector.component';
import { SelectCheckAllComponent } from './components/select-check-all/select-check-all.component';
// import { AutocompleteMultipleComponent } from '@components/autocomplete-multiple/autocomplete-multiple.component';
// import { SalaryIndexComponent } from '@components/salary-index/salary-index.component';
// import { IndexEditComponent } from '@components/salary-index/index-edit/index-edit.component';
// import { IndexSimulatedComponent } from './components/salary-index/index-simulated/index-simulated.component';
import { AutocompleteMultipleComponent } from './components/autocomplete-multiple/autocomplete-multiple.component';
import { DetailContractComponent } from './components/event-detail/detail-contract/detail-contract.component';
import { ServerPaginatorComponent } from './components/server-paginator/server-paginator.component';
import { TreeSelectionComponent } from './components/tree-selection/tree-selection.component';
import { SelectionCategoryComponent } from './components/tree-selection/selection-category/selection-category.component';
import { ItemsListComponent } from './components/tree-selection/selection-category/items-list/items-list.component';

@NgModule({
    declarations: [
        MultipleSelectFilterComponent,
        TooltipComponent,
        ConfirmDialogComponent,
        TreeMappingComponent,
        DependenciesListComponent,
        TreeComponent,
        TreeItemsComponent,
        EditFormComponent,
        BizzChartComponent,
        StructureEditComponent,
        UploadMultiComponent,
        UploadSingleComponent,
        FilesListComponent,
        UploadProgressComponent,
        SunburstComponent,
        ProgressBarComponent,
        // GaugeComponent,
        ExecutionStateComponent,
        SelectCheckAllComponent,
        EventsTableComponent,
        EventDetailComponent,
        MonthSelectorComponent,
        EventScalesAutocompleteComponent,
        EventStatusAutocompleteComponent,
        EventTypeSelectComponent,
        EventArticlesFunctionalComponent,
        InputNumberComponent,
        AgentSelectComponent,
        AutocompleteComponent,
        // AutocompleteMultipleComponent,
        DetailContractComponent,
        AutocompleteMultipleComponent,
        ServerPaginatorComponent,
        TreeSelectionComponent,
        SelectionCategoryComponent,
        ItemsListComponent,

        AutofocusDirective,
        TimelineContractDirective,
        DragDropDirective,
        PaddingDirective,
        SelectOnTabDirective,
        RatioBarDirective,
        HasMonthScenarioDirective,
        DisableFormControlDirective,
        IsCheckboxCheckedDirective,

        ArrayFilterPipe,
        ArrayLabelFilterPipe,
        PaginationFilterPipe,
        FormatAmountPipe,
        DiffPercentPipe,
        DiffNumberPipe,
        SearchPipe,
        ToTimePipe,
        MyCurrencyPipe,
        FormatToTimePipe,
        ArticleMappingPipe,
        GetTagsNamesPipe,
        MappingDisplayOrderPipe,
        FormatSalarayBasePipe
    ],
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ResizableModule,
        NgxDropzoneModule,
        RouterModule,
        FaModule
    ],
    exports: [
        MaterialModule,
        TranslateModule,
        FormsModule,
        ReactiveFormsModule,
        FaModule,
        NgxDropzoneModule,
        ResizableModule,

        BizzChartComponent,
        StructureEditComponent,
        UploadMultiComponent,
        UploadSingleComponent,
        FilesListComponent,
        UploadProgressComponent,
        SunburstComponent,
        ProgressBarComponent,
        // GaugeComponent,
        ExecutionStateComponent,
        MultipleSelectFilterComponent,
        TooltipComponent,
        TreeMappingComponent,
        SelectCheckAllComponent,
        EventsTableComponent,
        EventDetailComponent,
        MonthSelectorComponent,
        EventScalesAutocompleteComponent,
        EventStatusAutocompleteComponent,
        EventTypeSelectComponent,
        EventArticlesFunctionalComponent,
        InputNumberComponent,
        AgentSelectComponent,
        // SalaryIndexComponent,
        // IndexSimulatedComponent,
        AutocompleteComponent,
        // AutocompleteMultipleComponent,
        AutocompleteMultipleComponent,
        ServerPaginatorComponent,
        TreeSelectionComponent,

        AutofocusDirective,
        TimelineContractDirective,
        DragDropDirective,
        PaddingDirective,
        SelectOnTabDirective,
        RatioBarDirective,
        HasMonthScenarioDirective,
        DisableFormControlDirective,
        IsCheckboxCheckedDirective,

        ArrayFilterPipe,
        ArrayLabelFilterPipe,
        PaginationFilterPipe,
        FormatAmountPipe,
        DiffPercentPipe,
        DiffNumberPipe,
        SearchPipe,
        ToTimePipe,
        MyCurrencyPipe,
        FormatToTimePipe,
        ArticleMappingPipe,
        GetTagsNamesPipe,
        MappingDisplayOrderPipe,
        FormatSalarayBasePipe
    ]
})
export class SharedModule { }
