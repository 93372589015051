import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { ScaleAmount } from '@classes/parameters/scale-amount/scale-amount';
import { ScalesAmount } from '@classes/parameters/scale-amount/scales-amount';


@Injectable({
    providedIn: 'root'
})
export class ScaleAmountService {
    private baseUrl = environment.apiUrl + 'scale-amount'

    scalesAmountSubject = new Subject<ScalesAmount>()

    constructor(
        private http: HttpClient
    ) { }

    get(page: number, pageSize: number, filters?: string, sorting?: string): Observable<HttpResponse<ScaleAmount[]>> {
        let params = new HttpParams()
            .append('Page', `${page}`)
            .append('PageSize', `${pageSize}`)
            .append('Filters', `${filters ? `${filters}` : ''}`)
            .append('Sorts', `${sorting}`);

        if (params.get('Sorts') === 'undefined' || params.get('Sorts') === '') {
            params = params.delete('Sorts');
        }

        return this.http.get<ScaleAmount[]>(this.baseUrl, {observe: 'response', params})
            .pipe(
                tap(responseFromApi => {
                    this.scalesAmountSubject.next(new ScalesAmount(responseFromApi.body, responseFromApi.headers.get('x-pagination')));
                })
            )
    }

    exportExcel(filters?: string, sorting?: string): Observable<Blob> {
        let params = new HttpParams()
            .append('Page', '0')
            .append('PageSize', '0')
            .append('Filters', `${filters ? `${filters}` : ''}`)
            .append('Sorts', `${sorting}`);

        if (params.get('Sorts') === 'undefined' || params.get('Sorts') === '') {
            params = params.delete('Sorts');
        }

        return this.http.get(`${this.baseUrl}/excel`, { observe: 'response', responseType: 'blob', params })
            .pipe(map(res => this.saveFile(res)));
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }

}
