import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from 'app/shared/shared.module';

import { EventsTableServerComponent } from './events-table-server.component';
import { EventsToolbarComponent } from './events-toolbar/events-toolbar.component';
import { EventEditMultipleComponent } from './event-edit-multiple/event-edit-multiple.component';
import { EventsCreateComponent } from './events-create/events-create.component';
import { FteIncreaseComponent } from './events-create/event-type/fte-increase/fte-increase.component';
import { FteDecreaseComponent } from './events-create/event-type/fte-decrease/fte-decrease.component';
import { EntryIntoServiceComponent } from './events-create/event-type/entry-into-service/entry-into-service.component';
import { ScaleChangeComponent } from './events-create/event-type/scale-change/scale-change.component';
import { ScaleAdvancementComponent } from './events-create/event-type/scale-advancement/scale-advancement.component';
import { SuperiorScaleChangeComponent } from './events-create/event-type/superior-scale-change/superior-scale-change.component';
import { FixedTermContractComponent } from './events-create/event-type/fixed-term-contract/fixed-term-contract.component';
import { BudgetAssignementChangeComponent } from './events-create/event-type/budget-assignement-change/budget-assignement-change.component';
import { ParallelScaleChangeComponent } from './events-create/event-type/parallel-scale-change/parallel-scale-change.component';
import { StatusChangeComponent } from './events-create/event-type/status-change/status-change.component';
import { ReturnFromLeaveComponent } from './events-create/event-type/return-from-leave/return-from-leave.component';
import { TemporaryLeaveComponent } from './events-create/event-type/temporary-leave/temporary-leave.component';
import { PensionComponent } from './events-create/event-type/pension/pension.component';
import { AnonymeEventComponent } from './events-create/event-type/anonyme-event/anonyme-event.component';
import { FinalDepartureComponent } from './events-create/event-type/final-departure/final-departure.component';
import { SalaryChangeComponent } from './events-create/event-type/salary-change/salary-change.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule
    ],
    declarations: [
        EventsTableServerComponent,
        EventsToolbarComponent,
        EventEditMultipleComponent,
        EventsCreateComponent,
        FteIncreaseComponent,
        FteDecreaseComponent,
        EntryIntoServiceComponent,
        ScaleChangeComponent,
        ScaleAdvancementComponent,
        SuperiorScaleChangeComponent,
        FixedTermContractComponent,
        BudgetAssignementChangeComponent,
        ParallelScaleChangeComponent,
        StatusChangeComponent,
        ReturnFromLeaveComponent,
        TemporaryLeaveComponent,
        PensionComponent,
        AnonymeEventComponent,
        FinalDepartureComponent,
        SalaryChangeComponent
    ],
    exports: [
        EventsTableServerComponent
    ]
})
export class EventsTableServerModule { }
