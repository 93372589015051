import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn } from '@angular/forms';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';

import { ProfessionalStatus } from '@classes/contract/professional-status';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { Scale } from '@classes/scenario/scale';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';

@Component({
    selector: 'app-parallel-scale-change',
    templateUrl: './parallel-scale-change.component.html',
    styleUrls: ['./parallel-scale-change.component.scss']
})
export class ParallelScaleChangeComponent extends SubscriptionBaseComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    isIndividualEvent: boolean;

    constructor(
        private forecastEventSrv: ForecastEventService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initSubcription();
        this.group.markAllAsTouched();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
        this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
    }

    private parallelScalePaymentValidator(): ValidatorFn {
        return (group: UntypedFormGroup): { [key: string]: any } | null => {
            if (group.value.newParallelScalePayment?.id === group.value.oldParallelScalePayment?.id) {
                return {sameParallelScalePayment: true};
            } else {
                return null;
            }
        };
    }

    private initForm(): void {
        this.group.addControl('newParallelScalePayment', new UntypedFormControl(null, [Validators.required]));
        
        if (!this.isIndividualEvent) {
            this.group.addControl('newFte', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newScalePayment', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newSuperiorScalePayment', new UntypedFormControl(null));
            this.group.addControl('oldParallelScalePayment', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newSeniority', new UntypedFormControl(null, [Validators.required]));
            this.group.addControl('newSalaryBase', new UntypedFormControl(null));
            this.group.setValidators(this.parallelScalePaymentValidator());
            this.group.updateValueAndValidity();
        } else {
            this.group.removeControl('newFte');
            this.group.removeControl('newProfessionalStatus');
            this.group.removeControl('newFunctionalArticleRepartition');
            this.group.removeControl('newScalePayment');
            this.group.removeControl('newSuperiorScalePayment');
            this.group.removeControl('oldParallelScalePayment');
            this.group.removeControl('newSeniority');
            this.group.removeControl('newSalaryBase');
        }
    }

    private initSubcription(): void {
        this.subscription.add(
            this.forecastEventSrv.isIndividualEventSubject
                .subscribe(isIndividualEvent => {
                    if (isIndividualEvent) {
                        this.group.clearValidators();
                        this.group.updateValueAndValidity();
                    }
                    this.isIndividualEvent = isIndividualEvent;
                    this.initForm();
                })
        );
    }
}
