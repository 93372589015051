<app-agent-selector (selectedAgent)="agentSelected($event)"></app-agent-selector>

<mat-progress-bar *ngIf="isLoading" color="accent" mode="indeterminate"></mat-progress-bar>

<div class="agent-grid" *ngIf="agent">
  <aside class="agent-summary">
    <app-detail-summary [agent]="agent"></app-detail-summary>
  </aside>
  <mat-tab-group color="accent" dynamicHeight>
    <mat-tab [label]="'agent.agentDetail.contracts.title.fullLabel' | translate">
      <mat-expansion-panel class="contracts-list mat-elevation-z0" [expanded]="true">
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
          <mat-panel-title>
            <mat-icon>folder_open</mat-icon>
            <h2>{{ 'agent.agentDetail.contracts.openedContracts.fullLabel' | translate }}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-detail-contracts [contracts]="agent.contracts | arrayFilter: 'isOpen': true"
          (contractSelected)="onSelectContract($event)"></app-detail-contracts>
      </mat-expansion-panel>
      <mat-expansion-panel class="contracts-list mat-elevation-z0">
        <mat-expansion-panel-header collapsedHeight="42px" expandedHeight="42px">
          <mat-panel-title>
            <mat-icon>folder</mat-icon>
            <h2>{{ 'agent.agentDetail.contracts.closedContracts.fullLabel' |translate }}</h2>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <app-detail-contracts [contracts]="agent.contracts | arrayFilter: 'isOpen': false"
          (contractSelected)="onSelectContract($event)"></app-detail-contracts>
      </mat-expansion-panel>
    </mat-tab>
    <!-- <mat-tab [label]="('agent.agentDetail.refSituation.title.fullLabel' | translate) + ' (' + (refMonth | date: dateFormat) + ')'">
            <app-detail-ref-situation></app-detail-ref-situation>
        </mat-tab> -->
    <mat-tab [label]="'agent.agentDetail.timeline.title.fullLabel' | translate">
      <app-detail-events-timeline [contracts]="agent.contracts" [refMonth]="refMonth"></app-detail-events-timeline>
    </mat-tab>

    <mat-tab
      [label]="('agent.agentDetail.refSituation.title.fullLabel' | translate) + ' & ' + ('agent.agentDetail.events.title.shortLabel' | translate)"
      [disabled]="eventsDisabled">
      <app-detail-events *ngIf="!eventsDisabled" [selectedAgent]="agent"></app-detail-events>
    </mat-tab>

    <mat-tab [label]="'agent.agentDetail.pastEvents.fullLabel' | translate">
      <app-detail-past-events [refMonth]="refMonth" [agent]="agent"></app-detail-past-events>
    </mat-tab>
    
    <mat-tab [label]="'agent.agentDetail.prestations.title.fullLabel' | translate">
      <app-detail-prestation [selectedAgent]="agent"></app-detail-prestation>
    </mat-tab>
  </mat-tab-group>
</div>