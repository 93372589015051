import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { CotisationRate } from '@classes/parameters/conjonctural-parameters/cotisation-rate';

@Injectable({
    providedIn: 'root'
})
export class CotisationRateService {
    private baseUrl = environment.apiUrl + 'cotisation-rate'

    ratesSubject = new Subject<CotisationRate[]>();

    constructor(
        private http: HttpClient
    ) { }

    get(filters?: string): Observable<CotisationRate[]> {
        let params = new HttpParams()
            .append('sorts', '-validFrom')
            .append('filters', filters);

        if (params.get('filters') === 'undefined' || params.get('filters') === '') {
            params = params.delete('filters');
        }

        return this.http.get<CotisationRate[]>(this.baseUrl, {params});
    }

    post(newRate: CotisationRate): Observable<CotisationRate> {
        return this.http.post<CotisationRate>(`${this.baseUrl}`, newRate);
    }

    put(rate: CotisationRate): Observable<CotisationRate> {
        return this.http.put<CotisationRate>(`${this.baseUrl}`, rate);
    }

    delete(rateId: number): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/${rateId}`);
    }

    exportRates(): Observable<Blob> {
        return this.http.get(`${this.baseUrl}/excel`, { observe: 'response', responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }

}
