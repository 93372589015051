<div class="contracts-list">
    <article class="contract" *ngFor="let contract of contracts">
        <header>
            <h3>{{'agent.agentDetail.contracts.docNumber.shortLabel' | translate}} {{contract.docNumber}}</h3>
        </header>
        <div class="content">
            <mat-list>
                <mat-list-item>
                    <span class="label">{{'agent.agentDetail.contracts.startDate.shortLabel' | translate}}</span>
                    <span>{{contract.startDate | date: dateFormat}}</span>
                </mat-list-item>
                <mat-list-item>
                    <span class="label">{{'agent.agentDetail.contracts.endDate.shortLabel' | translate}}</span>
                    <span>{{contract.endDate | date: dateFormat}}</span>
                </mat-list-item>
                <mat-list-item *ngIf="contract.startMotif">
                    <span class="label">{{'agent.agentDetail.contracts.startMotif.shortLabel' | translate}}</span>
                    <span *ngIf="contract.startMotif; else nullProp">
                        {{contract.startMotif.externalId}} ({{contract.startMotif.externalDescription}})
                    </span>
                </mat-list-item>
                <mat-list-item *ngIf="contract.endMotif">
                    <span class="label">{{'agent.agentDetail.contracts.endMotif.shortLabel' | translate}}</span>
                    <span *ngIf="contract.endMotif; else nullProp">
                        {{contract.endMotif.externalId}} ({{contract.endMotif.externalDescription}})
                    </span>
                </mat-list-item>
                <mat-list-item>
                    <span class="label">{{'agent.agentDetail.contracts.workerCategory.shortLabel' | translate}}</span>
                    <span *ngIf="contract.workerCategory">
                        {{contract.workerCategory.externalId}} ({{contract.workerCategory.externalDescription}})
                    </span>
                </mat-list-item>
                <mat-list-item >
                    <span class="label">{{'agent.agentDetail.contracts.employerCategory.shortLabel' | translate}}</span>
                    <span *ngIf="contract.employerCategory">
                        {{contract.employerCategory.externalId}}
                    </span>
                </mat-list-item>
            </mat-list>
        </div>
        <div class="actions">
            <button mat-button (click)="onSelectContract(contract)" color="accent">
                {{'agent.agentDetail.contracts.viewContractStates.fullLabel' | translate}}
            </button>
        </div>
    </article>
    <article class="no-contract" *ngIf="!contracts || !contracts.length">
        <p>{{'agent.agentDetail.contracts.noContracts.fullLabel' | translate}}</p>
    </article>
</div>

<ng-template #nullProp>
    <span>-</span>
</ng-template>
