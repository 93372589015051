import { AfterViewInit, Directive, HostListener, OnDestroy, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[appSelectOnTab]'
})
export class SelectOnTabDirective implements AfterViewInit, OnDestroy {
    observable: Subscription;

    constructor(
        @Optional() private autoTrigger: MatAutocompleteTrigger,
        @Optional() private control: NgControl
    ) { }

    // @HostListener('keydown.tab', ['$event.target']) onBlur() {
    //     console.log('ici');
    //     if (this.autoTrigger.activeOption) {
    //         this.autoTrigger.writeValue(this.autoTrigger.activeOption.value);
    //     }
    // }

    ngAfterViewInit(): void {
        this.observable = this.autoTrigger.panelClosingActions.subscribe(x => {
            if (this.autoTrigger.activeOption) {
                const value = this.autoTrigger.activeOption.value;
                if (this.control) {
                    this.control.control.setValue(value, { emitEvent: false });
                }
                this.autoTrigger.writeValue(value);
            }
        });
    }

    ngOnDestroy() {
        this.observable.unsubscribe();
    }

}
