import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '@services/auth.service';

export const unauthGuard: CanActivateFn = (
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
) => {
    const authSrv = inject(AuthService); 
    const router = inject(Router); 
    if (authSrv.isAuth()) {
        return router.createUrlTree(['home']);
    } else {
        return true;
    }
}
