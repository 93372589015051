import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { WorkflowExecution } from '@classes/workflow/workflow-execution';
import { Monitoring } from '@classes/workflow/monitoring';
import { WorkflowType } from '@enums/workflow-type.enum';

@Injectable({
    providedIn: 'root'
})
export class WorkflowService {
    private baseUrl = environment.apiUrl + 'workflow';

    workflowSubject = new Subject<WorkflowExecution>();
    private workflowsSubject = new BehaviorSubject<WorkflowExecution[]>([]);
    readonly workflows$ = this.workflowsSubject.asObservable();

    private monitoringSubject = new BehaviorSubject<Monitoring>(null);
    readonly monitoring$ = this.monitoringSubject.asObservable();

    constructor(
        private http: HttpClient
    ) { }

    getList(type?: WorkflowType): Observable<WorkflowExecution[]> {
        let params = new HttpParams()
            .append('type', `${type}`);

        if (params.get('type') === 'undefined') {
            params = params.delete('type');
        }

        return this.http.get<WorkflowExecution[]>(this.baseUrl + '/executions', { params })
            .pipe(
                tap(workflowsFromApi => {
                    this.workflowsSubject.next(workflowsFromApi);
                })
            );
    }

    get(workflowId: number): Observable<WorkflowExecution> {
        return this.http.get<WorkflowExecution>(`${this.baseUrl}/executions/${workflowId}`)
            .pipe(
                tap(workflowFromApi => {
                    this.workflowSubject.next(workflowFromApi);
                })
            );
    }

    getMonitoring(month?: string): Observable<Monitoring> {
        let httpParams = new HttpParams();

        if (month) {
            httpParams = httpParams.append('refMonth', month);
        }

        return this.http.get<Monitoring>(this.baseUrl + '/monitoring', { params: httpParams })
            .pipe(
                tap(monitoringFromApi => {
                    this.monitoringSubject.next(monitoringFromApi);
                })
            );
    }

    process(workflowId: number): Observable<WorkflowExecution> {
        return this.http.put<WorkflowExecution>(`${this.baseUrl}/executions/${workflowId}/refresh-state`, null)
            .pipe(
                tap(workflowFromApi => {
                    const workflows = this.workflowsSubject.getValue()
                        .map(w => w.id === workflowFromApi.id ? workflowFromApi : w);
                    this.workflowsSubject.next([...workflows]);
                })
            );
    }

    normalize(workflowExecutionId: number): Observable<WorkflowExecution> {
        return this.http.post<WorkflowExecution>(`${this.baseUrl}/executions/${workflowExecutionId}/normalize`, null)
            .pipe(
                tap(workflowFromApi => {
                    this.workflowsSubject.next([...this.workflowsSubject.getValue(), workflowFromApi]);
                })
            );
    }

    // qualityCheck(): Observable<WorkflowExecution> {
    //     const refMonth: Date = this.monitoringSubject.getValue().refMonth;

    //     return this.http.post<WorkflowExecution>(`${this.baseUrl}/executions/${refMonth}/check`, null)
    //         .pipe(
    //             tap(workflowFromApi => {
    //                 this.workflowsSubject.next([...this.workflowsSubject.getValue(), workflowFromApi]);
    //             })
    //         );
    // }

    activateNextMonth(): Observable<Monitoring> {
        return this.http.post<Monitoring>(`${this.baseUrl}/executions/activate-next-month`, null)
            .pipe(
                tap(monitoringFromApi => {
                    this.monitoringSubject.next(monitoringFromApi);
                })
            );
    }

    completeWorkflow(workflowId: number): Observable<WorkflowExecution> {
        let params = new HttpParams()
            .append('state', '3');
        return this.http.patch<WorkflowExecution>(`${this.baseUrl}/executions/${workflowId}/state`, undefined, { params })
    }

    completeCollect(refMonth: any): Observable<any> {
        let params = new HttpParams()
            .append('type', `${WorkflowType.collect}`);
        return this.http.post<any>(`${this.baseUrl}/executions/${refMonth}/complete-collect`, null, { params });
    }
}
