import { Agent } from '@classes/agent/agent';
import { Pagination } from '@interfaces/pagination';
import { ThotEvent } from './thot-event';

export class EventsList {
    events: ThotEvent[];
    pagination: Pagination;

    constructor(
        events: ThotEvent[],
        pagination: string
    ) {
        this.events = events.map(event => {
            if (event.agent) {
                event.agent = Agent.fromObject(event.agent);
            } else if (event.virtualAgent) {
                event.virtualAgent = Agent.fromObject(event.virtualAgent);
            }
            return event;
        });
        this.pagination = JSON.parse(pagination);
    }
}
