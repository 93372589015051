import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, finalize, filter, take } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { AuthService } from '@services/auth.service';


@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private isRefreshingToken: boolean;
    private tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

    constructor(
        private uiSrv: UiService,
        private authSrv: AuthService,
        private router: Router
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError(err => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 401) {
                        // token outdated
                        return this.refreshToken(req, next);
                    }

                    this.uiSrv.isLoadingSubject.next(false);
                    this.uiSrv.routeLoadingStateChanged.next(false);
                    this.uiSrv.translationLoadingStateChanged.next(false);

                    this.handleErrorMessage(err);
                    return throwError(err);
                }
            })
        );
    }

    private refreshToken(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;

            // Reset here so that the following request wait until the token
            // commes back from the refreshToken call
            this.tokenSubject.next(null);

            return this.authSrv.refreshToken()
                .pipe(
                    switchMap(newToken => {
                        if (newToken) {
                            this.tokenSubject.next(newToken.access_token);
                            return next.handle(this.addToken(req, newToken.access_token));
                        }

                        // if we don't get a new token, we are in trouble so logout
                        this.authSrv.logout();
                        this.showMessage('auth.tokenRfFail.summary');
                        return throwError('');
                    }),
                    catchError(error => {
                        // if there is an exception calling 'refreshToken, bad news so logout
                        this.authSrv.logout();
                        return throwError(error);
                    }),
                    finalize(() => this.isRefreshingToken = false)
                );
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                })
            );
        }
    }

    private addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    private handleErrorMessage(err: HttpErrorResponse): void {
        const defaultText = `${err.status} ${err.statusText === 'OK' ? 'ERROR' : err.statusText}`;

        switch (err.status) {
            case 403:
                this.handle403Error(err);
                break;
            case 400:
                if (err.error && err.error.error) {
                    this.handle400Error(err.error.error, err.error.error_description);
                } else if (err.error) {
                    this.handle400Error(err.error);
                } else {
                    this.showMessage(defaultText, false);
                }
                break;
            case 409:
                if (err.error) {
                    this.handle409Error(err.error);
                } else {
                    this.showMessage(defaultText, false);
                }
                break;
            case 404:
                this.handle404Error(err);
                break;
            default:
                // redirect to error page if error status is not a known one
                this.showMessage(defaultText, false);
                this.router.navigate(['/error'], { queryParams: { code: err.status, message: err.statusText === 'OK' ? 'ERROR' : err.statusText } });
        }
    }

    private handle400Error(error: string, errorDescription?: string): void {
        switch (error) {
            case 'invalid_grant':
                this.handleInvalidGrant(errorDescription);
                break;
            case 'invalid_type':
                this.showMessage('files.uploadStates.typeError.summary');
                break;
            case 'username_already_used':
                this.showMessage('users.list.userExists.summary');
                break;
            case 'ref_month':
                this.showMessage('monthlyFollowUp.noRefMonth.summary');
                break;
            case 'mandatory_columns':
                this.showMessage('httpInterceptorErrors.mandatoryColumns.summary');
                break;
            case 'invalid_month':
                this.showMessage('scenarios.strategies.errors.invalidMonth.summary');
                break;
            default:
                this.showMessage(error, false);
        }
    }

    private handle403Error(error?: HttpErrorResponse): void {
        if (error.error) {
            switch (error.error) {
                case "used_by_scenario":
                    this.showMessage('parameters.scaleAmount.groupHasScenario.summary');
                    break;
                case "profile_used":
                    this.showMessage('httpInterceptorErrors.profileUsed.summary');
                    break;
                case "missing_files":
                    break;
                case "collect_not_started":
                    break;
            }
        } else {
            this.showMessage("auth.tokenRfFail.summary");
            this.router.navigate(['/']);
        }
    }

    private handle404Error(error: HttpErrorResponse): void {
        if (!error.url.match('budget-header')) {
            switch (error.error) {
                case 'default_scenario':
                    this.showMessage('scenarios.unavailableDefaultScenario.summary');
                    break;

                default:
                    this.showMessage('main.dialogs.serverNotFound.summary');
            }
        }
    }

    private handle409Error(error: string): void {
        switch (error) {
            case 'label_already_used':
                this.showMessage('storyLine.storyEdit.labelAlreadyUsed.summary');
                break;
            case 'username_already_exists':
                this.showMessage('licences.users.edit.alreadyExist.summary');
                break;
            case 'job_running':
                this.showMessage('storyLine.jobRunning.summary');
                break;
            case 'older_than_refmonth':
                this.showMessage('storyLine.olderThanRefmonth.summary');
                break;
            default:
                this.showMessage(error, false);
        }
    }

    private handleInvalidGrant(errorDescription: string): void {
        switch (errorDescription) {
            // Login failed
            case 'invalid_username_or_password':
                this.showMessage('auth.invalidGrant.summary');
                break;
            // Token refresh failed
            default:
                this.authSrv.logout();
                this.showMessage('auth.tokenRfFail.summary');
        }
    }

    private showMessage(errorMessage: string, isTranslated = true): void {
        this.uiSrv.showSnackbar(errorMessage, isTranslated, 5000);
    }
}
