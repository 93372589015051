export enum WorkflowType {
    collect = 1,
    normalize,
    allowImport,
    validateImport,
    mappingPaymentSection,
    mappingStatus,
    mappingArticle,
    initialisation,
    mappingNomenclature,
    organigram,
    allowQualityCheck,
    checkDebit,
    checkCompta,
    checkCalibrage,
    budgetCollect,
    validateQualityCheck,
    reimport,
    checkCollect,
    ComptaCollect,
	ComptaCheckCollect,
	ComptaNormalizeCollect 
}
