<span mat-dialog-title>{{'main.dialogs.confirm.shortLabel' | translate}}</span>
<mat-dialog-content>
    <p>
        <span [innerHTML]="passedData.message | translate: {detail: passedData.options.detail}" *ngIf="passedData.options?.detail"></span>
        <span [innerHTML]="passedData.message | translate" *ngIf="!passedData.options?.detail"></span> <br>
        {{'main.dialogs.confirm.fullLabel' | translate}}
    </p>
    <ul *ngIf="passedData.options?.list?.length">
        <li *ngFor="let item of passedData.options.list"> {{item}} </li>
    </ul>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true">{{'main.dialogs.confirmYes.shortLabel' | translate}}</button>
    <button mat-raised-button [mat-dialog-close]="false" color="accent">{{'main.dialogs.confirmNo.shortLabel' | translate}}</button>
</mat-dialog-actions>
