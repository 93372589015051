import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';

import { environment } from '@environments/environment';

import { Client } from '@classes/client';
import { SelectedClient } from '@classes/bizzdev/selected-client';

@Injectable({
  providedIn: 'root'
})
export class ClientService {
  selectedClientChanged = new Subject<SelectedClient>();

  private baseUrl = environment.apiUrl + 'client';

  constructor(
    private http: HttpClient
  ) { }

  get(): Observable<Client[]> {
    return this.http.get<Client[]>(this.baseUrl);
  }
}
