import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { LanguageService } from './language.service';
import { UiService } from './ui.service';


import { AuthData } from '@classes/auth/auth-data';
import { AuthResponse } from '@classes/auth/auth-response';
import { AuthUser } from '@classes/auth/auth-user';
import { Token } from '@classes/token';
import { RoleKeys } from '../models/enums/profile/roles';
import { ReferenceMonthService } from './reference-month.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    authChanged = new BehaviorSubject<boolean>(false);
    authUserChanged = new BehaviorSubject<AuthUser>(null);

    private identityApiUrl: string = environment.identityApiUrl;
    private apiUrl: string = environment.apiUrl;
    private tokenKey: string = environment.tokenKey;
    private rfTokenKey: string = environment.rfTokenKey;
    private tokenExpKey: string = environment.tokenExpKey;

    private authUser: AuthUser;

    public redirectUrl: string;

    constructor(
        private router: Router,
        private http: HttpClient,
        private jwtHelperSrv: JwtHelperService,
        private languageSrv: LanguageService,
        private refMonthSrv: ReferenceMonthService,
        private uiSrv: UiService,
        private zone: NgZone
    ) { }

    login(authData: AuthData): Observable<AuthResponse> {
        const hashedPassword = this.cryptString(authData.password);

        const httpParams = new HttpParams()
            .append('username', authData.username)
            .append('password', hashedPassword)
            .append('grant_type', 'password')
            .append('scope', 'api offline_access')
            .append('client_id', 'web');

        return this.http.post<AuthResponse>(this.identityApiUrl + 'connect/token', httpParams)
            .pipe(
                tap(result => {
                    this.saveToken(result.access_token, result.refresh_token, result.expires_in);
                    this.languageSrv.setCurrentLanguage();

                    if (this.redirectUrl) {
                        this.router.navigate([this.redirectUrl]);
                        this.redirectUrl = null;
                    } else {
                        this.router.navigate(['/home']);
                    }
                })
            );
    }

    updatePassword(resetData: { currentPassword: string, newPassword: string }): Observable<string> {
        const hashedCurrentPassword = this.cryptString(resetData.currentPassword);
        const hashedNewPassword = this.cryptString(resetData.newPassword);

        const httpParams = new HttpParams()
            .append('current_password', hashedCurrentPassword)
            .append('new_password', hashedNewPassword);

        return this.http.post<string>(this.apiUrl + 'users/password', httpParams)
            .pipe(
                tap(() => {
                    this.afterPwdReset(resetData.newPassword);
                })
            );
    }

    requestPasswordReset(username: string) {
        const httpParams = new HttpParams().append('username', username);

        return this.http.post(this.apiUrl + 'user/password-reset-request', httpParams);
    }

    requestPasswordInit(username: string) {
        const httpParams = new HttpParams()
            .append('username', username);

        return this.http.post(this.apiUrl + 'user/password-init-request', httpParams);
    }

    resetPassword(userId: string, token: string, newPassword: string) {
        const hashedNewPassword = this.cryptString(newPassword);

        // Send data in body in place of http params to avoid mess in token serialisation due to special characters
        const body = {
            token,
            new_password: hashedNewPassword
        };

        return this.http.post(`${this.apiUrl}user/${userId}/password-reset`, body)
            .pipe(
                tap(() => {
                    this.router.navigate(['/login']);
                })
            );
    }

    refreshToken(): Observable<AuthResponse> {
        const refreshToken = localStorage.getItem(this.rfTokenKey);

        const httpParams = new HttpParams()
            .append('grant_type', 'refresh_token')
            .append('scope', 'api offline_access')
            .append('client_id', 'web')
            .append('refresh_token', refreshToken);

        return this.http.post<AuthResponse>(this.identityApiUrl + 'connect/token', httpParams)
            .pipe(
                tap(result => {
                    // this.refMonthSrv.getRefMonth().subscribe();
                    // this.refMonthSrv.getLastNormalized().subscribe();
                    this.saveToken(result.access_token, result.refresh_token, result.expires_in);
                })
            );
    }

    logout(): void {
        localStorage.clear();
        this.authChanged.next(false);
        this.authUserChanged.next(null);
        this.authUser = null;
        this.refMonthSrv.refMonthSubject.next(null);
        this.refMonthSrv.lastNormalizedMonthSubject.next(null);
        this.zone.run(() => this.router.navigate(['/login']));
    }

    isAuth(): boolean {
        const decodedToken = this.getDecodedToken();

        if (!decodedToken) {
            return false;
        }

        return !this.isTokenExpired();
    }

    getDecodedToken(): Token {
        const token = this.jwtHelperSrv.tokenGetter() as string;

        if (token) {
            return this.jwtHelperSrv.decodeToken(token);
        }

        return null;
    }

    setAuthUser(client?: string): void {
        const decodedToken = this.getDecodedToken();
        if (!decodedToken) {
            return null;
        }

        client = client || decodedToken.client;
        const roles = typeof decodedToken.role === 'string' ? [decodedToken.role] : decodedToken.role;

        this.authUser = new AuthUser(decodedToken.name, roles, client, decodedToken.email, decodedToken.profile, decodedToken.language);
        this.authUserChanged.next(this.authUser);
    }

    getAuthUser(): AuthUser {
        if (!this.authUser) {
            this.setAuthUser();
        }

        return this.authUser;
    }

    isAuthorized(roles: RoleKeys[], clients?: string[]): boolean {
        const token = this.jwtHelperSrv.tokenGetter();

        if (clients?.length) {
            if (clients.some(client => this.authUser.client === client)) {
                if (!roles) {
                    return true;
                }

                if (!token) {
                    return false;
                }

                return this.hasRoles(roles);
                // return this.authUser.roles.some((role: RoleType) => roles.find(r => r === role));
            }
        } else {
            if (!roles) {
                return true;
            }

            if (!token) {
                return false;
            }
            return this.hasRoles(roles);
            // return this.authUser.roles.some((role: RoleType) => roles.find(r => r === role));
        }

        // role not authorised
        return false;
    }

    getToken(): string {
        return this.jwtHelperSrv.tokenGetter() as string;
    }

    private saveToken(accessToken: string, refreshToken: string, deadline: number): void {
        const tokenExpDate = new Date();
        tokenExpDate.setSeconds(tokenExpDate.getSeconds() + deadline);

        localStorage.setItem(this.tokenExpKey, tokenExpDate.toString());
        localStorage.setItem(this.tokenKey, accessToken);
        localStorage.setItem(this.rfTokenKey, refreshToken);

        this.authChanged.next(true);
        this.setAuthUser();
    }

    private afterPwdReset(newPassword: string): void {
        const decodedToken = this.getDecodedToken();

        if (decodedToken) {
            this.login({ username: decodedToken.name, password: newPassword });
        } else {
            this.logout();
        }
    }

    private isTokenExpired(): boolean {
        const tokenExpDate = new Date(localStorage.getItem(this.tokenExpKey));
        const now = new Date();
        return tokenExpDate < now;
    }

    private cryptString(stringTocrypt: string): string {
        return CryptoJS.SHA256(stringTocrypt).toString();
    }

    /**
     * 
     * @param roles string or string array of roles
     * @returns true if authenticated user has at least one of the roles given
     */
    hasRoles(roles: RoleKeys | RoleKeys[]): boolean {
        if (this.authUser.roles?.length) {
            if (typeof roles === 'string') {
                return this.authUser.roles.find(r => r === roles) ? true : false;
            } else {
                return roles.some(role => this.authUser.roles.find(r => r === role));
            }
        } else {
            this.uiSrv.showSnackbar('auth.noRoles.summary', true);
            this.logout();
            return false;
        }
    }

}
