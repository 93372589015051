<form [formGroup]="group">
    <div>
        <ng-container *ngIf="!isIndividualEvent">
            <app-input-number [label]="'scenarios.events.properties.newFte.shortLabel' | translate"
                              [group]="group"
                              [controlName]="'newFte'"
                              [min]="0"
                              [max]="1"
                              [step]="0.1"
                              [required]="true"></app-input-number>
            <app-autocomplete [list]="professionalStatus"
                              [control]="getControl('newProfessionalStatus')"
                              [label]="'scenarios.events.properties.newProfessionalStatus.shortLabel' | translate"
                              [required]="true"
                              [tooltipProperty]="'description'"></app-autocomplete>
            <app-autocomplete [list]="scalesPayment"
                              [control]="getControl('newScalePayment')"
                              [label]="'scenarios.events.properties.newScalePayment.shortLabel' | translate"
                              [required]="true"></app-autocomplete>
            <app-autocomplete [list]="scalesPayment"
                              [control]="getControl('newSuperiorScalePayment')"
                              [label]="'scenarios.events.properties.newSuperiorScalePayment.shortLabel' | translate"></app-autocomplete>
            <app-autocomplete [list]="scalesPayment"
                              [control]="getControl('newParallelScalePayment')"
                              [label]="'scenarios.events.properties.newParallelScalePayment.shortLabel' | translate"></app-autocomplete>
            <app-input-number [label]="'scenarios.events.properties.newSeniority.shortLabel' | translate"
                              [group]="group"
                              [controlName]="'newSeniority'"
                              [min]="0"
                              [required]="true"></app-input-number>
        </ng-container>
        <app-input-number *ngIf="!isIndividualEvent"
                          [label]="'scenarios.events.properties.oldSalaryBase.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'oldSalaryBase'"
                          [min]="0"
                          [required]="true"></app-input-number>
        <app-input-number [label]="'scenarios.events.properties.newSalaryBase.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'newSalaryBase'"
                          [min]="0"
                          [required]="true"></app-input-number>
    </div>
    <div *ngIf="!isIndividualEvent">
        <app-event-articles-functional [group]="group"
                                       [controlName]="'newFunctionalArticleRepartition'"
                                       [articles]="articlesFunctional"
                                       [required]="true"
                                       (articleRepartition)="onArticleRepartition($event)">
        </app-event-articles-functional>
        <p *ngIf="group.get('newFunctionalArticleRepartition').errors?.ratio"
           class="bad-ratio">
            {{'scenarios.events.error.badRatio.summary' | translate}} </p>
    </div>
</form>