export class ProfessionalStatus {
    code: string;
    description: string;
    id: number;
    mappedIn?: string;

    constructor()
    constructor(code?: string, description?: string, id?: number) {
        this.code = code;
        this.description = description;
        this.id = id;
    }

    static fromObject(object: ProfessionalStatus): ProfessionalStatus {
        if (object)
            return Object.assign(new ProfessionalStatus(), object);
        return;
    }

    get display(): string {
        return `${this.code}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.code], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
