import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { SubstitutionScalePayment } from '@classes/parameters/substitution-scale-payment';

@Injectable({
    providedIn: 'root'
})
export class SubstitutionScalePaymentService {
    private baseUrl = environment.apiUrl + 'substitution-scale-payment';

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<SubstitutionScalePayment[]> {
        return this.http.get<SubstitutionScalePayment[]>(this.baseUrl);
    }

    post(substitutionScalePayment: SubstitutionScalePayment): Observable<SubstitutionScalePayment> {
        return this.http.post<SubstitutionScalePayment>(this.baseUrl, substitutionScalePayment);
    }

    put(substitutionScalePayment: SubstitutionScalePayment): Observable<SubstitutionScalePayment> {
        return this.http.put<SubstitutionScalePayment>(this.baseUrl, substitutionScalePayment);
    }

    delete(substitutionScalePayment: SubstitutionScalePayment): Observable<void> {
        return this.http.delete<void>(this.baseUrl + '/' + substitutionScalePayment.id);
    }

}
