import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AgentDetailComponent } from './agent-detail/agent-detail.component';
import { DetailContractsComponent } from './agent-detail/detail-contracts/detail-contracts.component';
import { DetailSummaryComponent } from './agent-detail/detail-summary/detail-summary.component';
import { AgentSelectorComponent } from './agent-selector/agent-selector.component';
import { AgentComponent } from './agent.component';
import { ContractStateComponent } from './contract-state/contract-state.component';

import { EventsTableServerModule } from '@components/events-table-server/events-table-server.module';
import { SharedModule } from 'app/shared/shared.module';
import { DetailEventsTimelineComponent } from './agent-detail/detail-events-timeline/detail-events-timeline.component';
import { DetailEventsComponent } from './agent-detail/detail-events/detail-events.component';
import { DetailRefSituationComponent } from './agent-detail/detail-events/ref-situation/detail-ref-situation/detail-ref-situation.component';
import { RefSituationComponent } from './agent-detail/detail-events/ref-situation/ref-situation.component';
import { DetailPastEventsComponent } from './agent-detail/detail-past-events/detail-past-events.component';
import { DetailPrestationComponent } from './agent-detail/detail-prestation/detail-prestation.component';
import { AgentRoutingModule } from './agent-routing.module';
import { StateYearComponent } from './contract-state/state-year/state-year.component';
import { PrestationFilterComponent } from './agent-detail/detail-prestation/prestation-filter/prestation-filter.component';


@NgModule({
    declarations: [
        AgentDetailComponent,
        AgentSelectorComponent,
        ContractStateComponent,
        AgentComponent,
        DetailSummaryComponent,
        DetailContractsComponent,
        DetailEventsComponent,
        DetailEventsTimelineComponent,
        StateYearComponent,
        RefSituationComponent,
        DetailRefSituationComponent,
        DetailPrestationComponent,
        DetailPastEventsComponent,
        PrestationFilterComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        EventsTableServerModule,
        FormsModule,
        ReactiveFormsModule,
        AgentRoutingModule
    ]
})
export class AgentModule { }
