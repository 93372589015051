<div class="month-selector" [formGroup]="group" *ngIf="group; else noGroup">
    <mat-form-field class="operator" *ngIf="operator">
        <mat-select [formControl]="operator">
            <mat-option *ngFor="let operator of operators" [value]="operator.value"> {{operator.label}} </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label> {{label}} </mat-label>
        <input matInput [matDatepicker]="month" [min]="min" [max]="max" [formControlName]="controlName" placeholder="Ex: 12/2019" [required]="required" [errorStateMatcher]="showErrorOnChanges" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="month" [disabled]="group.controls[controlName].disabled"></mat-datepicker-toggle>
        <mat-datepicker #month startView="multi-year" panelClass="month-picker"
            (monthSelected)="onMonthSelected($event, month)"></mat-datepicker>
        <mat-error *ngIf="group.controls[controlName].errors?.matDatepickerMin"> {{ 'errors.minDate.fullLabel' | translate: {date: minDate} }} </mat-error>
        <mat-error *ngIf="group.controls[controlName].errors?.matDatepickerMax"> {{ 'errors.maxDate.fullLabel' | translate: {date: maxDate} }} </mat-error>
    </mat-form-field>
</div>
<ng-template #noGroup>
    <div class="month-selector">
        <mat-form-field class="operator" *ngIf="operator">
            <mat-select [formControl]="operator">
                <mat-option *ngFor="let operator of operators" [value]="operator.value"> {{operator.label}} </mat-option>
            </mat-select>
        </mat-form-field>
    
        <mat-form-field>
            <mat-label> {{label}} </mat-label>
            <input matInput [matDatepicker]="month" [min]="min" [max]="max" [formControl]="control" placeholder="Ex: 12/2019" [required]="required" [errorStateMatcher]="showErrorOnChanges" autocomplete="off">
            <mat-datepicker-toggle matSuffix [for]="month" [disabled]="control.disabled"></mat-datepicker-toggle>
            <mat-datepicker #month startView="multi-year" panelClass="month-picker"
                (monthSelected)="onMonthSelected($event, month)"></mat-datepicker>
            <mat-error *ngIf="control.errors?.matDatepickerMin"> {{ 'errors.minDate.fullLabel' | translate: {date: minDate} }} </mat-error>
            <mat-error *ngIf="control.errors?.matDatepickerMax"> {{ 'errors.maxDate.fullLabel' | translate: {date: maxDate} }} </mat-error>
        </mat-form-field>
    </div>
</ng-template>