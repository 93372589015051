import { BreakpointObserver, Breakpoints, MediaMatcher } from '@angular/cdk/layout';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Title } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';

import { environment } from '@environments/environment';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { AppRights } from '@classes/app-rights';
import { NotificationType } from '@enums/notifications/notification-type.enum';
import { ScreenSize } from '@enums/screen-size.enum';
import { SignalrNotifcation } from '@interfaces/notifications/signalrNotifcation';
import { AppConfigService } from '@services/app-config.service';
import { AppRightsService } from '@services/app-rights.service';
import { AuthService } from '@services/auth.service';
import { NotificationsService } from '@services/notifications.service';
import { ReferenceMonthService } from '@services/reference-month.service';
import { ReportService } from '@services/report.service';
import { UiService } from '@services/ui.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent extends SubscriptionBaseComponent implements OnInit, OnDestroy {
    displayNameMap = new Map([
        [Breakpoints.XSmall, ScreenSize.xsmall],
        [Breakpoints.Small, ScreenSize.small],
        [Breakpoints.Medium, ScreenSize.medium],
        [Breakpoints.Large, ScreenSize.large],
        [Breakpoints.XLarge, ScreenSize.xlarge]
    ]);

    isMaintenance: boolean;
    isAuth: boolean;
    isRouteLoading: boolean;
    isTranslationLoading: boolean;
    isWiki: boolean;

    screenSize: ScreenSize;
    screenSizes = ScreenSize;

    mobileQuery: MediaQueryList;
    private _mobileQueryListener: () => void;

    // # mean private
    private subscribeToNotifications: boolean;

    private reportExecutionId: number;
    @HostListener('window:beforeunload', ['$event']) onBeforeUnload() {
        this.reportSrv.processReportsList();
        if (this.reportExecutionId) {
            this.reportSrv.deleteReportExecutionId(this.reportExecutionId).subscribe();
        }
    }

    constructor(
        private configSrv: AppConfigService,
        private authSrv: AuthService,
        private uiSrv: UiService,
        private reportSrv: ReportService,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private media: MediaMatcher,
        private refMonthSrv: ReferenceMonthService,
        private titleSrv: Title,
        private notificationsSrv: NotificationsService,
        private http: HttpClient,
        private breakpointObserver: BreakpointObserver,
        private appRightsSrv: AppRightsService
    ) {
        super();
        if (environment.name === 'dev') {
            this.titleSrv.setTitle('THOT V4 - DEV');
        } else if (environment.name === 'qual') {
            this.titleSrv.setTitle('THOT V4 - QUAL');
        } else {
            this.titleSrv.setTitle('THOT V4');
        }
        this.isMaintenance = configSrv.isMaintenance;
        this.mobileQuery = media.matchMedia('(max-width: 999px)');
        this._mobileQueryListener = () => changeDetectorRef.detectChanges();
        this.mobileQuery.addEventListener('change', this._mobileQueryListener);
    }

    ngOnInit() {
        this.ClearCacheIfNewVersion();
        this.setScreenSize();
        this.authSrv.authChanged.next(this.authSrv.isAuth());

        this.initSubscriptions();

        if (this.authSrv.getDecodedToken()) {
            if (this.isMaintenance) {
                this.authSrv.logout();
            }
        }
    }

    ngOnDestroy(): void {
        this.mobileQuery.removeEventListener('change', this._mobileQueryListener);
    }

    toggleSidenav(sidenav: MatSidenav, isToggle?: boolean): void {
        if (isToggle) {
            sidenav.toggle();
        } else if (this.mobileQuery.matches) {
            sidenav.close();
        }
    }

    private getRefAndLastNormalizedMonths(): void {
        this.refMonthSrv.getRefMonth().subscribe();
        this.refMonthSrv.getLastNormalized().subscribe();
    }

    private initSubscriptions(): void {
        this.notificationsSrv.notificationsSubject
            .subscribe({
                next: (notification: SignalrNotifcation) => {
                    if (notification?.type === NotificationType.logout) {
                        this.uiSrv.showSnackbar('notifications.logout.summary', true, 5000);
                        this.authSrv.logout();
                        const headers = new HttpHeaders()
                            .set('Cache-Control', 'no-cache')
                            .set('Pragma', 'no-cache');

                        this.http
                            .get("", { headers, responseType: "text" })
                            .subscribe(() => location.reload());
                    }
                }
            });

        this.authSrv.authChanged.subscribe(authStatus => {
            if (authStatus) {
                this.appRightsSrv.appRights = new AppRights(this.authSrv.getAuthUser().roles);
                this.subscribeToNotifications = true;
                if (authStatus !== this.isAuth) {
                    this.getRefAndLastNormalizedMonths();
                    this.notificationsSrv.init();
                }
            } else {
                this.notificationsSrv.stop();
                this.subscribeToNotifications = false;
            }

            this.isAuth = authStatus;
        });

        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                if (event.url.match('/wiki')) {
                    this.isWiki = true;
                } else {
                    this.isWiki = false;
                }
            }
        });

        this.uiSrv.routeLoadingStateChanged
            .subscribe(loadingState => {
                this.isRouteLoading = loadingState;
            });

        this.uiSrv.translationLoadingStateChanged
            .subscribe(loadingState => {
                this.isTranslationLoading = loadingState;
            });

        this.reportSrv.reportExecutionIdSubject
            .subscribe(result => {
                this.reportExecutionId = result;
            });
    }

    private setScreenSize(): void {
        this.breakpointObserver.observe([
            Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,
        ]).subscribe(result => {
            for (const query of Object.keys(result.breakpoints)) {
                if (result.breakpoints[query]) {
                    this.uiSrv.screenSize = this.displayNameMap.get(query) ?? null;
                }
            }
        });

        this.uiSrv.screenSize$
            .subscribe(screenSize => this.screenSize = screenSize);
    }

    private ClearCacheIfNewVersion(): void {
        const newVersion = `${environment.version}`;
        const oldVersion = localStorage.getItem('version');
        if (newVersion !== oldVersion) {
            localStorage.setItem('version', newVersion);

            const headers = new HttpHeaders()
                .set('Cache-Control', 'no-cache')
                .set('Pragma', 'no-cache');

            this.http
                .get("", { headers, responseType: "text" })
                .subscribe(() => location.reload());
        }
    }
}
