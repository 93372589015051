export enum RefMonthStatus {
    collecting = 'Collecting',
    collectError = 'CollectError',
    collected = 'Collected',
    standardized = 'Standardized',
    standardizing = 'Standardizing',
    standardizeError = 'StandardizeError',
    initializing = 'Initializing',
    error = 'Error',
    ready = 'Ready',
    awaiting = 'Awaiting',
    checkingCollect = 'CheckingCollect',
    collectChecked = 'CollectChecked',
    checkCollectError = 'CheckCollectError',
    missing = 'Missing'
}
