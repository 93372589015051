export class ProfessionalStatusGroup {
    description: string;
    id: number;
    label: string;

    constructor()
    constructor(
        description?: string,
        id?: number,
        label?: string
    ) {
        this.description = description;
        this.id = id;
        this.label = label;
    }

    static fromObject(object: ProfessionalStatusGroup): ProfessionalStatusGroup {
        return Object.assign(new ProfessionalStatusGroup(), object);
    }

    get display(): string {
        return `${this.label}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.label], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
