export enum NotificationType {
    ReconciliationStatusChanged = 1,
    ScenarioStatusChanged,
    RefMonthActivated,
    RefMonthCreated,
    WorkflowStatusChanged,
    StoryDeleted,
    ScenarioDeleted,
    logout
}
