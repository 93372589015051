<ng-container [formGroup]="group">
    <mat-form-field>
        <mat-label> {{label | translate}} </mat-label>
        <input matInput
            appSelectOnTab
            type="text"
            aria-label="Agent"
            [formControlName]="controlName"
            [matAutocomplete]="agent"
            [required]="required">
        <mat-autocomplete #agent="matAutocomplete" [displayWith]="displayAgent">
            <mat-option *ngFor="let agent of filteredAgents" [value]="agent">
                {{agent.display}}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</ng-container>