<header class="title">
    <h3 #contractState>{{'agent.contractState.title.fullLabel' | translate}} {{contract.docNumber}}</h3>
</header>
<div class="year"
     *ngFor="let states of statesByYear">
    <header (click)="togglestates(states)">
        <h4>
            {{states.year}}
        </h4>
        <mat-icon>
            {{states.isExpanded ? 'expand_less' : 'expand_more'}}
        </mat-icon>
    </header>
    <div class="states"
         [ngClass]="{'expanded': states.isExpanded}">
        <app-state-year [contractStates]="states.contractStates"></app-state-year>
    </div>
</div>