import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { SalaryIndex } from '@classes/parameters/conjonctural-parameters/salary-index';

@Injectable({
    providedIn: 'root'
})
export class SalaryIndexService {
    private baseUrl = environment.apiUrl + 'salary-index'

    constructor(
        private http: HttpClient
    ) { }

    get(filters?: string): Observable<SalaryIndex[]> {
        let params = new HttpParams()
            .append('filters', filters);
        if (params.get('filters') === 'undefined' || params.get('filters') === '') {
            params = params.delete('filters');
        }

        return this.http.get<SalaryIndex[]>(this.baseUrl, {params});
    }

    post(newIndex: SalaryIndex): Observable<SalaryIndex[]> {
        return this.http.post<SalaryIndex[]>(this.baseUrl, newIndex);
    }

    put(newIndex: SalaryIndex): Observable<SalaryIndex[]> {
        return this.http.put<SalaryIndex[]>(this.baseUrl, newIndex);
    }

    delete(indexId: number): Observable<SalaryIndex[]> {
        return this.http.delete<SalaryIndex[]>(`${this.baseUrl}/${indexId}`);
    }

    exportIndexes(): Observable<Blob> {
        return this.http.get(`${this.baseUrl}/excel`, { observe: 'response', responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }

}
