import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { debounceTime, switchMap, finalize } from 'rxjs/operators';
import { of } from 'rxjs';

import { AgentService } from '@services/agent.service';

import { SelectedAgent } from '@classes/agent/selected-agent';
import { Agent } from '@classes/agent/agent';

@Component({
    selector: 'app-agent-selector',
    templateUrl: './agent-selector.component.html',
    styleUrls: ['./agent-selector.component.scss']
})
export class AgentSelectorComponent implements OnInit {
    agentForm: UntypedFormGroup;

    agents: Agent[];
    startMonths: Date[];
    endMonths: Date[];
    dateFormat = 'yyyy-MM';
    selectedAgentId: number;
    useMonth: boolean;
    isLoading: boolean;

    @Output() selectedAgent: EventEmitter<SelectedAgent> = new EventEmitter<SelectedAgent>();

    constructor(
        private agentSrv: AgentService,
        private fb: UntypedFormBuilder
    ) { }

    ngOnInit() {
        this.initForm();
    }

    onSubmit() {
        const id = this.agentForm.value.searchAgent.id;
        const from = this.agentForm.value.startMonth || this.startMonths[0];
        const to = this.agentForm.value.endMonth || this.startMonths[this.startMonths.length - 1];

        this.selectedAgent.emit(new SelectedAgent(id, from, to));
    }

    displayFn(agent?: Agent): string | undefined {
        return agent ? agent.display : undefined;
    }

    selectAgent(agent: Agent) {
        this.isLoading = true;
        this.startMonths = [];
        this.selectedAgentId = agent.id;
        this.agentForm.controls.startMonth.reset();
        this.agentForm.controls.endMonth.reset();

        this.agentSrv.getContractDates(this.selectedAgentId).subscribe((dates: Date[]) => {
                this.isLoading = false;
                this.startMonths = dates;
                // this.startMonths = Array.from(new Set(dates));
            });
    }

    private initForm(): void {
        this.agentForm = this.fb.group({
            searchAgent: ['', [Validators.required, this.forbiddenTypeValidator('string')]],
            useMonth: [false],
            startMonth: [''],
            endMonth: [''],
        });

        this.observeFormChanges();
    }

    private observeFormChanges(): void {
        this.agentForm.controls.searchAgent.valueChanges
            .pipe(
                debounceTime(500),
                switchMap((search: string | Agent) => {
                    if (search && typeof search === 'string') {
                        this.isLoading = true;
                        return this.agentSrv.getAgents(search)
                            .pipe(
                                finalize(() => this.isLoading = false)
                            );
                    } else {
                        return of(null);
                    }
                })
            ).subscribe(agents => {
                if (agents?.length) {
                    this.agents = agents;
                }
            });

        this.agentForm.controls.useMonth.valueChanges
            .subscribe((useMonth: boolean) => {
                this.useMonth = useMonth;
            });

        this.agentForm.controls.startMonth.valueChanges
            .subscribe((date: Date) => {
                this.setEndMonth(date);
            });
    }

    private setEndMonth(value: Date) {
        if (value) {
            this.agentForm.controls.endMonth.reset();
            this.endMonths = this.startMonths.filter(m => m >= value);
        }
    }

    private forbiddenTypeValidator(forbiddenType: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = control.value !== '' && typeof control.value === forbiddenType;
            return forbidden ? { forbiddenType: { value: control.value } } : null;
        };
    }
}
