<ng-container [formGroup]="group">
    <mat-form-field>
        <mat-label> {{label}} </mat-label>
        <input appSelectOnTab type="text" matInput [formControlName]="controlName" [matAutocomplete]="scale" (focus)="onAutoCompleteFocus()" [required]="required">
        <mat-autocomplete #scale="matAutocomplete" [displayWith]="displayScale" [panelWidth]="280">
            <mat-option *ngFor="let scale of filteredScales" [value]="scale" [matTooltip]="scale.externalDescription">
                {{scale.externalId}}
            </mat-option>
        </mat-autocomplete>
        <mat-error> {{'scenarios.events.error.noSelectedValue.summary' | translate}} </mat-error>
    </mat-form-field>
</ng-container>