import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { Scale } from '@classes/scenario/scale';
import { ForecastEventService } from '@services/forecast-event.service';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';

@Component({
    selector: 'app-salary-change',
    templateUrl: './salary-change.component.html',
    styleUrls: ['./salary-change.component.scss']
})
export class SalaryChangeComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    isIndividualEvent: boolean;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private forecastEventSrv: ForecastEventService
    ) { }

    ngOnInit(): void {
        this.initSubscription();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
        this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
    }

    getControl(controlName: string): UntypedFormControl {
        return this.group.get(controlName) as UntypedFormControl;
    }

    private initForm(): void {
        if (!this.group.get("newSalaryBase")) 
            this.group.addControl("newSalaryBase", new UntypedFormControl(null, [Validators.required]));
        
        if (this.isIndividualEvent) {
            this.group.removeControl("newFte");
            this.group.removeControl("newProfessionalStatus");
            this.group.removeControl("newScalePayment");
            this.group.removeControl("newSuperiorScalePayment");
            this.group.removeControl("newParallelScalePayment");
            this.group.removeControl("newSeniority");
            this.group.removeControl("oldSalaryBase");
            this.group.removeControl("newFunctionalArticleRepartition");
        } else {
            this.group.addControl("newFte", new UntypedFormControl(null, [Validators.required]));
            this.group.addControl("newProfessionalStatus", new UntypedFormControl(null, [Validators.required]));
            this.group.addControl("newScalePayment", new UntypedFormControl(null, [Validators.required]));
            this.group.addControl("newSuperiorScalePayment", new UntypedFormControl(null));
            this.group.addControl("newParallelScalePayment", new UntypedFormControl(null));
            this.group.addControl("newSeniority", new UntypedFormControl(null, [Validators.required]));
            this.group.addControl("oldSalaryBase", new UntypedFormControl(null, [Validators.required]));
            this.group.addControl("newFunctionalArticleRepartition", new UntypedFormControl(null, [Validators.required]));
        }
    }

    private initSubscription(): void {
        this.forecastEventSrv.isIndividualEventSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(isIndividualEvent => {
                this.isIndividualEvent = isIndividualEvent
                this.initForm();
                this.group.updateValueAndValidity();
            });
    }

}
