import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { RefMonthWithStatus } from '@interfaces/ref-month-with-status';

@Injectable({
  providedIn: 'root'
})
export class ReferenceMonthService {
  private baseUrl = environment.apiUrl + 'reference-month';

  refMonthSubject = new BehaviorSubject<string>(null);
  lastNormalizedMonthSubject = new BehaviorSubject<string>(null);

  // private refMonth: string;
  // private lastNormalizedMonth: string;

  constructor(
    private http: HttpClient
  ) { }

  get refMonth(): string {
    return this.refMonthSubject.value;
  }

  get lastNormalizedMonth(): string {
    return this.lastNormalizedMonthSubject.value;
  }

  getLastNormalized(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/last-normalized`)
      .pipe(
        tap(monthFromApi => {
          this.lastNormalizedMonthSubject.next(monthFromApi);
        })
      );
  }

  getRefMonth(): Observable<string> {
    return this.http.get<string>(`${this.baseUrl}/last`)
      .pipe(
        tap(refMonthFromApi => {
          this.refMonthSubject.next(refMonthFromApi);
        })
      );
  }

  getRefMonthsStatus(): Observable<RefMonthWithStatus> {
    return this.http.get<RefMonthWithStatus>(`${this.baseUrl}/status`);
  }

  activateRefMonth(refMonth: Date | string): Observable<any> {
    return this.http.put<any>(`${this.baseUrl}/${refMonth}/activate`, null);
  }
}
