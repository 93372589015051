import { ReportLayoutType } from '@enums/report-layout-type.enum';

export class DashboardItemConfig {
    displayOrder: number;
    id: number;
    isDefault: boolean;
    reportId: number;
    type: ReportLayoutType;

    constructor(
        displayOrder: number,
        isDefault: boolean,
        reportId: number,
        type: ReportLayoutType
    ) {
        this.displayOrder = displayOrder;
        this.isDefault = isDefault;
        this.reportId = reportId;
        this.type = type;
    }
}
