<form [formGroup]="group">
    <div>
        <app-input-number [label]="'scenarios.events.properties.newFte.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'newFte'"
                          [min]="0"
                          [max]="1"
                          [step]="0.1"
                          [required]="true"
                          *ngIf="!isIndividualEvent"></app-input-number>
        <app-event-status-autocomplete [label]="'scenarios.events.properties.newProfessionalStatus.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'newProfessionalStatus'"
                                       [status]="professionalStatus"
                                       [required]="true"
                                       *ngIf="!isIndividualEvent"></app-event-status-autocomplete>
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.oldScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'oldScalePayment'"
                                       [scales]="scalesPayment"
                                       [required]="true"
                                       *ngIf="!isIndividualEvent"></app-event-scales-autocomplete>
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.newScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'newScalePayment'"
                                       [scales]="scalesPayment"
                                       [required]="true"></app-event-scales-autocomplete>
        <!-- <app-event-scales-autocomplete [label]="'scenarios.events.properties.oldSuperiorScalePayment.shortLabel' | translate"
            [group]="group" [controlName]="'oldSuperiorScalePayment'" 
            [scales]="scalesPayment" *ngIf="!isIndividualEvent"></app-event-scales-autocomplete> -->
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.newSuperiorScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'newSuperiorScalePayment'"
                                       [scales]="scalesPayment"></app-event-scales-autocomplete>
        <!-- <app-event-scales-autocomplete [label]="'scenarios.events.properties.oldParallelScalePayment.shortLabel' | translate"
        [group]="group" [controlName]="'oldParallelScalePayment'" 
            [scales]="scalesPayment" *ngIf="!isIndividualEvent"></app-event-scales-autocomplete> -->
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.newParallelScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'newParallelScalePayment'"
                                       [scales]="scalesPayment"></app-event-scales-autocomplete>
        <app-input-number [label]="'scenarios.events.properties.newSeniority.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'newSeniority'"
                          [min]="0"
                          [required]="seniorityRequired"></app-input-number>
        <app-input-number [label]="'scenarios.events.properties.newSalaryBase.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'newSalaryBase'"
                          [min]="0"></app-input-number>
    </div>
    <div *ngIf="!isIndividualEvent">
        <app-event-articles-functional [group]="group"
                                       [controlName]="'newFunctionalArticleRepartition'"
                                       [articles]="articlesFunctional"
                                       [required]="true"
                                       (articleRepartition)="onArticleRepartition($event)"></app-event-articles-functional>
        <p *ngIf="group.get('newFunctionalArticleRepartition').errors?.ratio"
           class="bad-ratio"> {{'scenarios.events.error.badRatio.summary' | translate}} </p>
    </div>
</form>