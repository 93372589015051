import {
  Component,
  Input,
  OnChanges,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit
} from '@angular/core';
import {
  Chart,
  ChartData,
  ChartOptions,
  ChartDataSets,
  ChartConfiguration,
  PointStyle
} from 'chart.js';

import { FormatService } from '@services/format.service';

import { ChartType } from './chart-type.enum';
import { ReportDataType } from './report-data-type.enum';
import { ReportVariation } from './report-variation';
import { UiService } from '@services/ui.service';

@Component({
  selector: 'app-bizz-chart',
  templateUrl: './bizz-chart.component.html',
  styleUrls: ['./bizz-chart.component.scss']
})
export class BizzChartComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() variation: ReportVariation;
  @Input() showLegend = true;
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  colors = [];

  chart: Chart;

  stacksLabels: string[];
  hasMultiStack: boolean;
  hasMultiDatasets: boolean;
  chartType = ChartType;

  constructor(
    private formatSrv: FormatService,
    private uiSrv: UiService
  ) { }

  ngOnInit() {
    this.colors = this.uiSrv.getColors();
  }

  ngAfterViewInit() {
    this.setChart();
  }

  ngOnChanges() {
    this.setChart();
  }

  private setChart(): void {
    if (!this.chart) {
      this.chart = new Chart(this.canvas.nativeElement, this.getChartConfig());
    } else {
      this.chart.data = this.dataFromVariation();
      this.chart.update({ duration: 0 });
    }
  }

  private getChartConfig(): ChartConfiguration {
    const data = this.dataFromVariation();

    switch (this.variation.displayType) {
      case ChartType.doughnut:
        return {
          type: 'doughnut',
          data,
          options: this.setFlatChartOptions()
        };
      case ChartType.bar:
        return {
          type: 'bar',
          data,
          options: this.setAxesChartOptions(true, true, true)
        };
      case ChartType.horizontalBar:
        return {
          type: 'horizontalBar',
          data,
          options: this.setAxesChartOptions(true, true, false)
        };
      case ChartType.line:
        return {
          type: 'line',
          data,
          options: this.setAxesChartOptions(false, true, true)
        };
      case ChartType.radar:
        return {
          type: 'radar',
          data
        };
    }
  }

  /**
   * Configure datasets and colors based on report items
   */
  private dataFromVariation(): ChartData {
    const labels: string[] = this.variation.labels;
    this.hasMultiStack = this.variation.stacks.length > 1;
    this.stacksLabels = [];

    const datasets: ChartDataSets[] = this.variation.stacks.reduce((accumulator, stack, stackIndex) => {
      this.hasMultiDatasets = stack.datasets.length > 1;

      if (this.hasMultiStack && this.hasMultiDatasets) {
        this.stacksLabels.push(stack.name);
      }

      if (!this.hasMultiStack) {
        this.showLegend = false;
      }

      const dataset = stack.datasets.map((d, i) => {
        let colorIndex = i;
        let bgColor: string | string[];
        let borderColor = '#FFFFFF';
        let borderWidth = 1;

        // use stack index when there are only one dataset
        if (!this.hasMultiDatasets) {
          colorIndex = stackIndex;
        }

        switch (this.variation.displayType) {
          case ChartType.doughnut:
            bgColor = this.colors;
            break;
          case ChartType.bar:
          case ChartType.horizontalBar:
            bgColor = this.colors[colorIndex];
            break;
          case ChartType.line:
          case ChartType.radar:
            borderColor = this.colors[colorIndex];
            bgColor = 'transparent';
            borderWidth = 3;
            break;
        }

        return {
          label: d.label,
          data: d.data,
          stack: stack.name,
          borderColor,
          backgroundColor: bgColor,
          borderWidth,
          pointBackgroundColor: borderColor,
          pointBorderColor: borderColor
        };
      });
      return [...accumulator, ...dataset];
    }, []);

    return {
      labels,
      datasets
    };
  }

  private setFlatChartOptions(): ChartOptions {
    return {
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset: ChartDataSets = data.datasets[tooltipItem.datasetIndex];
            const dataValues: number[] = dataset.data as number[];
            const value: number = dataValues[tooltipItem.index];

            switch (this.variation.dataType) {
              case ReportDataType.amount:
                const total: number = dataValues.reduce((tot, val) => tot + val, 0);
                return `${data.labels[tooltipItem.index]}: ${this.formatSrv.formatPercent(value / total)}`;
              case ReportDataType.percent:
                return this.formatSrv.formatPercent(value);
              default:
                return value.toString();
            }
          }
        }
      },
      legend: {
        position: 'right',
        labels: {
          generateLabels: (chart) => {
            return chart.data.labels.map((label, i) => {
              const value: number = chart.data.datasets[0].data[i] as number;
              const text = `${label}: ${this.formatValue(value)}`;

              return {
                text,
                fillStyle: chart.data.datasets[0].backgroundColor[i] as string,
                hidden: false,
                lineCap: chart.data.datasets[0].borderCapStyle,
                lineDash: chart.data.datasets[0].borderDash,
                lineDashOffset: chart.data.datasets[0].borderDashOffset,
                lineJoin: chart.data.datasets[0].borderJoinStyle,
                lineWidth: chart.data.datasets[0].borderWidth as number,
                strokeStyle: chart.data.datasets[0].borderColor[i] as string,
                pointStyle: chart.data.datasets[0].pointStyle as PointStyle,
              };
            });
          }
        }
      }
    };
  }

  private setAxesChartOptions(doesBeginAtZero = true, isStacked = true, isVertical = true): ChartOptions {
    const yAxes = [{
      ticks: {
        beginAtZero: doesBeginAtZero,
        callback: (value: number) => {
          return this.formatValue(value);
        }
      },
      scaleLabel: {
        display: true,
        labelString: this.variation.axesLabels.data
      },
      stacked: isStacked
    }];
    const xAxes = [{
      scaleLabel: {
        display: true,
        labelString: this.variation.axesLabels.categories
      },
      stacked: isStacked,
      ticks: {
        autoSkip: false,
      }
    }];

    return {
      tooltips: {
        callbacks: {
          label: (tooltipItem, data) => {
            const dataset: ChartDataSets = data.datasets[tooltipItem.datasetIndex];
            const value: number = dataset.data[tooltipItem.index] as number;
            const formattedValue = this.formatValue(value);

            if (!this.showLegend) {
              return formattedValue;
            }

            const labels = [];

            if (dataset.stack) {
              labels.push(dataset.stack);
            }
            if (dataset.label) {
              labels.push(dataset.label);
            }

            return `${labels.join(' - ')}: ${formattedValue}`;
          }
        }
      },
      legend: {
        display: this.showLegend,
        labels: {
          generateLabels: (chart) => {
            const filteredDatasets: ChartDataSets[] = chart.data.datasets.filter((dataset) => {
              if (this.hasMultiStack && this.hasMultiDatasets) {
                return dataset.stack === chart.data.datasets[0].stack;
              }

              return true;
            });

            const returnedLabels = filteredDatasets.map((dataset, i) => {
              const labels = [];

              // stackLabels empty if only one stack OR only one dataset
              if (!this.stacksLabels.length && dataset.stack) {
                labels.push(dataset.stack);
              }

              if (dataset.label) {
                labels.push(dataset.label);
              }

              return {
                text: labels.join(' - '),
                fillStyle: dataset.backgroundColor as string,
                hidden: false,
                lineCap: dataset.borderCapStyle,
                lineDash: dataset.borderDash,
                lineDashOffset: dataset.borderDashOffset,
                lineJoin: dataset.borderJoinStyle,
                lineWidth: dataset.borderWidth as number,
                strokeStyle: dataset.borderColor as string,
                pointStyle: dataset.pointStyle as PointStyle,
              };
            });

            return returnedLabels;
          }
        },
        onClick: () => { }
      },
      scales: {
        yAxes: isVertical ? yAxes : xAxes,
        xAxes: isVertical ? xAxes : yAxes
      }
    };
  }

  private formatValue(value: number): string {
    switch (this.variation.dataType) {
      case ReportDataType.amount:
        if (Math.abs(value) < 10) {
          return this.formatSrv.formatAmount(value, 'fixed', 1);
        }
        if (Math.abs(value) < 1000) {
          return this.formatSrv.formatAmount(value);
        }
        return this.formatSrv.formatAmount(value, 'fixed', 0.001, false) + " K€";
      case ReportDataType.percent:
        return this.formatSrv.formatPercent(value);
      case ReportDataType.fte:
        return this.formatSrv.formatAmount(value, 'fixed', 1, false);
      default:
        return value.toString();
    }
  }
}
