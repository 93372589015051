import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { BudgetStructureService } from '@services/budget-structure.service';
import { Mapping } from '@classes/mapping/mapping';

@Injectable()
export class BudgetStructuresResolver implements Resolve<Mapping[]> {
    constructor(
        private uiSrv: UiService,
        private structureSrv: BudgetStructureService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mapping[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.structureSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
