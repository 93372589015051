import { Pipe, PipeTransform } from '@angular/core';
import { FormatService } from '@services/format.service';

@Pipe({
    name: 'formatSalarayBase'
})
export class FormatSalarayBasePipe implements PipeTransform {

    constructor(
        private formatSrv: FormatService
    ) {}

    transform(salary: number, index: number, contractualFte: number): string {
        return salary ? this.formatSrv.formatAmount(salary * index * contractualFte, "fixed", 1) : '';
    }

}
