import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject, BehaviorSubject, pipe } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import * as FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';
import { MappingService } from './mapping.service';

import { ReportExecutionId } from '@interfaces/report-execution-id';

import { ReportLayoutType } from '@enums/report-layout-type.enum';

import { Report } from '@classes/report/report';
import { Universe } from '@classes/report/universe';
import { Mapping } from '@classes/mapping/mapping';
import { ReportCategory } from '@classes/report/report-category';
import { OrganigramNode } from '@classes/organigram/organigram-node';
import { ReportLayout } from '@classes/report/report-layout';
import { ReportItem } from '@components/bizz-chart/report-item';

interface ExecutionId {
    id: number;
}

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    selectedReportSubject = new Subject<Report>();
    structureChanged = new Subject<Mapping>();
    reportExecutionIdSubject = new Subject<number>();

    isLoadingContextSubject = new Subject<boolean>();

    private reportsList: Report[] | ReportItem[] = [];
    private reportsListSubject = new BehaviorSubject<Report[]>([]);
    readonly reportsList$ = this.reportsListSubject.asObservable();

    private baseUrl = environment.apiUrl + 'report';

    constructor(
        private http: HttpClient,
        private uiSrv: UiService,
        private mappingSrv: MappingService,
        private router: Router
    ) {
        this.router.events.subscribe((event: any) => {
            if (event instanceof NavigationStart) {
                this.processReportsList();
                // this.selectedReportSubject.next(null);
            }
        });
    }

    getAll(layoutType?: ReportLayoutType): Observable<Report[]> {
        const httpParams = new HttpParams()
            .append('layoutType', layoutType);

        return this.http.get<Report[]>(`${this.baseUrl}`, { params: httpParams });
    }

    getForProfiles(layoutType?: ReportLayoutType): Observable<Report[]> {
        const httpParams = new HttpParams()
            .append('layoutType', layoutType);

        return this.http.get<Report[]>(`${this.baseUrl}/profiles`, { params: httpParams });
    }

    get(reportId: number, simpleMode?: boolean): Observable<Report> {
        this.uiSrv.isLoadingSubject.next(true);

        return this.http.get<Report>(`${this.baseUrl}/${reportId}`)
            .pipe(
                tap((result) => {
                    this.uiSrv.isLoadingSubject.next(false);
                    if (!simpleMode) {
                        this.selectedReportSubject.next(result);
                    }
                }));
    }

    getChart(reportId: number, type: ReportLayoutType): Observable<any> {
        const params = new HttpParams()
            .append('type', `${type}`);

        return this.http.get<any>(`${this.baseUrl}/${reportId}/chart`, { params });
    }

    getOrganigram(reportId: number): Observable<OrganigramNode> {
        return this.http.get<OrganigramNode>(`${this.baseUrl}/${reportId}/Organigram`)
            .pipe(
                tap(() => {
                    this.uiSrv.isLoadingSubject.next(false);
                })
            );
    }

    saveReport(reportToSave: Report): Observable<Report> {
        return this.http.post<Report>(`${this.baseUrl}`, reportToSave);
    }

    deleteReport(reportId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/${reportId}`)
            .pipe(
                tap(() => {
                    this.uiSrv.showSnackbar('reports.expert.reportDeleted.summary', true);
                })
            );
    }

    getCategories(isTree: boolean): Observable<ReportCategory[]> {
        const httpParams = new HttpParams().append('isTree', isTree.toString());
        return this.http.get<any>(`${this.baseUrl}/categories`, { params: httpParams });
    }

    downloadXls(report: Report, reportExecutionId: number): Observable<HttpEvent<Blob>> {
        const httpParams = new HttpParams()
            .append('reportExecutionId', `${reportExecutionId}`);

        return this.http.get(`${this.baseUrl}/download-excel`, { reportProgress: true, observe: 'events', responseType: 'blob', params: httpParams })
            .pipe(
                map(event => this.processDownloadEvent(event, report))
            );
    }

    getStructure(): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);
        this.mappingSrv.structureChanged.next(null);

        return this.http.get<Mapping>(`${this.baseUrl}/report-structure`)
            .pipe(
                tap(structureFromApi => {
                    this.uiSrv.isLoadingSubject.next(false);
                    this.mappingSrv.structureChanged.next(structureFromApi);
                })
            );
    }

    putStructure(structure: Mapping): Observable<Mapping> {
        this.uiSrv.isLoadingSubject.next(true);

        return this.http.put<Mapping>(`${this.baseUrl}/report-structure`, structure)
            .pipe(
                tap(structureFromApi => {
                    this.uiSrv.isLoadingSubject.next(false);
                    this.mappingSrv.structureChanged.next(structureFromApi);
                    this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
                })
            );
    }

    getLayouts(reportId: number): Observable<ReportLayout[]> {
        this.uiSrv.isLoadingSubject.next(true);

        return this.http.get<ReportLayout[]>(`${this.baseUrl}/${reportId}/layout`)
            .pipe(
                tap(() => {
                    this.uiSrv.isLoadingSubject.next(false);
                })
            );
    }

    putLayout(reportId: number, layout: ReportLayout): Observable<ReportLayout> {
        return this.http.put<ReportLayout>(`${this.baseUrl}/${reportId}/layout`, layout)
            .pipe(
                tap(() => {
                    this.uiSrv.showSnackbar('reports.layouts.saved.summary', true);
                })
            );
    }

    postLayout(reportId: number, layout: ReportLayout): Observable<ReportLayout> {
        return this.http.post<ReportLayout>(`${this.baseUrl}/${reportId}/layout`, layout)
            .pipe(
                tap(() => {
                    this.uiSrv.showSnackbar('reports.layouts.saved.summary', true);
                })
            );
    }

    deleteLayout(layoutId: number): Observable<void> {
        return this.http.delete<void>(`${this.baseUrl}/layout/${layoutId}`)
            .pipe(
                tap(() => {
                    this.uiSrv.showSnackbar('reports.layouts.details.layoutDeleted.summary', true);
                })
            );
    }

    getReportsFromContext(context: string, body: any[], isChart?: boolean): Observable<Report[] | ReportItem[]> {
        this.isLoadingContextSubject.next(true);
        if (!isChart) {
            this.processReportsList();
        }

        let params = new HttpParams()
            .append('isChart', `${isChart}`)
        if (params.get('isChart') === 'undefined') {
            params = params.delete('isChart');
        }
        return this.http.post<Report[]>(`${this.baseUrl}/context/${context}`, body, { params })
            .pipe(
                tap(reportsFromSrv => {
                    this.reportsList = reportsFromSrv;
                    if (!isChart) {
                        this.reportsListSubject.next(this.reportsList as Report[]);
                    }
                    this.isLoadingContextSubject.next(false);
                })
            );
    }

    getReportExecutionId(filter: Universe): Observable<ReportExecutionId> {
        filter.metadata = filter.metadata.map(m => {
            if (m.metaParameter.allowedValues?.length) {
                m.metaParameter.allowedValues.length = 0;
            }
            return m;
        });
        return this.http.post<ReportExecutionId>(`${this.baseUrl}/filter`, filter)
            .pipe(
                tap(result => this.reportExecutionIdSubject.next(result.reportExecutionId))
            );
    }

    deleteReportExecutionId(reportExecutionId: number): Observable<void> {
        this.reportExecutionIdSubject.next(null);
        const executionId: ExecutionId[] = [
            {
                id: reportExecutionId
            }
        ];

        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json'
            }),
            body: executionId
        };

        return this.http.delete<void>(`${this.baseUrl}/filter`, options);
    }

    processReportsList(): void {
        const executionIds: ExecutionId[] = this.reportsListSubject.getValue().map(r => {
            return { id: r.reportExecutionId };
        });
        this.reportsListSubject.next([]);
        if (executionIds && executionIds.length) {
            this.deleteReportsList(executionIds).subscribe();
        }
    }

    private deleteReportsList(executionIds: ExecutionId[]): Observable<void> {
        localStorage.removeItem('report');
        const options = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json-patch+json'
            }),
            body: executionIds
        };

        return this.http.delete<void>(`${this.baseUrl}/filter`, options);
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }

    private processDownloadEvent(event: HttpEvent<any>, report: Report): any {
        switch (event.type) {
            case HttpEventType.DownloadProgress:
                report.progress = Math.round(100 * event.loaded / event.total);
                break;
            case HttpEventType.Response:
                this.saveFile(event);
                // report.progress = 100;
                break;
        }
        return report;
    }

}
