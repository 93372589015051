<div class="edit-dashboard"
     *ngIf="appRights.dashboard.canEditHome">
    <mat-progress-spinner mode="indeterminate"
                          [diameter]="24"
                          *ngIf="isLoadingDefault"></mat-progress-spinner>
    <mat-checkbox [formControl]="isDefaultControl"
                  *ngIf="editMode && appRights.dashboard.canEditDefaultHome">
        {{'dashboard.base.defaultConfig.shortLabel' | translate}}
    </mat-checkbox>
    <button mat-icon-button
            color="accent"
            (click)="toggleEditMode()">
        <mat-icon *ngIf="!editMode">mode_edit</mat-icon>
        <mat-icon *ngIf="editMode">close</mat-icon>
    </button>
</div>
<div class="dashboard-view">
    <app-header class="app-header"
                [headers]="dashboard.headers"
                [isDefault]="isDefault"></app-header>
    <ng-container *ngFor="let item of dashboard.items">
        <mat-card class="chart-card">
            <button mat-icon-button
                    color="accent"
                    class="delete-chart"
                    (click)="deleteDashboardItem(item)"
                    [disabled]="isLoadingDefault"
                    *ngIf="editMode">
                <mat-icon>delete</mat-icon>
            </button>
            <mat-card-header>
                <mat-card-title>
                    <h2>
                        {{ item.title.fullLabel }}
                        <app-tooltip [text]="item.title.summary"></app-tooltip>
                    </h2>
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <ng-container *ngIf="item.variations?.length">
                    <mat-form-field *ngIf="item.variations.length > 1">
                        <mat-select [(ngModel)]="item.selectedVariation">
                            <mat-option *ngFor="let variation of item.variations"
                                        [value]="variation.id">
                                {{ variation.text?.fullLabel }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <ng-container *ngFor="let variation of item.variations">
                        <ng-container *ngIf="variation.id === item.selectedVariation">
                            <app-bizz-chart [variation]="variation"></app-bizz-chart>
                        </ng-container>
                    </ng-container>
                </ng-container>
            </mat-card-content>
        </mat-card>
    </ng-container>

    <ng-container *ngIf="editMode && reportsList?.length">
        <mat-card class="chart-card">
            <mat-card-content class="add-chart">
                <mat-form-field *ngIf="!isLoading">
                    <mat-label> {{'dashboard.base.selectReport.shortLabel' | translate}} </mat-label>
                    <mat-select [formControl]="selectReport">
                        <mat-option *ngFor="let report of reportsList"
                                    [value]="report.id">
                            {{report.text.fullLabel}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-progress-spinner mode="indeterminate"
                                      *ngIf="isLoading"></mat-progress-spinner>
            </mat-card-content>
        </mat-card>
    </ng-container>
</div>