import { AfterViewChecked, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';

import { Contract } from '@classes/contract/contract';
import { ContractState } from '@classes/contract/contract-state';
import { Fte } from '@classes/contract/fte';
import { StatesByYear } from '@classes/contract/states-by-year';
import { SalaryIndex } from '@classes/parameters/conjonctural-parameters/salary-index';
import { ReferenceMonthService } from '@services/reference-month.service';
import { SalaryIndexService } from '@services/salary-index.service';
import { DateTime } from 'luxon';

@Component({
    selector: 'app-contract-state',
    templateUrl: './contract-state.component.html',
    styleUrls: ['./contract-state.component.scss']
})
export class ContractStateComponent implements OnInit, OnChanges, AfterViewChecked {
    @Input() contract: Contract;
    @ViewChild(MatSort, { static: true }) sort: MatSort;

    @ViewChild('contractState') contractState: ElementRef;

    currentYear: number;
    statesByYear: StatesByYear[];
    salaryIndexes: SalaryIndex[];

    dataSource: MatTableDataSource<ContractState>;
    displayedColumns: string[] = [
        'dueMonth',
        'scalePayment',
        'superiorScalePayment',
        'parallelScalePayment',
        'scaleSeniority',
        'professionalStatus',
        'scaleAdministrative',
        'scaleAdministrativeSeniority'
    ];

    dateFormat: 'yyyy-MM';
    completeDateFormat: 'dd-MM-yyyy';

    private canScroll: boolean;

    constructor(
        private refMonthSrv: ReferenceMonthService,
        private salaryIndexSrv: SalaryIndexService
    ) { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.currentYear = DateTime.fromISO(this.refMonthSrv.refMonth).year;
        if (changes.contract.previousValue !== changes.contract.currentValue) {
            if (this.salaryIndexes?.length) {
                this.initStates();
            } else {
                this.salaryIndexSrv.get()
                    .subscribe((indexes: SalaryIndex[]) => {
                        this.salaryIndexes = indexes;
                        this.initStates();
                    });
            }
            this.canScroll = true;
        } else {
            this.canScroll = false;
        }
    }

    ngAfterViewChecked(): void {
        if (this.canScroll && this.contractState) {
            this.contractState.nativeElement.scrollIntoView();
            this.canScroll = false;
        }
    }

    togglestates(states: StatesByYear): void {
        states.isExpanded = !states.isExpanded;
    }

    private initStates(): void {
        const uniqueYears = Array.from(new Set(this.contract.contractStates.map((state: ContractState) => DateTime.fromISO(state.dueMonth).year)));
        const budgetaryFte = this.contract.fte.filter((f: Fte) => f.code === 'BudgetaryFte');
        const contractFte = this.contract.fte.filter((f: Fte) => f.code === 'ContractFte');
        this.statesByYear = uniqueYears.map(year => {
            const contractStates = this.contract.contractStates.filter((contractState: ContractState) => {
                const budFte = budgetaryFte.filter(fte => fte.month === contractState.dueMonth);
                contractState.budgetaryFte = budFte.reduce((accumulator, current) => accumulator + current.value, 0);
                contractState.contractFte = contractFte.find((fte: Fte) => fte.month === contractState.dueMonth).value;
                contractState.indexValue = this.salaryIndexes.filter((index: SalaryIndex) => index.fromDueMonth <= contractState.dueMonth)[0].indexValue;
                return DateTime.fromISO(contractState.dueMonth).year === year;
            }).sort((a: ContractState, b: ContractState) => {
                return a.dueMonth < b.dueMonth ? 1 : a.dueMonth > b.dueMonth ? -1 : 0;
            });
            return new StatesByYear(
                contractStates,
                year === this.currentYear ? true : false,
                year
            );
        }).sort((a, b) => b.year - a.year);
    }
}
