<h4> {{'scenarios.events.create.title.fullLabel' | translate}} </h4>
<form class="events-edit"
      [formGroup]="eventForm"
      (ngSubmit)="onSubmit()">
    <div class="inputs">
        <app-month-selector [label]="'scenarios.events.properties.eventMonth.shortLabel' | translate"
                            [group]="eventForm"
                            [controlName]="'eventMonth'"
                            [min]="minMonth"
                            [required]="true"></app-month-selector>

        <app-event-type-select [label]="'scenarios.events.properties.eventType.shortLabel' | translate"
                               [control]="getControl('eventType')"
                               [required]="true"></app-event-type-select>

        <mat-checkbox [disableRipple]="true"
                      formControlName="individualEvent">
            {{'scenarios.events.create.individualEvent.shortLabel' | translate}} </mat-checkbox>

        <app-input-number *ngIf="!individualEvent"
                          [label]="'scenarios.events.create.eventsNumber.shortLabel' | translate"
                          [control]="getControl('eventsNumber')"
                          [min]="0"
                          [required]="true"></app-input-number>

        <app-agent-select *ngIf="individualEvent"
                          [label]="'scenarios.events.properties.agent.shortLabel'"
                          [group]="eventForm"
                          [controlName]="'agent'"
                          [required]="true"></app-agent-select>

        <mat-form-field *ngIf="eventForm.controls.agent.enabled && eventForm.value.agent?.id">
            <mat-label> {{'scenarios.events.create.contract.shortLabel' | translate}} </mat-label>
            <mat-select formControlName="contract">
                <mat-option [value]="null"> - </mat-option>
                <mat-option *ngFor="let contract of contracts"
                            [value]="contract">
                    {{contract.docNumber}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <app-autocomplete *ngIf="!contract && workerCategories?.length"
                          [control]="getControl('workerCategory')"
                          [list]="workerCategories"
                          [label]="'scenarios.events.create.workerCategory.shortLabel' | translate"
                          [required]="true"></app-autocomplete>

        <app-autocomplete *ngIf="!contract && !hideEmployerCategories"
                          [control]="getControl('employerCategory')"
                          [list]="employerCategories"
                          [label]="'scenarios.events.create.employerCategory.shortLabel' | translate"
                          [required]="true"></app-autocomplete>

        <mat-form-field class="free-label">
            <mat-label> {{'scenarios.events.properties.userComment.shortLabel' | translate}} </mat-label>
            <textarea matInput
                      cdkTextareaAutosize
                      formControlName="userComment"></textarea>
        </mat-form-field>

        <!-- <mat-form-field>
            <mat-label> {{'scenarios.strategies.userTags.shortLabel' | translate}} </mat-label>
            <mat-select formControlName="tags" multiple>
                <mat-option *ngFor="let tag of userTags" [value]="tag"> {{tag.name}} </mat-option>
            </mat-select>
        </mat-form-field> -->

        <mat-form-field>
            <mat-label> {{'scenarios.strategies.userTags.shortLabel' | translate}} </mat-label>
            <mat-select multiple
                        formControlName="tags">
                <mat-select-trigger>
                    <!-- {{eventForm.controls.tags.value?.length ? (eventForm.controls.tags.value | getTagsNames : userTags) : ''}} -->
                    <ng-container *ngIf="eventForm.controls.tags.value?.length">
                        <span *ngFor="let tag of eventForm.controls.tags.value; index as i">
                            {{tag.name}}<ng-container *ngIf="i < eventForm.controls.tags.value.length - 1">,
                            </ng-container>
                        </span>
                    </ng-container>
                </mat-select-trigger>
                <mat-option *ngFor="let tag of userTags"
                            [value]="tag"
                            [disabled]="hasReportTag(tag) || hasContract(tag)">
                    <div class="user-tag">
                        <mat-icon color="accent"
                                  [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                  *ngIf="tag.type.id === 2">grid_on</mat-icon>
                        <mat-icon color="accent"
                                  [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                  *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                        <span> {{tag.name}} </span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="inputs"
         [ngClass]="{'hidden': !selectedEventType}"
         *ngIf="selectedEventType">
        <!-- TODO: Regarder pour utiliser le composant app-autocomplete pour remplacer les composants individuels pour professionalStatus et scalesPayment + 
            subscribe dans les composants à la place des inputs pour scales, status et articles -->
        <!-- Réduction temps de travail  -->
        <app-fte-decrease *ngIf="selectedEventType?.id === 3"
                          [group]="eventDetailsForm"
                          [professionalStatus]="professionalStatus"
                          [scalesPayment]="scalesPayment"
                          [articlesFunctional]="articlesFunctional"></app-fte-decrease>
        <!-- Augmentation temps de travail -->
        <app-fte-increase *ngIf="selectedEventType?.id === 4"
                          [group]="eventDetailsForm"
                          [professionalStatus]="professionalStatus"
                          [scalesPayment]="scalesPayment"
                          [articlesFunctional]="articlesFunctional"></app-fte-increase>
        <!-- Départ définitif -->
        <app-final-departure *ngIf="selectedEventType?.id === 9"
                             [group]="eventDetailsForm"
                             [professionalStatus]="professionalStatus"
                             [scalesPayment]="scalesPayment"
                             [articlesFunctional]="articlesFunctional"></app-final-departure>
        <!-- Entrée en service -->
        <app-entry-into-service *ngIf="selectedEventType?.id === 10"
                                [group]="eventDetailsForm"
                                [professionalStatus]="professionalStatus"
                                [scalesPayment]="scalesPayment"
                                [articlesFunctional]="articlesFunctional"></app-entry-into-service>
        <!-- Pension -->
        <app-pension *ngIf="selectedEventType?.id === 11"
                     [group]="eventDetailsForm"
                     [professionalStatus]="professionalStatus"
                     [scalesPayment]="scalesPayment"
                     [articlesFunctional]="articlesFunctional"></app-pension>
        <!-- Sortie temporaire -->
        <app-temporary-leave *ngIf="selectedEventType?.id === 12"
                             [group]="eventDetailsForm"
                             [professionalStatus]="professionalStatus"
                             [scalesPayment]="scalesPayment"
                             [articlesFunctional]="articlesFunctional"></app-temporary-leave>
        <!-- Retour de congé -->
        <app-return-from-leave *ngIf="selectedEventType?.id === 13"
                               [group]="eventDetailsForm"
                               [professionalStatus]="professionalStatus"
                               [scalesPayment]="scalesPayment"
                               [articlesFunctional]="articlesFunctional"></app-return-from-leave>
        <!-- Changement d'échelle -->
        <app-scale-change *ngIf="selectedEventType?.id === 14"
                          [group]="eventDetailsForm"
                          [professionalStatus]="professionalStatus"
                          [scalesPayment]="scalesPayment"
                          [articlesFunctional]="articlesFunctional"></app-scale-change>
        <!-- Avancement barémique -->
        <app-scale-advancement *ngIf="selectedEventType?.id === 16"
                               [group]="eventDetailsForm"
                               [professionalStatus]="professionalStatus"
                               [scalesPayment]="scalesPayment"
                               [articlesFunctional]="articlesFunctional"></app-scale-advancement>
        <!-- Changement d'échelle FF -->
        <app-superior-scale-change *ngIf="selectedEventType?.id === 20"
                                   [group]="eventDetailsForm"
                                   [professionalStatus]="professionalStatus"
                                   [scalesPayment]="scalesPayment"
                                   [articlesFunctional]="articlesFunctional"></app-superior-scale-change>
        <!-- Contrat à durée déterminée -->
        <app-fixed-term-contract *ngIf="selectedEventType?.id === 21"
                                 [group]="eventDetailsForm"
                                 [professionalStatus]="professionalStatus"
                                 [scalesPayment]="scalesPayment"
                                 [articlesFunctional]="articlesFunctional"></app-fixed-term-contract>
        <!-- Changement d'affectation budgétaire -->
        <app-budget-assignement-change *ngIf="selectedEventType?.id === 23"
                                       [group]="eventDetailsForm"
                                       [articlesFunctional]="articlesFunctional"
                                       [professionalStatus]="professionalStatus"
                                       [scalesPayment]="scalesPayment"
                                       [articlesFunctional]="articlesFunctional"></app-budget-assignement-change>
        <!-- Changement d'échelle // -->
        <app-parallel-scale-change *ngIf="selectedEventType?.id === 26"
                                   [group]="eventDetailsForm"
                                   [professionalStatus]="professionalStatus"
                                   [scalesPayment]="scalesPayment"
                                   [articlesFunctional]="articlesFunctional"></app-parallel-scale-change>
        <!-- Changement de statut -->
        <app-status-change *ngIf="selectedEventType?.id === 27"
                           [group]="eventDetailsForm"
                           [professionalStatus]="professionalStatus"
                           [scalesPayment]="scalesPayment"
                           [articlesFunctional]="articlesFunctional"></app-status-change>
        <!-- Changement de salaire -->
        <app-salary-change *ngIf="selectedEventType?.id === 28"
                           [group]="eventDetailsForm"
                           [professionalStatus]="professionalStatus"
                           [scalesPayment]="scalesPayment"
                           [articlesFunctional]="articlesFunctional"></app-salary-change>
    </div>

    <div>
        <button type="submit"
                mat-raised-button
                color="accent"> {{'scenarios.events.create.next.fullLabel' | translate}}
        </button>
        <button type="button"
                mat-raised-button
                color="accent"
                (click)="onCancel()">
            <mat-icon aria-label="cancel">clear</mat-icon>
            {{'scenarios.events.create.cancel.fullLabel' | translate}}
        </button>
        <span> {{'scenarios.events.create.requiredFields.fullLabel' | translate}} </span>
    </div>
</form>