import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { DashboardService } from '@services/dashboard.service';
import { ReportService } from '@services/report.service';
// import { AuthService } from '@services/auth.service';
import { UiService } from '@services/ui.service';

import { ReportLayoutType } from '@enums/report-layout-type.enum';

import { AppRights } from '@classes/app-rights';
import { Dashboard } from '@classes/dashboard/dashboard';
import { DashboardHeaderItem } from '@classes/dashboard/dashboard-header-item';
import { DashboardItemConfig } from '@classes/dashboard/dashboard-item-config';
import { Report } from '@classes/report/report';
import { ReportItem } from '@components/bizz-chart/report-item';
import { AppRightsService } from '@services/app-rights.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
    appRights: Partial<AppRights>

    dashboard: Dashboard;
    reports: Report[];
    reportsList: Report[];

    // user: AuthUser;

    editMode: boolean;
    isDefault = false;
    isLoading: boolean;
    isLoadingDefault: boolean;

    selectReport = new UntypedFormControl();
    isDefaultControl = new UntypedFormControl();

    private unsubscribeAll = new Subject<void>;

    constructor(
        private route: ActivatedRoute,
        private dashboardSrv: DashboardService,
        private reportSrv: ReportService,
        private uiSrv: UiService,
        private appRightsSrv: AppRightsService
    ) { }

    ngOnInit() {
        this.getRouteData();
        this.initSubscription();
        this.ObserveFormControls();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    toggleEditMode(): void {
        this.dashboardSrv.editModeSubject.next(!this.editMode);
    }

    deleteDashboardItem(item: ReportItem): void {
        if (!this.isDefault) {
            item.config.isDefault = false;
        }
        this.dashboardSrv.delete(item.config)
            .subscribe(() => {
                const index = this.dashboard.items.findIndex(i => i.title.fullLabel === item.title.fullLabel);
                this.dashboard.items.splice(index, 1);
                this.checkAvailableReports();
            });
    }

    private removeNullItems(): void {
        this.dashboard.items = this.dashboard.items.filter((item: ReportItem) => {
            if (item) {
                return item;
            }
        });
        this.dashboard.headers = this.dashboard.headers.filter((header: DashboardHeaderItem) => {
            if (header) {
                return header;
            }
        });
    }

    private ObserveFormControls(): void {
        this.selectReport.valueChanges
            .subscribe((reportId: number) => {
                if (reportId) {
                    this.isLoading = true;
                    const config = new DashboardItemConfig(
                        this.dashboard.items.length,
                        this.isDefault,
                        reportId,
                        ReportLayoutType.kpi
                    );

                    this.dashboardSrv.post(config)
                        .subscribe((items: ReportItem[]) => {
                            if (items && items.length) {
                                items.forEach(item => {
                                    this.dashboard.items = [...this.dashboard.items, item];
                                });
                                const index = this.reportsList.findIndex(r => r.id === reportId);
                                this.reportsList.splice(index, 1);
                                this.isLoading = false;
                            }
                            this.selectReport.setValue('');
                        });
                }
            });

        this.isDefaultControl.valueChanges
            .subscribe((isDefault: boolean) => {
                this.isDefault = isDefault;
                this.selectReport.disable();
                if (isDefault) {
                    this.dashboardSrv.getAll(true)
                        .subscribe((dashboardFromSrv: Dashboard) => {
                            this.dashboard = dashboardFromSrv;
                            this.removeNullItems();
                            this.checkAvailableReports();
                            this.selectReport.enable();
                        });
                } else {
                    this.dashboardSrv.getAll(false)
                        .subscribe((dashboardFromSrv: Dashboard) => {
                            this.dashboard = dashboardFromSrv;
                            this.removeNullItems();
                            this.checkAvailableReports();
                            this.selectReport.enable();
                        });
                }
            });
    }

    private checkAvailableReports(): void {
        this.reportsList = this.reports.filter(report => {
            return !this.dashboard.items.find(item => {
                if (item) {
                    return item.config.reportId === report.id
                }
            });
        });
    }

    private getRouteData(): void {
        this.route.data
            .subscribe((data: { dashboard: Dashboard }) => {
                this.dashboard = data.dashboard;
                this.removeNullItems();
                if (this.dashboard.items?.length && this.dashboard.items.every((item: ReportItem) => item.title.fullLabel === 'ERROR')) {
                    this.uiSrv.showSnackbar('dashboard.refreshScenario.summary', true, 5000);
                }
            });
    }

    private initSubscription(): void {
        this.appRightsSrv.appRights$
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((appRights: AppRights) => {
                this.appRights = {
                    dashboard: appRights.dashboard
                }
            });
        
        if (this.appRights.dashboard.canEditHome) {
            this.reportSrv.getAll(ReportLayoutType.kpi)
                .subscribe((reportsFromSrv: Report[]) => {
                    this.reports = reportsFromSrv;
                    this.checkAvailableReports();
                });
        }

        // this.subscription.add(
        //   this.authSrv.authUserChanged
        //     .subscribe(authUser => {
        //       this.user = authUser;
        //     })
        // );

        this.dashboardSrv.editModeSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((isEditMode: boolean) => {
                this.editMode = isEditMode;
            })

        this.dashboardSrv.isLoadingDefaultSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((isLoadingDefault: boolean) => {
                this.isLoadingDefault = isLoadingDefault;
            })
    }
}
