import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { CotisationCode } from '@classes/parameters/conjonctural-parameters/cotisation-code';

@Injectable({
    providedIn: 'root'
})
export class CotisationCodeService {
    private baseUrl = environment.apiUrl + 'cotisation-code'

    codesSubject = new Subject<CotisationCode[]>();

    private cotisationCodesSubject = new BehaviorSubject<CotisationCode[]>([])

    get cotisationCodes$(): Observable<CotisationCode[]> {
        return this.cotisationCodesSubject.asObservable();
    }

    set cotisationCodes(cotisationCodes: CotisationCode[]) {
        this.cotisationCodesSubject.next(cotisationCodes);
    }

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<CotisationCode[]> {
        return this.http.get<CotisationCode[]>(this.baseUrl)
            .pipe(
                map((codesFromApi: CotisationCode[]) => {
                    this.cotisationCodes = codesFromApi.map(c => CotisationCode.fromObject(c))
                    return this.cotisationCodesSubject.getValue();
                })
            );
    }

    post(newCode: CotisationCode): Observable<CotisationCode> {
        return this.http.post<any>(`${this.baseUrl}`, newCode);
    }

    put(code: CotisationCode): Observable<CotisationCode> {
        return this.http.put<any>(`${this.baseUrl}`, code);
    }

    delete(code: CotisationCode): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/${code.id}`);
    }

    exportCodes(): Observable<Blob> {
        return this.http.get(`${this.baseUrl}/excel`, { observe: 'response', responseType: 'blob' })
            .pipe(map(res => this.saveFile(res)));
    }

    private saveFile(res: HttpResponse<Blob>): Blob {
        FileSaver.saveAs(res.body, res.headers.get('filename'));
        return res.body;
    }

}
