import { Component, OnInit, Input } from '@angular/core';
import { Agent } from '@classes/agent/agent';

@Component({
    selector: 'app-detail-summary',
    templateUrl: './detail-summary.component.html',
    styleUrls: ['./detail-summary.component.scss']
})
export class DetailSummaryComponent implements OnInit {
    @Input() agent: Agent;
    dateFormat = 'dd-MM-yyyy';

    constructor() { }

    ngOnInit() {
    }

}
