import { HttpResponse } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { ForecastEventService } from '@services/forecast-event.service';
// import { MetadataService } from '@services/metadata.service';
import { ReferenceContractService } from '@services/reference-contract.service';
import { SalaryIndexService } from '@services/salary-index.service';
import { ScenarioService } from '@services/scenario.service';

import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';
import { Agent } from '@classes/agent/agent';
import { ReferenceSituation } from '@classes/contract/reference-situation';
import { SalaryIndex } from '@classes/parameters/conjonctural-parameters/salary-index';
import { Scenario } from '@classes/scenario/scenario';
import { ThotEvent } from '@classes/scenario/thot-event';
import { EventsTableMode } from '@enums/events-table-mode.enum';

interface RefSituationByContract {
    events: ThotEvent[];
    refSituation: ReferenceSituation;
    includeDeleted: boolean;
}

@Component({
    selector: 'app-detail-events',
    templateUrl: './detail-events.component.html',
    styleUrls: ['./detail-events.component.scss']
})
export class DetailEventsComponent extends SubscriptionBaseComponent implements OnInit, OnChanges {
    @Input() selectedAgent: Agent

    scenarioSelect = new FormControl<Scenario>(null);
    selectedSCenario: Scenario;
    isLoading: boolean;

    scenarios: Scenario[] = [];
    events: ThotEvent[];
    refsituationByContract: RefSituationByContract[];
    indexValue: number;
    eventModes = EventsTableMode;
    includeDeleted = false;

    private refSituation: ReferenceSituation[];

    constructor(
        // private metadataSrv: MetadataService,
        private forecastEventSrv: ForecastEventService,
        private refContract: ReferenceContractService,
        private scenarioSrv: ScenarioService,
        private salaryIndexSrv: SalaryIndexService
    ) {
        super();
    }

    ngOnInit() {
        this.initSubscription();
        this.observeControl();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.events = [];
        this.scenarioSelect.reset();

        if (changes.selectedAgent.previousValue !== changes.selectedAgent.currentValue) {
            this.refsituationByContract = [];
        }
    }

    toggleDeleted(includeDeleted: MatLegacyCheckboxChange): void {
        this.getEvents(this.selectedSCenario, includeDeleted.checked)
    }

    private observeControl(): void {
        this.scenarioSelect.valueChanges.subscribe((scenario: Scenario) => {
            if (scenario) {
                this.selectedSCenario = scenario;
                this.getEvents(scenario, false);
            }
        });
    }

    private getEvents(scenario: Scenario, includeDeleted: boolean): void {
        this.includeDeleted = includeDeleted;
        this.isLoading = true;
        const indexFilter = `fromDueMonth<=${scenario.referenceMonth}`;
        this.salaryIndexSrv.get(indexFilter).subscribe((indexesFromSrv: SalaryIndex[]) => {
            this.indexValue = indexesFromSrv[0].indexValue;
            // const date = scenario.label.substring(scenario.label.search(/[\(]\d{2}[\/]\d{4}[\)]/g) + 1, scenario.label.length - 1).split('/');
            // const scenarioMonth = `${date[1]}-${date[0]}-01T00:00:00`;
            this.refContract.get(this.selectedAgent, scenario.referenceMonth).subscribe((refSituationFromSrv: ReferenceSituation[]) => {
                this.refSituation = refSituationFromSrv;
                const eventsFilter = `agent.matricule@=${this.selectedAgent.matricule}`
                this.forecastEventSrv.getForAgent(scenario.id, eventsFilter, includeDeleted).subscribe((responseFromSrv: HttpResponse<ThotEvent[]>) => {
                    this.events = responseFromSrv.body;
                    this.refsituationByContract = this.refSituation.map((rs: ReferenceSituation) => {
                        return {
                            events: this.events.filter((event: ThotEvent) => {
                                if (event.newContract) {
                                    return event.newContract.docNumber === rs.contract.docNumber;
                                } else if (event.oldContract) {
                                    return event.oldContract.docNumber === rs.contract.docNumber;
                                }
                            }),
                            refSituation: rs,
                            includeDeleted: false
                        }
                    });
                    this.isLoading = false;
                });
            });

        });
    }

    private initSubscription(): void {
        this.scenarioSrv.getAll()
            .subscribe((scenariosFromSrv: Scenario[]) => {
                this.scenarios = scenariosFromSrv.sort((a, b) => b.referenceMonth.localeCompare(a.referenceMonth)
                    || Number(b.story.isDefault) - Number(a.story.isDefault)
                    || a.story.label.localeCompare(b.story.label));
            });

        // this.metadataSrv.getValues(352).subscribe((scenariosFromSrv: FilterValue[]) => {
        //   this.scenarios = scenariosFromSrv;
        // });
    }

}
