import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LOCATION_INITIALIZED } from '@angular/common';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { FxConfig } from '@interfaces/fx-config';
import { AppConfig } from '@interfaces/app-config';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {
  private config: AppConfig;

  constructor(
    private http: HttpClient,
    private injector: Injector,
    private translateSrv: TranslateService,
    private authSrv: AuthService
  ) { }

  loadConfig(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // const configFile = `assets/config/${environment.name}-config.json`;

      this.http.get<AppConfig>(`./assets/config/app-config.json`)
        .pipe(
          map(data => {
            this.config = data;
            resolve(true);
          }),
          catchError(error => {
            resolve(false);
            return of(error);
          })
        ).subscribe();
    });
  }

  initTranslations(): Promise<boolean> {
    return new Promise<any>((resolve, reject) => {
      const locationInitialized = this.injector.get(LOCATION_INITIALIZED, Promise.resolve(null));

      locationInitialized.then(() => {
        let langToSet = this.translateSrv.getBrowserLang();
        if (this.authSrv.getDecodedToken()) {
          langToSet = null;
        }
        this.translateSrv.setDefaultLang('fr');
        resolve(null);
      });
    });
  }

  // loadTranslations(): Promise<boolean> {
  //   return new Promise<any>((resolve, reject) => {
  //     let langToSet = this.translateSrv.getBrowserLang();
  //     this.translateSrv.use(langToSet)
  //       .subscribe({
  //         complete() { resolve(null); }
  //       });
  //   });
  // }

  get isMaintenance(): boolean {
    return this.config ? this.config.maintenance : null;
  }

  get fxConfig(): FxConfig {
    return this.config ? this.config.fxConfig : null;
  }
}
