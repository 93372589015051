import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, BehaviorSubject, Observable, fromEvent } from 'rxjs';

import { ConfirmDialogComponent } from '@components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogOptions } from '@interfaces/confirm-dialog-options';
import { ScreenSize } from '@enums/screen-size.enum';

@Injectable({
    providedIn: 'root'
})
export class UiService {
    isLoadingSubject = new BehaviorSubject<boolean>(false);
    isDownloadingSubject = new Subject<boolean>();
    routeLoadingStateChanged = new Subject<boolean>();
    translationLoadingStateChanged = new Subject<boolean>();
    timelineChanged = new Subject<void>();

    resizeWindowObservable$: Observable<Event> = fromEvent(window, 'resize');

    dateTimeFormat = "yyyy-MM-dd'T'TT";

    private screenSizeSubject = new BehaviorSubject<ScreenSize>(null);

    constructor(
        private snackbar: MatSnackBar,
        private translateSrv: TranslateService,
        private dialog: MatDialog
    ) {
    }

    get screenSize$(): Observable<ScreenSize> {
        return this.screenSizeSubject.asObservable();
    }

    set screenSize(screenSize: ScreenSize) {
        this.screenSizeSubject.next(screenSize);
    }

    /**
     *
     * @param message Can be keys or custom string
     * @param isTranslated false by default, put to true if message is a key
     * @param ms 3000 by default
     * @param translationParam allow to add variable to translation ex: { "name": variableName }
     */
    showSnackbar(message: string, isTranslated = false, ms = 3000, translationParam: { [key: string]: string } = null): void {
        // console.log('message :', message);
        if (isTranslated) {
            this.translateSrv.get(message, translationParam).subscribe(text => {
                this.snackbar.open(text, null, { duration: ms });
            });
        } else {
            this.snackbar.open(message, null, { duration: ms });
        }
    }

    confirmDialog(translationKey: string, options?: ConfirmDialogOptions): MatDialogRef<ConfirmDialogComponent> {
        return this.dialog.open(ConfirmDialogComponent, {
            // Send data to the dialog
            data: {
                message: translationKey,
                options
            }
        });
    }

    translate(key: string, params?: any): string {
        return this.translateSrv.instant(key, params);
    }

    getColors(): string[] {
        return [
            "#1592ca", "#22c2a0", "#334d5b",
            "#5bb3da", "#607d8b", "#64e3c8",
            "#a25bb9", "#a1c2d1", "#f34044",
            "#0663ab", "#cfd8dc", "#0b876d",
            "#763b8a"
        ];
    }
}
