import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';
import { Injectable } from "@angular/core";

@Injectable()
export class PreloadSelectedModules implements PreloadingStrategy {
    // preload modules that have "data: { preload: true }"" added to their route in app-routing.module
    preload(route: Route, fn: () => import("rxjs").Observable<any>): Observable<any> {
        return route.data && route.data.preload ? fn() : of(null);
    }
}
