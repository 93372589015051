export class FilterValue {
    id: string;
    label: string;

    constructor(id?: string, label?: string) {
        this.id = id;
        this.label = label;
    }

    static fromObject(object: FilterValue): FilterValue {
        return Object.assign(new FilterValue(), object);
    }

    get display(): string {
        return `${this.label}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.label], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
