<ng-container [formGroup]="group" *ngIf="!control">
    <mat-form-field>
        <mat-label> {{label}} </mat-label>
        <input type="number" matInput [formControlName]="controlName" [step]="step ? step : 1" [required]="required" autocomplete="off">
        <mat-hint> {{hint}} </mat-hint>
        <!-- <mat-error *ngIf="group.get(controlName).invalid"> Entrez une valeur entre {{min}} et {{max}} </mat-error> -->
    </mat-form-field>
</ng-container>

<ng-container *ngIf="control">
    <mat-form-field>
        <mat-label> {{label}} </mat-label>
        <input type="number" matInput [formControl]="control" [step]="step ? step : 1" [required]="required" autocomplete="off">
        <mat-hint> {{hint}} </mat-hint>
        <!-- <mat-error *ngIf="group.get(controlName).invalid"> Entrez une valeur entre {{min}} et {{max}} </mat-error> -->
    </mat-form-field>
</ng-container>