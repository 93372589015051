import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';

import { AuthService } from '@services/auth.service';
import { ReferenceMonthService } from '@services/reference-month.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    loginForm: UntypedFormGroup;
    isLoading: boolean;
    showErrorMessage: boolean;
    hidePassword = true;

    constructor(
        private fb: UntypedFormBuilder,
        private authSrv: AuthService,
        private refMonthSrv: ReferenceMonthService
    ) { }

    ngOnInit() {
        this.initForm();
        this.isLoading = false;
    }

    onSubmit(): void {
        this.isLoading = true;
        this.showErrorMessage = false;
        this.authSrv.login(this.loginForm.value).subscribe({
            next: () => {
                this.isLoading = false;
            },
            error: () => {
                this.isLoading = false;
                this.showErrorMessage = true;
            }
        });
    }

    private initForm(): void {
        this.loginForm = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }
}
