import { AfterContentChecked, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-input-number',
    templateUrl: './input-number.component.html',
    styleUrls: ['./input-number.component.scss']
})
export class InputNumberComponent implements OnInit, AfterContentChecked {
    @Input() label: string;
    @Input() hint: string;
    @Input() group: UntypedFormGroup;
    @Input() controlName: string;
    @Input() control: UntypedFormControl;
    @Input() required: boolean;
    @Input() min: number; // pass null or nothing if we want it deactivated
    @Input() max: number;
    @Input() step: number; // can be ignored if we want step === 1

    constructor(
        private changeDetectorRef: ChangeDetectorRef
    ) { }

    ngOnInit(): void {
        this.setValidator();
        this.observeControl();
    }

    ngAfterContentChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    private setValidator(): void {
        if (this.required) {
            if (this.control) {
                this.control.setValidators(Validators.required);
                this.control.updateValueAndValidity({ emitEvent: false });
            } else {
                this.group.controls[this.controlName].setValidators(Validators.required);
                this.group.updateValueAndValidity({ emitEvent: false });
            }

        }
    }

    /**
     * Prevent form typing a value that is outside the min and max boundaries
     */
    private observeControl(): void {
        if (this.control) {
            this.control.valueChanges
            .subscribe(value => {
                if (value < this.min) {
                    this.group.controls[this.controlName].setValue(this.min);
                } else if (value > this.max) {
                    this.group.controls[this.controlName].setValue(this.max);
                }
            });
        } else {
            this.group.controls[this.controlName].valueChanges
            .subscribe(value => {
                if (value < this.min) {
                    this.group.controls[this.controlName].setValue(this.min);
                } else if (value > this.max) {
                    this.group.controls[this.controlName].setValue(this.max);
                }
            });
        }
        
    }

}
