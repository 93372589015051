import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import FileSaver from 'file-saver';

import { environment } from '@environments/environment';

import { UiService } from './ui.service';
import { MappingService } from './mapping.service';
import { ReportService } from './report.service';

import { Mapping } from '@classes/mapping/mapping';

@Injectable({
  providedIn: 'root'
})
export class PaymentSectionStructureService {
  structuresChanged = new Subject<Mapping[]>();

  private readonly context = 'PaymentSection ';
  private baseUrl = environment.apiUrl + 'payment-section-structure';
  private structures: Mapping[];

  private defaultPaymentSectionsSubject = new BehaviorSubject<Mapping>(undefined);

  constructor(
    private http: HttpClient,
    private uiSrv: UiService,
    private mappingSrv: MappingService,
    private reportSrv: ReportService
  ) { }

  get defaultPaymentSections$(): Observable<Mapping> {
    return this.defaultPaymentSectionsSubject.asObservable();
  }

  set defaultPaymentSections(mapping: Mapping) {
    this.defaultPaymentSectionsSubject.next(mapping);
  }

  getList(): Observable<Mapping[]> {
    return this.http.get<Mapping[]>(this.baseUrl)
      .pipe(
        tap(structuresFromApi => {
          this.structures = structuresFromApi;
          this.structuresChanged.next([...this.structures]);
        })
      );
  }

  get(structureId: number, isEstimated?: boolean, loadReport = true): Observable<Mapping> {
    let params = new HttpParams()
      .append('isEstimated', `${isEstimated}`);
    if (isEstimated === undefined) {
      params = params.delete('isEstimated');
    }

    this.uiSrv.isLoadingSubject.next(true);
    this.mappingSrv.structureChanged.next(null);

    if (loadReport) {
      this.reportSrv.getReportsFromContext(this.context, [{ id: structureId }]).subscribe();
    }

    return this.http.get<Mapping>(`${this.baseUrl}/${structureId}`, { params })
      .pipe(
        tap(structureFromApi => {
          this.uiSrv.isLoadingSubject.next(false);
          this.mappingSrv.structureChanged.next(structureFromApi);
        })
      );
  }

  getDefault(): Observable<Mapping> {
    return this.http.get<Mapping>(`${this.baseUrl}/default`)
        .pipe(
            tap(mappingFromApi => this.defaultPaymentSections = mappingFromApi)
        );
  }

  post(structure: Mapping): Observable<Mapping> {
    this.uiSrv.isLoadingSubject.next(true);

    return this.http.post<Mapping>(`${this.baseUrl}`, structure)
      .pipe(
        tap(createdStructure => {
          this.structures.push(createdStructure);
          this.structuresChanged.next([...this.structures]);
          this.uiSrv.isLoadingSubject.next(false);
          this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
        })
      );
  }

  put(structure: Mapping): Observable<Mapping> {
    this.uiSrv.isLoadingSubject.next(true);

    return this.http.put<Mapping>(`${this.baseUrl}`, structure)
      .pipe(
        tap(structureFromApi => {
          this.structures = this.structures.map(s => s.id === structureFromApi.id ? structureFromApi : s);
          this.structuresChanged.next([...this.structures]);
          this.mappingSrv.structureChanged.next(structureFromApi);

          this.uiSrv.isLoadingSubject.next(false);
          this.uiSrv.showSnackbar('mapping.base.saved.summary', true);
        })
      );
  }

  downloadExcel(structureId: number): Observable<Blob> {
    return this.http.get(`${this.baseUrl}/${structureId}/download-excel`, { observe: 'response', responseType: 'blob' })
      .pipe(map(res => this.saveFile(res)));
  }

  private saveFile(res: HttpResponse<Blob>): Blob {
    FileSaver.saveAs(res.body, res.headers.get('filename'));
    return res.body;
  }
}
