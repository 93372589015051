<table mat-table
       [dataSource]="dataSource"
       matSort>
    <ng-container matColumnDef="dueMonth">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.dueMonth.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            {{ contractState.dueMonth | date: 'MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="budgetaryFte">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.budgetaryFte.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState; let i = index;">
            <span>
                {{contractState.budgetaryFte ? contractState.budgetaryFte : '-'}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="scalePayment">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.scalePayment.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.scalePayment; else nullProp">
                {{ contractState.scalePayment.externalId }} ({{ contractState.scalePayment.externalDescription }})
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="superiorScalePayment">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.superiorScalePayment.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.superiorScalePayment; else nullProp">
                {{ contractState.superiorScalePayment.externalId }} ({{
                contractState.superiorScalePayment.externalDescription
                }})
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="parallelScalePayment">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.parallelScalePayment.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.parallelScalePayment; else nullProp">
                {{ contractState.parallelScalePayment.externalId }} ({{
                contractState.parallelScalePayment.externalDescription
                }})
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="scaleSeniority">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.scaleSeniority.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.scaleSeniority; else nullProp">
                {{ contractState.scaleSeniority | date: 'dd-MM-YYYY'}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="professionalStatus">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.professionalStatus.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.professionalStatus; else nullProp">
                {{ contractState.professionalStatus.code }} ({{ contractState.professionalStatus.description }})
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="scaleAdministrative">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.scaleAdministrative.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.scaleAdministrative; else nullProp">
                {{ contractState.scaleAdministrative.externalId }} ({{
                contractState.scaleAdministrative.externalDescription }})
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="seniority">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.seniority.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.seniority; else nullProp">
                {{ contractState.seniority | date: 'dd-MM-YYYY'}}
            </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="salaryBase">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'agent.contractState.salaryBase.shortLabel' | translate}}€
        </th>
        <td mat-cell
            *matCellDef="let contractState">
            <span *ngIf="contractState.salaryBase; else nullProp">
                {{ contractState.salaryBase | formatSalarayBase: contractState.indexValue:contractState.contractFte }}
            </span>
        </td>
    </ng-container>
    <!-- <ng-container matColumnDef="scaleAdministrativeSeniority">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'agent.contractState.scaleAdministrativeSeniority.shortLabel' | translate}}
        </th>
        <td mat-cell *matCellDef="let contractState">
            <span *ngIf="contractState.scaleAdministrativeSeniority; else nullProp">
                {{ contractState.scaleAdministrativeSeniority | amDateFormat: 'DD-MM-YYYY'}}
            </span>
        </td>
    </ng-container> -->

    <tr mat-header-row
        *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let row; columns: displayedColumns;"></tr>
</table>

<ng-template #nullProp>
    <span>-</span>
</ng-template>