import { Injectable } from '@angular/core';

import { AuthService } from './auth.service';

import { navLinks } from '../routes';

import { LinkDisplay } from '@enums/link-display.enum';

import { NavLink } from '@classes/nav-link';
import { AuthUser } from '@classes/auth/auth-user';

@Injectable({
    providedIn: 'root'
})
export class NavService {
    private readonly routes: NavLink[] = [...navLinks];
    private authUser: AuthUser;

    constructor(
        private authSrv: AuthService
    ) {
        this.routes = this.configureLinks();
    }

    /**
     * Return hierarchical nav links based on auth status, user role and client
     * Used in main menu and home page
     * Can be called without any param
     */
    getNavLinks(links?: NavLink[], isAuth?: boolean): NavLink[] {
        links = links || JSON.parse(JSON.stringify(this.routes));
        isAuth = isAuth != null ? isAuth : !!this.authSrv.isAuth();
        this.authUser = this.authSrv.getAuthUser();

        return links.filter(link => {
            const showAuthLinks: boolean = isAuth ? this.authSrv.isAuthorized(link.roles, link.clients ? link.clients : undefined) : false;
            
            return link.display === LinkDisplay.all
                || (showAuthLinks && link.display === LinkDisplay.auth)
                || (!showAuthLinks && link.display === LinkDisplay.unauth);
        }).map(link => {
            if (link.children && link.children.length) {
                link.children = this.getNavLinks(link.children, isAuth);
                // Remove hierarchy if only one child
                if (link.children.length === 1) {
                    const icon = link.icon;
                    const title = link.title;
                    link = link.children[0];
                    link.icon = link.icon || icon;
                    link.title = title;
                }
            }

            return link;
        });
    }

    /**
     * Get array of nav links. Generate texts and add roles based on routes config
     * Used to manage route texts and role in only one place
     * @param links: NavLink[]
     */
    private configureLinks(links?: NavLink[]): NavLink[] {
        links = links || this.routes;

        return links.map(link => {
            // const splittedRoute = link.route.split('/').slice(1);
            // const routeFound = this.getRouteFromPath(link.route, false);

            link.title = link.title + '.shortLabel';
            link.content = link.title + '.summary';

            if (link.children) {
                link.children = this.configureLinks(link.children);
            }

            return link;
        });
    }

    /**
     * Return NavLink object from array of navigations points
     * Return null if user is not allowed on this route or if the route does'nt exists
     * @param path: array of navigations points from parent to selected child
     * @param icon: code of showed icon
     */
    // getLinkFromRoute(path: string, icon?: string): any {
    //     const routeFound = this.getRouteFromPath(path);
    //     if (!routeFound) {
    //         return null;
    //     }

    //     const fullPath = '/' + path.join('/');
    //     return new NavLink(fullPath, routeFound.data.title, icon);
    // }

    /**
     * Return corresponding route, if allowed, from array of navigations points
     * Check user role and authentication
     * By default, return null if user is not allowed/authenticated or if the route does'nt exists
     * @param path: array of navigations points from parent to selected child
     * @param testRole: if true (default), return null if user role is not allowed
     */
    // private getRouteFromPath(path: string[], testRole = true, routes?: Route[], parentRoles?: Role[], index = 0): Route {
    //     routes = routes || this.routes;
    //     // console.log(routes);

    //     return routes.reduce((found, current) => {
    //         // If already found in previous loop
    //         if (found) {
    //             // console.log('found:' + found);
    //             return found;
    //         }

    //         if (parentRoles && !current.data.roles) {
    //             current.data.roles = parentRoles;
    //         }

    //         if (current.path !== path[index] || (testRole && !this.authSrv.isAuthorized(current.data.roles))) {
    //             return null;
    //         }

    //         // If last point of path's array
    //         if (!path[index + 1]) {
    //             // console.log('current:' + current);
    //             return current;
    //         }

    //         if (current.children && current.children.length) {
    //             return this.getRouteFromPath(path, testRole, current.children, current.data.roles, index + 1);
    //         }
    //     }, null);
    // }
}
