import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { Scale } from '@classes/scenario/scale';

@Injectable({
    providedIn: 'root'
})
export class ScalePaymentService {
    private baseUrl = environment.apiUrl + 'scale-payment'

    scalePaymentSubject = new BehaviorSubject<Scale[]>([])

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<Scale[]> {
        return this.http.get<Scale[]>(this.baseUrl)
            .pipe(
                tap({
                    next: scalesFromApi => {
                        const scales = scalesFromApi.sort((a: Scale, b: Scale) => {
                            const idA = a.externalId.toLowerCase();
                            const idB = b.externalId.toLowerCase();
                            if (idA < idB) {
                                return -1;
                            } else if (idA > idB) {
                                return 1;
                            }
                            return 0;
                        });
                        return scales;
                    }
                })
            );
    }

}
