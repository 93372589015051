import { ContractState } from './contract-state';
import { Fte } from './fte';

export class StatesByYear {
    contractStates: ContractState[];
    // fte: Fte[];
    isExpanded: boolean;
    year: number;

    constructor(
        contractStates: ContractState[],
        // fte: Fte[],
        isExpanded: boolean,
        year: number
    ) {
        this.contractStates = contractStates;
        // this.fte = fte;
        this.isExpanded = isExpanded;
        this.year = year;
    }
}
