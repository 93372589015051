<ng-container [formGroup]="group">
  <mat-form-field *ngIf="!multiple">
    <mat-label> {{label}} </mat-label>
    <input appSelectOnTab type="text" matInput [formControlName]="controlName" [matAutocomplete]="status"
      (focus)="onAutoCompleteFocus()" [required]="required">
    <mat-autocomplete #status="matAutocomplete" [displayWith]="displayStatus" [panelWidth]="280">
      <mat-option *ngFor="let status of filteredStatus" [value]="status" [matTooltip]="status.description"
        [matTooltipShowDelay]="750">
        {{status.code}}
      </mat-option>
    </mat-autocomplete>
    <mat-error> {{'scenarios.events.error.noSelectedValue.summary' | translate}} </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="multiple">
    <mat-label>{{label}}</mat-label>
    <mat-select [formControlName]="controlName" multiple [required]="required">
      <div class="select-container">
        <mat-optgroup class="sticky">
          <mat-form-field>
            <mat-label>Search</mat-label>
            <input matInput [formControl]="searchControl" aria-label="Search">
            <button [disableRipple]="true" matSuffix mat-icon-button aria-label="Clear" (click)="clearSearch()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </mat-optgroup>
        <mat-optgroup>
          <div *ngIf="filteredStatus.length === 0"> {{'shared.components.multipleSelectFilter.noResult.fullLabel' |
            translate}} </div>
          <mat-option *ngFor="let status of filteredStatus" [value]="status">
            {{status.code}}
          </mat-option>
        </mat-optgroup>
      </div>
    </mat-select>
    <mat-error> {{'scenarios.events.error.noSelectedValue.summary' | translate}} </mat-error>
  </mat-form-field>
</ng-container>