import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { Mapping } from '@classes/mapping/mapping';
import { PaymentSectionStructureService } from '@services/payment-section-structure.service';

@Injectable()
export class PaymentSectionResolver implements Resolve<Mapping[]> {
    constructor(
        private uiSrv: UiService,
        private paymentSrv: PaymentSectionStructureService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Mapping[]> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return this.paymentSrv.getList()
        .pipe(
            tap(() => {
                this.uiSrv.routeLoadingStateChanged.next(false);
            })
        );
    }
}
