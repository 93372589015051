import { HttpResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Subject, merge } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { SubscriptionBaseComponent } from '@components/subscription-base/subscription-base.component';

import { EventOriginService } from '@services/event-origin.service';
import { ForecastEventService } from '@services/forecast-event.service';
import { ScenarioService } from '@services/scenario.service';
import { UiService } from '@services/ui.service';

import { EventsTableMode } from '@enums/events-table-mode.enum';

import { Agent } from '@classes/agent/agent';
import { ProfessionalStatus } from '@classes/contract/professional-status';
import { DateTimeFormat } from '@classes/date-time-format';
import { UserTag } from '@classes/parameters/user-tag';
import { EventOrigin } from '@classes/scenario/event-origin';
import { EventType } from '@classes/scenario/event-type';
import { EventsTags } from '@classes/scenario/events-tags';
import { InitialOrigin } from '@classes/scenario/initial-origin';
import { Scale } from '@classes/scenario/scale';
import { Scenario } from '@classes/scenario/scenario';
import { ThotEvent } from '@classes/scenario/thot-event';
import { UserTagService } from '@services/user-tag.service';
import { DateTime } from 'luxon';

interface OperatorValue {
    label: string;
    value: string;
}

interface SelectionParameter {
    label: string;
    filter: string;
    withOperator: boolean;
    canReplace: boolean;
    canFilter?: boolean;
}

@Component({
    selector: 'app-event-edit-multiple',
    templateUrl: './event-edit-multiple.component.html',
    styleUrls: ['./event-edit-multiple.component.scss']
})
export class EventEditMultipleComponent extends SubscriptionBaseComponent implements OnInit, OnDestroy {
    @Input() mode: EventsTableMode;
    @Input() maxMonth: DateTime;
    @Input() minMonth: DateTime;
    @Input() indexValue: number;
    events: ThotEvent[];

    selectedScenario: Scenario;
    professionalStatus: ProfessionalStatus[];
    scalesPayment: Scale[];
    eventTypes: EventType[];
    origins: EventOrigin[];
    initialOrigins: InitialOrigin[];
    userTags: UserTag[];

    criteriaForm: UntypedFormGroup;
    editForm: UntypedFormGroup;
    changesUnsubscribe = new Subject();

    eventsToEdit: ThotEvent[] = [];
    editedEvents: ThotEvent[];

    isDeleting: boolean;
    isSearching: boolean;
    isReplacing: boolean;

    exceptionParams = 'Scale|Status|eventType|strategyId|initialEventOrigin|tags';
    params: SelectionParameter[] = [
        {
            label: 'scenarios.events.properties.matricule',
            filter: 'agent.matricule',
            withOperator: false,
            canReplace: false
        },
        {
            label: 'scenarios.events.properties.lastName',
            filter: 'agent.lastName',
            withOperator: false,
            canReplace: false
        },
        {
            label: 'scenarios.events.properties.firstName',
            filter: 'agent.firstName',
            withOperator: false,
            canReplace: false
        },
        {
            label: "scenarios.events.properties.eventType",
            filter: 'eventType',
            withOperator: false,
            canReplace: false
        },
        {
            label: 'scenarios.events.properties.eventMonth',
            filter: 'eventMonth',
            withOperator: true,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.initialEventMonth',
            filter: 'initialEventMonth',
            withOperator: true,
            canReplace: false
        },
        {
            label: 'scenarios.events.properties.oldFte',
            filter: 'oldFte',
            withOperator: true,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.newFte',
            filter: 'newFte',
            withOperator: true,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.oldProfessionalStatus',
            filter: 'oldProfessionalStatus',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.newProfessionalStatus',
            filter: 'newProfessionalStatus',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.oldScalePayment',
            filter: 'oldScalePayment',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.newScalePayment',
            filter: 'newScalePayment',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.oldSuperiorScalePayment',
            filter: 'oldSuperiorScalePayment',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.newSuperiorScalePayment',
            filter: 'newSuperiorScalePayment',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.oldParallelScalePayment',
            filter: 'oldParallelScalePayment',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.newParallelScalePayment',
            filter: 'newParallelScalePayment',
            withOperator: false,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.oldSalaryBase',
            filter: 'oldSalaryBase',
            withOperator: true,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.newSalaryBase',
            filter: 'newSalaryBase',
            withOperator: true,
            canReplace: true
        },
        {
            label: 'scenarios.events.properties.systemComment',
            filter: 'systemComment',
            withOperator: false,
            canReplace: false
        },
        {
            label: 'scenarios.events.properties.userComment',
            filter: 'userComment',
            withOperator: false,
            canReplace: true,
            canFilter: false
        },
        {
            label: 'scenarios.events.properties.userTags',
            filter: 'tags',
            withOperator: false,
            canReplace: true
        }
    ];
    operators: OperatorValue[] = [
        {
            label: '=',
            value: '=='
        },
        {
            label: '≠',
            value: '!='
        },
        {
            label: '<=',
            value: '<='
        },
        {
            label: '>=',
            value: '>='
        },
        {
            label: '<',
            value: '<'
        },
        {
            label: '>',
            value: '>'
        }
    ];

    private baseFilter: string;

    private unsubscribeAll = new Subject<void>();

    constructor(
        private fb: UntypedFormBuilder,
        private forecastEventSrv: ForecastEventService,
        private scenarioSrv: ScenarioService,
        private uiSrv: UiService,
        private eventOriginSrv: EventOriginService,
        private userTagSrv: UserTagService,
        protected changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.initsubscription();
        this.initForm();
    }

    ngOnDestroy(): void {
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    get selectionCriterias(): UntypedFormArray {
        return this.criteriaForm.controls.selectionCriteria as UntypedFormArray;
    }

    get criterias(): UntypedFormGroup[] {
        return this.selectionCriterias.controls as UntypedFormGroup[];
    }

    getCriteriaControl(formGroup: UntypedFormGroup, controlName: string) {
        return formGroup.get(controlName) as UntypedFormControl;
    }

    addCriteria(): void {
        this.selectionCriterias.push(this.createCriteria());
        this.watchForChanges();
    }

    deleteCriteria(index: number): void {
        this.selectionCriterias.removeAt(index);
        this.watchForChanges();
    }

    onSubmitSearch(): void {
        this.isSearching = true;
        let filter = this.baseFilter ? this.baseFilter + ',' : '';
        if (this.criteriaForm.value.selectionCriteria.every((c: { criteria: SelectionParameter, operator: string, value: any }) => c.criteria?.filter && c.value)) {
            this.criteriaForm.value.selectionCriteria.forEach((c: { criteria: SelectionParameter, operator: string, value: any }) => {
                if (c.criteria.filter.match('Month')) {
                    const value = c.value as DateTime;
                    const newDate = value.toFormat(DateTimeFormat.dateTime)
                    filter += `${c.criteria.filter}${c.operator}${newDate},`;
                } else if (c.criteria.filter.match('Scale') && !c.operator) {
                    filter += `${c.criteria.filter}==${c.value.externalId},`;
                } else if (c.criteria.filter.match('Status') || c.criteria.filter.match('eventType')) {
                    filter += `${c.criteria.filter}==${c.value.code},`;
                } else if (c.criteria.filter.match('strategyId')) {
                    filter += `${c.criteria.filter}==${c.value.strategy.id},${c.value.label ? 'label==' + c.value.label + ',' : 'hasLabel==false,'}`;
                } else if (c.criteria.filter.match('initialEventOrigin')) {
                    filter += `${c.criteria.filter}==${c.value.label},`;
                } else if (c.criteria.filter.match('tags')) {
                    filter += `${c.criteria.filter}==${c.value.join('|')},`;
                } else if (c.operator) {
                    filter += `${c.criteria.filter}${c.operator}${c.value},`;
                } else {
                    filter += `${c.criteria.filter}@=${c.value},`;
                }
            });
        }
        if (filter.slice(-1) === ',') {
            filter = filter.slice(0, -1);
        }
        this.forecastEventSrv.getForMultipleEdit(this.selectedScenario.id, 0, 0, filter)
            .subscribe({
                next: (responseFromSrv: HttpResponse<ThotEvent[]>) => {
                    this.events = responseFromSrv.body;
                    this.isSearching = false;
                },
                error: err => {
                    // console.log(err);
                    this.isSearching = false;
                }
            });
    }

    toggleEventForEditing(events: ThotEvent[]): void {
        events.forEach(event => {
            const index = this.eventsToEdit.findIndex(e => event.id === e.id);
            if (index !== -1) {
                this.eventsToEdit.splice(index, 1);
            } else {
                this.eventsToEdit.push(event);
            }
        });
    }

    onSubmitEdits(): void {
        this.isReplacing = true;
        if (this.editForm.value.criteria.filter === 'tags') {
            const tagIds = this.editForm.value.value as number[];
            let isEstimationTag = tagIds.some(tagId => this.userTags.find(tag => tag.id === tagId).type.id === 3);
            if (isEstimationTag) {
                if (this.eventsToEdit?.length) {
                    if (this.eventsToEdit.find(event => event.newContract || event.oldContract)) {
                        this.uiSrv.showSnackbar('parameters.tags.estimationTagOnlyAnonymous.summary', true);
                        return;
                    }
                } else if (this.events?.length) {
                    if (this.events.find(event => event.newContract || event.oldContract)) {
                        this.uiSrv.showSnackbar('parameters.tags.estimationTagOnlyAnonymous.summary', true);
                        return;
                    }
                }
            }
            let replacedReportTag = false;
            let eventIds: number[];
            if (this.eventsToEdit?.length) {
                eventIds = this.eventsToEdit.map((event: ThotEvent) => {
                    if (!replacedReportTag) {
                        replacedReportTag = !!event.userTags.find((tag: UserTag) => tag.type.id === 2);
                    }
                    return event.id;
                });
            } else {
                eventIds = this.events.map((event: ThotEvent) => {
                    if (!replacedReportTag) {
                        replacedReportTag = !!event.userTags.find((tag: UserTag) => tag.type.id === 2);
                    }
                    return event.id;
                });
            }
            const eventsTags = new EventsTags(eventIds, this.editForm.value.value?.length ? this.editForm.value.value : []);
            this.forecastEventSrv.editUserTags(eventsTags, this.selectedScenario).subscribe(() => {
                // if (this.userTagSrv.hasTagType(eventsTags.tags, 3)) {
                //   this.scenarioSrv.editStatus(this.selectedScenario, ScenarioStatus.outdated).subscribe();
                // }
                if (this.eventsToEdit?.length) {
                    this.events.map((event: ThotEvent) => {
                        if (this.eventsToEdit.findIndex((e: ThotEvent) => event.id === e.id) !== -1) {
                            return this.editTags(event);
                        } else {
                            return event;
                        }
                    });
                } else {
                    this.events = this.events.map((event: ThotEvent) => {
                        return this.editTags(event);
                    });
                }
                if (replacedReportTag) {
                    this.uiSrv.showSnackbar('scenarios.events.multipleEdit.masterTagReplaced.summary', true);
                }
                this.isReplacing = false;
            });
        } else {
            if (this.eventsToEdit?.length) {
                this.events = this.events.map((event: ThotEvent) => {
                    if (this.eventsToEdit.findIndex((e: ThotEvent) => event.id === e.id) !== -1) {
                        return this.editEvent(event);
                    } else {
                        return event;
                    }
                });
            } else {
                this.events = this.events.map((event: ThotEvent) => {
                    return this.editEvent(event);
                });
            }
            this.isReplacing = false;
            this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
        }
    }

    deleteEvents(): void {
        const message = 'scenarios.events.multipleEdit.confirmDelete.summary';

        this.uiSrv.confirmDialog(message).afterClosed().subscribe((result: boolean) => {
            if (result) {
                if (this.eventsToEdit?.length) {
                    this.events = this.events.map((event: ThotEvent) => {
                        if (this.eventsToEdit.findIndex((e: ThotEvent) => event.id === e.id) !== -1) {
                            return this.deleteEvent(event);
                        } else {
                            return event;
                        }
                    });
                } else {
                    this.events = this.events.map((event: ThotEvent) => {
                        return this.deleteEvent(event);
                    });
                }

                this.forecastEventSrv.editedEventsSubject.next(this.editedEvents);
            }
        });
    }

    hasReportTag(tag: UserTag): boolean {
        if (tag.type.id === 2 && this.editForm.get('value').value?.length) {
            return !!this.editForm.get('value').value.find((userTagId: number) => {
                return this.userTags.find(uT => uT.id === userTagId).type.id === 2 && tag.id !== userTagId;
            });
        } else {
            return false;
        }
    }

    private editEvent(event: ThotEvent): ThotEvent {
        if (!event.oldEvent) {
            event.oldEvent = JSON.parse(JSON.stringify(event));
            if (event.oldEvent.agent) {
                event.oldEvent.agent = Agent.fromObject(event.oldEvent.agent);
            }
        }
        event.edited = true;
        event.manuallyModified = true;

        if (this.editForm.value.criteria.filter === 'eventMonth') {
            const value = this.editForm.value.value as DateTime;
            const newDate = value.toFormat(DateTimeFormat.dateTime)
            event[this.editForm.value.criteria.filter] = newDate;
        } else if (this.editForm.value.criteria.filter === 'oldSalaryBase') {
            event.oldSalaryBase = Math.round((this.editForm.value.value / this.indexValue / event.fteContractual) * 100) / 100;
        } else if (this.editForm.value.criteria.filter === 'newSalaryBase') {
            event.newSalaryBase = Math.round((this.editForm.value.value / this.indexValue / event.fteContractual) * 100) / 100;
        } else {
            event[this.editForm.value.criteria.filter] = this.editForm.value.value;
        }
        const index = this.editedEvents.findIndex(e => e.id === event.id);
        if (index !== -1) {
            this.editedEvents[index] = event;
        } else {
            this.editedEvents.push(event);
        }
        return event;
    }

    private editTags(event: ThotEvent): ThotEvent {
        if (this.editForm.value.value?.length) {
            event.userTags = this.editForm.value.value.map((tagId: number) => {
                return this.userTags.find((userTag: UserTag) => userTag.id === tagId);
            });
        } else {
            event.userTags = []
        }
        return event;
    }

    private deleteEvent(event: ThotEvent): ThotEvent {
        if (event.oldEvent) {
            event = event.oldEvent;
        }
        event.oldEvent = JSON.parse(JSON.stringify(event));
        if (event.oldEvent.agent) {
            event.oldEvent.agent = Agent.fromObject(event.oldEvent.agent);
        }
        event.deleted = true;
        event.manuallyModified = true;
        event.edited = true;
        const index = this.editedEvents.findIndex(e => e.id === event.id);
        if (index !== -1) {
            this.editedEvents[index] = event;
        } else {
            this.editedEvents.push(event);
        }
        return event;
    }

    private createCriteria(): UntypedFormGroup {
        return this.fb.group({
            criteria: [],
            operator: [{ value: '==', disabled: true }],
            value: [{ value: '', disabled: true }]
        });
    }

    private onValueChange(changes: { rowIndex: number, group: UntypedFormGroup, data: SelectionParameter }): void {
        if (changes.data.withOperator) {
            changes.group.controls.operator.enable();
        } else {
            changes.group.controls.operator.setValue('==');
            changes.group.controls.operator.disable();
        }
        changes.group.controls.value.enable();
    }

    private watchForChanges() {
        // cleanup any prior subscriptions before re-establishing new ones
        this.changesUnsubscribe.next();

        merge(...this.selectionCriterias.controls.map((group: UntypedFormGroup, index: number) =>
            group.controls.criteria.valueChanges.pipe(
                takeUntil(this.changesUnsubscribe),
                map(value => ({ rowIndex: index, group, data: value })))
        )).subscribe(changes => {
            this.onValueChange(changes);
        });
    }

    private initForm(): void {
        this.criteriaForm = this.fb.group({
            selectionCriteria: this.fb.array([
                this.createCriteria()
            ])
        });

        this.editForm = this.fb.group({
            criteria: ['', [Validators.required]],
            value: ['']
        });

        this.watchForChanges();
        this.editForm.controls.criteria.valueChanges.subscribe(value => {
            if (value.filter === 'tags') {
                this.editForm.controls.value.removeValidators(Validators.required);
            } else {
                this.editForm.controls.value.setValidators(Validators.required);
            }
            this.changeDetectorRef.detectChanges();
        });
    }

    private initsubscription(): void {
        if (this.mode !== EventsTableMode.provider && this.mode !== EventsTableMode.realized) {
            const newParams = [
                {
                    label: 'scenarios.events.properties.origin',
                    filter: 'strategyId',
                    withOperator: false,
                    canReplace: false
                },
                {
                    label: 'scenarios.events.properties.initialEventOrigin',
                    filter: 'initialEventOrigin',
                    withOperator: false,
                    canReplace: false
                }
            ]
            this.params = [...this.params, ...newParams]
        }

        this.scenarioSrv.selectedScenarioSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(scenarioFromSrv => {
                if (scenarioFromSrv) {
                    this.selectedScenario = scenarioFromSrv;
                }
            });

        this.forecastEventSrv.editedEventsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(eventsFromSrv => this.editedEvents = eventsFromSrv);

        this.eventOriginSrv.originsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((originsFromSrv: EventOrigin[]) => {
                this.origins = originsFromSrv.map((origin: EventOrigin) => EventOrigin.fromObject(origin));
            });

        this.eventOriginSrv.initialOriginsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((originsFromSrv: InitialOrigin[]) => this.initialOrigins = originsFromSrv);

        this.forecastEventSrv.eventTypesSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(typesFromSrv => this.eventTypes = typesFromSrv);

        this.forecastEventSrv.professionalStatusSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(statusFromSrv => this.professionalStatus = statusFromSrv);

        this.forecastEventSrv.scalePaymentSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(scalesFromSrv => this.scalesPayment = scalesFromSrv);

        this.userTagSrv.userTagsSubject
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe((userTagsFromSrv: UserTag[]) => this.userTags = userTagsFromSrv);
    }

}
