export enum EventsTableMode {
    all = 'all',
    restricted = 'restricted',
    notNoted = 'notNoted',
    toPersonalize = 'toPersonalize',
    provider = 'provider',
    realized = 'realized',
    agent = 'agent',
    reconciliation = 'reconciliation',
    invalid = "invalid"
}
