import { Contract } from '../contract/contract';
import { BaseInterface } from '@interfaces/base-interface';
import { RefContract } from '@classes/contract/ref-contract';

export class Agent implements BaseInterface {
    id: number;
    matricule: string;
    lastName: string;
    firstName: string;
    birthDate: Date | string;
    gender: string;
    contracts?: Contract[];
    referenceContracts?: RefContract[];

    constructor()
    constructor(id: number, matricule: string, lastName: string, firstName: string)
    constructor(id?: number, matricule?: string, lastName?: string, firstName?: string) {
        this.id = id;
        this.matricule = matricule;
        this.lastName = lastName;
        this.firstName = firstName;
    }

    static fromObject(object: Agent): Agent {
        return Object.assign(new Agent(), object);
    }

    get display(): string {
        return `${this.lastName} ${this.firstName} (${this.matricule})`;
    }
}
