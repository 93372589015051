<header>
    <h4> Détail de la situation de référence </h4>
</header>
<div>
    <ul>
        <li>
            <span> {{'scenarios.events.properties.agent.fullLabel' | translate}} </span>
            <span> {{refSituation.agent.display}} </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.contract.fullLabel' | translate}} </span>
            <span> {{refSituation.contract.docNumber}} </span>
        </li>
        <li>
            <span> {{'agent.agentDetail.refSituation.startDate.fullLabel' | translate}} </span>
            <span> {{refSituation.startDate | date: dateFormat}} </span>
        </li>
        <li>
            <span> {{'agent.agentDetail.refSituation.endDate.fullLabel' | translate}} </span>
            <span> {{refSituation.endDate | date: dateFormat}} </span>
        </li>
        <li>
            <span> {{'agent.agentDetail.refSituation.referenceFte.fullLabel' | translate}} </span>
            <span> {{refSituation.fteReference}} </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.seniority.fullLabel' | translate}} </span>
            <span> {{refSituation.seniority | date: dateFormat}} </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.contractualSector.fullLabel' | translate}} </span>
            <span [matTooltip]="refSituation?.contractualSector?.externalDescription"
                  *ngIf="refSituation?.contractualSector?.id > -1; else nullProp">
                {{refSituation?.contractualSector?.externalId}}
            </span>
        </li>
        <li>
            <span> {{'agent.agentDetail.refSituation.paidHolidayComputingMode.fullLabel' | translate}} </span>
            <span [matTooltip]="refSituation?.paidHolidayComputingMode?.externalDescription"
                  *ngIf="refSituation.paidHolidayComputingMode?.id > -1; else nullProp">
                {{refSituation.paidHolidayComputingMode.externalId}}
            </span>
        </li>
        <li>
            <span> {{'agent.agentDetail.refSituation.yearEndBonusComputingMode.fullLabel' | translate}} </span>
            <span [matTooltip]="refSituation?.yearEndBonusComputingMode?.externalDescription"
                  *ngIf="refSituation.yearEndBonusComputingMode?.id > -1; else nullProp">
                {{refSituation?.yearEndBonusComputingMode?.externalId}}
            </span>
        </li>
    </ul>
    <ul>
        <li>
            <span> {{'scenarios.events.properties.scalePayment.fullLabel' | translate}} </span>
            <span *ngIf="refSituation.scalePayment?.id && refSituation.scalePayment?.id > -1; else nullProp">
                {{refSituation.scalePayment.externalId}}
            </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.superiorScalePayment.fullLabel' | translate}} </span>
            <span
                  *ngIf="refSituation.superiorScalePayment?.id && refSituation.superiorScalePayment?.id > -1; else nullProp">
                {{refSituation.superiorScalePayment.externalId}}
            </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.parallelScalePayment.fullLabel' | translate}} </span>
            <span
                  *ngIf="refSituation.parallelScalePayment?.id && refSituation.parallelScalePayment?.id > -1; else nullProp">
                {{refSituation.parallelScalePayment.externalId}}
            </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.scaleAdministrative.fullLabel' | translate}} </span>
            <span *ngIf="refSituation.scaleAdministrative?.id > -1; else nullProp">
                {{refSituation.scaleAdministrative.externalId}}
            </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.professionalStatus.fullLabel' | translate}} </span>
            <span
                  *ngIf="refSituation.professionalStatus?.id && refSituation.professionalStatus?.id > -1; else nullProp">
                {{refSituation.professionalStatus.code}}
            </span>
        </li>
        <li>
            <span> {{'scenarios.events.properties.salaryBase.fullLabel' | translate}} </span>
            <span *ngIf="refSituation.salaryBase; else nullProp">
                {{refSituation.salaryBase | formatSalarayBase: indexValue:refSituation.fteContractuel}}
            </span>
        </li>
        <li class="functional-article-repartition">
            <span> {{'agent.agentDetail.refSituation.functionalArticleRepartition.fullLabel' | translate}} </span>
            <div class="container">
                <div class="functional-article-repartition-bar"
                     *ngFor="let article of refSituation.functionalArticleRepartition; index as i"
                     [matTooltip]="article.ArticleFunctionalCode + ' : ' + article.Ratio * 100 + '%'"
                     appRatioBar
                     [ratio]="article.Ratio"
                     [color]="colors[i]"> {{article.ArticleFunctionalCode}} </div>
            </div>
        </li>
    </ul>
</div>

<ng-template #nullProp>
    <span>-</span>
</ng-template>