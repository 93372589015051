import { Component, Input, ViewChild, OnChanges } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { MatSort } from '@angular/material/sort';

import { ContractState } from '@classes/contract/contract-state';
import { Fte } from '@classes/contract/fte';

@Component({
  selector: 'app-state-year',
  templateUrl: './state-year.component.html',
  styleUrls: ['./state-year.component.scss']
})
export class StateYearComponent implements OnChanges {
  @Input() contractStates: ContractState[];
  @Input() fte: Fte[];
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  dataSource: MatTableDataSource<ContractState>;
  displayedColumns: string[] = [
    'dueMonth',
    'budgetaryFte',
    'scalePayment',
    'superiorScalePayment',
    'parallelScalePayment',
    'scaleSeniority',
    'professionalStatus',
    'scaleAdministrative',
    'seniority',
    'salaryBase'
    // 'scaleAdministrativeSeniority'
  ];

  constructor() { }

  ngOnChanges(): void {
    this.dataSource = new MatTableDataSource(this.contractStates);
    this.dataSource.sort = this.sort;
  }

}
