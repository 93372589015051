<div class="search"
     [ngStyle]="{'margin-top': isEditingMultiple ? '12px' : '0'}">
    <ng-container *ngIf="!isEditingMultiple">
        <mat-form-field>
            <mat-label> {{'scenarios.events.toolbar.search.shortLabel' | translate}} </mat-label>
            <input matInput
                   [formControl]="globalSearch">
        </mat-form-field>
        <div class="show-filters"
             (click)="toggleFilters()">
            <span> {{'scenarios.events.toolbar.advancedSearch.fullLabel' | translate}} </span>
            <mat-icon>
                {{showFilters ? 'expand_less' : 'expand_more'}}
            </mat-icon>
        </div>
        <!-- RESET FILTERS -->
        <button mat-icon-button
                color="accent"
                (click)="resetFilters()"
                [matTooltip]="'scenarios.events.toolbar.resetSearch.summary' | translate">
            <mat-icon>refresh</mat-icon>
        </button>
        <!-- DOWNLOAD EXCEL -->
        <button mat-icon-button
                color="accent"
                (click)="downloadExcel()"
                [matTooltip]="'scenarios.events.toolbar.downloadExcel.summary' | translate"
                *ngIf="appRights.scenario.canExportEvents">
            <mat-icon *ngIf="!isDownloadingEvents">file_download</mat-icon>
            <mat-spinner [diameter]="24"
                         *ngIf="isDownloadingEvents"></mat-spinner>
        </button>
        <!-- ADD EVENTS -->
        <button mat-raised-button
                color="accent"
                (click)="onAddEvents()"
                *ngIf="mode === eventsTableMode.all && appRights.scenario.canAddEvents">
            <mat-icon aria-label="Add">add</mat-icon>
            {{'scenarios.events.toolbar.addEvents.fullLabel' | translate}}
        </button>
    </ng-container>
    <!-- EDIT MULTIPLE -->
    <button mat-raised-button
            color="accent"
            (click)="toggleEditMultiple()"
            *ngIf="mode !== eventsTableMode.provider 
            && mode !== eventsTableMode.realized 
            && mode !== eventsTableMode.restricted 
            && mode !== eventsTableMode.agent
            && appRights.scenario.canEditEvents">
        <ng-container *ngIf="isEditingMultiple">
            <mat-icon aria-label="Cancel">clear</mat-icon>
            {{'scenarios.events.toolbar.stopMultipleEdit.fullLabel' | translate}}
        </ng-container>
        <ng-container *ngIf="!isEditingMultiple">
            <mat-icon aria-label="Edit">edit</mat-icon>
            {{'scenarios.events.toolbar.startMultipleEdit.fullLabel' | translate}}
        </ng-container>
    </button>
    <!-- SAVE EVENTS -->
    <button mat-raised-button
            color="accent"
            (click)="saveEdits()"
            *ngIf="(editedEventsCount > 0 || createdEventsCount > 0 || deletedEventsCount > 0) 
            && mode === eventsTableMode.all
            && !isEditingMultiple 
            && (appRights.scenario.canEditEvents || appRights.scenario.canAddEvents)">
        <mat-icon aria-label="Save">save</mat-icon>
        {{'scenarios.events.toolbar.saveEvents.fullLabel' | translate}}
    </button>
    <!-- CREATED/DELETED/EDITED EVENTS COUNTS -->
    <div class="events-counts"
         *ngIf="mode !== eventsTableMode.provider 
        && mode !== eventsTableMode.realized 
        && mode !== eventsTableMode.restricted 
        && !isEditingMultiple 
        && (appRights.scenario.canEditEvents || appRights.scenario.canAddEvents)">
        <div *ngIf="editedEventsCount > 0 && appRights.scenario.canEditEvents"
             class="count">
            <span> {{'scenarios.events.toolbar.modifiedEvents.fullLabel' | translate}} : {{editedEventsCount}} </span>
            <button mat-icon-button
                    color="accent"
                    (click)="onCancelEdits(false)"
                    [matTooltip]="'scenarios.events.toolbar.cancelModifications.summary' | translate"
                    style="font-size: 12px;">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="createdEventsCount > 0 && appRights.scenario.canAddEvents"
             class="count">
            <span> {{'scenarios.events.toolbar.createdEvents.fullLabel' | translate}} : {{createdEventsCount}} </span>
            <button mat-icon-button
                    color="accent"
                    (click)="onCancelNew()"
                    [matTooltip]="'scenarios.events.toolbar.deleteCreatedEvents.summary' | translate">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="deletedEventsCount > 0 && appRights.scenario.canDeleteEvents"
             class="count">
            <span> {{'scenarios.events.toolbar.eventsToDelete.fullLabel' | translate}} : {{deletedEventsCount}} </span>
            <button mat-icon-button
                    color="accent"
                    (click)="onCancelEdits(true)"
                    [matTooltip]="'scenarios.events.toolbar.cancelDeletion.summary' | translate">
                <mat-icon>close</mat-icon>
            </button>
        </div>
    </div>
</div>
<!-- ADVANCED SEARCH FORM -->
<form [formGroup]="searchForm"
      (ngSubmit)="onSubmit()"
      *ngIf="showFilters"
      class="advanced-search">
    <div>
        <mat-form-field *ngIf="mode !== eventsTableMode.agent">
            <mat-label> {{'scenarios.events.properties.matricule.shortLabel' | translate}} </mat-label>
            <input matInput
                   formControlName="matricule">
        </mat-form-field>
        <mat-form-field *ngIf="mode !== eventsTableMode.agent">
            <mat-label> {{'scenarios.events.properties.lastName.shortLabel' | translate}} </mat-label>
            <input matInput
                   formControlName="lastName">
        </mat-form-field>
        <mat-form-field *ngIf="mode !== eventsTableMode.agent">
            <mat-label> {{'scenarios.events.properties.firstName.shortLabel' | translate}} </mat-label>
            <input matInput
                   formControlName="firstName">
        </mat-form-field>
        <app-event-type-select [label]="'scenarios.events.properties.eventType.shortLabel' | translate"
                               [control]="getControl('eventType')"
                               class="with-margin">
        </app-event-type-select>
    </div>

    <div
         *ngIf="mode !== eventsTableMode.realized && mode !== eventsTableMode.provider && mode !== eventsTableMode.agent">
        <app-autocomplete [control]="getControl('origin')"
                          [list]="origins"
                          [label]="'scenarios.events.properties.origin.shortLabel' | translate"
                          *ngIf="searchForm.controls.origin"
                          class="event-origin">
        </app-autocomplete>
        <app-autocomplete [control]="getControl('initialEventOrigin')"
                          [list]="initialOrigins"
                          [label]="'scenarios.events.properties.initialEventOrigin.shortLabel' | translate"
                          *ngIf="searchForm.controls.initialEventOrigin">
        </app-autocomplete>
    </div>

    <div>
        <mat-form-field class="operator">
            <mat-select formControlName="monthOperator">
                <mat-option *ngFor="let operator of operators"
                            [value]="operator.value"> {{operator.label}} </mat-option>
            </mat-select>
        </mat-form-field>
        <app-month-selector class="with-operator"
                            [label]="'scenarios.events.properties.eventMonth.shortLabel' | translate"
                            [group]="searchForm"
                            [controlName]="'eventMonth'"
                            [min]="minMonth"
                            [max]="maxMonth"></app-month-selector>

        <ng-container
                      *ngIf="mode !== eventsTableMode.realized && mode !== eventsTableMode.provider && mode !== eventsTableMode.agent">
            <mat-form-field class="operator">
                <mat-select formControlName="initialMonthOperator">
                    <mat-option *ngFor="let operator of operators"
                                [value]="operator.value"> {{operator.label}} </mat-option>
                </mat-select>
            </mat-form-field>
            <app-month-selector class="with-operator"
                                [label]="'scenarios.events.properties.initialEventMonth.shortLabel' | translate"
                                [group]="searchForm"
                                [controlName]="'initialEventMonth'"></app-month-selector>
        </ng-container>
    </div>

    <div>
        <mat-form-field class="operator">
            <mat-select formControlName="oldFteOperator">
                <mat-option *ngFor="let operator of operators"
                            [value]="operator.value"> {{operator.label}} </mat-option>
            </mat-select>
        </mat-form-field>
        <app-input-number class="with-operator"
                          [label]="'scenarios.events.properties.oldFte.shortLabel' | translate"
                          [group]="searchForm"
                          [controlName]="'oldFte'"
                          [min]="0"
                          [max]="1"
                          [step]="0.1"></app-input-number>

        <mat-form-field class="operator">
            <mat-select formControlName="newFteOperator">
                <mat-option *ngFor="let operator of operators"
                            [value]="operator.value"> {{operator.label}} </mat-option>
            </mat-select>
        </mat-form-field>
        <app-input-number class="with-operator"
                          [label]="'scenarios.events.properties.newFte.shortLabel' | translate"
                          [group]="searchForm"
                          [controlName]="'newFte'"
                          [min]="0"
                          [max]="1"
                          [step]="0.1"></app-input-number>
    </div>

    <div>
        <app-autocomplete class="with-margin"
                          [control]="getControl('oldProfessionalStatus')"
                          [list]="professionalStatuts"
                          [label]="'scenarios.events.properties.oldProfessionalStatus.shortLabel' | translate">
        </app-autocomplete>
        <app-autocomplete [control]="getControl('newProfessionalStatus')"
                          [list]="professionalStatuts"
                          [label]="'scenarios.events.properties.newProfessionalStatus.shortLabel' | translate">
        </app-autocomplete>
    </div>


    <div>
        <app-autocomplete class="with-margin"
                          [control]="getControl('oldScalePayment')"
                          [list]="scalePayment"
                          [label]="'scenarios.events.properties.oldScalePayment.shortLabel' | translate">
        </app-autocomplete>
        <app-autocomplete class="with-margin"
                          [control]="getControl('oldSuperiorScalePayment')"
                          [list]="scalePayment"
                          [label]="'scenarios.events.properties.oldSuperiorScalePayment.shortLabel' | translate">
        </app-autocomplete>
        <app-autocomplete [control]="getControl('oldParallelScalePayment')"
                          [list]="scalePayment"
                          [label]="'scenarios.events.properties.oldParallelScalePayment.shortLabel' | translate">
        </app-autocomplete>
    </div>

    <div>
        <app-autocomplete class="with-margin"
                          [control]="getControl('newScalePayment')"
                          [list]="scalePayment"
                          [label]="'scenarios.events.properties.newScalePayment.shortLabel' | translate">
        </app-autocomplete>
        <app-autocomplete class="with-margin"
                          [control]="getControl('newSuperiorScalePayment')"
                          [list]="scalePayment"
                          [label]="'scenarios.events.properties.newSuperiorScalePayment.shortLabel' | translate">
        </app-autocomplete>
        <app-autocomplete [control]="getControl('newParallelScalePayment')"
                          [list]="scalePayment"
                          [label]="'scenarios.events.properties.newParallelScalePayment.shortLabel' | translate">
        </app-autocomplete>
    </div>

    <div
         *ngIf="mode !== eventsTableMode.realized && mode !== eventsTableMode.provider && mode !== eventsTableMode.agent">
        <mat-form-field>
            <mat-label> {{'scenarios.events.properties.systemComment.shortLabel' | translate}} </mat-label>
            <input matInput
                   formControlName="systemComment">
        </mat-form-field>
        <mat-form-field>
            <mat-label> {{'scenarios.events.properties.userComment.shortLabel' | translate}} </mat-label>
            <input matInput
                   formControlName="userComment">
        </mat-form-field>
        <mat-form-field>
            <mat-label> {{'scenarios.events.properties.userTags.shortLabel' | translate}} </mat-label>
            <mat-select formControlName="tags"
                        multiple>
                <mat-select-trigger>
                    {{searchForm.controls.tags.value?.length ? (searchForm.controls.tags.value | getTagsNames :
                    userTags) : ''}}
                </mat-select-trigger>
                <mat-option *ngFor="let tag of userTags"
                            [value]="tag.id">
                    <div class="user-tag">
                        <mat-icon color="accent"
                                  [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                                  *ngIf="tag.type.id === 2">grid_on</mat-icon>
                        <mat-icon color="accent"
                                  [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                                  *ngIf="tag.type.id === 3">insert_chart</mat-icon>
                        <span> {{tag.name}} </span>
                    </div>
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <button type="submit"
            mat-raised-button
            color="accent"
            [disabled]="searchForm.invalid || isLoading">
        <!-- <mat-spinner [diameter]="24" *ngIf="isLoading"></mat-spinner> -->
        <!-- <mat-icon aria-label="Search" *ngIf="!isLoading">search</mat-icon> -->
        <mat-icon aria-label="Search">search</mat-icon>
        {{'scenarios.events.toolbar.search.fullLabel' | translate}}
    </button>
</form>
<!-- TOGGLE DISPLAY EVENT CREATED -->
<mat-checkbox class="display-created"
              [disableRipple]="true"
              (change)="toggleCreatedEvents($event)"
              *ngIf="(!isEditingMultiple && createdEventsCount > 0) && mode === eventsTableMode.all && appRights.scenario.canAddEvents">
    {{'scenarios.events.toolbar.displayCreatedEvents.fullLabel' | translate}}
</mat-checkbox>
<!-- TOGGLE DISPLAY EVENT DELETED -->
<mat-checkbox [disableRipple]="true"
              [checked]="isDeletedDisplayed"
              (change)="toggleDeletedEvents($event)"
              *ngIf="!isEditingMultiple && (mode === eventsTableMode.all || mode === eventsTableMode.restricted) && appRights.scenario.canViewDeletedEvents">
    {{'scenarios.events.toolbar.displayDeletedEvents.fullLabel' | translate}}
</mat-checkbox>
