import { Component, Input, ViewEncapsulation, Output, EventEmitter, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { NavLink } from '@classes/nav-link';

@Component({
    selector: 'app-main-nav-item',
    templateUrl: './main-nav-item.component.html',
    styleUrls: ['./main-nav-item.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MainNavItemComponent implements OnInit {
    @Input() link: NavLink;
    @Output() closeSidenav = new EventEmitter<void>();

    isActive: boolean;

    constructor(
        private router: Router
    ) { }

    ngOnInit(): void {
        this.initSubsciption();
    }

    onCloseSidenav(): void {
        this.closeSidenav.emit();
    }

    private initSubsciption(): void {
        this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                if (this.link.children?.length) {
                    const urlSegments = event.url.split('/');
                    const linkSegments = this.link.route.split('/');
                    if (urlSegments[1] === linkSegments[1]) {
                        this.isActive = true;
                    } else {
                        this.isActive = false;
                    }
                }
            }
        });
    }

}
