import { Agent } from '@classes/agent/agent';
import { ContractMotif } from './contract-motif';
import { ContractState } from './contract-state';
import { EmployerCategory } from './employer-category';
import { Fte } from './fte';
import { WorkerCategory } from './worker-category';

export class Contract {
    agent?: Agent;
    contractStates?: ContractState[];
    docNumber?: string;
    employerCategory?: EmployerCategory;
    endDate?: string;
    endMotif?: ContractMotif;
    fte?: Fte[];
    id?: number;
    isOpen?: boolean;
    lastDueMonth?: string;
    startDate?: string;
    startMotif?: ContractMotif;
    workerCategory?: WorkerCategory;

    constructor()
    constructor(
        agent?: Agent,
        contractStates?: ContractState[],
        docNumber?: string,
        employerCategory?: EmployerCategory,
        endDate?: string,
        endMotif?: ContractMotif,
        fte?: Fte[],
        id?: number,
        isOpen?: boolean,
        lastDueMonth?: string,
        startDate?: string,
        startMotif?: ContractMotif,
        workerCategory?: WorkerCategory
    ) {
        this.agent = agent
        this.contractStates = contractStates
        this.docNumber = docNumber
        this.employerCategory = employerCategory
        this.endDate = endDate
        this.endMotif = endMotif
        this.fte = fte
        this.id = id
        this.isOpen = isOpen
        this.lastDueMonth = lastDueMonth
        this.startDate = startDate
        this.startMotif = startMotif
        this.workerCategory = workerCategory
    }

    static fromObject(object: Contract): Contract {
        if (object)
            return Object.assign(new Contract(), object);
        return;
    }

    get display(): string {
        return `${this.docNumber}`;
    }

    doesInclude(value: string): boolean {
        return this.some([this.docNumber], value);
    }

    protected some(props: string[], value: string): boolean {
        return props.some(prop => prop.toLowerCase().includes(value));
    }
}
