import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { StoryService } from '@services/story.service';
import { WorkflowService } from '@services/workflow.service';

import { Story } from '@classes/scenario/story';
import { WorkflowExecution } from '@classes/workflow/workflow-execution';
import { ScenarioService } from '@services/scenario.service';
import { Scenario } from '@classes/scenario/scenario';
import { WorkflowType } from '@enums/workflow-type.enum';
import { DateTime } from 'luxon';

@Injectable()
export class StoryLineResolver implements Resolve<{stories: Story[], collectedMonths: WorkflowExecution[], scenarios: Scenario[]}> {
    constructor(
        private uiSrv: UiService,
        private storySrv: StoryService,
        private workflowSrv: WorkflowService,
        private scenarioSrv: ScenarioService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{stories: Story[], collectedMonths: WorkflowExecution[], scenarios: Scenario[]}> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return forkJoin([
            this.storySrv.getAll(),
            this.workflowSrv.getList(WorkflowType.collect),
            this.scenarioSrv.getAll()
        ]).pipe(
            map(([stories, collectedMonths, scenarios]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return {stories, collectedMonths, scenarios}
            })
        )

        // return this.storySrv.getAll()
        //     .pipe(
        //         tap(() => {
        //             this.uiSrv.routeLoadingStateChanged.next(false);
        //         })
        //     );
    }
}
