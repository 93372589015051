import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from '@services/format.service';

@Pipe({
    name: 'formatAmount'
})
export class FormatAmountPipe implements PipeTransform {

    constructor( private formatSrv: FormatService) {}

    transform(inputValue: string | number, mode?: string, base = null, doesShowCurrency = true): string {
        let value: number;
        if(inputValue == null || inputValue === "") {
            return "";
        }
        value = inputValue as number;

        return this.formatSrv.formatAmount(value, mode, base, doesShowCurrency);
    }

}
