import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

import { environment } from '@environments/environment';

import { User } from '@classes/user/user';
import { AuthService } from './auth.service';
import { Client } from '@classes/client';
import { UiService } from './ui.service';
import { ReferenceMonthService } from './reference-month.service';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private usersSubject = new BehaviorSubject<User[]>([]);
    private baseUrl = environment.apiUrl + 'user';

    constructor(
        private http: HttpClient,
        private authSrv: AuthService,
        private uiSrv: UiService,
        private refMonthSrv: ReferenceMonthService,
        private router: Router
    ) { }

    get users$(): Observable<User[]> {
        return this.usersSubject.asObservable();
    }

    get users(): User[] {
        return this.usersSubject.getValue();
    }

    set users(users: User[]) {
        this.usersSubject.next(users);
    }

    getList(): Observable<User[]> {
        return this.http.get<User[]>(this.baseUrl)
            .pipe(
                tap(usersFromApi => {
                    this.users = usersFromApi;
                })
            );
    }

    post(user: User): Observable<User> {
        return this.http.post<User>(this.baseUrl, user)
            .pipe(
                tap(createdUser => {
                    this.users = [...this.users, createdUser];
                    this.showEmailSentMsg();
                })
            );
    }

    put(user: User): Observable<User> {
        return this.http.put<User>(this.baseUrl, user)
            .pipe(
                tap(editedUser => {
                    const users = this.users
                        .map(u => u.id === editedUser.id ? editedUser : u);
                    this.users = [...users];
                })
            );
    }

    setStatus(user: User, isEnabled: boolean): Observable<void> {
        const url = `${this.baseUrl}/enable-or-disable/${user.id}`;

        const httpParams = new HttpParams()
            .append('isEnabled', isEnabled.toString());

        return this.http.put<void>(url, null, { params: httpParams })
            .pipe(
                tap(() => {
                    const users = this.usersSubject.getValue().map(u => {
                        if (u.id === user.id) {
                            u.isEnabled = isEnabled;
                        }
                        return u;
                    });
                    this.usersSubject.next([...users]);
                })
            );
    }

    setClient(client: Client, userId?: number): Observable<void> {
        let url = this.baseUrl;

        if (userId != null) {
            url += `/${userId}`;
        }

        url += `/client/${client.id}`;

        return this.http.put<void>(url, null)
            .pipe(
                tap(() => {
                    this.authSrv.refreshToken().subscribe();
                    this.refMonthSrv.getRefMonth().subscribe();
                    this.refMonthSrv.getLastNormalized().subscribe();
                    // reload page to update translation
                    const headers = new HttpHeaders()
                        .set('Cache-Control', 'no-cache')
                        .set('Pragma', 'no-cache');
                    this.http
                        .get("", { headers, responseType: "text" })
                        .pipe(delay(100))
                        .subscribe(() => {
                            this.router.navigate(['/home']).then(() => location.reload());
                        });
                })
            );
    }

    private showEmailSentMsg(isEmailSent = true): void {
        if (isEmailSent) {
            this.uiSrv.showSnackbar('licences.users.emailSent.fullLabel', true, 5000);
        }
    }
}
