<form [formGroup]="filterForm"
      (ngSubmit)="onSubmit()"
      class="prestation-filter"
      *ngIf="filterForm">
    <app-autocomplete [control]="getControl('contractId')"
                      [list]="contracts"
                      [label]="'agent.agentDetail.prestations.contract.shortLabel' | translate"></app-autocomplete>

    <app-month-selector [label]="'agent.agentDetail.prestations.dueMonth.shortLabel' | translate"
                        [control]="getControl('dueMonth')"></app-month-selector>

    <mat-form-field>
        <mat-label> {{'agent.agentDetail.prestations.periodStartdate.shortLabel' | translate}} </mat-label>
        <input matInput
               [matDatepicker]="periodStartdate"
               formControlName="periodStartdate"
               placeholder="Ex: 01/12/2019">
        <mat-datepicker-toggle matSuffix
                               [for]="periodStartdate"></mat-datepicker-toggle>
        <mat-datepicker #periodStartdate
                        panelClass="month-picker"></mat-datepicker>
    </mat-form-field>

    <mat-form-field>
        <mat-label> {{'agent.agentDetail.prestations.periodEnddate.shortLabel' | translate}} </mat-label>
        <input matInput
               [matDatepicker]="periodEnddate"
               formControlName="periodEnddate"
               placeholder="Ex: 01/12/2019">
        <mat-datepicker-toggle matSuffix
                               [for]="periodEnddate"></mat-datepicker-toggle>
        <mat-datepicker #periodEnddate
                        panelClass="month-picker"></mat-datepicker>
    </mat-form-field>

    <app-autocomplete [control]="getControl('prestationTypeId')"
                      [list]="prestationTypes"
                      [label]="'agent.agentDetail.prestations.prestationType.shortLabel' | translate"></app-autocomplete>
    <div>
        <button type="submit"
                mat-raised-button> {{'shared.search.fullLabel' | translate}} </button>
        <button type="button"
                mat-icon-button
                (click)="resetFilter()">
            <mat-icon>refresh</mat-icon>
        </button>
    </div>
</form>