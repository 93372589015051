import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { environment } from '@environments/environment';

import { EventOrigin } from '@classes/scenario/event-origin';
import { map, tap } from 'rxjs/operators';
import { InitialOrigin } from '@classes/scenario/initial-origin';
import { EventPreviewSummary } from '@classes/scenario/event-preview-summary';
import { UiService } from './ui.service';
import { ForecastEventService } from './forecast-event.service';

@Injectable({
  providedIn: 'root'
})
export class EventOriginService {
  private baseUrl = environment.apiUrl + 'event-origin';

  originsSubject = new BehaviorSubject<EventOrigin[]>([]);
  initialOriginsSubject = new BehaviorSubject<InitialOrigin[]>([]);

  constructor(
    private http: HttpClient,
    private uiSrv: UiService,
    private forecastEventSrv: ForecastEventService
  ) { }

  get(scenarioId: number, strategyId?: number): Observable<EventOrigin[]> {
    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('strategyId', `${strategyId}`);
    if (params.get('strategyId') === 'undefined') {
      params = params.delete('strategyId');
    }

    return this.http.get<EventOrigin[]>(`${this.baseUrl}`, { params });
  }

  put(originId: number, label: string): Observable<EventOrigin> {
    return this.http.put<EventOrigin>(`${this.baseUrl}/${originId}/label`, label);
  }

  delete(scenarioId: number, originId: number): Observable<EventPreviewSummary> {
    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`)
      .append('originId', `${originId}`);

    return this.http.delete<EventPreviewSummary>(`${this.baseUrl}`, { params })
      .pipe(
        tap((result: EventPreviewSummary) => {
          if (result?.invalidEvents?.length) {
            this.uiSrv.showSnackbar('scenarios.events.previewSummary.validationError.summary', true);
            this.forecastEventSrv.isPreviewingEvents.next(true);
            this.forecastEventSrv.eventPreviewSummary.next(result);
          }
        })
      );
  }

  getInitialOrigins(scenarioId: number): Observable<any[]> {
    let params = new HttpParams()
      .append('scenarioId', `${scenarioId}`);

    return this.http.get<string[]>(`${environment.apiUrl}initial-event-origin`, { params })
      .pipe(
        map((initialOrigins: string[]) => {
          const origins: InitialOrigin[] = [];
          initialOrigins.forEach((origin: string) => {
            const o = { label: origin } as InitialOrigin;
            origins.push(InitialOrigin.fromObject(o));
          });
          return origins;
        })
      );
  }

}
