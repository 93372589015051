import { Pipe, PipeTransform } from '@angular/core';

import { FormatService } from '@services/format.service';

@Pipe({
    name: 'diffPercent'
})
export class DiffPercentPipe implements PipeTransform {

    constructor(private formatSrv: FormatService) { }

    transform(values: number[]): string {
        if (values == null || values.length === 0) {
            return "";
        }

        const diff = 100 - (values[0] / values[1]) * 100;
        return this.formatSrv.formatAmount(diff, 'fixed', 1, false);
    }

}
