import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { Contract } from '@classes/contract/contract';

@Injectable({
  providedIn: 'root'
})
export class VirtualContractService {
  private baseUrl = environment.apiUrl + 'virtual-contract'

  constructor(
    private http: HttpClient
  ) { }

  get(id: number): Observable<Contract> {
    return this.http.get<Contract>(`${this.baseUrl}/${id}`);
  }

  put(contract: Contract, scenarioId: number): Observable<Contract> {
    let body = {
      scenarioId
    };
    Object.keys(contract).forEach(key => body[key] = contract[key]);
    return this.http.put<Contract>(`${this.baseUrl}`, body);
  }

}
