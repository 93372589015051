import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ChangePwdGuard } from 'app/shared/guards/change-pwd.guard';

import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { unauthGuard } from 'app/shared/guards/unauth.guard';


const routes: Routes = [
    {
        path: 'login', component: LoginComponent, canActivate: [unauthGuard] },
    {
        path: 'forgot-password', component: ForgotPasswordComponent },

    {
        path: 'welcome', component: ChangePasswordComponent,
        canActivate: [ChangePwdGuard]
    },
    {
        path: 'change-password', component: ChangePasswordComponent,
        canActivate: [ChangePwdGuard]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }
