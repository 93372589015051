export enum ScenarioStatus {
    outdated = 'Outdated', // 1
    inProgress = 'Inprogress', // 2
    upToDate = 'Uptodate', // 3
    failed = 'Failed', // 4
    invalid = 'Invalid', // 5
    blocked = 'Blocked', // 6
    pendingCompute = 'PendingCompute', // 7
    nearUpTodate = 'NearUptodate', // 8
    reconciliation = 'Reconciliation' // 9
}
