import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

import { FormatService } from '@services/format.service';

import { ProfessionalStatus } from '@classes/contract/professional-status';

@Component({
    selector: 'app-event-status-autocomplete',
    templateUrl: './event-status-autocomplete.component.html',
    styleUrls: ['./event-status-autocomplete.component.scss']
})
export class EventStatusAutocompleteComponent implements OnInit {
    @Input() label: string;
    @Input() multiple: boolean;
    @Input() group: UntypedFormGroup;
    @Input() controlName: string;
    @Input() status: ProfessionalStatus[];
    @Input() required: boolean;

    filteredStatus: ProfessionalStatus[];

    searchControl: UntypedFormControl;

    constructor(
        private formatSrv: FormatService
    ) { }

    ngOnInit(): void {
        this.filteredStatus = this.status;
        this.setValidators();
        if (this.multiple) {
            this.searchControl = new UntypedFormControl();
            this.observeMultipleControl();
        } else {
            this.observeControl();
        }
    }

    displayStatus(status?: ProfessionalStatus): string {
        return status ? status.code : '';
    }

    onAutoCompleteFocus(): void {
        this.filteredStatus = this.status.slice();
    }

    private setValidators(): void {
        if (this.required) {
            this.group.controls[this.controlName].setValidators([Validators.required, this.formatSrv.forbiddenTypeValidator('string')]);
        } else {
            this.group.controls[this.controlName].setValidators(this.formatSrv.forbiddenTypeValidator('string'));
        }
        this.group.updateValueAndValidity({emitEvent: false});
    }

    private _filter(code: string): ProfessionalStatus[] {
        const filterValue = code.toLowerCase();
        return this.status.filter(status => {
            if (status.code) {
                return status.code.toLowerCase().indexOf(filterValue) !== -1;
            }
        });
    }

    private observeControl(): void {
        this.group.get(this.controlName).valueChanges
            .pipe(
                startWith(''),
                map(value => {
                    if (value) {
                        return typeof value === 'string' ? value : value.code;
                    }
                }),
                map(code => code ? this._filter(code) : this.status.slice())
            ).subscribe(status => this.filteredStatus = status);
    }

    private observeMultipleControl(): void {
        this.searchControl.valueChanges
            .subscribe(searchValue => {
                const filterValue = searchValue.toLowerCase();
                this.filteredStatus = this.status
                    .filter(status => status.code.toLowerCase().indexOf(filterValue) !== -1);
            })
    }

    clearSearch() {
        this.searchControl.setValue('');
        this.filteredStatus = this.status.slice();
    }
}
