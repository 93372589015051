import { Component, Input, OnInit } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { animate, state, style, transition, trigger } from '@angular/animations';

import { ReferenceSituation } from '@classes/contract/reference-situation';
import { Agent } from '@classes/agent/agent';
import { thotAnimations } from 'app/shared/animations/thot-animations';

@Component({
    selector: 'app-ref-situation',
    templateUrl: './ref-situation.component.html',
    styleUrls: ['./ref-situation.component.scss'],
    animations: thotAnimations
})
export class RefSituationComponent implements OnInit {
    @Input() refSituation: ReferenceSituation;
    @Input() indexValue: number;

    dataSource: MatTableDataSource<ReferenceSituation>;
    displayedColumns = [
        'agent',
        'status',
        'startDate',
        'endDate',
        'dueMonth',
        'fte',
        'scale'
    ];
    isExpanded: boolean;

    constructor() { }

    ngOnInit() {
        this.refSituation.agent = Agent.fromObject(this.refSituation.agent);
        this.initTable();
    }

    private initTable(): void {
        this.dataSource = new MatTableDataSource<ReferenceSituation>([this.refSituation])
    }

}
