import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
export class FormatService {

    constructor(
        private translateSrv: TranslateService
    ) { }

    forbiddenTypeValidator(forbiddenType: string): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const forbidden = control.value !== '' && typeof control.value === forbiddenType;
            return forbidden ? { forbiddenType: { value: control.value } } : null;
        };
    }

    encryptWikiLink(text: string): string {
        return btoa(unescape(encodeURIComponent(this.translateSrv.instant(text))));
    }

    formatAmount(value: number, mode: string = 'rounded', base?: number, doesShowCurrency = true): string {
        const currency = doesShowCurrency ? '€' : '';

        if(value == null || isNaN(value)) {
            return "";
        }

        switch(mode) {
            case 'auto' :
                return this.formatAuto(value) + currency;
            case 'fixed' :
                return this.formatFixe(value, base) + currency;
            case 'rounded':
            default :
                return this.localiseNumber(Math.round(value)) + currency;
        }
    }

    formatPercent(value: number, offset = 0): string {
        if(value == null || isNaN(value) || isNaN(offset)) {
            return "";
        }

        return this.localiseNumber(this.precision(((value + offset) * 100), 2)) + " %";
    }

    precision(value: number, precision: number): number {
        if(value == null || isNaN(value)) {
            return 0;
        }

        const factor = Math.pow(10, precision);
        return Math.round(value * factor) / factor;
    }

    // Calculate the shorten value and add corresponding symbol
    private formatAuto(value: number): string {
        if(Math.abs(value) < 1000) {
            return this.precision(value, 2).toString();
        }

        const symbols = ["K", "M", "B"];
        let i = 0;

        while (Math.abs(value) >= 1000 && i < symbols.length) {
            value /= 1000;

            i++;
        }

        return this.formatFixe(value) + " " + symbols[i - 1];
    }

    // Calculate the value multiply by base with " " between thousands
    private formatFixe(value: number, base: number = 1): string {
        return this.localiseNumber(this.precision(value * base, 2));
    }

    private localiseNumber(value: number): string {
        return value.toString().replace(/[.]/, ",").replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        // return number.toLocaleString('de-DE');
    }
}
