import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ProfessionalStatus } from '@classes/contract/professional-status';
import { FunctionalArticle } from '@classes/scenario/functional-article';
import { FunctionalArticleRepartition } from '@classes/scenario/functional-article-repartition';
import { Scale } from '@classes/scenario/scale';

@Component({
    selector: 'app-fixed-term-contract',
    templateUrl: './fixed-term-contract.component.html',
    styleUrls: ['./fixed-term-contract.component.scss']
})
export class FixedTermContractComponent implements OnInit {
    @Input() group: UntypedFormGroup;
    @Input() professionalStatus: ProfessionalStatus[];
    @Input() scalesPayment: Scale[];
    @Input() articlesFunctional: FunctionalArticle[];

    constructor() { }

    ngOnInit(): void {
        this.initForm();
        this.group.markAllAsTouched();
    }

    onArticleRepartition(articlesRepartition: FunctionalArticleRepartition[]): void {
        this.group.controls.newFunctionalArticleRepartition.setValue(articlesRepartition);
    }

    private initForm(): void {
        this.group.addControl('durationInMonth', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newFte', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newProfessionalStatus', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newFunctionalArticleRepartition', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newScalePayment', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newSuperiorScalePayment', new UntypedFormControl(null));
        this.group.addControl('newParallelScalePayment', new UntypedFormControl(null));
        this.group.addControl('newSeniority', new UntypedFormControl(null, [Validators.required]));
        this.group.addControl('newSalaryBase', new UntypedFormControl(null));
    }

}
