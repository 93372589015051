import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MAT_LUXON_DATE_ADAPTER_OPTIONS } from '@angular/material-luxon-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DateTimeFormat } from '@classes/date-time-format';
import { ShowErrorOnChanges } from '@classes/show-error-on-changes';
import { OperatorValue } from '@interfaces/operator-value';
import { DateTime } from 'luxon';

const MONTH_FORMATS = {
    parse: {
        dateInput: 'MM/yyyy',
    },
    display: {
        dateInput: 'MM/yyyy',
        monthYearLabel: 'MMM yyyy',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM yyyy',
    }
};

@Component({
    selector: 'app-month-selector',
    templateUrl: './month-selector.component.html',
    styleUrls: ['./month-selector.component.scss'],
    providers: [
        { provide: MAT_DATE_FORMATS, useValue: MONTH_FORMATS },
        { provide: MAT_LUXON_DATE_ADAPTER_OPTIONS, useValue: { strict: true } }
    ]
})
export class MonthSelectorComponent implements OnInit {
    @Input() label: string;
    @Input() group: UntypedFormGroup;
    @Input() controlName: string;
    @Input() control: UntypedFormControl;
    @Input() operator: UntypedFormControl;
    @Input() required: boolean;
    @Input() min: DateTime;
    @Input() max: DateTime;

    minDate: string;
    maxDate: string;
    operators: OperatorValue[] = [
        {
            label: '=',
            value: '=='
        },
        {
            label: '≠',
            value: '!='
        },
        {
            label: '<=',
            value: '<='
        },
        {
            label: '>=',
            value: '>='
        },
        {
            label: '<',
            value: '<'
        },
        {
            label: '>',
            value: '>'
        }
    ];

    showErrorOnChanges = new ShowErrorOnChanges();

    constructor( ) { }

    ngOnInit(): void {
        if (this.min) {
            this.minDate = this.min.minus({ months: 1 }).toFormat(DateTimeFormat.monthSlash);
        }
        if (this.max) {
            this.maxDate = this.max.plus({ months: 1 }).toFormat(DateTimeFormat.monthSlash);
        }
    }

    onMonthSelected(normalizedMonth: DateTime, datepicker: MatDatepicker<DateTime>) {
        if (this.controlName) {
            this.group.get(this.controlName).setValue(normalizedMonth);
            this.group.markAsDirty();
        } else {
            this.control.setValue(normalizedMonth);
            this.control.markAsDirty();
        }
        datepicker.close();
    }

}
