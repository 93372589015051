import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { TextService } from '@services/text.service';

import { UiItem } from '@classes/bizzdev/text/ui-item';

@Injectable()
export class UiItemsResolver implements Resolve<UiItem[]> {
  constructor(
    private uiSrv: UiService,
    private textSrv: TextService

  ) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UiItem[]> {
    this.uiSrv.routeLoadingStateChanged.next(true);
    return this.textSrv.getUiItems()
      .pipe(
        tap(() => this.uiSrv.routeLoadingStateChanged.next(false))
      );
  }
}
