import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'app/shared/guards/auth.guard';
import { ScenarioResolver } from 'app/shared/resolvers/scenario.resolver';

import { Roles } from '@enums/profile/roles';
import { AgentComponent } from './agent.component';

const routes: Routes = [
    {
        path: 'agent', component: AgentComponent,
        canActivate: [AuthGuard],
        resolve: {
            scenario: ScenarioResolver
        },
        data: {
            roles: [
                Roles.visuAgent,
                Roles.administrateurClient,
                Roles.visuClient
            ]
        }
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AgentRoutingModule { }
