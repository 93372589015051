<div class="dashboard-header" *ngIf="headers">
    <ng-container *ngFor="let header of headers">
        <section>
            <div>
                <span>
                    <!-- <mat-icon>person</mat-icon> -->
                    {{header.title}} <ng-container *ngIf="header.dates?.length">{{header.dates[1] | date: dateFormat}}</ng-container>
                    <button 
                        mat-icon-button 
                        color="accent" 
                        class="delete-header" 
                        (click)="deleteHeader(header)" 
                        [disabled]="isLoadingDefault"
                        *ngIf="editMode">
                        <mat-icon>delete</mat-icon>
                    </button>
                </span>
                <ng-container  *ngIf="header.data">
                    <p *ngIf="header.type === dataType.amount"> {{header.data[1] | formatAmount: 'fixed':1}} </p>
                    <p *ngIf="header.type === dataType.number || header.type === dataType.fte"> {{header.data[1] | formatAmount: 'fixed':1:false}} </p>
                    <div class="differences">
                        <div class="symbol">
                            <mat-icon class="negatif" *ngIf="header.data[1] - header.data[0] < 0">arrow_drop_down</mat-icon>
                            <mat-icon class="positif" *ngIf="header.data[1] - header.data[0] > 0">arrow_drop_up</mat-icon>
                        </div>
                        <span class="percent">{{header.data | diffPercent}} % </span>
                        <span class="number">
                            {{header.data | diffNumber}} <ng-container *ngIf="header.type === dataType.amount">€</ng-container> 
                        </span>
                        <div class="date"> 
                            <span>{{'dashboard.header.since.fullLabel' | translate}} {{header.dates[0] | date: dateFormat}}</span>
                        </div>
                    </div>
                </ng-container>
            </div>
        </section>
    </ng-container>
    <section *ngIf="editMode && reportsList?.length" class="add-header">
        <div>
            <ng-container>
                <mat-form-field *ngIf="!isLoading">
                    <mat-label> {{'dashboard.base.selectReport.shortLabel' | translate}} </mat-label>
                    <mat-select [formControl]="selectReport">
                        <mat-option *ngFor="let report of reportsList" [value]="report.id">
                            {{report.text.fullLabel}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>
            <mat-progress-spinner mode="indeterminate"  [diameter]="50" *ngIf="isLoading"></mat-progress-spinner>
        </div>
    </section>
</div>
