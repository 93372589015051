<form [formGroup]="group">
    <div>
        <app-input-number [label]="'scenarios.events.properties.oldFte.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'oldFte'"
                          [min]="0"
                          [max]="1"
                          [step]="0.1"
                          [required]="true"
                          *ngIf="!isIndividualEvent"></app-input-number>
        <app-input-number [label]="'scenarios.events.properties.newFte.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'newFte'"
                          [min]="0"
                          [max]="1"
                          [step]="0.1"
                          [required]="true"></app-input-number>
        <ng-container *ngIf="!isIndividualEvent">
            <app-event-status-autocomplete [label]="'scenarios.events.properties.newProfessionalStatus.shortLabel' | translate"
                                           [group]="group"
                                           [controlName]="'newProfessionalStatus'"
                                           [status]="professionalStatus"
                                           [required]="true"></app-event-status-autocomplete>
            <app-event-scales-autocomplete [label]="'scenarios.events.properties.newScalePayment.shortLabel' | translate"
                                           [group]="group"
                                           [controlName]="'newScalePayment'"
                                           [scales]="scalesPayment"
                                           [required]="true"></app-event-scales-autocomplete>
            <app-event-scales-autocomplete [label]="'scenarios.events.properties.newSuperiorScalePayment.shortLabel' | translate"
                                           [group]="group"
                                           [controlName]="'newSuperiorScalePayment'"
                                           [scales]="scalesPayment"></app-event-scales-autocomplete>
            <app-event-scales-autocomplete [label]="'scenarios.events.properties.newParallelScalePayment.shortLabel' | translate"
                                           [group]="group"
                                           [controlName]="'newParallelScalePayment'"
                                           [scales]="scalesPayment"></app-event-scales-autocomplete>
            <app-input-number [label]="'scenarios.events.properties.newSeniority.shortLabel' | translate"
                              [group]="group"
                              [controlName]="'newSeniority'"
                              [min]="0"
                              [required]="true"></app-input-number>
            <app-input-number [label]="'scenarios.events.properties.newSalaryBase.shortLabel' | translate"
                              [group]="group"
                              [controlName]="'newSalaryBase'"
                              [min]="0"></app-input-number>
        </ng-container>
    </div>
    <div *ngIf="!isIndividualEvent">
        <app-event-articles-functional [group]="group"
                                       [controlName]="'newFunctionalArticleRepartition'"
                                       [articles]="articlesFunctional"
                                       [required]="true"
                                       (articleRepartition)="onArticleRepartition($event)"></app-event-articles-functional>
        <p *ngIf="group.get('newFunctionalArticleRepartition').errors?.ratio"
           class="bad-ratio"> {{'scenarios.events.error.badRatio.summary' | translate}} </p>
    </div>
</form>