<div class="toolbar"
     *ngIf="!restrictedMode">
    <mat-form-field>
        <mat-label> {{'scenarios.events.toolbar.search.shortLabel' | translate}} </mat-label>
        <input matInput
               [formControl]="searchControl">
    </mat-form-field>
    <span *ngIf="editedEventsCount > 0 || editedCreatedEvents?.length"
          class="events-count">
        {{'scenarios.events.toolbar.modifiedEvents.fullLabel' | translate}} : {{editedEventsCount +
        editedCreatedEvents.length}} </span>
    <button mat-icon-button
            color="accent"
            (click)="onCancelEdits(false)"
            [matTooltip]="'scenarios.events.toolbar.cancelModifications.summary' | translate"
            *ngIf="editedEventsCount > 0 || editedCreatedEvents?.length">
        <mat-icon>refresh</mat-icon>
    </button>
    <span *ngIf="deletedEventsCount > 0"
          class="events-count"> {{'scenarios.events.toolbar.eventsToDelete.fullLabel' |
        translate}} : {{deletedEventsCount}} </span>
    <button mat-icon-button
            color="accent"
            (click)="onCancelEdits(true)"
            [matTooltip]="'scenarios.events.toolbar.cancelDeletion.summary' | translate"
            *ngIf="deletedEventsCount > 0">
        <mat-icon>refresh</mat-icon>
    </button>
</div>
<table mat-table
       [dataSource]="dataSource"
       multiTemplateDataRows
       matSort
       matPaginator>
    <ng-container matColumnDef="checkbox">
        <th mat-header-cell
            *matHeaderCellDef
            class="hide-checkbox"
            [ngClass]="{'show-checkbox': isEditMultiple || (filterEvents?.length && appRights.scenario.canAddScenario)}">
            <mat-checkbox [checked]="eventsSelectedForEditing === events?.length"
                          [indeterminate]="eventsSelectedForEditing > 0 && eventsSelectedForEditing < events?.length"
                          [disabled]="!events?.length"
                          (change)="toggleAllForEditing()"
                          *ngIf="isEditMultiple">
            </mat-checkbox>
        </th>
        <td mat-cell
            *matCellDef="let event;"
            class="hide-checkbox"
            [ngClass]="{'show-checkbox': isEditMultiple || (filterEvents?.length && appRights.scenario.canAddScenario)}">
            <mat-checkbox color="accent"
                          [matTooltip]="'Cocher la case pour exclure l\'évènement'"
                          [checked]="isEventFilered(event.id)"
                          [disabled]="disableReconciliarionFilter"
                          (change)="toggleEvent(event.id)"
                          *ngIf="filterEvents?.length && appRights.scenario.canAddScenario"></mat-checkbox>
            <mat-checkbox color="accent"
                          [matTooltip]="'scenarios.events.multipleEdit.toggleEditing.summary' | translate"
                          [checked]="event.isSelectedForEditing"
                          (change)="toggleEditing(event)"
                          *ngIf="isEditMultiple"></mat-checkbox>
        </td>
    </ng-container>
    <ng-container matColumnDef="agent">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.agent.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            <div>
                <button type="button"
                        mat-icon-button
                        color="accent"
                        (click)="expandEvent(event)">
                    <mat-icon>
                        {{event === selectedEvent ? 'expand_less' : 'expand_more'}}
                    </mat-icon>
                </button>
                <span *ngIf="event.agent"> {{event.agent.display}} </span>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="eventType">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.eventType.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.eventType?.text.fullLabel}}
        </td>
    </ng-container>

    <ng-container matColumnDef="status">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.professionalStatus.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.newProfessionalStatus?.code}}
        </td>
    </ng-container>

    <ng-container matColumnDef="initialEventMonth"
                  *ngIf="isReconciliation">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.initialEventMonth.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.initialEventMonth | date : 'MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="eventMonth">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.eventMonth.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.eventMonth | date : 'MM/yyyy'}}
        </td>
    </ng-container>

    <ng-container matColumnDef="newFte">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.fte.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.newFte}}
        </td>
    </ng-container>

    <ng-container matColumnDef="scale">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.scales.fullLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            <span> {{event.newScalePayment?.externalId}} </span>
            <span *ngIf="event.newParallelScalePayment && event.newParallelScalePayment?.id > 0 "> {{'{'}}
                {{event.newParallelScalePayment?.externalId}} {{'}'}} </span>
            <span *ngIf="event.newSuperiorScalePayment && event.newSuperiorScalePayment?.id > 0"> {{'['}}
                {{event.newSuperiorScalePayment?.externalId}} {{']'}} </span>
            <span *ngIf="event.newSeniority"> {{'('}} {{event.newSeniority}} {{')'}} </span>
        </td>
    </ng-container>

    <ng-container matColumnDef="origin"
                  *ngIf="!isReconciliation">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.eventOrigin.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.origin?.strategy.text.shortLabel}}
            <ng-container *ngIf="event.origin?.label">({{event.origin?.label}})</ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="initialEventOrigin"
                  *ngIf="isReconciliation">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.initialEventOrigin.fullLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event;">
            {{event.initialEventOrigin}}
        </td>
    </ng-container>

    <ng-container matColumnDef="salaryBase">
        <th mat-header-cell
            *matHeaderCellDef
            mat-sort-header>
            {{'scenarios.events.properties.salaryBase.shortLabel' | translate}}
        </th>
        <td mat-cell
            *matCellDef="let event">
            <ng-container *ngIf="event.newSalaryBase">
                <span *ngIf="(indexValue || event.indexValue) && event.fteContractual > 0; else rawSalary">
                    {{event.newSalaryBase | formatSalarayBase: indexValue ? indexValue : event.indexValue
                    :event.fteContractual}}
                </span>
                <ng-template #rawSalary>
                    <span [matTooltip]="'shared.rawSalary.summary' | translate">
                        {{event.newSalaryBase}}€
                        <span style="color:red">*</span>
                    </span>
                </ng-template>
            </ng-container>
        </td>
    </ng-container>

    <ng-container matColumnDef="actions">
        <th mat-header-cell
            *matHeaderCellDef></th>
        <td mat-cell
            *matCellDef="let event;">
            <div>
                <ng-container *ngIf="!restrictedMode && !event.isInvalid && !filterEvents?.length">
                    <button mat-icon-button
                            color="accent"
                            onclick="this.blur()"
                            [disabled]="event.deleted"
                            (click)="editEvent(event)">
                        <mat-icon *ngIf="event !== editedEvent"
                                  [matTooltip]="'scenarios.events.list.actions.edit.summary' | translate"
                                  aria-label="Edit">
                            mode_edit</mat-icon>
                        <mat-icon *ngIf="event === editedEvent && isEditing"
                                  [matTooltip]="'scenarios.events.list.actions.stopEdit.summary' | translate"
                                  aria-label="Close">close</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="accent"
                            onclick="this.blur()"
                            [disabled]="!event.oldEvent"
                            [matTooltip]="'scenarios.events.list.actions.reset.summary' | translate"
                            (click)="resetEvent(event)">
                        <mat-icon>refresh</mat-icon>
                    </button>
                    <button mat-icon-button
                            color="accent"
                            onclick="this.blur()"
                            [matTooltip]="'scenarios.events.list.actions.delete.summary' | translate"
                            [disabled]="event.deleted"
                            (click)="onDelete(event)">
                        <mat-icon aria-label="Delete">delete</mat-icon>
                    </button>
                </ng-container>
                <button *ngIf="event.userComment"
                        mat-icon-button
                        color="accent"
                        [disableRipple]="true"
                        [matTooltip]="event.userComment">
                    <mat-icon>comment</mat-icon>
                </button>
                <button *ngIf="event.systemComment"
                        mat-icon-button
                        color="accent"
                        [disableRipple]="true"
                        [matTooltip]="event.systemComment">
                    <mat-icon>build</mat-icon>
                </button>
                <button *ngIf="event.manuallyModified"
                        mat-icon-button
                        color="accent"
                        [disableRipple]="true"
                        [matTooltip]="'scenarios.events.list.actions.manuallyModified.summary' | translate">
                    <mat-icon>accessibility</mat-icon>
                </button>
                <button *ngIf="event.rule"
                        mat-icon-button
                        color="warn"
                        [disableRipple]="true"
                        [matTooltip]="event.rule.text.detail">
                    <mat-icon>error</mat-icon>
                </button>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="tags">
        <td mat-cell
            *matCellDef="let event"
            [attr.colSpan]="displayedColumns.length">
            <div class="tags">
                <div *ngFor="let tag of event.userTags"
                     class="tag"
                     [style.backgroundColor]="tag.color">
                    <mat-icon [matTooltip]="'parameters.tags.reportTag.shortLabel' | translate"
                              *ngIf="tag?.type?.id === 2">grid_on</mat-icon>
                    <mat-icon [matTooltip]="'parameters.tags.estimationTag.shortLabel' | translate"
                              *ngIf="tag?.type?.id === 3">insert_chart</mat-icon>
                    <span> {{tag.name}} </span>
                </div>
            </div>
        </td>
    </ng-container>

    <ng-container matColumnDef="expandedDetail">
        <td mat-cell
            *matCellDef="let event"
            [attr.colSpan]="displayedColumns.length">
            <div class="event-expanded"
                 *ngIf="event === selectedEvent || event === editedEvent"
                 @expandCollapse>
                <app-event-detail [event]="event"
                                  [isEdited]="event === editedEvent ? true : false"
                                  [isInvalidEvent]="isInvalidEvents"
                                  [mode]="mode"
                                  [indexValue]="indexValue"
                                  (editedEvent)="onEditEvent(event)"
                                  (deleteContractEvents)="deleteContractEvents($event)"></app-event-detail>
            </div>
        </td>
    </ng-container>

    <tr mat-header-row
        *matHeaderRowDef="isReconciliation ? reconciliationDisplayedColumns: displayedColumns"></tr>
    <tr mat-row
        *matRowDef="let event; columns: ['tags']"
        class="tags-events"
        [ngClass]="{'expanded-row': selectedEvent === event || event === editedEvent, 'no-tags': !event.userTags?.length}">
    </tr>
    <tr mat-row
        *matRowDef="let event; columns: isReconciliation ? reconciliationDisplayedColumns: displayedColumns"
        class="row-event"
        [class.expanded-row]="selectedEvent === event"
        [ngClass]="{'edited': event.oldEvent, 'deleted': event.deleted, 'invalid': event.isInvalid}"></tr>
    <tr mat-row
        *matRowDef="let event; columns: ['expandedDetail']"
        class="detail-event"></tr>
</table>
<mat-paginator showFirstLastButtons
               [pageSize]="15"
               [hidePageSize]="true"></mat-paginator>