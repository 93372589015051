import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@environments/environment';

import { ScaleAmountEvolutionGroup } from '@classes/parameters/scale-amount/scale-amount-evolution-group';

@Injectable({
    providedIn: 'root'
})
export class ScaleAmountEvolutionGroupService {
    private baseUrl = `${environment.apiUrl}scale-amount-evolution-group`;

    constructor(
        private http: HttpClient
    ) { }

    get(): Observable<ScaleAmountEvolutionGroup[]> {
        return this.http.get<ScaleAmountEvolutionGroup[]>(this.baseUrl);
    }

    put(scaleAmountEvolutionGroup: ScaleAmountEvolutionGroup): Observable<ScaleAmountEvolutionGroup> {
        return this.http.put<ScaleAmountEvolutionGroup>(`${this.baseUrl}`, scaleAmountEvolutionGroup);
    }

    post(scaleAmountEvolutionGroup: ScaleAmountEvolutionGroup): Observable<ScaleAmountEvolutionGroup> {
        return this.http.post<ScaleAmountEvolutionGroup>(`${this.baseUrl}`, scaleAmountEvolutionGroup);
    }

    getOne(id: number): Observable<ScaleAmountEvolutionGroup> {
        return this.http.get<ScaleAmountEvolutionGroup>(`${this.baseUrl}/${id}`);
    }

    delete(groupId: number): Observable<any> {
        return this.http.delete<any>(`${this.baseUrl}/${groupId}`)
    }

}
