import { Component, Input, OnChanges } from '@angular/core';

import { UiService } from '@services/ui.service';

import { Contract } from '@classes/contract/contract';
import { DateTimeFormat } from '@classes/date-time-format';
import { DateTime } from 'luxon';
@Component({
    selector: 'app-detail-events-timeline',
    templateUrl: './detail-events-timeline.component.html',
    styleUrls: ['./detail-events-timeline.component.scss']
})
export class DetailEventsTimelineComponent implements OnChanges {
    @Input() contracts: Contract[];
    @Input() refMonth: string;

    years: number[] = [];

    dateFormat = DateTimeFormat.fullYear;

    constructor(
        private uiSrv: UiService
    ) { }

    ngOnChanges(): void {
        this.years = [];
        this.setYears();
    }

    getDates(contract: Contract): string {
        const startDate = DateTime.fromISO(contract.startDate).toFormat(DateTimeFormat.fullYear);
        const endDate = DateTime.fromISO(contract.endDate).year === 9999 ? '...' : DateTime.fromISO(contract.endDate).toFormat(DateTimeFormat.fullYear);

        return `${startDate} - ${endDate}`;
    }

    nextYear(): void {
        this.years.shift();
        this.years.push(this.years[this.years.length - 1] + 1);
        this.uiSrv.timelineChanged.next();
    }

    previousYear(): void {
        this.years.pop();
        this.years.unshift(this.years[0] - 1);
        this.uiSrv.timelineChanged.next();
    }

    private setYears(): void {
        const startYear = DateTime.fromISO(this.refMonth).year - 1;
        const endYear = startYear + 4;

        for (let i = startYear; i <= endYear; i++) {
            this.years.push(i);
        }
    }
}
