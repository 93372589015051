export enum EventTypeId {
    DECFTE = 3, // Réduction temps de travail
    INCFTE, // Augmentation temps de travail
    OUTUNKNOWN = 9, // Départ définitif
    INUNKNOWN, // Entrée en service
    PENSIONUNKNOWN, // Pension
    OUTHOLIDAY, // Sortie temporaire
    INHOLIDAY, // Retour de congé
    PROSTAT, // Changement d'échelle
    PROSCALE = 16, // Avancement barémique
    PROSTATFF = 20, // Changement d'échelle FF
    CDD, // Contrat à durée déterminée
    BACHANGE = 23, // Changement d'affectation budgetaire
    PROSTATPARA = 26, // Changement d'échelle //
    STATUSCHANGE, // Changement de statut
    SALARYCHANGE // Changement de salaire
}
