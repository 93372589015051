import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot
} from '@angular/router';
import { Observable, forkJoin } from 'rxjs';

import { CotisationRateService } from '@services/cotisation-rate.service';

import { ConjoncturalParameters } from '@interfaces/conjonctural-parameters';
import { CotisationCodeService } from '@services/cotisation-code.service';
import { UiService } from '@services/ui.service';
import { map } from 'rxjs/operators';
import { WorkerCategoryService } from '@services/worker-category.service';
import { EmployerCategoryService } from '@services/employer-category.service';

@Injectable({
    providedIn: 'root'
})
export class ConjoncturalParametersResolver implements Resolve<ConjoncturalParameters> {
    constructor(
        private uiSrv: UiService,
        private cotisationCodeSrv: CotisationCodeService,
        private cotisationRateSrv: CotisationRateService,
        private workerCategorySrv: WorkerCategoryService,
        private employerCategorySrv: EmployerCategoryService
    ) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ConjoncturalParameters> {

        // this.workerCategorySrv.get().subscribe((categoriesFromSrv: WorkerCategory[]) => {
        //     categoriesFromSrv.forEach(category => {
        //         if (category.id > 0) {
        //             this.workerCategories.push(WorkerCategory.fromObject(category));
        //         }
        //     });
        // });

        // this.employerCategorySrv.get().subscribe((categoriesFromSrv: EmployerCategory[]) => {
        //     if (categoriesFromSrv.length === 2) {
        //         this.employerCategories = categoriesFromSrv;
        //     } else {
        //         categoriesFromSrv.forEach(category => {
        //             if (category.id > 0) {
        //                 this.employerCategories.push(category);
        //             }
        //         });
        //     }
        // });

        this.uiSrv.routeLoadingStateChanged.next(true);
        return forkJoin([
            this.cotisationCodeSrv.get(),
            this.cotisationRateSrv.get(),
            this.employerCategorySrv.get(),
            this.workerCategorySrv.get()
        ]).pipe(
            map(([codes, rates, employerCategories, workerCategories]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                const params: ConjoncturalParameters = {
                    cotisationCodes: codes,
                    cotisationRates: {
                        employerCategories,
                        rates,
                        workerCategories
                    }
                }
                return params;
            })
        )
    }
}
