import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UiService } from '@services/ui.service';
import { FilterValue } from '@classes/report/filter-value';
import { MetadataService } from '@services/metadata.service';

@Injectable()
// {reports: Report[], universes: Universe[]}
export class ReportCustomResolver implements Resolve<{scenarios: FilterValue[], statusGroup: FilterValue[]}> {
    constructor(
        private uiSrv: UiService,
        private metadataSrv: MetadataService
    ) {}

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<{scenarios: FilterValue[], statusGroup: FilterValue[]}> {
        this.uiSrv.routeLoadingStateChanged.next(true);

        return forkJoin([
            this.metadataSrv.getValues(352),
            this.metadataSrv.getValues(347)
        ]).pipe(
            map(([scenarios, statusGroup]) => {
                this.uiSrv.routeLoadingStateChanged.next(false);
                return {scenarios, statusGroup};
            })
        );
    }
}
