<form [formGroup]="group"
      *ngIf="!isIndividualEvent">
    <div>
        <app-input-number [label]="'scenarios.events.properties.oldFte.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'oldFte'"
                          [min]="0"
                          [max]="1"
                          [step]="0.1"
                          [required]="true"></app-input-number>
        <app-event-status-autocomplete [label]="'scenarios.events.properties.oldProfessionalStatus.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'oldProfessionalStatus'"
                                       [status]="professionalStatus"
                                       [required]="true"></app-event-status-autocomplete>
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.oldScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'oldScalePayment'"
                                       [scales]="scalesPayment"
                                       [required]="true"></app-event-scales-autocomplete>
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.oldSuperiorScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'oldSuperiorScalePayment'"
                                       [scales]="scalesPayment"></app-event-scales-autocomplete>
        <app-event-scales-autocomplete [label]="'scenarios.events.properties.oldParallelScalePayment.shortLabel' | translate"
                                       [group]="group"
                                       [controlName]="'oldParallelScalePayment'"
                                       [scales]="scalesPayment"></app-event-scales-autocomplete>
        <app-input-number [label]="'scenarios.events.properties.oldSeniority.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'oldSeniority'"
                          [min]="0"
                          [required]="true"></app-input-number>
        <app-input-number [label]="'scenarios.events.properties.oldSalaryBase.shortLabel' | translate"
                          [group]="group"
                          [controlName]="'oldSalaryBase'"
                          [min]="0"></app-input-number>
    </div>
    <div>
        <app-event-articles-functional [group]="group"
                                       [controlName]="'oldFunctionalArticleRepartition'"
                                       [articles]="articlesFunctional"
                                       [required]="true"
                                       (articleRepartition)="onArticleRepartition($event)">
        </app-event-articles-functional>
        <p *ngIf="group.get('oldFunctionalArticleRepartition').errors?.ratio"
           class="bad-ratio">
            {{'scenarios.events.error.badRatio.summary' | translate}} </p>
    </div>
</form>