import { Component, Input, OnInit } from '@angular/core';

import { UiService } from '@services/ui.service';

import { ReferenceSituation } from '@classes/contract/reference-situation';

@Component({
    selector: 'app-detail-ref-situation',
    templateUrl: './detail-ref-situation.component.html',
    styleUrls: ['./detail-ref-situation.component.scss']
})
export class DetailRefSituationComponent implements OnInit {
    @Input() refSituation: ReferenceSituation;
    @Input() indexValue: number;

    dateFormat = 'dd-MM-yyyy';
    colors: string[];

    constructor(
        private uiSrv: UiService
    ) { }

    ngOnInit() {
        this.colors = this.uiSrv.getColors();
    }

}
